import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import Courses from "./courses";
import Breadcrumbs from "../topbars/breadcrumbs";
import Footer from "../layout/footer";

class CoursesOverview extends Component {
  state = {};
  render() {
    return (
      <div
        id="pageCourses"
        className="page-with-topbar-and-breadcrumbs darkmode"
      >
        <MainTopbar />

        <Breadcrumbs breadcrumbs={["dashboard", "courses-active"]} />

        <Courses page="courses" />

        <Footer />
      </div>
    );
  }
}

export default CoursesOverview;
