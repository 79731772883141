import React, { Component } from "react";
import {
  Grid,
  Row,
  Cell,
  Button,
  Icon,
  SingleCellRow,
} from "../foundation/foundation";
import IndexTopBar from "../topbars/indexTopbar";
import Courses from "../courses/courses";
import PTPopup from "../ptPopup/ptPopup";
import LoginPopup from "./loginPopup";
import withContext from "../../context/contextHOC";
import Footer from "../layout/footer";

class Index extends Component {
  state = {
    popupJoinNow: true,
  };

  componentDidMount() {
    this.props.context.initAuth("index");
  }

  render() {
    return (
      <div id="pageIndex" className="page-with-topbar-and-hero">
        <IndexTopBar
          handleLoginOpen={() => {
            this.showPopupJoinNow();
          }}
        />
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24} lg={24}>
              <div id="indexHero">
                <div id="indexHeroContentWrapper">
                  <div id="indexHeroContent">
                    <Grid>
                      <Row>
                        <Cell
                          sm={24}
                          md={24}
                          lg={24}
                          classes="text-center medium-text-left"
                        >
                          <h1>
                            TPA Media
                            <br />
                            Torben Platzer
                            <br className="show-for-small-only" /> Member Area
                          </h1>
                          <h2>
                            Deine Plattform, mit der Du Dich und
                            <br className="hide-for-small-only" /> Dein Business
                            auf das nächste Level hebst!
                          </h2>
                          <div id="indexButtons">
                            <div className="hide-for-small-only">
                              <Button type="primary" size="large" href="#kurse">
                                <Icon icon="play-circle" left /> Kursübersicht
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                type="primary"
                                size="large"
                                click={() => {
                                  this.showPopupJoinNow();
                                }}
                              >
                                <Icon icon="rocket" left /> JOIN NOW!
                              </Button>
                            </div>
                            <div className="show-for-small-only">
                              <Button
                                type="primary"
                                size="medium"
                                href="#kurse"
                              >
                                <Icon icon="play-circle" left /> Kursübersicht
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                type="primary"
                                size="medium"
                                click={() => {
                                  this.showPopupJoinNow();
                                }}
                              >
                                <Icon icon="rocket" left /> JOIN NOW!
                              </Button>
                            </div>
                          </div>
                        </Cell>
                      </Row>
                    </Grid>
                  </div>
                </div>

                <div className="login-popup-wrapper">
                  <PTPopup
                    show={this.state.popupJoinNow}
                    size="small"
                    handleClose={() => {
                      this.hidePopupJoinNow();
                    }}
                  >
                    <LoginPopup
                      handleClose={() => {
                        this.hidePopupJoinNow();
                      }}
                    />
                  </PTPopup>
                </div>
              </div>
              <div id="kurse">
                <div id="indexCourses">
                  <SingleCellRow sm={24}>
                    <h2>Verfügbare Kurse</h2>
                    <div>&nbsp;</div>
                  </SingleCellRow>
                  <Courses page="index" />
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
        <Footer />
      </div>
    );
  }

  showPopupJoinNow() {
    this.setState({ popupJoinNow: true });
  }

  hidePopupJoinNow() {
    //this.setState({ popupJoinNow: false });
  }
}

export default withContext(Index);
