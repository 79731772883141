import React, { Component } from "react";
import { Grid, Row, Cell, Button, Icon } from "../foundation/foundation";
import withContext from "../../context/contextHOC";
import ForumLayout from "./forumLayout";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { Link } from "react-router-dom";

class NewThread extends Component {
  state = {
    board: {
      name: "",
      slug: ""
    },
    boardSlug: this.props.match.params.boardSlug,
    people: [],
    newThreadName: "",
    newThreadText: "",
    lastPosition: 0,
    value: "",
    suggestions: []
  };

  componentDidMount() {
    this.loadBoard();

    var getCaretCoordinates = require("textarea-caret");

    document
      .querySelector("#newThreadText")
      .addEventListener("input", function() {
        var caret = getCaretCoordinates(this, this.selectionEnd);
        /*console.log(
        "thread",
        "(top, left, height) = (%s, %s, %s)",
        caret.top,
        caret.left,
        caret.height
      );*/

        // Put Autosuggest Popup to Caret Position in textarea
        const textarea = document.getElementById("newThreadText");
        const autosuggestPopup = document.getElementById("autosuggestPopup");

        autosuggestPopup.style.top =
          parseInt(textarea.offsetTop + caret.top - 5) + "px";
        autosuggestPopup.style.left =
          parseInt(textarea.offsetLeft + caret.left) + "px";
      });
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Benutzernamen suchen",
      value,
      onChange: this.onChange
    };

    return (
      <ForumLayout
        breadcrumbs={[
          "dashboard",
          "community",
          {
            id: "board",
            to: this.props.context.generateForumBoardUrl(this.state.board.slug),
            name: this.state.board.name
          },
          { id: "new", to: undefined, name: "Neues Thema erstellen" }
        ]}
      >
        <div id="autosuggestPopup">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            highlightFirstSuggestion={true}
            inputProps={inputProps}
            ref={this.storeInputReference}
          />
        </div>
        <Grid>
          <Row>
            <Cell sm={24} md={18}>
              <h2>Neues Thema eröffnen</h2>

              <div className="box">
                <div className="box-content">
                  <label>
                    Betreff
                    <input
                      type="text"
                      id="newThreadName"
                      value={this.state.newThreadName}
                      onChange={this.handleNameChange}
                    />
                  </label>

                  <label>
                    Beitrag
                    <textarea
                      rows={14}
                      id="newThreadText"
                      value={this.state.newThreadText}
                      onChange={this.handleTextChange}
                      onClick={this.hideAutosuggestPopup}
                    />
                  </label>
                </div>
              </div>

              <div className="text-right">
                <Link
                  to={this.props.context.generateForumBoardUrl(
                    this.state.boardSlug
                  )}
                  className="hollow primary button"
                >
                  <Icon icon="times" left /> Abbrechen
                </Link>
                <Button type="primary" click={this.saveNewThread}>
                  <Icon icon="check" left /> Speichern
                </Button>
              </div>
            </Cell>
          </Row>
        </Grid>
      </ForumLayout>
    );
  }

  loadBoard() {
    const { boardSlug } = this.state;

    axios
      .get(this.props.context.apiEndpoints.forumBoard + "/" + boardSlug, {
        headers: this.props.context.headers
      })
      .then(response => {
        const board = response.data.board;
        const people = response.data.people;

        this.setState({ board, people });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }

  saveNewThread = () => {
    const { newThreadName, newThreadText, board } = this.state;

    axios
      .post(
        this.props.context.apiEndpoints.forumNewThread,
        {
          name: newThreadName,
          text: newThreadText,
          board_id: board.id
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const threadIdentifier = response.data;
        window.location = this.props.context.generateForumThreadUrl(
          this.state.boardSlug,
          threadIdentifier
        );
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  handleNameChange = event => {
    const name = event.target.value;
    this.setState({
      newThreadName: name
    });
  };

  handleTextChange = event => {
    const text = event.target.value;
    const lastPosition = event.target.selectionStart;
    const lastInputChar = event.target.value[event.target.selectionStart - 1];

    this.setState({
      newThreadText: text,
      lastPosition: lastPosition
    });

    if (lastInputChar === "@") {
      this.showAutosuggestPopup();
      this.input.focus();
    }
  };

  /**
   * BEGINN AUTO SUGGEST
   */

  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  showAutosuggestPopup() {
    const autosuggestPopup = document.getElementById("autosuggestPopup");
    autosuggestPopup.style.display = "block";
  }

  hideAutosuggestPopup() {
    const autosuggestPopup = document.getElementById("autosuggestPopup");
    autosuggestPopup.style.display = "none";
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });

    if (method === "escape") {
      this.hideAutosuggestPopup();
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, method }) => {
    const { lastPosition } = this.state;

    let { newThreadText } = this.state;

    const newText =
      newThreadText.substring(0, lastPosition) +
      suggestion.username +
      " " +
      newThreadText.substring(lastPosition);

    newThreadText = newText;

    this.setState({ newThreadText, value: "" });
    this.hideAutosuggestPopup();

    // Timeout to prevent adding new line into textarea
    setTimeout(() => {
      document.getElementById("newThreadText").focus();
    }, 50);
  };

  // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp("\\b" + escapedValue, "i");

    const { people } = this.state;

    return people.filter(person => regex.test(this.getSuggestionValue(person)));
  }

  getSuggestionValue = suggestion => {
    return `${suggestion.username}`;
  };

  renderSuggestion = (suggestion, { query }) => {
    //const suggestionText = `${suggestion.firstname} ${suggestion.lastname}`;
    const suggestionText = `${suggestion.username}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <span className={"suggestion-content " + suggestion.twitter}>
        <span className="name">
          @
          {parts.map((part, index) => {
            const className = part.highlight ? "highlight" : null;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
      </span>
    );
  };

  /**
   * ENDE AUTOSUGGEST
   */
}

export default withContext(NewThread);
