import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Icon } from "../foundation/_buttons";

class Footer extends Component {
  render() {
    const _c = this.props.context;
    const now = new Date();

    return (
      <footer>
        {this.view__showWhatsappSupport()}
        <div className="default-footer">
          <p className="text-center">
            &copy; {now.getFullYear()} TPA Media GmbH{" "}
            <span className="hide-for-small-only">|</span>
            <br className="show-for-small-only" />{" "}
            <a
              href={this.props.context.urls.impressum}
              target="_blank"
              rel="noopener noreferrer"
            >
              Impressum
            </a>{" "}
            |{" "}
            <a
              href={this.props.context.urls.datenschutz}
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutz
            </a>{" "}
            <span className="hide-for-small-only">|</span>
            <br className="show-for-small-only" /> v{_c.getVersion()}
          </p>
        </div>
      </footer>
    );
  }

  view__showWhatsappSupport() {
    const selfmadeStatus = this.props.context.getUserSelfmadeStatus();

    if (this.props.context.userHasSelfmadeVIP()) {
      return (
        <div
          className="clickable whatsapp footer text-center"
          onClick={this.props.context.showSupportPopup}
        >
          SELFMADE VIP WhatsApp Support
          <span className="hide-for-small-only"> &nbsp;&nbsp; </span>
          <br className="show-for-small-only" />
          <Icon icon="whatsapp" left /> {this.props.context.whatsappNr}
        </div>
      );
    } else if (
      selfmadeStatus.active === true &&
      selfmadeStatus.type === "monthly"
    ) {
      return (
        <div
          className="clickable whatsapp footer text-center"
          onClick={this.props.context.showSupportPopup}
        >
          <Icon icon="whatsapp" left />
          &nbsp;SELFMADE VIP WhatsApp Support
        </div>
      );
    }

    return null;
  }
}

export default withContext(Footer);
