import React, { Component } from "react";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import PTPopup from "../ptPopup/ptPopup";
import { isUndefined, isString, isNumber } from "util";
import withContext from "../../context/contextHOC";

import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";

registerLocale("de", de);

class CalendarEntry extends Component {
  state = {
    originalEntry: this.props.entry,
    year: this.props.year,
    month: this.props.month,
    entry: undefined,
    type: this.props.type,
    editMode: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.entry !== this.props.entry) {
      const originalEntry = this.props.entry;
      if (!isUndefined(originalEntry)) {
        const entry = JSON.parse(JSON.stringify(originalEntry));

        let editMode = false;
        if (!entry.id) {
          editMode = true;
        }

        this.setState({ entry, originalEntry, editMode });
      }
    }

    if (prevProps.year !== this.props.year) {
      this.setState({ year: this.props.year });
    }

    if (prevProps.month !== this.props.month) {
      this.setState({ month: this.props.month });
    }

    if (prevProps.type !== this.props.type) {
      this.setState({ type: this.props.type });
    }
  }

  render() {
    return <React.Fragment>{this.show__content()}</React.Fragment>;
  }

  show__content() {
    let popupContent = "";

    if (this.state.editMode === true) {
      popupContent = this.show__editEntry();
    } else {
      popupContent = this.show__entry();
    }

    return (
      <PTPopup
        size="fullscreen"
        show={this.state.entry !== undefined}
        handleClose={() => {
          this.setState({ entry: undefined });
          this.props.handleClose();
        }}
      >
        <div className="calendar-popup">{popupContent}</div>
      </PTPopup>
    );
  }

  show__entry() {
    const { entry } = this.state;

    let popupContent = <div />;

    if (!isUndefined(entry)) {
      let endDate = entry.end_date + " - " + entry.end_time;
      if (entry.start_date === entry.end_date) {
        endDate = entry.end_time;
      }

      let description =
        entry.description ||
        "<small><em>Keine Beschreibung vorhanden.</em></small>";

      let location = "";
      let editButtons = "";
      if (entry.owner === 0) {
        location = <div dangerouslySetInnerHTML={{ __html: entry.location }} />;
        editButtons = "";
      } else {
        location = <div>{entry.location}</div>;
        editButtons = (
          <div className="float-right">
            <button
              className="small primary button icon-button button-no-margin"
              title="Kalendereintrag löschen"
              onClick={this.handle__delete}
            >
              <Icon icon="trash" />
            </button>
            <button
              className="small primary button icon-button button-no-margin"
              title="Kalendereintrag bearbeiten"
              onClick={() => {
                this.setState({ editMode: true });
              }}
            >
              <Icon icon="cog" />
            </button>
          </div>
        );
      }

      let calendarPopupInfo = "";
      if (entry.location) {
        calendarPopupInfo = (
          <Grid type="full">
            <Row>
              <Cell sm={24} md={12}>
                <div className="calendar-label">Datum &amp; Zeit</div>
                <div>
                  {entry.start_date} - {entry.start_time} bis {endDate}
                </div>
              </Cell>
              <Cell sm={24} md={12}>
                <div className="calendar-label">Ort</div>
                {location}
              </Cell>
            </Row>
          </Grid>
        );
      } else {
        calendarPopupInfo = (
          <Grid type="full">
            <Row>
              <Cell sm={24} md={24}>
                <div className="calendar-label">Datum &amp; Zeit</div>
                <div>
                  {entry.start_date} - {entry.start_time} bis {endDate}
                </div>
              </Cell>
            </Row>
          </Grid>
        );
      }

      popupContent = (
        <Grid>
          <Row>
            <Cell sm={24} md={12}>
              <h2>
                {editButtons}
                {entry.name}
              </h2>
              <div className="box  calendar-popup-info">
                <div className="box-content">{calendarPopupInfo}</div>
              </div>
              <div className="box calendar-popup-description">
                <div className="box-content">
                  <div className="calendar-label">Beschreibung</div>
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      );
    } else {
      popupContent = (
        <Grid>
          <Row>
            <Cell sm={24} md={12}>
              <h2>Kalendereintrag</h2>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <p>Kein Eintrag ausgewählt.</p>
            </Cell>
          </Row>
        </Grid>
      );
    }

    return popupContent;
  }

  show__editEntry() {
    const { entry } = this.state;

    let popupContent = <div />;

    if (!isUndefined(entry)) {
      let entryStart = new Date(entry.start);
      let entryEnd = new Date(entry.end);

      let entryName = (
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24}>
              <div className="calendar-label">Name</div>
              <input
                type="text"
                value={entry.name || ""}
                placeholder="Titel des Eintrags"
                onChange={event => {
                  this.handle__input("name", event.target.value);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      );

      let entryDateStart = (
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24}>
              <div className="calendar-label">Beginn</div>
              <div>
                <DatePicker
                  selected={entryStart || new Date()}
                  onChange={value => {
                    this.handle__input("start", value);
                  }}
                  dateFormat="dd.MM.yyyy - HH:mm"
                  locale="de"
                  showTimeSelect
                  timeIntervals={5}
                  timeFormat="HH:mm"
                  timeCaption="Uhrzeit"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </Cell>
          </Row>
        </Grid>
      );

      let entryDateEnd = (
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24}>
              <div className="calendar-label">Ende</div>
              <div>
                <DatePicker
                  selected={entryEnd || new Date()}
                  onChange={value => {
                    this.handle__input("end", value);
                  }}
                  dateFormat="dd.MM.yyyy - HH:mm"
                  locale="de"
                  showTimeSelect
                  timeIntervals={5}
                  timeFormat="HH:mm"
                  timeCaption="Uhrzeit"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </Cell>
          </Row>
        </Grid>
      );

      let entryLocation = (
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24}>
              <div className="calendar-label">Ort</div>
              <input
                type="text"
                value={entry.location || ""}
                placeholder="Ort, Location"
                onChange={event => {
                  this.handle__input("location", event.target.value);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      );

      let entryDescription = (
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24}>
              <div className="calendar-label">Beschreibung</div>
              <textarea
                value={entry.description || ""}
                placeholder="Weitere Informationen, Notizen, etc."
                rows="6"
                onChange={event => {
                  this.handle__input("description", event.target.value);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      );

      popupContent = (
        <Grid>
          <Row>
            <Cell sm={24} md={12}>
              <h2>{entry.name}</h2>

              {entryName}

              <Grid type="full">
                <Row>
                  <Cell sm={24} md={12}>
                    {entryDateStart}
                  </Cell>
                  <Cell sm={24} md={12}>
                    {entryDateEnd}
                  </Cell>
                </Row>
              </Grid>
            </Cell>
            <Cell md={1} className="hide-for-small-only">
              &nbsp;
            </Cell>
            <Cell sm={24} md={11}>
              <h2>&nbsp;</h2>

              {entryLocation}
              {entryDescription}

              <div className="ptpopup-buttons">
                <button
                  className="primary hollow button"
                  onClick={this.handle__cancel}
                >
                  <Icon icon="times" left /> Abbrechen
                </button>
                <button className="primary button" onClick={this.handle__save}>
                  <Icon icon="check" left /> Speichern
                </button>
              </div>
            </Cell>
          </Row>
        </Grid>
      );

      return popupContent;
    }
  }

  handle__input(type, value) {
    const { entry } = this.state;

    if (!value) {
      value = null;
    }

    entry[type] = value;

    this.setState({ entry });

    if (type === "start") {
      if (this.check__dates() === false) {
        const now = new Date(entry.start);
        const then = new Date(entry.start);

        entry.end = then.setHours(now.getHours() + 1);
        this.setState({ entry });
      }
    }
  }

  check__dates() {
    const { entry } = this.state;

    //console.log("1", entry.start, typeof entry.start);
    //console.log("1", entry.end, typeof entry.end);

    if (isString(entry.start) || isNumber(entry.start)) {
      entry.start = new Date(entry.start);
    }

    if (isString(entry.end) || isNumber(entry.end)) {
      entry.end = new Date(entry.end);
    }

    //console.log("2", entry.start, typeof entry.start);
    //console.log("2", entry.end, typeof entry.end);

    if (entry.start > entry.end) {
      return false;
    } else {
      return true;
    }
  }

  cancel__edit() {
    let { originalEntry } = this.state;

    let entry = {};

    if (!originalEntry.id) {
      entry = undefined;
      originalEntry = undefined;
    } else {
      entry = JSON.parse(JSON.stringify(originalEntry));
    }

    this.setState({ entry, originalEntry, editMode: false });
  }

  handle__cancel = () => {
    const { originalEntry, entry } = this.state;

    // Check if changes were made
    let changesMade = false;

    const checkFor = ["name", "start", "end", "location", "description"];

    checkFor.map(item => {
      //console.log(entry[item], originalEntry[item]);
      if (entry[item] !== originalEntry[item]) {
        changesMade = true;
      }
      return null;
    });

    //console.log(changesMade);

    if (changesMade === true) {
      if (
        window.confirm(
          "Möchtest Du wirklich abbrechen? Alle nicht gespeicherten Änderungen gehen dann verloren."
        )
      ) {
        this.cancel__edit();
      } else {
        return false;
      }
    } else {
      this.cancel__edit();
    }
  };

  handle__save = () => {
    const { entry, year, month } = this.state;

    if (this.check__dates() === true) {
      if (entry.start) {
        const entryStart =
          ("0" + entry.start.getDate()).slice(-2) +
          "." +
          ("0" + (entry.start.getMonth() + 1)).slice(-2) +
          "." +
          entry.start.getFullYear() +
          " - " +
          ("0" + entry.start.getHours()).slice(-2) +
          ":" +
          ("0" + entry.start.getMinutes()).slice(-2);

        entry.start = entryStart;
      }

      if (entry.end) {
        const entryEnd =
          ("0" + entry.end.getDate()).slice(-2) +
          "." +
          ("0" + (entry.end.getMonth() + 1)).slice(-2) +
          "." +
          entry.end.getFullYear() +
          " - " +
          ("0" + entry.end.getHours()).slice(-2) +
          ":" +
          ("0" + entry.end.getMinutes()).slice(-2);

        entry.end = entryEnd;
      }

      axios
        .post(
          this.props.context.apiEndpoints.calendarEntrySave,
          {
            entry: entry,
            year: year,
            month: month
          },
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          const { days, entry } = response.data;
          this.setState({ editMode: false });
          this.props.handleUpdate(days, entry);
        })
        .catch(error => {
          console.log("Error", error);
        });
    } else {
      window.alert("Der Termin darf nicht vor dem Beginn enden. ;)");
    }
  };

  handle__delete = () => {
    const { entry, year, month } = this.state;

    if (
      window.confirm(
        "Soll der Kalendereintrag [" +
          entry.name +
          "] wirklich unwiderruflich gelöscht werden?"
      )
    ) {
      axios
        .delete(
          this.props.context.apiEndpoints.calendarEntryDelete +
            "/" +
            year +
            "/" +
            month +
            "/" +
            entry.id,
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          const { days } = response.data;
          this.setState({ originalEntry: undefined, entry: undefined });
          this.props.handleUpdate(days, undefined);
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  };
}

export default withContext(CalendarEntry);
