import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";

class AdminBook extends Component {
  state = {
    book: {},
    originalBook: {},
    bookId: this.props.match.params.bookId,
    editMode: false,
    nextYear: 2000,
    nextWeek: 0
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    const { book } = this.state;

    return (
      <AdminLayout active="books">
        <Grid>
          <Row>
            <Cell sm={24}>
              <p>
                <a href="/admin/books">
                  <small>
                    <Icon icon="angle-double-left" left /> Zurück zur Übersicht
                  </small>
                </a>
              </p>
              {this.view__showTitle()}
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={12} lg={12}>
              <div className="admin-box adminBoxBookData">
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3>Buchinformationen</h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <div style={this.view__visible("view")}>
                          <button
                            className="admin-icon-button"
                            onClick={() => {
                              this.setState({ editMode: true });
                              Array.from(
                                document.getElementsByClassName(
                                  "adminBoxBookData"
                                )
                              ).forEach(element => {
                                element.classList.remove("background-saved");
                              });
                            }}
                          >
                            <Icon icon="cog" />
                          </button>
                        </div>
                        <div style={this.view__hideWhenNew()}>
                          <div style={this.view__visible("edit")}>
                            <button
                              onClick={this.handle__editCancel}
                              className="small primary hollow button"
                            >
                              Abbrechen
                            </button>
                            <button
                              onClick={this.handle__editSave}
                              className="small primary button"
                            >
                              Speichern
                            </button>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {book.author || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={book.author || ""}
                            onChange={event => {
                              this.handle__editChange(event, "author");
                            }}
                          />
                        </div>

                        <div className="info-caption">Autor</div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {book.name || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={book.name || ""}
                            onChange={event => {
                              this.handle__editChange(event, "name");
                            }}
                          />
                        </div>

                        <div className="info-caption">Buchname</div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {book.image || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={book.image || ""}
                            onChange={event => {
                              this.handle__editChange(event, "image");
                            }}
                            maxLength={255}
                          />
                        </div>

                        <div className="info-caption">
                          URL zum Bild (z.B. Amazon)
                        </div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {book.url || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={book.url || ""}
                            onChange={event => {
                              this.handle__editChange(event, "url");
                            }}
                            maxLength={255}
                          />
                        </div>

                        <div className="info-caption">
                          URL zur Verkaufsseite (z.B. Amazon)
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>
            </Cell>
            <Cell sm={24} md={12} lg={12}>
              <div className="admin-box adminBoxBookData">
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3>Veröffentlichung</h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <div style={this.view__visible("view")}>
                          <button
                            className="admin-icon-button"
                            onClick={() => {
                              this.setState({ editMode: true });
                              Array.from(
                                document.getElementsByClassName(
                                  "adminBoxBookData"
                                )
                              ).forEach(element => {
                                element.classList.remove("background-saved");
                              });
                            }}
                          >
                            <Icon icon="cog" />
                          </button>
                        </div>
                        <div style={this.view__hideWhenNew()}>
                          <div style={this.view__visible("edit")}>
                            <button
                              onClick={this.handle__editCancel}
                              className="small primary hollow button"
                            >
                              Abbrechen
                            </button>
                            <button
                              onClick={this.handle__editSave}
                              className="small primary button"
                            >
                              Speichern
                            </button>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={12} lg={12}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {book.year || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={book.year || ""}
                            onChange={event => {
                              this.handle__editChange(event, "year");
                            }}
                          />
                        </div>

                        <div className="info-caption">Jahr</div>
                      </Cell>
                      <Cell sm={24} md={12} lg={12}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {book.week || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={book.week || ""}
                            onChange={event => {
                              this.handle__editChange(event, "week");
                            }}
                          />
                        </div>

                        <div className="info-caption">Kalenderwoche</div>
                      </Cell>
                    </Row>
                    <Row>
                      <Cell sm={24}>
                        <div style={this.view__showWhenNew()}>
                          &nbsp;
                          <div className="alert callout small">
                            <small>
                              <strong>Hinweis:</strong> Das System hat den
                              neuesten Bucheintrag aus der Datenbank gelesen und
                              hier bei Kalenderwoche und Jahr automatisch die
                              darauffolgende Woche eingetragen.
                              <br />
                              Wenn das neue Buch nicht in einer ganz bestimmten
                              Woche angezeigt, sondern einfach in die Liste der
                              Bücher eingereiht werden soll, kannst Du die hier
                              voreingetragenen Werte einfach stehen lassen.
                            </small>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>

              <div style={this.view__showWhenNew()}>
                <div style={this.view__visible("edit")} className="text-right">
                  <a href="/admin/books" className="primary hollow button">
                    Abbrechen
                  </a>
                  <button
                    onClick={this.handle__editSave}
                    className="primary button"
                  >
                    Speichern <Icon icon="angle-right" />
                  </button>
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showTitle() {
    const book = this.state.originalBook;
    const { bookId } = this.state;

    let title = "";

    if (book) {
      if (bookId === "new") {
        title = "Neues Buch der Woche";
      } else {
        title = book.name;
      }
    } else {
      title = "Wird geladen...";
    }

    return <h1>{title}</h1>;
  }

  view__hideWhenNew() {
    if (this.state.bookId === "new") {
      return { display: "none" };
    }
  }

  view__showWhenNew() {
    if (this.state.bookId !== "new") {
      return { display: "none" };
    }
  }

  view__visible(type) {
    const { editMode } = this.state;

    if (
      (type === "edit" && editMode === true) ||
      (type === "view" && editMode === false)
    ) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  handle__editChange(event, property) {
    const { book } = this.state;

    book[property] = event.target.value;

    this.setState({ book });
  }

  handle__editCancel = () => {
    const originalBook = JSON.parse(JSON.stringify(this.state.originalBook));
    this.setState({
      book: originalBook,
      editMode: false
    });
  };

  handle__editSave = () => {
    const { bookId, book } = this.state;

    // Check if first and last name are given
    if (!book.author || !book.name) {
      window.alert(
        "Bitte gib mindestens den Autor und den Namen des Buches an."
      );
      return;
    }

    let url = this.props.context.apiEndpoints.adminBooks + "/" + book.id;
    if (bookId === "new") {
      url = this.props.context.apiEndpoints.adminBooks + "/new";
    }

    axios
      .post(
        url,
        { book: book },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        if (bookId === "new") {
          const { book } = response.data;

          //console.log(response.data);
          window.location = "/admin/books/" + book.id;

          return;
        } else {
          const { book } = response.data;
          const originalBook = JSON.parse(JSON.stringify(book));
          this.setState({ book, originalBook, editMode: false });
          Array.from(
            document.getElementsByClassName("adminBoxBookData")
          ).forEach(element => {
            element.classList.add("background-saved");
          });
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  /**
   * Init
   * Load Book
   */
  init__load() {
    const { bookId } = this.state;

    if (bookId) {
      axios
        .get(this.props.context.apiEndpoints.adminBooks + "/" + bookId, {
          headers: this.props.context.headers
        })
        .then(response => {
          const { book, nextYear, nextWeek } = response.data;
          const originalBook = JSON.parse(JSON.stringify(book));
          this.setState({ book, originalBook, nextYear, nextWeek });
          if (bookId === "new") {
            this.setState({ editMode: true });
          }
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  }
}

export default withContext(AdminBook);
