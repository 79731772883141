import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";

class AdminQuote extends Component {
  state = {
    quote: {},
    originalQuote: {},
    quoteId: this.props.match.params.quoteId,
    editMode: false
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    return (
      <AdminLayout active="quotes">{this.view__showContent()}</AdminLayout>
    );
  }

  view__showContent() {
    const { quote } = this.state;

    if (!quote) {
      return null;
    }

    return (
      <Grid>
        <Row>
          <Cell sm={24} md={18}>
            <p>
              <a href="/admin/quotes">
                <small>
                  <Icon icon="angle-double-left" left /> Zurück zur Übersicht
                </small>
              </a>
            </p>
            {this.view__showTitle()}
          </Cell>
          <Cell sm={24} md={6} classes="text-right">
            <div style={this.view__visible("view")}>
              <div style={this.view__hideWhenNew()}>
                <button
                  className="primary button hide-for-small-only"
                  onClick={this.handle__delete}
                >
                  <Icon icon="trash" left /> Zitat löschen
                </button>
                <button
                  className="small primary button show-for-small-only"
                  onClick={this.handle__delete}
                >
                  <Icon icon="trash" left /> Zitat löschen
                </button>
              </div>
            </div>
          </Cell>
        </Row>
        <Row margin="x">
          <Cell sm={24} md={12} lg={12}>
            <div className="admin-box adminBoxData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Zitat</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <div style={this.view__visible("view")}>
                        <button
                          className="admin-icon-button"
                          onClick={() => {
                            this.setState({ editMode: true });
                            Array.from(
                              document.getElementsByClassName("adminBoxData")
                            ).forEach(element => {
                              element.classList.remove("background-saved");
                            });
                          }}
                        >
                          <Icon icon="cog" />
                        </button>
                      </div>
                      <div style={this.view__hideWhenNew()}>
                        <div style={this.view__visible("edit")}>
                          <button
                            onClick={this.handle__editCancel}
                            className="small primary hollow button"
                          >
                            Abbrechen
                          </button>
                          <button
                            onClick={this.handle__editSave}
                            className="small primary button"
                          >
                            Speichern
                          </button>
                        </div>
                      </div>
                    </Cell>
                  </Row>

                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div
                        className="info-content"
                        style={this.view__visible("view")}
                      >
                        {quote.quote || (
                          <span
                            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                          />
                        )}
                      </div>
                      <div
                        className="info-content edit-mode"
                        style={this.view__visible("edit")}
                      >
                        <textarea
                          rows="5"
                          value={quote.quote || ""}
                          onChange={event => {
                            this.handle__editChange(
                              event.target.value,
                              "quote"
                            );
                          }}
                        />
                      </div>

                      <div className="info-caption">Zitat</div>
                    </Cell>
                  </Row>
                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div
                        className="info-content"
                        style={this.view__visible("view")}
                      >
                        {quote.author || (
                          <span
                            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                          />
                        )}
                      </div>
                      <div
                        className="info-content edit-mode"
                        style={this.view__visible("edit")}
                      >
                        <input
                          type="text"
                          value={quote.author || ""}
                          onChange={event => {
                            this.handle__editChange(
                              event.target.value,
                              "author"
                            );
                          }}
                        />
                      </div>

                      <div className="info-caption">Autor</div>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            </div>
          </Cell>
          <Cell sm={24} md={12} lg={12}>
            <div style={this.view__showWhenNew()}>
              <div style={this.view__visible("edit")} className="text-right">
                <a href="/admin/books" className="primary hollow button">
                  Abbrechen
                </a>
                <button
                  onClick={this.handle__editSave}
                  className="primary button"
                >
                  Speichern <Icon icon="angle-right" />
                </button>
              </div>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showTitle() {
    const quote = this.state.originalQuote;
    const { quoteId } = this.state;

    let title = "";

    if (quote) {
      if (quoteId === "new") {
        title = "Neues Zitat";
      } else {
        if (quote.quote) {
          title = quote.quote.slice(0, 40) + "...";
        } else {
          title = "Zitat";
        }
      }
    } else {
      title = "Wird geladen...";
    }

    return <h1>{title}</h1>;
  }

  view__hideWhenNew() {
    if (this.state.quoteId === "new") {
      return { display: "none" };
    }
  }

  view__showWhenNew() {
    if (this.state.quoteId !== "new") {
      return { display: "none" };
    }
  }

  view__visible(type) {
    const { editMode } = this.state;

    if (
      (type === "edit" && editMode === true) ||
      (type === "view" && editMode === false)
    ) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  handle__delete = () => {
    const { quote } = this.state;

    if (
      window.confirm(
        "Möchtest Du dieses Zitat wirklich unwiderruflich löschen?\r\n\r\nDiese Aktion kann nicht rückgängig gemacht werden."
      )
    ) {
      let url = this.props.context.apiEndpoints.adminQuotes + "/" + quote.id;

      axios
        .delete(url, {
          headers: this.props.context.headers
        })
        .then(response => {
          window.location = "/admin/quotes";
        })
        .catch(error => {
          console.log("Error", error);
        });
    } else {
      return;
    }
  };

  handle__editChange(value, property) {
    const { quote } = this.state;
    quote[property] = value;
    this.setState({ quote });
  }

  handle__editCancel = () => {
    let quote = JSON.parse(JSON.stringify(this.state.originalQuote));

    this.setState({
      quote,
      editMode: false
    });
  };

  handle__editSave = () => {
    const { quoteId, quote } = this.state;

    // Check if first and last name are given
    if (!quote.quote) {
      window.alert("Bitte gib ein Zitat ein.");
      return;
    }

    let url = this.props.context.apiEndpoints.adminQuotes + "/" + quote.id;
    if (quoteId === "new") {
      url = this.props.context.apiEndpoints.adminQuotes + "/new";
    }

    axios
      .post(
        url,
        { quote: quote },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        if (quoteId === "new") {
          const { quote } = response.data;

          //console.log(response.data);
          window.location = "/admin/quotes/" + quote.id;

          return;
        } else {
          let { quote } = response.data;
          const originalQuote = JSON.parse(JSON.stringify(quote));

          this.setState({
            quote,
            originalQuote,
            editMode: false
          });
          Array.from(document.getElementsByClassName("adminBoxData")).forEach(
            element => {
              element.classList.add("background-saved");
            }
          );
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  /**
   * Init
   */
  init__load() {
    const { quoteId } = this.state;

    if (quoteId) {
      axios
        .get(this.props.context.apiEndpoints.adminQuotes + "/" + quoteId, {
          headers: this.props.context.headers
        })
        .then(response => {
          let { quote } = response.data;
          if (quoteId === "new") {
            this.setState({ editMode: true });
          }
          const originalQuote = JSON.parse(JSON.stringify(quote));
          this.setState({ quote, originalQuote });
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  }
}

export default withContext(AdminQuote);
