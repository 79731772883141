import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import withContext from "../../context/contextHOC";
import Avatar from "../user/_avatar";
import { isUndefined } from "util";
import PtCountdown from "../ptCountdown/ptCountdown";
import PTPopup from "../ptPopup/ptPopup";
import ReactTooltip from "react-tooltip";
import closeButton from "../../images/close-narrow-white.png";

class MainTopbar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      userDropDownExtra: 20,
      userDropDownStyleObject: {
        left: 0,
        width: 240
      },
      notificationsDropDownStyleObject: {
        left: 0,
        width: 280
      },
      showUserMenu: false,
      showNotificationsMenu: false,
      showTrialPopup: false,
      showFavorites: false,
      showUserMenuPopup: false,
      showMobileNav: false
    };
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    this.props.context.initAuth(null, user => {
      this.setState({
        notifications: user.notifications
      });
    });
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUpdate(prevState) {
    this.updateUserDropDown();
    this.updateNotificationsDropDown();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate() {
    if (
      document.getElementById("userMenuIcon") &&
      document.getElementById("userMenuPopup")
    ) {
      let userMenuIcon = document.getElementById("userMenuIcon");
      let userMenuPopup = document.getElementById("userMenuPopup");

      let userMenuIconRect = userMenuIcon.getBoundingClientRect();
      //let userMenuPopupRect = userMenuPopup.getBoundingClientRect();

      userMenuPopup.style.left =
        userMenuIconRect.left -
        userMenuPopup.offsetWidth +
        userMenuIcon.offsetWidth +
        "px";
      userMenuPopup.style.top =
        userMenuIconRect.top + userMenuIcon.offsetHeight + "px";
    }

    if (
      document.getElementById("favoritesIcon") &&
      document.getElementById("favoritesPopup")
    ) {
      let favIcon = document.getElementById("favoritesIcon");
      let favPopup = document.getElementById("favoritesPopup");

      let favIconRect = favIcon.getBoundingClientRect();

      favPopup.style.left = favIconRect.left + "px";
      favPopup.style.top = favIconRect.top + favIcon.offsetHeight + "px";
    }
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  updateUserDropDown() {
    return;
  }

  toggleUserDropDown = () => {
    const { showUserMenu } = this.state;

    if (showUserMenu === true) {
      this.setState({ showUserMenu: false });
    } else {
      this.hideAllMenus();
      this.setState({ showUserMenu: true });
    }
  };

  updateNotificationsDropDown() {
    const { type } = this.props;
    if (type === "admin") {
      //return;
    }

    // Position Notifications Drop Down Menu
    let notificationsMenu = document.getElementById("notificationsMenu");
    let notificationsDropDown = document.getElementById(
      "notificationsDropDown"
    );

    const { notificationsDropDownStyleObject } = this.state;

    let newNotificationsDropDownPos =
      notificationsMenu.getBoundingClientRect().x +
      notificationsMenu.getBoundingClientRect().width -
      notificationsDropDownStyleObject.width +
      this.state.userDropDownExtra;

    if (notificationsDropDownStyleObject.left !== newNotificationsDropDownPos) {
      notificationsDropDown.style.left = newNotificationsDropDownPos;

      notificationsDropDownStyleObject.left = newNotificationsDropDownPos;
      this.setState({ notificationsDropDownStyleObject });
    }
  }

  toggleNotificationsDropDown = () => {
    const { showNotificationsMenu } = this.state;

    if (showNotificationsMenu === true) {
      this.setState({ showNotificationsMenu: false });
    } else {
      this.hideAllMenus();
      this.setState({ showNotificationsMenu: true });
    }
  };

  hideAllMenus() {
    this.setState({
      showNotificationsMenu: false,
      showUserMenu: false
    });
  }

  render() {
    const { type } = this.props;

    if (type === "admin") {
      return this.view__showAdminTopbar();
    } else {
      return this.view__showMainTopbar();
    }
  }

  view__showAdminTopbar() {
    const _c = this.props.context;

    //return null;
    return (
      <div id="top" className="top-admin">
        <div id="topInner">
          <Grid type="full">
            <Row>
              <Cell sm={10} md={4} lg={2}>
                <span className="nav-admin-toggle" onClick={_c.toggleAdminNav}>
                  <Icon icon="bars" />
                </span>
                <a href="/dashboard" className="nav-admin-logo">
                  <img
                    src="/images/tpa-media.png"
                    alt="TPA Media"
                    id="mainTopbarLogo"
                  />
                </a>
              </Cell>
              <Cell sm={24} md={14} lg={16} classes="hide-for-small-only">
                <a href="/admin" className="topbar-quicklink">
                  <span /> Administration
                </a>
              </Cell>
              <Cell sm={14} md={6} lg={6} classes="text-right">
                <ul id="topIcons">
                  <li style={{ display: "none" }}>
                    <div
                      id="notificationsMenu"
                      onClick={this.toggleNotificationsDropDown}
                    >
                      <button className="top-icon-notifications">
                        {this.showNotificationsIcon()}
                      </button>
                    </div>
                    <div
                      id="notificationsDropDown"
                      style={this.getNotificationsDropDownStyles()}
                    >
                      <ul>{this.showNotifications()}</ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href={this.view__userProfileLink()}
                      onMouseOver={() => {
                        this.setState({ showUserMenuPopup: true });
                      }}
                      className="topbar-usermenu hide-for-small-only"
                      id="userMenuIcon"
                    >
                      <Avatar
                        user={this.props.context.user}
                        size="mainTopbar"
                      />
                      {this.showUserName()} {this.view__showVIPStatus()}{" "}
                    </a>
                    <button
                      onClick={() => {
                        if (this.state.showUserMenuPopup === false) {
                          this.setState({ showUserMenuPopup: true });
                        } else {
                          this.setState({ showUserMenuPopup: false });
                        }
                      }}
                      className="topbar-usermenu show-for-small-only"
                      id="userMenuIcon"
                    >
                      <Avatar
                        user={this.props.context.user}
                        size="mainTopbar"
                      />
                      {this.showUserName()} {this.view__showVIPStatus()}{" "}
                    </button>
                  </li>
                </ul>
                {this.view__showUserMenuPopup()}
                {this.view__showUserMenuPopup("mobile")}
              </Cell>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }

  view__showMainTopbar() {
    return (
      <div id="top">
        <div id="topInner">
          <ReactTooltip place="top" type="dark" effect="solid" />
          <Grid>
            <Row>
              <Cell sm={12} md={5} lg={2}>
                <span
                  className="nav-mobile-toggle"
                  onClick={this.toggleMobileNav}
                >
                  <Icon icon="bars" />
                </span>
                <a href="/dashboard" className="nav-admin-logo">
                  <img
                    src="/images/tpa-media.png"
                    alt="TPA Media"
                    id="mainTopbarLogo"
                  />
                </a>
                <span className="show-for-small-only">
                  <button
                    onMouseOver={() => {
                      this.setState({ showFavorites: true });
                    }}
                    className="topbar-favorites"
                  >
                    <Icon icon="star" />
                  </button>
                  {this.view__showWhatsappButton()}
                </span>
              </Cell>
              <Cell sm={24} md={13} lg={15} classes="hide-for-small-only">
                <button
                  onMouseOver={() => {
                    this.setState({ showFavorites: true });
                  }}
                  className="topbar-favorites"
                  id="favoritesIcon"
                >
                  <Icon icon="star" />
                </button>

                {this.view__showNavigationLinks()}

                {this.view__showWhatsappButton()}
              </Cell>
              {/*<Cell md={7} lg={6} classes="hide-for-small-only">
                {this.show__countdown()}
                </Cell>*/}
              <Cell sm={12} md={7} lg={7} classes="text-right">
                <ul id="topIcons">
                  <li style={{ display: "none" }}>
                    <div
                      id="notificationsMenu"
                      onClick={this.toggleNotificationsDropDown}
                    >
                      <button className="top-icon-notifications">
                        {this.showNotificationsIcon()}
                      </button>
                    </div>
                    <div
                      id="notificationsDropDown"
                      style={this.getNotificationsDropDownStyles()}
                    >
                      <ul>{this.showNotifications()}</ul>
                    </div>
                  </li>
                  <li>
                    <a
                      href={this.view__userProfileLink()}
                      onMouseOver={() => {
                        this.setState({ showUserMenuPopup: true });
                      }}
                      className="topbar-usermenu hide-for-small-only"
                      id="userMenuIcon"
                    >
                      <Avatar
                        user={this.props.context.user}
                        size="mainTopbar"
                      />
                      {this.showUserName()} {this.view__showVIPStatus()}{" "}
                    </a>
                    <button
                      onClick={() => {
                        if (this.state.showUserMenuPopup === false) {
                          this.setState({ showUserMenuPopup: true });
                        } else {
                          this.setState({ showUserMenuPopup: false });
                        }
                      }}
                      className="topbar-usermenu show-for-small-only"
                      id="userMenuIcon"
                    >
                      <Avatar
                        user={this.props.context.user}
                        size="mainTopbar"
                      />
                      {this.showUserName()} {this.view__showVIPStatus()}{" "}
                    </button>
                  </li>
                </ul>
                {this.view__showFavoritesPopup()}
                {this.view__showFavoritesPopup("mobile")}

                {this.view__showUserMenuPopup()}
                {this.view__showUserMenuPopup("mobile")}

                {this.view__showNavigationLinks("mobile")}
              </Cell>
            </Row>
          </Grid>
          {this.view__showSupportPopup()}
        </div>
      </div>
    );
  }

  view__showNavigationLinks(type) {
    if (type === "mobile") {
      const { showMobileNav } = this.state;
      if (showMobileNav === true) {
        return (
          <div className="topbar-nav">
            <div className="close show-for-small-only">
              <img
                src={closeButton}
                alt="X"
                onClick={() => {
                  this.setState({ showMobileNav: false });
                }}
              />
            </div>
            <div>
              <a href="/dashboard" className="topbar-quicklink">
                <span /> Home
              </a>
              <a href="/workspace" className="topbar-quicklink">
                <span /> Workspace
              </a>
              {this.show__selfmadeCourseLink()}
              <a href="/kurse" className="topbar-quicklink">
                <span /> Kurse
              </a>
              <a href="/community" className="topbar-quicklink">
                <span /> Community
              </a>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return (
        <span className="topbar-nav">
          <a href="/dashboard" className="topbar-quicklink">
            <span /> Home
          </a>
          <a href="/workspace" className="topbar-quicklink">
            <span /> Workspace
          </a>
          {this.show__selfmadeCourseLink()}
          <a href="/kurse" className="topbar-quicklink">
            <span /> Kurse
          </a>
          <a href="/community" className="topbar-quicklink">
            <span /> Community
          </a>
        </span>
      );
    }
  }

  view__showAdminLink() {
    const { user } = this.props.context;

    if (user && user.admin) {
      if (user.admin.access === true) {
        return (
          <Row>
            <Cell sm={24} md={24}>
              <a className="topbar-usermenu-link" href="/admin">
                <Icon icon="sliders" left /> Administration
              </a>
            </Cell>
          </Row>
        );
      }
    }

    return null;
  }

  view__userProfileLink() {
    const { user } = this.props.context;
    if (!user || !user.id) {
      return null;
    }

    return `/community/@${user.username}`;
  }

  view__showVIPStatus() {
    let selfmadeStatus = {
      active: false,
      url: null
    };

    if (this.props.context.user && this.props.context.user.selfmadeStatus) {
      selfmadeStatus = this.props.context.user.selfmadeStatus;
    }

    if (
      selfmadeStatus.active === true &&
      (selfmadeStatus.type === "annually" ||
        selfmadeStatus.type === "annually_upgraded")
    ) {
      return (
        <React.Fragment>
          <ReactTooltip place="top" type="dark" effect="solid" />
          <span className="vip">
            <Icon icon="star" right />
          </span>
        </React.Fragment>
      );
    }
    return null;
  }

  view__showUserMenuPopup = type => {
    const { showUserMenuPopup } = this.state;
    const _c = this.props.context;
    const { user } = this.props.context;

    if (!user || showUserMenuPopup !== true) {
      return null;
    }

    let output = (
      <React.Fragment>
        <h3>Hallo, {user.firstname}!</h3>
        <Grid type="full">
          {this.view__showVIPStatusInMenu()}
          {this.view__showAdminLink()}
          <Row>
            <Cell sm={24} md={12}>
              <a
                className="topbar-usermenu-link"
                href={this.view__userProfileLink()}
              >
                <Icon icon="user" left /> Mein Profil
              </a>
            </Cell>
            <Cell sm={24} md={12}>
              <a className="topbar-usermenu-link" href="/profil">
                <Icon icon="edit" left /> Profil bearbeiten
              </a>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={12}>
              <a className="topbar-usermenu-link" href="/password">
                <Icon icon="key" left /> Passwort ändern
              </a>
            </Cell>
            <Cell sm={24} md={12}>
              <a
                href="/profil"
                className="topbar-usermenu-link"
                onClick={e => {
                  e.preventDefault();
                  _c.handleLogout();
                }}
              >
                <Icon icon="sign-out" left /> Abmelden
              </a>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24}>
              <hr />
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={12}>
              <a
                href="/"
                className="topbar-usermenu-link"
                onClick={e => {
                  e.preventDefault();
                  this.props.context.showSupportPopup();
                }}
              >
                <Icon icon="whatsapp" left /> WhatsApp Support
              </a>
            </Cell>
            <Cell sm={24} md={12}>
              <a
                className="topbar-usermenu-link"
                href="mailto:support@torbenplatzer.com?subject=Supportanfrage"
              >
                <Icon icon="envelope" left /> E-Mail Support
              </a>
            </Cell>
          </Row>
        </Grid>
      </React.Fragment>
    );

    if (type === "mobile") {
      return (
        <div className="show-for-small-only">
          <div className="top-usermenu-wrapper"></div>
          <div className="top-usermenu">
            <div className="close show-for-small-only">
              <img
                src={closeButton}
                alt="X"
                onClick={() => {
                  this.setState({ showUserMenuPopup: false });
                }}
              />
            </div>
            {output}
          </div>
        </div>
      );
    } else {
      return (
        <div className="hide-for-small-only">
          <div
            className="top-usermenu-wrapper"
            onMouseOver={() => {
              this.setState({ showUserMenuPopup: false });
            }}
          ></div>
          <div
            className="top-usermenu"
            id="userMenuPopup"
            onMouseOver={() => {
              this.setState({ showUserMenuPopup: true });
            }}
          >
            {output}
          </div>
        </div>
      );
    }
  };

  view__showVIPStatusInMenu() {
    let selfmadeStatus = {
      active: false,
      url: null
    };

    if (this.props.context.user && this.props.context.user.selfmadeStatus) {
      selfmadeStatus = this.props.context.user.selfmadeStatus;
    }

    if (
      selfmadeStatus.active === true &&
      (selfmadeStatus.type === "annually" ||
        selfmadeStatus.type === "annually_upgraded")
    ) {
      return (
        <Row>
          <Cell sm={24} md={24}>
            <div className="topbar-usermenu-link vip">
              <Icon icon="star" left /> VIP-Mitglied (Jährl. Abonnement)
            </div>
          </Cell>
        </Row>
      );
    }
    return null;
  }

  view__showFavoritesPopup = type => {
    const { showFavorites } = this.state;
    const { user } = this.props.context;

    if (showFavorites !== true || !user || !user.favorites) {
      return null;
    }

    let output;
    if (!user.favorites || user.favorites.length === 0) {
      output = (
        <React.Fragment>
          <p className="text-center" style={{ fontSize: "0.9rem" }}>
            Füge Deine häufig besuchten Seiten im Mitgliederbereich zu Deinen
            Favoriten hinzu.
          </p>
          <div
            className="text-center"
            style={{ fontSize: "0.8rem", lineHeight: "150%" }}
          >
            Auf den Seiten, die Du zu den Favoriten hinzufügen kannst, findest
            Du rechts oben einen weißen Stern, den Du anklicken kannst.
          </div>
        </React.Fragment>
      );
    } else {
      output = (
        <Grid type="full">
          {user.favorites.map((favorite, index) => {
            let icon;
            if (favorite.icon) {
              if (favorite.icon === "live") {
                icon = <Icon icon="youtube-play" left />;
              } else if (favorite.icon === "course") {
                icon = <Icon icon="graduation-cap" left />;
              } else if (favorite.icon === "workspace") {
                icon = <Icon icon="th" left />;
              } else if (favorite.icon === "visionboard") {
                icon = <Icon icon="eye" left />;
              } else if (favorite.icon === "community") {
                icon = <Icon icon="comments-o" left />;
              } else if (favorite.icon === "routines") {
                icon = <Icon icon="calendar-check-o" left />;
              } else if (favorite.icon === "calendar") {
                icon = <Icon icon="calendar" left />;
              } else if (favorite.icon === "todo") {
                icon = <Icon icon="check-square-o" left />;
              }

              icon = <span style={{ marginRight: "6px" }}>{icon}</span>;
            }

            return (
              <Row key={index}>
                <Cell sm={24}>
                  <Link to={favorite.page} className="topbar-favorites-link">
                    {icon}
                    {favorite.name}
                  </Link>
                </Cell>
              </Row>
            );
          })}
        </Grid>
      );
    }

    if (type === "mobile") {
      return (
        <div className="show-for-small-only">
          <div className="top-favorites-wrapper"></div>
          <div className="top-favorites">
            <div className="close show-for-small-only">
              <img
                src={closeButton}
                alt="X"
                onClick={() => {
                  this.setState({ showFavorites: false });
                }}
              />
            </div>
            <h3>Meine Favoriten</h3>
            {output}
          </div>
        </div>
      );
    } else {
      return (
        <div className="hide-for-small-only">
          <div
            className="top-favorites-wrapper"
            onMouseOver={() => {
              this.setState({ showFavorites: false });
            }}
          ></div>
          <div
            className="top-favorites"
            id="favoritesPopup"
            onMouseOver={() => {
              this.setState({ showFavorites: true });
            }}
          >
            <h3>Meine Favoriten</h3>
            {output}
          </div>
        </div>
      );
    }
  };

  view__showSupportPopup = () => {
    let content;

    const selfmadeStatus = this.props.context.getUserSelfmadeStatus();

    if (this.props.context.userHasSelfmadeVIP()) {
      content = (
        <React.Fragment>
          <div className="popup-support">
            <h2>
              <Icon icon="whatsapp" left /> SELFMADE
              <br />
              VIP WhatsApp Support
            </h2>
            <p>
              Als VIP-Mitglied hast Du die Möglichkeit, dem SELFMADE Team Deine
              Fragen direkt per WhatsApp stellen zu können.
            </p>
            <p>
              Damit wir Deine Nachricht schnellstmöglich beantworten können,
              beachte bitte folgende Punkte:
            </p>
            <ol>
              <li>
                <strong>Halte Dich kurz:</strong> Sprachnachrichten unter einer
                Minute und kurze Textnachrichten können wir schneller und
                effizienter beantworten.
              </li>
              <li>
                <strong>Stelle eine konkrete Frage:</strong> Komm schnell auf
                den Punkt und formuliere eine konkrete Frage.
              </li>
            </ol>

            <p className="text-center">
              <a
                href="https://wa.me/491737058215"
                target="_blank"
                rel="noopener noreferrer"
                className="primary button"
              >
                <Icon icon="whatsapp" left /> {this.props.context.whatsappNr}
              </a>
            </p>
          </div>
        </React.Fragment>
      );
    } else if (
      selfmadeStatus.active === true &&
      selfmadeStatus.type === "monthly"
    ) {
      content = (
        <React.Fragment>
          <div className="popup-support">
            <h2>
              <Icon icon="whatsapp" left /> SELFMADE
              <br />
              VIP WhatsApp Support
            </h2>
            <p>
              Als VIP-Mitglied hast Du die Möglichkeit, dem SELFMADE Team Deine
              Fragen direkt per WhatsApp stellen zu können.
            </p>
            <p>
              <strong>
                Hol Dir jetzt das Upgrade auf die VIP-Mitgliedschaft und
                profitiere von vielen Vorteilen wie den exklusiven VIP WhatsApp
                Support, Dein persönlicher SELFMADE Hoodie und vielen mehr.
              </strong>
            </p>

            <p className="text-center" style={{ marginTop: "1.8rem" }}>
              <a
                href="https://www.digistore24.com/product/273551"
                target="_blank"
                rel="noopener noreferrer"
                className="primary button"
              >
                Mehr erfahren <Icon icon="angle-right" />
              </a>
            </p>
          </div>
        </React.Fragment>
      );
    } else {
      content = (
        <React.Fragment>
          <div className="popup-support">
            <h2>
              <Icon icon="whatsapp" left /> SELFMADE
              <br />
              VIP WhatsApp Support
            </h2>
            <p>
              Als SELFMADE VIP-Mitglied hast Du die Möglichkeit, dem SELFMADE
              Team Deine Fragen direkt per WhatsApp stellen zu können.
            </p>
            <p>
              <strong>
                Hol Dir jetzt Deine VIP-Mitgliedschaft und profitiere von vielen
                Vorteilen wie den exklusiven VIP WhatsApp Support, Dein
                persönlicher SELFMADE Hoodie und vielen mehr.
              </strong>
            </p>

            <p className="text-center" style={{ marginTop: "1.8rem" }}>
              <a
                href="https://www.digistore24.com/product/267399"
                target="_blank"
                rel="noopener noreferrer"
                className="primary button"
              >
                Mehr erfahren <Icon icon="angle-right" />
              </a>
            </p>
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="darkmode">
        <div className="selfmade-popup">
          <PTPopup
            show={this.props.context.getSupportPopupStatus()}
            size="small"
            handleClose={this.props.context.hideSupportPopup}
          >
            <Grid type="full">
              <Row padding="xy">
                <Cell sm={24}>{content}</Cell>
              </Row>
            </Grid>
          </PTPopup>
        </div>
      </div>
    );
  };

  view__showWhatsappButton() {
    return (
      <React.Fragment>
        <ReactTooltip place="top" type="dark" effect="solid" />
        <button
          className="topbar-whatsapp"
          onClick={this.props.context.showSupportPopup}
          data-tip="SELFMADE VIP WhatsApp Support"
        >
          <Icon icon="whatsapp" />
        </button>
      </React.Fragment>
    );
  }

  show__countdown() {
    if (
      !isUndefined(this.props.context.user.endOfTrial) &&
      this.props.context.user.endOfTrial !== null
    ) {
      return (
        <React.Fragment>
          <button
            className="maintopbar-countdown"
            onClick={() => {
              this.setState({ showTrialPopup: true });
            }}
          >
            <Grid>
              <Row>
                <Cell sm={22}>
                  <div className="maintopbar-countdown-caption">
                    Deine Testphase endet in
                  </div>
                  <PtCountdown
                    date={this.props.context.user.endOfTrial}
                    type="maintopbar"
                  >
                    ---
                  </PtCountdown>
                </Cell>
                <Cell sm={2}>
                  <div className="maintopbar-countdown-icon">
                    <Icon icon="question-circle" />
                  </div>
                </Cell>
              </Row>
            </Grid>
          </button>
          {this.show__trialPopup()}
        </React.Fragment>
      );
    }
  }

  show__trialPopup = () => {
    return (
      <div className="darkmode">
        <div className="selfmade-popup">
          <PTPopup
            show={this.state.showTrialPopup}
            size="small"
            handleClose={() => {
              this.setState({
                showTrialPopup: false
              });
            }}
          >
            <Grid type="full">
              <Row>
                <Cell sm={24}>
                  <h2>Deine Testphase läuft ab</h2>
                  <p>Deine kostenfreie Testphase geht in Kürze zu Ende.</p>
                  <p>
                    <strong>
                      <u>
                        Wenn Du den Workspace und den Selfmade Kurs weiter
                        nutzen möchtest, musst Du nichts tun.
                      </u>
                    </strong>
                  </p>
                  <p>
                    Nach Ende der kostenfreien Testphase wird Dein Account
                    automatisch in einen vollwertigen, zahlungspflichtigen
                    Account umgestellt.
                  </p>
                  <p className="text-center">
                    <button
                      onClick={() => {
                        this.setState({
                          showTrialPopup: false
                        });
                      }}
                      className="primary button"
                    >
                      Selfmade weiternutzen
                    </button>
                  </p>
                  <p>
                    <strong>
                      <u>
                        Möchtest Du den Selfmade Kurs nicht weiter nutzen,
                        <br />
                        kündige bitte Dein Abonnement.
                      </u>
                    </strong>
                  </p>
                  {this.show__stopRebilling()}
                  {/*<p>
                    Dazu musst Du eine Kündigungs-E-Mail an unseren
                    Zahlungspartner Digistore senden. Wenn Du auf den unten
                    stehenden Button klickst, wird Dein E-Mail-Programm mit
                    einer vorformulierten Kündigungs-E-Mail geöffnet.
                  </p>
                  <p>
                    Wir würden uns sehr freuen, wenn Du weiterhin Teil der
                    Community bleibst, damit wir Dein Business mit vier neuen
                    Videos und einem Live Call pro Monat (!) gemeinsam auf das
                    nächste Level heben können!
                  </p>
                  <p className="text-center">
                  
                    </p>*/}
                </Cell>
              </Row>
            </Grid>
          </PTPopup>
        </div>
      </div>
    );
  };

  show__stopRebilling() {
    if (
      !isUndefined(this.props.context.user.stopRebillingUrl) &&
      this.props.context.user.stopRebillingUrl !== null
    ) {
      return (
        <React.Fragment>
          <p>
            Um Dein Abo zu kündigen, klicke bitte auf den untenstehenden Button
            und folge den dort stehenden Anweisungen.
          </p>
          <p className="text-center">
            <a
              href={this.props.context.user.stopRebillingUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="primary button"
            >
              Abonnement kündigen
            </a>
          </p>
        </React.Fragment>
      );
    } else {
      return (
        <p>
          In der Bestellbestägigungs-E-Mail von Digistore findest Du im
          Abschnitt{" "}
          <strong>
            &quot;Eine Übersicht über Ihre Bestellung finden Sie hier:&quot;
          </strong>{" "}
          einen Link zu den Details Deiner Bestellung. Dort findest Du einen
          Link <strong>&quot;Kündigen&quot;</strong>. Über diesen Link kannst Du
          Deine Testphase direkt kündigen.{" "}
        </p>
      );
    }
  }

  getUserDropDownStyles() {
    const { userDropDownStyleObject, showUserMenu } = this.state;
    const styles = JSON.parse(JSON.stringify(userDropDownStyleObject));

    if (showUserMenu === true) {
      styles.display = "block";
    } else {
      styles.display = "none";
    }

    return styles;
  }

  getNotificationsDropDownStyles() {
    const {
      notificationsDropDownStyleObject,
      showNotificationsMenu
    } = this.state;
    const styles = JSON.parse(JSON.stringify(notificationsDropDownStyleObject));

    if (showNotificationsMenu === true) {
      styles.display = "block";
    } else {
      styles.display = "none";
    }

    return styles;
  }

  showUserName() {
    const { user } = this.props.context;
    if (user && user.firstname) {
      return user.firstname;
    }
  }

  show__selfmadeCourseLink() {
    let selfmadeStatus = {
      active: false,
      url: null
    };

    if (this.props.context.user && this.props.context.user.selfmadeStatus) {
      selfmadeStatus = this.props.context.user.selfmadeStatus;
    }

    if (selfmadeStatus.active === true) {
      return (
        <a href={selfmadeStatus.url} className="topbar-quicklink">
          <span /> Selfmade
        </a>
      );
    } else {
      return (
        <a
          href={selfmadeStatus.url}
          className="topbar-quicklink"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span /> Selfmade
        </a>
      );
    }
  }

  showNotifications() {
    const { notifications } = this.state;

    if (this.hasNotifications()) {
      return notifications.map((notification, index) => {
        return (
          <li key={index}>
            <a href={notification.url}>{notification.text}</a>
          </li>
        );
      });
    } else {
      return (
        <li>
          <span>Keine Neuigkeiten</span>
        </li>
      );
    }
  }

  hasNotifications() {
    const { notifications } = this.state;
    if (notifications.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  showNotificationsIcon() {
    if (this.hasNotifications()) {
      return <Icon icon="heart" />;
    } else {
      return <Icon icon="heart-o" />;
    }
  }

  toggleMobileNav = () => {
    const { showMobileNav } = this.state;
    if (showMobileNav === true) {
      this.setState({ showMobileNav: false });
    } else {
      this.setState({ showMobileNav: true });
    }
  };
}

export default withContext(MainTopbar);
