import React, { Component } from "react";
import SelfmadeLayout from "./selfmadeLayout";
import { Grid, Row, Cell } from "../foundation/foundation";
import withContext from "../../context/contextHOC";
import VisionboardImage from "./elements/visionboardImage";
import { isUndefined } from "util";
import axios from "axios";

class SelfmadeVisionboard extends Component {
  state = {
    visionboardImages: [],
    defaultVisionboardImages: []
  };

  componentDidMount() {
    this.init__loadVisionboardImages();
  }

  render() {
    return (
      <SelfmadeLayout
        breadcrumbs={["dashboard", "workspace", "visionboard"]}
        favorite={{
          name: "Visionboard",
          page: "/workspace/visionboard",
          icon: "visionboard"
        }}
      >
        <Grid>
          <Row>
            <Cell sm={24}>
              <div id="selfmadeVisionboard">
                <Grid type="full">
                  <Row>
                    <Cell sm={24}>
                      <div className="selfmade-visionboard-subtitle">
                        Warum ich jeden Tag aufstehe
                      </div>
                      <h2 className="selfmade-visionboard-title">
                        Meine tägliche Inspiration
                      </h2>
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={24} md={10}>
                      <VisionboardImage
                        position={2}
                        type="normal"
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          2,
                          "normal"
                        )}
                      />
                    </Cell>
                    <Cell sm={24} md={14}>
                      <VisionboardImage
                        position={1}
                        type="normal"
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          1,
                          "normal"
                        )}
                      />
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={24} md={8}>
                      <VisionboardImage
                        position={3}
                        type="normal"
                        size="small"
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          3,
                          "normal"
                        )}
                      />
                    </Cell>
                    <Cell sm={24} md={8}>
                      <VisionboardImage
                        position={4}
                        type="normal"
                        size="small"
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          4,
                          "normal"
                        )}
                      />
                    </Cell>
                    <Cell sm={24} md={8}>
                      <VisionboardImage
                        position={5}
                        type="normal"
                        size="small"
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          5,
                          "normal"
                        )}
                      />
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={24} md={14}>
                      <VisionboardImage
                        position={6}
                        type="normal"
                        size="medium"
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          6,
                          "normal"
                        )}
                      />
                    </Cell>
                    <Cell sm={24} md={10}>
                      <VisionboardImage
                        position={7}
                        type="normal"
                        size="medium"
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          7,
                          "normal"
                        )}
                      />
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={24} md={10}>
                      <VisionboardImage
                        position={8}
                        type="normal"
                        size="medium"
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          8,
                          "normal"
                        )}
                      />
                    </Cell>
                    <Cell sm={24} md={14}>
                      <VisionboardImage
                        position={9}
                        type="normal"
                        size="medium"
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          9,
                          "normal"
                        )}
                      />
                    </Cell>
                  </Row>
                </Grid>
              </div>
            </Cell>
          </Row>
        </Grid>
      </SelfmadeLayout>
    );
  }

  /**
   * Get Visionboard Image Url by Position and Type
   */
  get__visionboardImageUrl = (position, type) => {
    const { visionboardImages, defaultVisionboardImages } = this.state;

    let visionboardImage = undefined;

    visionboardImages.map(image => {
      if (image.position && image.position === position) {
        visionboardImage = image;
      }
      return null;
    });

    if (isUndefined(visionboardImage)) {
      defaultVisionboardImages.map(image => {
        if (image.position && image.position === position) {
          visionboardImage = image;
        }
        return null;
      });
    }

    if (isUndefined(visionboardImage)) {
      return null;
    }

    if (type === "blur") {
      return visionboardImage.url_blur;
    } else {
      return visionboardImage.url;
    }
  };

  /**
   * Load Vision Board Images
   */
  init__loadVisionboardImages() {
    axios
      .get(this.props.context.apiEndpoints.selfmadeVisionboardImages, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { visionboardImages, defaultVisionboardImages } = response.data;
        this.setState({ visionboardImages, defaultVisionboardImages });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(SelfmadeVisionboard);
