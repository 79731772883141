import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import withContext from "../../context/contextHOC";
import closeButton from "../../images/close-narrow-white.png";

class AdminLayout extends Component {
  state = {
    defaultBreadcrumbs: ["dashboard", "admin-dashboard"],
    breadcrumbs: [],
    active: this.props.active,
    user: {},
    hasPermission: null
  };

  componentDidMount() {
    /*const { defaultBreadcrumbs } = this.state;

    let breadcrumbs = defaultBreadcrumbs;

    const addedBreadcrumbs = this.props.breadcrumbs;
    if (addedBreadcrumbs) {
      breadcrumbs = [...defaultBreadcrumbs, ...addedBreadcrumbs];
    }

    this.setState({ breadcrumbs });*/
  }

  componentDidUpdate(prevProps) {
    if (this.props.context.user !== prevProps.context.user) {
      this.setState(
        {
          user: this.props.context.user
        },
        () => {
          // Check permissions
          const { user, active } = this.state;

          let activePermission = active;
          if (activePermission === "livequestions") {
            activePermission = "live";
          } else if (activePermission === "videorequests") {
            activePermission = "courses";
          }

          if (user && user.admin) {
            if (user.admin.permissions[activePermission] === true) {
              this.setState({ hasPermission: true });
            } else {
              this.setState({ hasPermission: false });
            }
          }
        }
      );
    }
  }

  render() {
    const { hasPermission } = this.state;
    const _c = this.props.context;

    let output = "";

    if (hasPermission === true) {
      output = this.props.children;
    } else if (hasPermission === false) {
      output = <div>No access.</div>;
    }

    return (
      <div id="pageAdministration" className="page-with-topbar">
        <MainTopbar type="admin" />
        {/*<Breadcrumbs breadcrumbs={this.state.breadcrumbs} />*/}

        <div id="adminNavigationWrapper">
          <div className="admin-nav-close show-for-small-only">
            <img src={closeButton} alt="X" onClick={_c.toggleAdminNav} />
          </div>
          <div id="adminNavigation">{this.view__showNavigation()}</div>
        </div>

        <section className="content">
          <Grid type="full">
            <Row>
              <Cell sm={24} md={24} lg={24}>
                {output}
              </Cell>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }

  view__showNavigation() {
    const { user } = this.props.context;
    const _c = this.props.context;

    if (user && user.admin) {
      const navCategories = [
        {
          name: "",
          items: [
            {
              slug: "dashboard",
              url: "/admin",
              name: "Dashboard",
              icon: "th",
              permission: "dashboard"
            },
            {
              slug: "announcements",
              url: "/admin/announcements",
              name: "Ankündigungen",
              icon: "bullhorn",
              permission: "announcements"
            }
          ]
        },
        {
          name: "Kurse",
          items: [
            {
              slug: "courses",
              url: "/admin/courses",
              name: "Kurse",
              icon: "graduation-cap",
              permission: "courses"
            },
            {
              slug: "live",
              url: "/admin/live",
              name: "Live Calls",
              icon: "video-camera",
              permission: "live"
            },
            {
              slug: "livequestions",
              url: "/admin/live/questions",
              name: "Live Call Fragen",
              icon: "question-circle",
              permission: "liveQuestions"
            },
            {
              slug: "videorequests",
              url: "/admin/videorequests",
              name: "Videowünsche",
              icon: "play-circle",
              permission: "courses"
            }
          ]
        },
        {
          name: "Workspace",
          items: [
            {
              slug: "quotes",
              url: "/admin/quotes",
              name: "Zitate",
              icon: "comment-o",
              permission: "quotes"
            },
            {
              slug: "books",
              url: "/admin/books",
              name: "Bücher der Woche",
              icon: "book",
              permission: "books"
            },
            {
              slug: "youtube",
              url: "/admin/youtube",
              name: "YouTube",
              icon: "youtube",
              permission: "youtube"
            }
          ]
        },
        {
          name: "Verwaltung",
          items: [
            {
              slug: "users",
              url: "/admin/users",
              name: "Mitglieder",
              icon: "users",
              permission: "users"
            },
            {
              slug: "permissions",
              url: "/admin/permissions",
              name: "Administratoren",
              icon: "vcard-o",
              permission: "permissions"
            },

            {
              slug: "settings",
              url: "/admin/settings",
              name: "Einstellungen",
              icon: "cogs",
              permission: "settings"
            }
          ]
        }
      ];

      return (
        <React.Fragment>
          <ul>
            {navCategories.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  <li className="admin-nav-category">{category.name}</li>

                  {category.items.map((item, index) => {
                    if (user.admin.permissions[item.permission] === true) {
                      return (
                        <li
                          key={index}
                          className={this.getNavigationClass(item.slug)}
                        >
                          <a href={item.url}>
                            <Icon icon={item.icon} left /> {item.name}
                          </a>
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })}
                </React.Fragment>
              );
            })}
          </ul>
          <div className="admin-nav-version">v{_c.getVersion()}</div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  getNavigationClass(type) {
    const { active } = this.state;

    if (active === type) {
      return "active";
    } else {
      return "";
    }
  }
}

export default withContext(AdminLayout);
