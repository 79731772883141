import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import Switch from "react-switch";

import qs from "query-string";
import { isUndefined } from "util";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

registerLocale("de", de);

class AdminCourseLesson extends Component {
  state = {
    courseId: this.props.match.params.courseId,
    course: {},
    lessonId: this.props.match.params.lessonId,
    lesson: {},
    originalLesson: {},
    editMode: true,
    lessonTypes: {
      vimeo: "Vimeo",
      live: "Live Call",
    },
    categories: [],
    newLessonCategory: 0,
    editorState: undefined,
  };

  componentDidMount() {
    const queryParams = qs.parse(this.props.location.search);
    if (!isUndefined(queryParams.category)) {
      this.setState({ newLessonCategory: queryParams.category }, () => {
        this.init__load();
      });
    } else {
      this.init__load();
    }
  }

  render() {
    const { course, lesson, lessonId, editorState } = this.state;

    let backUrl = "/admin/courses/" + this.state.courseId;

    let autoPublishDate = new Date();
    if (lesson.publish) {
      autoPublishDate = new Date(lesson.publish);
    }

    let vimeoPreviewUrl = "";

    if (lesson.type === "vimeo") {
      vimeoPreviewUrl = "https://player.vimeo.com/video/" + lesson.content;
    }

    let lessonUrl =
      this.props.context.hostUrl +
      "/kurse/" +
      course.identifier +
      "/" +
      lesson.identifier;

    if (lessonId === "new") {
      lessonUrl = null;
    }

    return (
      <AdminLayout active="courses">
        <Grid>
          <Row>
            <Cell sm={24}>
              <p>
                <a href={backUrl}>
                  <small>
                    <Icon icon="angle-double-left" left /> Zurück zum Kurs
                  </small>
                </a>
              </p>
              <strong>{course.name || "Wird geladen ..."}</strong>
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={16}>
                    <h1>{this.view__showLessonTitle()}</h1>
                  </Cell>
                  <Cell sm={24} md={8} classes="text-right">
                    <div style={this.view__hideWhenNew()}>
                      <button
                        className="small primary button"
                        onClick={this.handle__deleteLesson}
                      >
                        <Icon icon="trash" left /> Lektion löschen
                      </button>
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={12} lg={12}>
              <div className="admin-box adminBoxLessonData">
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3>Lektion</h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <Switch
                          onChange={this.handle__activation}
                          checked={!this.state.lesson.draft}
                          className="react-switch"
                          height={16}
                          width={36}
                        />{" "}
                        {this.view__showIsDraft()}
                      </Cell>
                    </Row>
                    <Row>
                      <Cell sm={24}>
                        <span className="admin-courses-lesson-url">
                          <Icon icon="long-arrow-right" left />
                          &nbsp;
                          {this.view__showLessonUrl(lessonUrl)}
                        </span>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>

              <div className="admin-box adminBoxLessonData">
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3 style={{ marginBottom: 0 }}>Informationen</h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <div style={this.view__visible("view")}>
                          <button
                            className="admin-icon-button"
                            onClick={() => {
                              this.setState({ editMode: true });

                              Array.from(
                                document.getElementsByClassName(
                                  "adminBoxLessonData"
                                )
                              ).forEach((element) => {
                                element.classList.remove("background-saved");
                              });
                            }}
                          >
                            <Icon icon="cog" />
                          </button>
                        </div>
                        <div style={this.view__hideWhenNew()}>
                          <div style={this.view__visible("edit")}>
                            <button
                              onClick={this.handle__editCancel}
                              className="small primary hollow button"
                            >
                              Abbrechen
                            </button>
                            <button
                              onClick={this.handle__editSave}
                              className="small primary button"
                            >
                              Speichern
                            </button>
                          </div>
                        </div>
                      </Cell>
                    </Row>

                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {lesson.name || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={lesson.name || ""}
                            onChange={(event) => {
                              this.handle__editChange(event, "name");
                            }}
                          />
                        </div>

                        <div className="info-caption">Name der Lektion</div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {lesson.type || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <select
                            style={{ marginBottom: 0 }}
                            onChange={(event) =>
                              this.handle__editChange(event, "type")
                            }
                            value={lesson.type}
                          >
                            {/*<option key={9999} value={0}>
                            --- bitte auswählen ---
                        </option>*
                          {Object.keys(this.state.courseTypes).map(
                            (key, index) => {
                              return (
                                <option key={index} value={key}>
                                  {this.state.courseTypes[key]}
                                </option>
                              );
                            }
                        )}*/}
                            <option value="vimeo">Vimeo</option>
                          </select>
                        </div>

                        <div className="info-caption">Typ</div>
                      </Cell>
                    </Row>
                    <div style={this.view__showVimeoContent()}>
                      <Row margin="x">
                        <Cell sm={24} md={24} lg={24}>
                          <div
                            className="info-content"
                            style={this.view__visible("view")}
                          >
                            {lesson.content || (
                              <span
                                dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                              />
                            )}
                          </div>
                          <div
                            className="info-content edit-mode"
                            style={this.view__visible("edit")}
                          >
                            <input
                              type="text"
                              value={lesson.content || ""}
                              onChange={(event) => {
                                this.handle__editChange(event, "content");
                              }}
                            />
                          </div>

                          <div className="info-caption">Vimeo-Video-Id</div>
                        </Cell>
                      </Row>
                    </div>
                    <div style={this.view__showVimeoContent()}>
                      <Row margin="x">
                        <Cell sm={24} md={24} lg={24}>
                          <div
                            className="info-content"
                            style={this.view__visible("view")}
                          >
                            {lesson.live || (
                              <span
                                dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                              />
                            )}
                          </div>
                          <div
                            className="info-content edit-mode"
                            style={this.view__visible("edit")}
                          >
                            <select
                              style={{ marginBottom: 0 }}
                              onChange={(event) =>
                                this.handle__editChange(event, "live")
                              }
                              value={lesson.live}
                            >
                              <option value={true}>ja</option>
                              <option value={false}>nein</option>
                            </select>
                          </div>

                          <div className="info-caption">
                            Live-Call-Aufzeichnung
                          </div>
                        </Cell>
                      </Row>
                    </div>
                  </Grid>
                </div>
              </div>

              <div className="admin-box adminBoxLessonData">
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3 style={{ marginBottom: 0 }}>Kategorie</h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <div style={this.view__visible("view")}>
                          <button
                            className="admin-icon-button"
                            onClick={() => {
                              this.setState({ editMode: true });

                              Array.from(
                                document.getElementsByClassName(
                                  "adminBoxLessonData"
                                )
                              ).forEach((element) => {
                                element.classList.remove("background-saved");
                              });
                            }}
                          >
                            <Icon icon="cog" />
                          </button>
                        </div>
                        <div style={this.view__hideWhenNew()}>
                          <div style={this.view__visible("edit")}>
                            <button
                              onClick={this.handle__editCancel}
                              className="small primary hollow button"
                            >
                              Abbrechen
                            </button>
                            <button
                              onClick={this.handle__editSave}
                              className="small primary button"
                            >
                              Speichern
                            </button>
                          </div>
                        </div>
                      </Cell>
                    </Row>

                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {lesson.category || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <select
                            value={
                              lesson.category || this.state.newLessonCategory
                            }
                            onChange={(event) => {
                              this.handle__editChange(event, "category");
                            }}
                          >
                            {this.state.categories.map((category, index) => {
                              return (
                                <option key={index} value={category.id}>
                                  {category.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="info-caption">
                          Kategorie der Lektion
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>

              <div className="admin-box adminBoxLessonData">
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3 style={{ marginBottom: 0 }}>
                          Automatische Veröffentlichung
                        </h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <div style={this.view__visible("view")}>
                          <button
                            className="admin-icon-button"
                            onClick={() => {
                              this.setState({ editMode: true });

                              Array.from(
                                document.getElementsByClassName(
                                  "adminBoxLessonData"
                                )
                              ).forEach((element) => {
                                element.classList.remove("background-saved");
                              });
                            }}
                          >
                            <Icon icon="cog" />
                          </button>
                        </div>
                        <div style={this.view__hideWhenNew()}>
                          <div style={this.view__visible("edit")}>
                            <button
                              onClick={this.handle__editCancel}
                              className="small primary hollow button"
                            >
                              Abbrechen
                            </button>
                            <button
                              onClick={this.handle__editSave}
                              className="small primary button"
                            >
                              Speichern
                            </button>
                          </div>
                        </div>
                      </Cell>
                    </Row>

                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          &nbsp;
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <select
                            style={{ marginBottom: 0 }}
                            onChange={(event) =>
                              this.handle__editChange(event, "publish")
                            }
                            value={lesson.publish !== null}
                          >
                            <option value={true}>ja</option>
                            <option value={false}>nein</option>
                          </select>
                        </div>

                        <div className="info-caption">
                          Automatisch veröffentlichen
                        </div>
                      </Cell>
                    </Row>

                    <div style={this.view__showAutoPublishDate()}>
                      <Row margin="x">
                        <Cell sm={24} md={24} lg={24}>
                          <div
                            className="info-content"
                            style={this.view__visible("view")}
                          >
                            {lesson.name || (
                              <span
                                dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                              />
                            )}
                          </div>
                          <div
                            className="info-content edit-mode"
                            style={this.view__visible("edit")}
                          >
                            <DatePicker
                              selected={autoPublishDate || new Date()}
                              onChange={(value) => {
                                this.handle__editPublishDate(value);
                              }}
                              dateFormat="dd.MM.yyyy - HH:mm"
                              locale="de"
                              showTimeSelect
                              timeIntervals={5}
                              timeFormat="HH:mm"
                              timeCaption="Uhrzeit"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </div>

                          <div className="info-caption">
                            Datum der Veröffentlichung
                          </div>
                        </Cell>
                      </Row>
                    </div>
                  </Grid>
                </div>
              </div>

              <div
                className="admin-box adminBoxLessonData"
                style={{
                  display: course.countdownable === true ? "block" : "none",
                }}
              >
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3 style={{ marginBottom: 0 }}>Countdown</h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <div style={this.view__visible("view")}>
                          <button
                            className="admin-icon-button"
                            onClick={() => {
                              this.setState({ editMode: true });

                              Array.from(
                                document.getElementsByClassName(
                                  "adminBoxLessonData"
                                )
                              ).forEach((element) => {
                                element.classList.remove("background-saved");
                              });
                            }}
                          >
                            <Icon icon="cog" />
                          </button>
                        </div>
                        <div style={this.view__hideWhenNew()}>
                          <div style={this.view__visible("edit")}>
                            <button
                              onClick={this.handle__editCancel}
                              className="small primary hollow button"
                            >
                              Abbrechen
                            </button>
                            <button
                              onClick={this.handle__editSave}
                              className="small primary button"
                            >
                              Speichern
                            </button>
                          </div>
                        </div>
                      </Cell>
                    </Row>

                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          &nbsp;
                        </div>
                        <div style={this.view__visible("edit")}>
                          <p style={{ paddingTop: "7px" }}>
                            Falls diese Lektion erst angezeigt werden soll,
                            sobald der individuelle Countdown abgelaufen ist,
                            wähle bitte <em>ja</em>. Falls diese Lektion direkt
                            von Anfang an angezeigt werden soll, wähle bitte{" "}
                            <em>nein</em>.
                          </p>

                          <div className="info-content edit-mode">
                            <select
                              style={{ marginBottom: 0 }}
                              onChange={(event) =>
                                this.handle__editChange(
                                  event,
                                  "show_after_countdown"
                                )
                              }
                              value={lesson.show_after_countdown}
                            >
                              <option value={true}>ja</option>
                              <option value={false}>nein</option>
                            </select>
                          </div>

                          <div className="info-caption">
                            Lektion erst nach dem Countdown zeigen?
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>
            </Cell>
            <Cell sm={24} md={12} lg={12}>
              <div className="admin-box adminBoxLessonData">
                <div className="admin-box-content">
                  <h3>Vorschau</h3>
                  <div className="responsive-embed widescreen">
                    <iframe
                      src={vimeoPreviewUrl}
                      width="640"
                      height="360"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                      title="Vorschau"
                    />
                  </div>
                </div>
              </div>

              <div className="admin-box adminBoxLessonData">
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3 style={{ marginBottom: 0 }}>
                          Beschreibung / Shownotes
                        </h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <div style={this.view__visible("view")}>
                          <button
                            className="admin-icon-button"
                            onClick={() => {
                              this.setState({ editMode: true });

                              Array.from(
                                document.getElementsByClassName(
                                  "adminBoxLessonData"
                                )
                              ).forEach((element) => {
                                element.classList.remove("background-saved");
                              });
                            }}
                          >
                            <Icon icon="cog" />
                          </button>
                        </div>
                        <div style={this.view__hideWhenNew()}>
                          <div style={this.view__visible("edit")}>
                            <button
                              onClick={this.handle__editCancel}
                              className="small primary hollow button"
                            >
                              Abbrechen
                            </button>
                            <button
                              onClick={this.handle__editSave}
                              className="small primary button"
                            >
                              Speichern
                            </button>
                          </div>
                        </div>
                      </Cell>
                    </Row>

                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content edit-mode no-edit-bg-color"
                          style={this.view__visible("edit")}
                        >
                          <Editor
                            editorState={editorState}
                            wrapperClassName="editor-wrapper"
                            editorClassName="editor-editor"
                            onEditorStateChange={this.onEditorStateChange}
                            localization={{
                              locale: "de",
                            }}
                            toolbar={{
                              options: [
                                "inline",
                                "blockType",
                                "fontSize",
                                "fontFamily",
                                "list",
                                "textAlign",
                                "link",
                                "emoji",
                                "remove",
                                "history",
                              ],
                            }}
                          />
                        </div>

                        <div className="info-caption">
                          Beschreibung / Shownotes
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>

              <div style={this.view__showWhenNew()}>
                <div style={this.view__visible("edit")} className="text-right">
                  <a
                    href={`/admin/courses/${course.id}`}
                    className="primary hollow button"
                  >
                    Abbrechen
                  </a>
                  <button
                    onClick={this.handle__editSave}
                    className="primary button"
                  >
                    Speichern <Icon icon="angle-right" />
                  </button>
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showLessonTitle() {
    const { lessonId, lesson } = this.state;

    if (lessonId === "new") {
      return "Neue Lektion";
    } else {
      if (lesson && lesson.name) {
        return lesson.name;
      } else {
        return "Wird geladen...";
      }
    }
  }

  view__showVimeoContent() {
    const { lesson } = this.state;

    if (lesson && lesson.type === "vimeo") {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  view__showAutoPublishDate() {
    const { lesson } = this.state;

    if (lesson && lesson.publish !== null) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  view__hideWhenNew() {
    if (this.state.lessonId === "new") {
      return { display: "none" };
    }
  }

  view__showWhenNew() {
    if (this.state.lessonId !== "new") {
      return { display: "none" };
    }
  }

  view__visible(type) {
    const { editMode } = this.state;

    if (
      (type === "edit" && editMode === true) ||
      (type === "view" && editMode === false)
    ) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  view__showIsDraft() {
    const { lesson } = this.state;

    if (!lesson.draft) {
      return (
        <small className="purchase-active next-to-switch">
          &nbsp;freigegeben&nbsp;&nbsp;&nbsp;
        </small>
      );
    } else {
      return (
        <small className="purchase-inactive next-to-switch">
          &nbsp;Entwurf&nbsp;&nbsp;&nbsp;
        </small>
      );
    }
  }

  view__showLessonUrl(lessonUrl) {
    if (lessonUrl === null) {
      return (
        <span>Die URL zur Lektion wird nach dem Speichern generiert.</span>
      );
    } else {
      return (
        <a href={lessonUrl} target="_blank" rel="noopener noreferrer">
          {lessonUrl}
        </a>
      );
    }
  }

  handle__editChange(event, property) {
    const { lesson } = this.state;

    if (property === "live" && event.target.value === "true") {
      lesson[property] = true;
    } else if (property === "live" && event.target.value === "false") {
      lesson[property] = false;
    } else {
      lesson[property] = event.target.value;
    }

    if (property === "publish" && event.target.value === "true") {
      let lessonPublishDate = new Date();
      lessonPublishDate.setHours(lessonPublishDate.getHours() + 1, 0, 0);
      lesson[property] = lessonPublishDate;
    } else if (property === "publish" && event.target.value === "false") {
      lesson[property] = null;
    }

    this.setState({ lesson });
  }

  handle__editPublishDate(value) {
    const { lesson } = this.state;
    lesson.publish = new Date(value);
    this.setState({ lesson });
  }

  handle__editCancel = () => {
    window.location.href = "/admin/courses/" + this.state.courseId;
  };

  handle__editSave = () => {
    const { lessonId, lesson, editorState } = this.state;

    Array.from(document.getElementsByClassName("adminBoxLessonData")).forEach(
      (element) => {
        element.classList.remove("background-saved");
      }
    );

    // Check if required fields are filled in
    if (!lesson.name || !lesson.type) {
      window.alert(
        "Bitte gib mindestens den Namen und den Typen der Lektion an."
      );
      return;
    }

    let url = this.props.context.apiEndpoints.adminLesson + "/" + lesson.id;
    if (lessonId === "new") {
      url = this.props.context.apiEndpoints.adminLesson + "/new";
    }

    if (lesson.publish !== null) {
      lesson.publish = new Date(lesson.publish);

      const publishDate =
        ("0" + lesson.publish.getDate()).slice(-2) +
        "." +
        ("0" + (lesson.publish.getMonth() + 1)).slice(-2) +
        "." +
        lesson.publish.getFullYear() +
        " - " +
        ("0" + lesson.publish.getHours()).slice(-2) +
        ":" +
        ("0" + lesson.publish.getMinutes()).slice(-2);

      lesson.publish = publishDate;
    }

    if (editorState.getCurrentContent().hasText()) {
      lesson.description = convertToRaw(editorState.getCurrentContent());
    } else {
      lesson.description = null;
    }

    axios
      .post(
        url,
        { lesson: lesson },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        if (lessonId === "new") {
          const { lesson } = response.data;

          //console.log(response.data);

          window.location.href =
            "/admin/courses/" + lesson.course + "/lesson/" + lesson.id;
          return;
        } else {
          const { lesson } = response.data;
          const originalLesson = JSON.parse(JSON.stringify(lesson));
          this.setState({ lesson, originalLesson });

          Array.from(
            document.getElementsByClassName("adminBoxLessonData")
          ).forEach((element) => {
            element.classList.add("background-saved");
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handle__activation = () => {
    const { lesson } = this.state;

    Array.from(document.getElementsByClassName("adminBoxLessonData")).forEach(
      (element) => {
        element.classList.remove("background-saved");
      }
    );

    axios
      .post(
        this.props.context.apiEndpoints.adminLessonActivation + "/" + lesson.id,
        {},
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { lesson } = response.data;
        const originalLesson = JSON.parse(JSON.stringify(lesson));
        this.setState({ lesson, originalLesson });

        Array.from(
          document.getElementsByClassName("adminBoxLessonData")
        ).forEach((element) => {
          element.classList.add("background-saved");
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handle__deleteLesson = () => {
    const { lesson } = this.state;

    if (
      window.confirm(
        "Möchtest Du diese Lektion wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!"
      )
    ) {
      axios
        .delete(
          this.props.context.apiEndpoints.adminLessonDelete + "/" + lesson.id,
          {
            headers: this.props.context.headers,
          }
        )
        .then((response) => {
          window.location.href = "/admin/courses/" + lesson.course;
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }

    return;
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  /**
   * Init
   * Load Course + Lesson Info
   */
  init__load() {
    const { lessonId, courseId, newLessonCategory } = this.state;

    let url = this.props.context.apiEndpoints.adminLesson + "/" + lessonId;
    if (lessonId === "new") {
      url = this.props.context.apiEndpoints.adminNewLesson + "/" + courseId;
    }

    if (lessonId) {
      axios
        .get(url, {
          headers: this.props.context.headers,
        })
        .then((response) => {
          let { lesson, course, categories } = response.data;

          if (!lesson.category) {
            lesson.category = newLessonCategory;
          }

          const originalLesson = JSON.parse(JSON.stringify(lesson));
          lesson = this.init_lesson(lesson);
          this.setState({ lesson, originalLesson, course, categories });
          if (lessonId === "new") {
            this.setState({ editMode: true });
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }

  init_lesson(lesson) {
    let editorState = EditorState.createEmpty();

    console.log("lesson", lesson);

    if (lesson.description) {
      editorState = EditorState.createWithContent(
        convertFromRaw(lesson.description)
      );
    }

    this.setState({ editorState });

    lesson.description_preview = draftToHtml(
      lesson.description_preview
    ).replace(/<\/?[^>]+>/gi, "");

    return lesson;
  }
}

export default withContext(AdminCourseLesson);
