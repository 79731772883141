import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import Breadcrumbs from "../topbars/breadcrumbs";
import Footer from "../layout/footer";

class ForumLayout extends Component {
  state = {};
  render() {
    return (
      <div id="pageForum" className="darkmode page-with-topbar-and-breadcrumbs">
        <MainTopbar />
        <Breadcrumbs
          breadcrumbs={this.props.breadcrumbs}
          favorite={{
            name: "Community",
            page: "/community",
            icon: "community"
          }}
        />
        <section className="content">{this.props.children}</section>
        <Footer />
      </div>
    );
  }
}

export default ForumLayout;
