import React, { Component } from "react";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import Task from "../../todo/task.jsx";
import { isNull } from "util";

import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

class TodoBig extends Component {
  state = {
    backgroundImageUrl: this.props.backgroundImageUrl,
    tasksToday: [],
    tasksTomorrow: [],
    tasksTodayFinished: {},
    tasksTomorrowFinished: {},
    showTasks: {},
    todoView: "today",
    showNewTask: false,
    newTask: {
      id: "new",
      due: null,
      finished: null,
      todolist: {}
    }
  };

  componentDidMount() {
    this.init__loadTasks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.backgroundImageUrl !== this.props.backgroundImageUrl) {
      this.setState({ backgroundImageUrl: this.props.backgroundImageUrl });
    }
  }

  render() {
    const { showTasks, backgroundImageUrl } = this.state;
    return (
      <React.Fragment>
        {this.state.tasksToday.map((task, index) => {
          return (
            <Task
              key={index}
              task={task}
              type="selfmade"
              show={showTasks[task.id]}
              onClose={() => {
                this.handle__closeTaskPopup(task.id);
              }}
              onUpdate={this.handle__onUpdate}
              onUpdateSelfmade={this.handle__onUpdateSelfmade}
            />
          );
        })}
        {this.state.tasksTomorrow.map((task, index) => {
          return (
            <Task
              key={index}
              task={task}
              type="selfmade"
              show={showTasks[task.id]}
              onClose={() => {
                this.handle__closeTaskPopup(task.id);
              }}
              onUpdate={this.handle__onUpdate}
              onUpdateSelfmade={this.handle__onUpdateSelfmade}
            />
          );
        })}
        <Task
          task={this.state.newTask}
          selectedList={this.state.selectedList}
          show={this.state.showNewTask}
          onClose={() => {
            this.handle__closeTaskPopup(this.state.newTask.id);
          }}
          onUpdate={this.handle__onUpdate}
          onListsUpdate={this.handle__onListsUpdate}
        />

        <div
          id="selfmadeGridTodoBig"
          className="selfmade-grid-bg-blur"
          style={{
            backgroundImage: `url('${backgroundImageUrl}')`
          }}
        >
          <div className="selfmade-grid-item selfmade-grid-bg-darker">
            <div className="selfmade-grid-item-content">
              {this.show__todo()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  /**
   * Show Tasks for today and tomorrow
   */
  show__todo() {
    const { todoView } = this.state;

    if (todoView === "tomorrow") {
      return (
        <div id="selfmadeGridTodoTomorrow">
          {this.show__todoTitleBar("tomorrow")}

          <div className="selfmade-todo-wrapper">
            <div className="selfmade-todo-content">
              {this.show__tasks(
                this.state.tasksTomorrow,
                this.state.tasksTomorrowFinished,
                "tomorrow"
              )}
            </div>
          </div>
        </div>
      );
    } else if (todoView === "today") {
      return (
        <div id="selfmadeGridTodoToday">
          {this.show__todoTitleBar("today")}

          <div className="selfmade-todo-wrapper">
            <div className="selfmade-todo-content">
              {this.show__tasks(
                this.state.tasksToday,
                this.state.tasksTodayFinished,
                "today"
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  /**
   * Show Tasks for Today or Tomorrow
   */
  show__tasks(tasks, tasksFinished, type) {
    let typeName = "";
    if (type === "today") {
      typeName = "heute";
    } else if (type === "tomorrow") {
      typeName = "morgen";
    }
    if (tasks.length === 0) {
      return (
        <Grid>
          <Row>
            <Cell sm={24} md={24} classes="text-center">
              <p>
                <em>Keine Aufgaben für {typeName}.</em>
              </p>
            </Cell>
          </Row>
        </Grid>
      );
    } else {
      return (
        <Grid>
          <Row>
            <Cell
              sm={12}
              smo={3}
              md={6}
              classes="medium-offset-0 large-offset-0"
            >
              {this.show__todoTaskFinishedPercentage(type)}
            </Cell>
            <Cell md={1} classes="hide-for-small-only" />
            <Cell sm={24} md={17}>
              <ul className="selfmade-todo-tasks">
                {tasks.map((task, index) => {
                  if (tasksFinished && tasksFinished.total > 3 && index >= 3) {
                    if (index === 3) {
                      return (
                        <li key={index} className="selfmade-todo-showalltasks">
                          <a href="/todo">Alle Aufgaben anzeigen</a>
                        </li>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <React.Fragment key={index}>
                        <li
                          onClick={() => {
                            this.handle__taskFinishedSwitch(task);
                          }}
                        >
                          <Grid type="full">
                            <Row>
                              <Cell sm={2} md={2}>
                                {this.show__todoTaskFinishedIcon(task)}
                              </Cell>
                              <Cell sm={20} md={20}>
                                {this.show__todoTaskName(task.name)}
                              </Cell>
                              <Cell sm={2} md={2} classes="text-right">
                                <button
                                  className="selfmade-todo-tasks-task-edit"
                                  onClick={event => {
                                    event.stopPropagation();
                                    this.handle__selectTask(task.id);
                                  }}
                                >
                                  <Icon icon="cog" />
                                </button>
                              </Cell>
                            </Row>
                          </Grid>
                        </li>
                      </React.Fragment>
                    );
                  }
                })}
              </ul>
            </Cell>
          </Row>
        </Grid>
      );
    }
  }

  /**
   * Show Titlebar for today or tomorrow
   */
  show__todoTitleBar(type) {
    let title = "";
    if (type === "today") {
      title = "Heutige Aufgaben";
    } else if (type === "tomorrow") {
      title = "Morgige Aufgaben";
    }

    let leftDisabled = false;
    let rightDisabled = false;

    if (type === "today") {
      leftDisabled = true;
    } else if (type === "tomorrow") {
      rightDisabled = true;
    }

    return (
      <Grid type="full">
        <Row>
          <Cell sm={20} md={20}>
            <h3 className="grid-caption">{title}</h3>
          </Cell>
          <Cell sm={4} md={4} classes="text-right">
            <button
              className="selfmade-todo-new-task"
              title="Neue Aufgabe hinzufügen"
              onClick={this.handle__newTask}
            >
              <Icon icon="plus" />
            </button>
            <button
              className="selfmade-todo-view-switch"
              onClick={this.handle__switchTodoView}
              disabled={leftDisabled}
            >
              <Icon icon="angle-left" left />
            </button>
            <button
              className="selfmade-todo-view-switch"
              onClick={this.handle__switchTodoView}
              disabled={rightDisabled}
            >
              <Icon icon="angle-right" right />
            </button>
          </Cell>
        </Row>
      </Grid>
    );
  }

  show__todoTaskName(taskName) {
    if (taskName.length > 30) {
      return taskName.substr(0, 30) + "...";
    } else {
      return taskName;
    }
  }

  /**
   * Show Icon for finished and unfinished tasks
   */

  show__todoTaskFinishedIcon(task) {
    if (task.finished !== null) {
      return (
        <span className="selfmade-grid-scheduled-routines-finished">
          <Icon icon="check-square-o" />
          <Icon icon="square-o" />
        </span>
      );
    } else {
      return (
        <span className="selfmade-grid-scheduled-routines-unfinished">
          <Icon icon="square-o" />
          <Icon icon="check-square-o" />
        </span>
      );
    }
  }

  /**
   * Show circular percentage
   */
  show__todoTaskFinishedPercentage(type) {
    let finished = undefined;
    if (type === "today") {
      finished = this.state.tasksTodayFinished;
    } else if (type === "tomorrow") {
      finished = this.state.tasksTomorrowFinished;
    } else {
      return null;
    }

    if (finished.total) {
      let text = finished.finished + " / " + finished.total;
      let color = "#b00606";

      if (finished.percentage === 100) {
        color = "green";
      } else if (finished.percentage >= 50) {
        color = "orange";
      }

      return (
        <div className="circular-progress">
          <CircularProgressbar
            percentage={finished.percentage}
            text={text}
            strokeWidth={10}
            styles={{
              path: { stroke: color },
              text: { fill: color, fontSize: "20px", fontFamily: "Oswald" }
            }}
          />
        </div>
      );
    }
  }

  /**
   * Handle CLick on top right arrow buttons in Todo View
   */
  handle__switchTodoView = () => {
    let { todoView } = this.state;

    if (todoView === "today") {
      todoView = "tomorrow";
    } else if (todoView === "tomorrow") {
      todoView = "today";
    }

    this.setState({ todoView });
  };

  /**
   * Show New Task Popup
   */
  handle__newTask = () => {
    this.setState({ showNewTask: true });
  };

  /**
   * Handle On Update from Task Popup
   */
  handle__onUpdate = updatedTask => {
    window.location.reload();
    /*
    let { tasksToday, tasksTomorrow } = this.state;

    tasksToday = tasksToday.map(task => {
      if (task.id === updatedTask.id) {
        task = updatedTask;
      }
      return task;
    });

    tasksTomorrow = tasksTomorrow.map(task => {
      if (task.id === updatedTask.id) {
        task = updatedTask;
      }
      return task;
    });

    this.setState({ tasksToday, tasksTomorrow });*/
  };

  handle__onUpdateSelfmade = data => {
    const { today, tomorrow, today_finished, tomorrow_finished } = data;

    this.setState({
      tasksToday: today,
      tasksTomorrow: tomorrow,
      tasksTodayFinished: today_finished,
      tasksTomorrowFinished: tomorrow_finished
    });
  };

  /**
   * Show Task Popup
   */
  handle__selectTask(taskId) {
    const { showTasks } = this.state;
    showTasks[taskId] = true;
    this.setState({ showTasks });
  }

  /**
   * Handle Finish Switch
   */
  handle__taskFinishedSwitch = task => {
    if (isNull(task.finished)) {
      task.finished = true;
    } else {
      task.finished = null;
    }

    this.setState({ task });

    const type = "selfmade";

    // Update in Database
    axios
      .patch(
        this.props.context.apiEndpoints.todoTaskFinish,
        {
          task_id: task.id,
          type: type
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        this.handle__onUpdateSelfmade(response.data);
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  /**
   * Close Task Popup
   */
  handle__closeTaskPopup = taskId => {
    if (taskId === "new") {
      this.setState({ showNewTask: false });
    } else {
      const { showTasks } = this.state;
      showTasks[taskId] = false;
      this.setState({ showTasks });
    }
  };

  /**
   * Load Tasks for today and tomorrow
   */
  init__loadTasks() {
    axios
      .get(this.props.context.apiEndpoints.selfmadeTodo, {
        headers: this.props.context.headers
      })
      .then(response => {
        const {
          today,
          tomorrow,
          today_finished,
          tomorrow_finished
        } = response.data;
        const { showTasks } = this.state;

        today.map(task => {
          showTasks[task.id] = false;
          return null;
        });

        tomorrow.map(task => {
          showTasks[task.id] = false;
          return null;
        });

        this.setState({
          tasksToday: today,
          tasksTomorrow: tomorrow,
          tasksTodayFinished: today_finished,
          tasksTomorrowFinished: tomorrow_finished,
          showTasks
        });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(TodoBig);
