import React, { Component } from "react";
import { Grid, Row, Cell } from "../foundation/foundation";
import Vimeo from "@vimeo/player";
import PTPopup from "../ptPopup/ptPopup";
import { Button, Icon } from "../foundation/_buttons";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { Link } from "react-router-dom";
import Live from "../live/live";
import { isUndefined } from "util";
import VideoTopicRequest from "../selfmade/elements/videoTopicRequest";
import AnnouncementBox from "../selfmade/AnnouncementBox";
import draftToHtml from "draftjs-to-html";
import PtCountdown from "../ptCountdown/ptCountdown";
import course from "./course";

class Lesson extends Component {
  state = {
    course: this.props.course,
    lesson: this.props.lesson,
    player: undefined,
    videoSeconds: this.props.lesson.last_played,
    lastUpdated: 0,
    videoTime: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    courseViewMode: "category",
    categoriesOpened: {},
    counter: 0,
    showNotePopup: false,
    showNoteDeleteButton: false,
    popupTitle: "Neue Notiz",
    notesView: "list",
    note: {
      id: 0,
      text: "",
    },
    countdownZero: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.lesson !== prevProps.lesson) {
      this.setState(
        {
          course: this.props.course,
          lesson: this.props.lesson,
        },
        () => {
          console.log(this.state.course);
          this.loadVimeoPlayer();
        }
      );
    }
  }

  componentDidMount() {
    if (this.state.lesson.type === "vimeo") {
      this.loadVimeoPlayer();
    }

    window.onbeforeunload = () => {
      this.updateCourseStatus();
    };

    const { categoriesOpened, lesson, course } = this.state;
    let { courseViewMode } = this.state;

    if (lesson.category) {
      categoriesOpened[lesson.category] = true;
    } else {
      categoriesOpened[0] = true;
    }
    if (course && course.viewMode) {
      courseViewMode = course.viewMode;
    }

    this.setState({ categoriesOpened, courseViewMode });
  }

  render() {
    const { course } = this.state;
    let announcementBox = "";
    let allLessons = this.showAllLessons();

    if (course.id === 4) {
      announcementBox = (
        <div style={{ marginBottom: "20px" }}>
          <AnnouncementBox type="selfmade_relaunch_august_2019" />
        </div>
      );
    } else if (course.id === 8 || course.id === 9) {
      announcementBox = (
        <div style={{ marginBottom: "20px" }}>
          <AnnouncementBox type="brand_building_2020" />
        </div>
      );
    } else if (course.id === 10) {
      announcementBox = (
        <div style={{ marginBottom: "20px" }}>
          <AnnouncementBox type="brand_building_special_2020" />
        </div>
      );
      /*allLessons = (
        <div style={{ marginTop: "-20px" }}>{this.showAllLessons()}</div>
      );*/
    }

    if (
      course.announcements &&
      (course.announcements.admin || course.announcements.dev)
    ) {
      announcementBox = (
        <div style={{ marginBottom: "20px" }}>
          <AnnouncementBox
            type="course"
            announcements={course.announcements}
            course={course}
          />
        </div>
      );
    }

    return (
      <div id="pageLesson">
        <Grid type="full">
          <Row padding="y">
            <Cell sm={24} md={18}>
              {announcementBox}
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={18}>
              <h2>{this.state.course.name}</h2>
              {this.showLessonName()}
              {this.showDescription()}
              {this.view__showLesson()}
            </Cell>
            <Cell sm={24} md={6}>
              {allLessons}
            </Cell>
          </Row>
        </Grid>

        <PTPopup
          show={this.state.showNotePopup}
          size="medium"
          handleClose={() => {
            this.setState({ showNotePopup: false });
          }}
        >
          <Grid>
            <Row>
              <Cell sm={24}>
                <h2>{this.state.popupTitle}</h2>
              </Cell>
            </Row>

            <Row>
              <Cell sm={9}>
                <label className="middle">Zeitpunkt im Video (H:M:S):</label>
              </Cell>
              <Cell sm={2}>
                <input
                  type="text"
                  className="note-popup-time"
                  placeholder="Std."
                  value={this.state.videoTime.hours || ""}
                  onChange={(event) => {
                    this.handleTimeChange(event, "h");
                  }}
                />
              </Cell>
              <Cell sm={1}>
                <div className="text-center">
                  <label className="middle">:</label>
                </div>
              </Cell>
              <Cell sm={2}>
                <input
                  type="text"
                  className="note-popup-time"
                  placeholder="Min."
                  value={this.state.videoTime.minutes || ""}
                  onChange={(event) => {
                    this.handleTimeChange(event, "m");
                  }}
                />
              </Cell>
              <Cell sm={1}>
                <div className="text-center">
                  <label className="middle">:</label>
                </div>
              </Cell>
              <Cell sm={2}>
                <input
                  type="text"
                  className="note-popup-time"
                  placeholder="Sek."
                  value={this.state.videoTime.seconds || ""}
                  onChange={(event) => {
                    this.handleTimeChange(event, "s");
                  }}
                />
              </Cell>
            </Row>

            <Row>
              <Cell sm={24}>
                <textarea
                  value={this.state.note.text}
                  rows={10}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                />
              </Cell>
            </Row>

            <div className="popup-form-buttons">
              <Row>
                <Cell sm={24} md={6}>
                  <div className="text-left">
                    <Button
                      type="primary"
                      hollow
                      classes={!this.state.showNoteDeleteButton ? "hide" : ""}
                      click={() => this.deleteNote(this.state.note.id)}
                    >
                      <Icon icon="trash" left /> Löschen
                    </Button>
                  </div>
                </Cell>
                <Cell sm={24} md={18}>
                  <div className="text-right">
                    <Button
                      type="primary"
                      hollow
                      click={() => {
                        this.notePopupCancel();
                      }}
                    >
                      <Icon icon="times" left /> Abbrechen
                    </Button>{" "}
                    <Button
                      type="primary"
                      click={() => {
                        this.saveNote();
                      }}
                    >
                      <Icon icon="check" left /> Speichern
                    </Button>
                  </div>
                </Cell>
              </Row>
            </div>
          </Grid>
        </PTPopup>
      </div>
    );
  }

  view__showLesson() {
    const { lesson } = this.state;

    if (!isUndefined(lesson) && lesson !== 404) {
      let bbSpecialBoxes;

      if (this.state.course.id === 10) {
        let link = "https://tpa-media.com/bb1";
        bbSpecialBoxes = (
          <div className="bb-special-boxes">
            <Grid type="full">
              <Row margin="x">
                <Cell sm={12} md={6}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <img src="/images/bb-special-box-4.jpg" alt="" />
                  </a>
                </Cell>
                <Cell sm={12} md={6}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <img src="/images/bb-special-box-2.jpg" alt="" />
                  </a>
                </Cell>
                <Cell sm={12} md={6}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <img src="/images/bb-special-box-1.jpg" alt="" />
                  </a>
                </Cell>
                <Cell sm={12} md={6}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <img src="/images/bb-special-box-3.jpg" alt="" />
                  </a>
                </Cell>
              </Row>
            </Grid>
          </div>
        );
      }

      return (
        <React.Fragment>
          {this.showContent()}
          {bbSpecialBoxes}
          {this.view__showCountdown()}
          <div className="text-right">
            {this.show__downloadButtons()}
            <Button
              type="primary"
              click={() => {
                this.notePopupShow();
              }}
            >
              <Icon icon="plus" left /> Neue Notiz
            </Button>
          </div>
          {this.showNotesArea()}
        </React.Fragment>
      );
    } else {
      return (
        <div className="text-center">
          <p style={{ paddingTop: "4rem" }}>
            <strong>Lektion nicht gefunden.</strong>
          </p>
        </div>
      );
    }
  }

  showLessonName() {
    const { course, lesson } = this.state;
    if (course.name === lesson.name || !lesson.name) {
      return null;
    } else {
      return <h3>{lesson.name}</h3>;
    }
  }

  showDescription() {
    if (this.state.lesson.description) {
      return (
        <div
          id="pageLessonDescription"
          dangerouslySetInnerHTML={{
            __html: draftToHtml(this.state.lesson.description),
          }}
        />
      );
    } else {
      return <div id="pageLessonDescription" />;
    }
  }

  showContent() {
    const { lesson } = this.state;

    if (lesson.type === "vimeo") {
      return (
        <div className="responsive-embed widescreen">
          <div id="vimeoPlayer">
            <div id="vimeoPlayerLoading">
              <i className="fa fa-spin fa-circle-o-notch" />
            </div>
          </div>
        </div>
      );
    }
  }

  view__showCountdown() {
    const { course, countdownZero } = this.state;
    const { purchase } = course;

    if (countdownZero === true) {
      return null;
    }

    if (!purchase || !purchase.countdown) {
      return null;
    }

    let countdownDate = new Date(purchase.countdown);
    let countdownTitle, countdownText;
    let countdownStyle = {};

    if (course.countdown_title) {
      countdownTitle = <h4>{course.countdown_title}</h4>;
      countdownStyle.marginTop = "20px";
    }

    if (course.countdown_text) {
      countdownText = (
        <div className="countdown-text">{course.countdown_text}</div>
      );
      countdownStyle.marginTop = "20px";
    }

    if (countdownDate) {
      return (
        <div className="course-countdown">
          <Grid type="full">
            <Row>
              <Cell sm={24} md={12} mdo={3}>
                {countdownTitle}
                {countdownText}
                <div style={countdownStyle}>
                  <PtCountdown
                    date={countdownDate}
                    type="course"
                    onZero={() => {
                      const { countdownZero } = this.state;
                      if (countdownZero === false) {
                        this.setState({ countdownZero: true });
                      }
                    }}
                  />
                </div>
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    }
  }

  view__showLiveCountdown() {
    const { course } = this.state;
    if (!course.nextLiveEvent) {
      return null;
    } else {
      return (
        <React.Fragment>
          <div id="pageLessonLiveCountdown">
            <Live type="course" event={course.nextLiveEvent} />
          </div>
          <div id="pageLessonLiveQuestion">
            <VideoTopicRequest type="course" course={course.id} />
          </div>
        </React.Fragment>
      );
    }
  }

  showAllLessons() {
    const { course, courseViewMode, countdownZero } = this.state;
    const { lessons, categories } = course;
    let counter = 0;
    let hrShown = false;

    let classesTime = "course-viewmode";
    let classesCategory = "course-viewmode";
    let stylesTimes = {};
    let stylesCategory = {};
    if (courseViewMode === "time") {
      classesTime += " selected";
      stylesTimes = { display: "block" };
      stylesCategory = { display: "none" };
    } else if (courseViewMode === "category") {
      classesCategory += " selected";
      stylesTimes = { display: "none" };
      stylesCategory = { display: "block" };
    }

    return (
      <React.Fragment>
        {this.view__showWhatsappSupportButton()}
        {this.view__showLiveCountdown()}
        <div className="course-box">
          <div className="course-box-content">
            <Grid type="full">
              <Row>
                <Cell sm={16}>
                  <h2>Kursübersicht</h2>
                </Cell>
                <Cell sm={8} classes="text-right">
                  <button
                    onClick={() => {
                      this.setState({ courseViewMode: "category" }, () => {
                        this.handle__updateViewMode();
                      });
                    }}
                    className={classesCategory}
                    title="Ansicht nach Themen"
                  >
                    <Icon icon="bars" />
                  </button>
                  <button
                    onClick={() => {
                      this.setState({ courseViewMode: "time" }, () => {
                        this.handle__updateViewMode();
                      });
                    }}
                    className={classesTime}
                    title="Chronologische Ansicht"
                  >
                    <Icon icon="clock-o" />
                  </button>
                </Cell>
              </Row>
            </Grid>
          </div>
        </div>
        <div className="small course-box course-view-time" style={stylesTimes}>
          <div className="course-box-content">
            <h3>
              <Icon icon="clock-o" left /> Chronologische Ansicht
            </h3>

            <ul>
              {lessons.map((lesson, index) => {
                if (
                  course.countdownable === true &&
                  lesson.show_after_countdown === true &&
                  countdownZero !== true
                ) {
                  return;
                }
                if (
                  course.id === 4 &&
                  lesson.position === 13 &&
                  hrShown === false
                ) {
                  counter++;
                  hrShown = true;
                  return (
                    <React.Fragment key={index}>
                      <li className="separator">
                        <hr />
                      </li>
                      <li className={this.get__lessonClasses(lesson)}>
                        {counter}. {this.show__lessonListEntry(lesson, course)}
                      </li>
                    </React.Fragment>
                  );
                } else {
                  counter++;
                  return (
                    <li key={index} className={this.get__lessonClasses(lesson)}>
                      {counter}. {this.show__lessonListEntry(lesson, course)}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
        {categories.map((category, index) => {
          const { lessons } = category;
          counter = 0;

          if (
            course.countdownable === true &&
            category.show_after_countdown === true &&
            countdownZero !== true
          ) {
            return;
          }

          // Is category opened or not?
          let categoryContentStyle = { display: "none" };
          if (this.isCategoryOpened(category.id) === true) {
            categoryContentStyle = { display: "block" };
          } else {
            categoryContentStyle = { display: "none" };
          }

          return (
            <div
              className="small course-box course-view-category"
              style={stylesCategory}
              key={index}
            >
              <div className="course-box-content">
                <div
                  className="course-box-opener"
                  onClick={() => {
                    this.handle__selectCategory(category.id);
                  }}
                >
                  <Grid type="full">
                    <Row>
                      <Cell sm={22}>
                        <h3>
                          <Icon icon="play-circle" left /> {category.name}
                        </h3>
                      </Cell>
                      <Cell sm={2} classes="text-right">
                        <Icon icon="caret-down" />
                      </Cell>
                    </Row>
                  </Grid>
                </div>

                <ul style={categoryContentStyle}>
                  {lessons.map((lesson, index) => {
                    if (
                      course.countdownable === true &&
                      lesson.show_after_countdown === true &&
                      countdownZero !== true
                    ) {
                      return;
                    }

                    counter++;
                    return (
                      <li
                        key={index}
                        className={this.get__lessonClasses(lesson)}
                      >
                        {counter}. {this.show__lessonListEntry(lesson, course)}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
        {this.showCourseDownloads()}
      </React.Fragment>
    );
  }

  get__lessonClasses(lesson) {
    let classes = "";

    if (lesson.id === this.state.lesson.id) {
      classes += " selected";
    }

    if (lesson.disabled === true) {
      classes += " lesson-link-disabled";
    }

    return classes;
  }

  show__lessonListEntry(lesson, course) {
    if (lesson.disabled === true) {
      if (lesson.publish !== null) {
        return (
          <span>
            <span>{lesson.name || course.name}</span>
            <br />
            <span title="Veröffentlichungsdatum" style={{ fontSize: "0.8rem" }}>
              <Icon icon="calendar-plus-o" left /> {lesson.publish_date} -{" "}
              {lesson.publish_time}
            </span>
          </span>
        );
      }
      return <span>{lesson.name || course.name}</span>;
    } else {
      return (
        <Link
          to={this.props.context.generateLessonUrl(
            course.identifier,
            lesson.identifier
          )}
        >
          {lesson.name || course.name}
        </Link>
      );
    }
  }

  showCourseDownloads() {
    const { course } = this.state;
    const downloads = course.downloads;

    if (downloads.length > 0) {
      return (
        <React.Fragment>
          <div className="course-box">
            <div className="course-box-content">
              <h3>
                <Icon icon="download" left /> Downloads
              </h3>
              <ul>
                {downloads.map((download, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={download.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.showDownloadType(download.type)}
                        {download.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  show__downloadButtons() {
    const { course } = this.state;
    const downloads = course.downloads;

    if (downloads.length > 0) {
      return (
        <React.Fragment>
          {downloads.map((download, index) => {
            return (
              <a
                className="hollow primary button lesson-download-button"
                href={download.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon icon="download" left />
                {download.name}
              </a>
            );
          })}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  showDownloadType(type) {
    if (type === "pdf") {
      return <span className="primary label">PDF</span>;
    } else {
      return null;
    }
  }

  view__showWhatsappSupportButton() {
    if (this.props.context.userHasSelfmadeVIP() && this.state.course.id === 4) {
      return (
        <div
          className="clickable whatsapp course-box"
          onClick={this.props.context.showSupportPopup}
        >
          <div className="course-box-content text-center">
            <h3 style={{ position: "relative", left: "-4px" }}>
              <Icon icon="whatsapp" left /> VIP WhatsApp Support
            </h3>
          </div>
        </div>
      );
    }

    return null;
  }

  showNotesArea() {
    return (
      <div id="pageLessonNotes">
        {/*<Grid type="full">
          <Row>
              
            <Cell sm={24} md={12}>
              <h3>Meine Notizen</h3>
            </Cell>
            <Cell sm={24} md={12} classes="text-right">
              <div className="shifter small button-group float-right">
                <button
                  className="secondary hollow button"
                  title="Als Liste anzeigen"
                  onClick={() => {
                    this.setState({ notesView: "list" });
                  }}
                >
                  <Icon icon="list" />
                </button>
                <button
                  className="secondary hollow button"
                  title="Als Karten anzeigen"
                  onClick={() => {
                    this.setState({ notesView: "cards" });
                  }}
                >
                  <Icon icon="th" />
                </button>
                </div>
            </Cell>
          </Row>
                </Grid>*/}

        <h3>Meine Notizen</h3>
        {this.showNotes()}
      </div>
    );
  }

  showNotes() {
    const { lesson } = this.state;
    if (lesson.notes.length === 0) {
      return <p>Noch keine Notizen vorhanden.</p>;
    } else {
      if (this.state.notesView === "cards") {
        return this.showNotesAsCards(lesson.notes);
      } else if (this.state.notesView === "list") {
        return this.showNotesAsList(lesson.notes);
      }
    }
  }

  showNotesAsCards(notes) {
    return (
      <div className="notes-as-cards">
        <Grid type="full">
          <Row margin="xy">
            {notes.map((note) => {
              return (
                <Cell sm={24} md={12} lg={8} key={note.id}>
                  <div
                    className="note"
                    onClick={() => {
                      this.editNote(note);
                    }}
                  >
                    <div
                      className="note-time"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.goToSecond(note.seconds);
                      }}
                      title="Zu dieser Stelle im Video springen"
                    >
                      <Icon icon="play-circle-o" left />
                      {this.showNoteTime(note)}
                    </div>
                    <div className="note-text">{note.text}</div>
                  </div>
                </Cell>
              );
            })}
          </Row>
        </Grid>
      </div>
    );
  }

  showNotesAsList(notes) {
    return (
      <div className="notes-as-list">
        <Grid type="full">
          <Row>
            {notes.map((note) => {
              return (
                <Cell sm={24} md={24} lg={24} key={note.id}>
                  <div
                    className="note"
                    onClick={() => {
                      this.editNote(note);
                    }}
                  >
                    <span
                      className="note-time"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.goToSecond(note.seconds);
                      }}
                      title="Zu dieser Stelle im Video springen"
                    >
                      <Icon icon="play-circle-o" left />
                      {this.showNoteTime(note)}
                    </span>
                    <div className="note-text">{note.text}</div>
                  </div>
                </Cell>
              );
            })}
          </Row>
        </Grid>
      </div>
    );
  }

  showNoteTime(note) {
    if (!note.seconds) {
      return null;
    } else {
      let { hours, minutes, seconds } = this.props.context.convertSeconds(
        note.seconds
      );

      let showTime = "";

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      if (hours > 0) {
        showTime = hours + ":";
      }

      showTime += minutes + ":" + seconds;

      return <React.Fragment>{showTime}</React.Fragment>;
    }
  }

  handle__selectCategory(categoryId) {
    const { categoriesOpened } = this.state;

    if (this.isCategoryOpened(categoryId) === true) {
      categoriesOpened[categoryId] = false;
    } else {
      categoriesOpened[categoryId] = true;
    }

    this.setState({ categoriesOpened });
  }

  isCategoryOpened(categoryId) {
    const { categoriesOpened } = this.state;
    let isOpened = false;

    if (categoriesOpened[categoryId]) {
      if (categoriesOpened[categoryId] === true) {
        isOpened = true;
      } else {
        isOpened = false;
      }
    } else {
      isOpened = false;
    }

    return isOpened;
  }

  loadVimeoPlayer() {
    let options = {
      id: this.state.lesson.content,
      loop: false,
    };

    if (this.state.player) {
      this.state.player.destroy();
    }

    let player = new Vimeo("vimeoPlayer", options);

    if (this.state.lesson.last_played > 0) {
      player.setCurrentTime(this.state.lesson.last_played);
    }

    player.on("play", (data) => {
      this.setState({ videoSeconds: data.seconds }, () => {
        this.updateCourseStatus();
      });
    });

    player.on("pause", (data) => {
      this.setState({ videoSeconds: data.seconds }, () => {
        this.updateCourseStatus();
      });
    });

    player.on("timeupdate", (data) => {
      this.setState({ videoSeconds: data.seconds });

      // Update Playback Position in Database every 15 seconds
      if (
        this.state.lastUpdated - data.seconds > 15 ||
        data.seconds - this.state.lastUpdated > 15
      ) {
        this.updateCourseStatus();
      }
    });

    this.setState({ player });
  }

  goToSecond(second) {
    const { player } = this.state;
    player.setCurrentTime(second);
  }

  getVideoTime(currentTime) {
    this.setState({ videoSeconds: currentTime.seconds });
  }

  handleChange(event) {
    const { note } = this.state;
    note.text = event.target.value;
    this.setState({ note });
  }

  handleTimeChange(event, type) {
    const { videoTime } = this.state;
    if (type === "h") {
      videoTime.hours = event.target.value;
    }
    if (type === "m") {
      videoTime.minutes = event.target.value;
    }
    if (type === "s") {
      videoTime.seconds = event.target.value;
    }
    this.setState({ videoTime });
  }

  editNote(note) {
    const editNote = JSON.parse(JSON.stringify(note));

    this.setState({
      note: editNote,
      popupTitle: "Notiz bearbeiten",
      showNoteDeleteButton: true,
      showNotePopup: true,
      videoTime: this.props.context.convertSeconds(note.seconds),
    });
  }

  notePopupShow() {
    this.state.player.getCurrentTime().then(
      function (seconds) {
        this.setState({
          videoSeconds: seconds,
          videoTime: this.props.context.convertSeconds(seconds),
          showNotePopup: true,
        });
      }.bind(this)
    );

    this.state.player.pause();
  }

  notePopupCancel() {
    const { note } = this.state;
    note.text = "";
    this.setState({
      note,
      popupTitle: "Neue Notiz",
      showNoteDeleteButton: false,
      showNotePopup: false,
    });
  }

  saveNote() {
    const { note } = this.state;

    let noteId = note.id ? note.id : undefined;
    let seconds = this.props.context.convertTimeToSeconds(this.state.videoTime);
    if (seconds < 0) {
      seconds = 0;
    }

    axios
      .post(
        this.props.context.apiEndpoints.note,
        {
          note_id: noteId,
          text: note.text,
          lesson: this.state.lesson.id,
          seconds: seconds,
        },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { lesson } = this.state;
        lesson.notes = response.data;
        this.setState({ lesson });

        this.notePopupCancel();
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  deleteNote(noteId) {
    if (
      window.confirm("Möchtest Du diese Notiz wirklich unwiderruflich löschen?")
    ) {
      axios
        .delete(this.props.context.apiEndpoints.note + "/" + noteId, {
          headers: this.props.context.headers,
        })
        .then((response) => {
          const { lesson } = this.state;
          lesson.notes = response.data;
          this.setState({ lesson });

          this.notePopupCancel();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  }

  updateCourseStatus() {
    const courseId = this.state.course.id;
    const lessonId = this.state.lesson.id;
    const seconds = this.state.videoSeconds;

    this.setState({ lastUpdated: seconds });

    axios
      .post(
        this.props.context.apiEndpoints.courseUpdateStatus,
        { course: courseId, lesson: lessonId, seconds: seconds },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        //console.log(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  handle__updateViewMode() {
    const { course, courseViewMode } = this.state;

    axios
      .post(
        this.props.context.apiEndpoints.courseUpdateViewMode +
          "/" +
          course.id +
          "/" +
          courseViewMode,
        {},
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        //
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
}

export default withContext(Lesson);
