import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Icon } from "../foundation/_buttons";
import axios from "axios";
import { Grid, Row, Cell } from "../foundation/_grid";
import draftToHtml from "draftjs-to-html";

class AnnouncementBox extends Component {
  state = {
    type: this.props.type,
    announcements: this.props.announcements,
    course: this.props.course,
    contents: {
      selfmade_relaunch_august_2019: {
        image: "/images/selfmade-relaunch.jpg",
        url: "https://www.digistore24.com/product/273551",
        external: true,
        needSelfmade: true,
      },
    },
    boxOpen: true,
  };

  componentDidUpdate(prevProps) {
    if (this.props.context.user !== prevProps.context.user) {
      this.updateBoxStatus();
    }
  }

  componentDidMount() {
    this.updateBoxStatus();
  }

  render() {
    return <React.Fragment>{this.view__showBox()}</React.Fragment>;
  }

  view__showBox() {
    const { type, contents, boxOpen } = this.state;

    if (contents && contents[type] && contents[type].needSelfmade === true) {
      const selfmadeStatus = this.props.context.getUserSelfmadeStatus();

      if (selfmadeStatus.active === true && selfmadeStatus.type !== "monthly") {
        return null;
      }
    }

    if (type === "dashboard") {
      let boxContent = "";
      if (boxOpen === true) {
        boxContent = (
          <React.Fragment>
            {this.view__showCloseIcon()}
            <div
              style={{
                position: "relative",
                backgroundColor: "#b00606",
                color: "white",
                height: "40px",
                lineHeight: "40px",
                fontSize: "1.1rem",
                fontWeight: "600",
                fontFamily: "Oswald",
                padding: "0 20px",
              }}
            >
              SELFMADE Neuigkeiten
            </div>
            {this.view__showDashboardBoxContent()}
          </React.Fragment>
        );
      } else if (boxOpen === false) {
        boxContent = (
          <React.Fragment>
            {this.view__showCloseIcon()}
            <div
              style={{
                position: "relative",
                backgroundColor: "#b00606",
                color: "white",
                height: "40px",
                lineHeight: "40px",
                fontSize: "1.1rem",
                fontWeight: "600",
                fontFamily: "Oswald",
                padding: "0 20px",
              }}
            >
              SELFMADE Neuigkeiten
            </div>
          </React.Fragment>
        );
      }

      return <div className="small announcement-box">{boxContent}</div>;
    } else if (type === "course") {
      let v__title = "Neuigkeiten";
      const { course } = this.state;
      if (course && course.name) {
        v__title = `${course.name}`;
      }

      let boxContent = "";
      if (boxOpen === true) {
        boxContent = (
          <React.Fragment>
            {this.view__showCloseIcon()}
            <div
              style={{
                position: "relative",
                backgroundColor: "#b00606",
                color: "white",
                height: "40px",
                lineHeight: "40px",
                fontSize: "1.1rem",
                fontWeight: "600",
                fontFamily: "Oswald",
                padding: "0 20px",
              }}
            >
              {v__title}
            </div>
            {this.view__showCourseBoxContent()}
          </React.Fragment>
        );
      } else if (boxOpen === false) {
        boxContent = (
          <React.Fragment>
            {this.view__showCloseIcon()}
            <div
              style={{
                position: "relative",
                backgroundColor: "#b00606",
                color: "white",
                height: "40px",
                lineHeight: "40px",
                fontSize: "1.1rem",
                fontWeight: "600",
                fontFamily: "Oswald",
                padding: "0 20px",
              }}
            >
              {v__title}
            </div>
          </React.Fragment>
        );
      }

      return <div className="small announcement-box">{boxContent}</div>;
    } else if (type === "brand_building_special_2020") {
      const _c = this.props.context;
      const { user } = _c;

      if (!user || !user.purchases) {
        return null;
      }

      let hasBBSpecial = false;
      Object.keys(user.purchases).map((purchaseCourseId) => {
        if (parseInt(purchaseCourseId) === 10) {
          hasBBSpecial = true;
        }
      });

      let imageUrl;
      let courseLink;
      if (hasBBSpecial === true) {
        imageUrl = "/images/banner-brand-building-special.jpg";
        //courseLink = "/kurse/736451";
        courseLink = "https://tpa-media.com/bb1";
      } else {
        return null;
      }

      let boxContent = "";
      if (boxOpen === true) {
        boxContent = (
          <React.Fragment>
            {this.view__showCloseIcon()}
            <img src={imageUrl} alt="" />
          </React.Fragment>
        );
      } else if (boxOpen === false) {
        boxContent = (
          <React.Fragment>
            {/*this.view__showCloseIcon()*/}
            <span
              className="announcement-box-open"
              title="Mehr anzeigen"
              style={{ marginTop: "-10px" }}
              onClick={(e) => {
                e.preventDefault();
                this.handle__button("open");
              }}
            >
              <Icon icon="caret-down" />
            </span>
            <div
              style={{
                position: "relative",
                backgroundColor: "#b00606",
                color: "white",
                height: "40px",
                lineHeight: "40px",
                fontSize: "1.2rem",
                fontWeight: "600",
                fontFamily: "Oswald",
                padding: "0 20px",
              }}
            >
              Lass uns Deine BRAND konzipieren
            </div>
          </React.Fragment>
        );
      }

      return (
        <a
          href={courseLink}
          target="_blank"
          rel="noopener noreferrer"
          className="announcement-box"
        >
          {boxContent}
        </a>
      );
    } else if (type === "brand_building_2020") {
      const _c = this.props.context;
      const { user } = _c;

      if (!user || !user.purchases) {
        return null;
      }

      let hasBB = false;
      let hasBBLight = false;
      Object.keys(user.purchases).map((purchaseCourseId) => {
        if (parseInt(purchaseCourseId) === 8) {
          hasBB = true;
        }
        if (parseInt(purchaseCourseId) === 9) {
          hasBBLight = true;
        }
      });

      let imageUrl;
      let courseLink;
      if (hasBB === true) {
        imageUrl = "/images/banner-brand-building.jpg";
        courseLink = "/kurse/335652";
      } else if (hasBBLight === true) {
        imageUrl = "/images/banner-brand-building-light.jpg";
        courseLink = "/kurse/470929";
      } else {
        return null;
      }

      let boxContent = "";
      if (boxOpen === true) {
        boxContent = (
          <React.Fragment>
            {this.view__showCloseIcon()}
            <img src={imageUrl} alt="" />
          </React.Fragment>
        );
      } else if (boxOpen === false) {
        boxContent = (
          <React.Fragment>
            {/*this.view__showCloseIcon()*/}
            <span
              className="announcement-box-open"
              title="Mehr anzeigen"
              style={{ marginTop: "-10px" }}
              onClick={(e) => {
                e.preventDefault();
                this.handle__button("open");
              }}
            >
              <Icon icon="caret-down" />
            </span>
            <div
              style={{
                position: "relative",
                backgroundColor: "#b00606",
                color: "white",
                height: "40px",
                lineHeight: "40px",
                fontSize: "1.2rem",
                fontWeight: "600",
                fontFamily: "Oswald",
                padding: "0 20px",
              }}
            >
              BRAND BUILDING Live Call Termine
            </div>
          </React.Fragment>
        );
      }

      return (
        <a href={courseLink} className="announcement-box">
          {boxContent}
        </a>
      );
    } else if (type === "selfmade_relaunch_august_2019") {
      return null;
      const content = contents[type];

      let target = "";
      let rel = "";

      if (content.external === true) {
        target = "_blank";
        rel = "noopener noreferrer";
      }

      let boxContent = "";
      if (boxOpen === true) {
        boxContent = (
          <React.Fragment>
            {this.view__showCloseIcon()}
            <img src={content.image} alt="" />
          </React.Fragment>
        );
      } else if (boxOpen === false) {
        boxContent = (
          <React.Fragment>
            {this.view__showCloseIcon()}
            <div
              style={{
                position: "relative",
                backgroundColor: "#b00606",
                color: "white",
                height: "55px",
                lineHeight: "55px",
                fontSize: "1.6rem",
                fontWeight: "600",
                fontFamily: "Oswald",
                padding: "0 20px",
              }}
            >
              50% RABATT AUF DAS UPGRADE
            </div>
          </React.Fragment>
        );
      }

      return (
        <a
          href={content.url}
          target={target}
          rel={rel}
          className="announcement-box"
        >
          {boxContent}
        </a>
      );
    }

    return null;
  }

  view__showCloseIcon() {
    const { boxOpen } = this.state;

    const closeButton = (
      <span
        title="Schließen"
        className="announcement-box-close"
        onClick={(e) => {
          e.preventDefault();
          this.handle__button("close");
        }}
      >
        <Icon icon="times" />
      </span>
    );
    const openButton = (
      <span
        className="announcement-box-open"
        title="Mehr anzeigen"
        onClick={(e) => {
          e.preventDefault();
          this.handle__button("open");
        }}
      >
        <Icon icon="caret-down" />
      </span>
    );

    let output = closeButton;

    if (boxOpen === false) {
      output = openButton;
    }

    return output;
  }

  view__showDashboardBoxContent() {
    const { announcements } = this.state;

    if (announcements.admin && announcements.dev) {
      return null;
    } else if (announcements.admin || announcements.dev) {
      let announcement;
      if (announcements.admin) {
        announcement = announcements.admin;
      } else if (announcements.dev) {
        announcement = announcements.dev;
      }

      if (announcement) {
        let boxContentText = (
          <div className="announcement-box-content-text">
            <div
              dangerouslySetInnerHTML={{
                __html: draftToHtml(announcement.content),
              }}
            />
          </div>
        );

        // image is actually a vimeo ID ;)
        if (announcement.image) {
          let vimeoUrl = "https://player.vimeo.com/video/" + announcement.image;
          return (
            <div className="announcement-box-content">
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={12} lg={12}>
                    {boxContentText}
                  </Cell>
                  <Cell sm={24} md={12} lg={12}>
                    <div className="responsive-embed widescreen">
                      <iframe
                        src={vimeoUrl}
                        width="640"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        title="Ankündigung"
                      />
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </div>
          );
        } else {
          return (
            <div className="announcement-box-content">
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={24} lg={24}>
                    {boxContentText}
                  </Cell>
                </Row>
              </Grid>
            </div>
          );
        }
      }
    }
  }

  view__showCourseBoxContent() {
    const { announcements } = this.state;

    if (announcements.admin && announcements.dev) {
      return null;
    } else if (announcements.admin || announcements.dev) {
      let announcement;
      if (announcements.admin) {
        announcement = announcements.admin;
      } else if (announcements.dev) {
        announcement = announcements.dev;
      }

      if (announcement) {
        let boxContentText = (
          <div className="announcement-box-content-text">
            <div
              dangerouslySetInnerHTML={{
                __html: draftToHtml(announcement.content),
              }}
            />
          </div>
        );

        // image is actually a vimeo ID ;)
        if (announcement.image) {
          let vimeoUrl = "https://player.vimeo.com/video/" + announcement.image;
          return (
            <div className="announcement-box-content">
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={12} lg={12}>
                    {boxContentText}
                  </Cell>
                  <Cell sm={24} md={12} lg={12}>
                    <div className="responsive-embed widescreen">
                      <iframe
                        src={vimeoUrl}
                        width="640"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        title="Ankündigung"
                      />
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </div>
          );
        } else {
          return (
            <div className="announcement-box-content">
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={24} lg={24}>
                    {boxContentText}
                  </Cell>
                </Row>
              </Grid>
            </div>
          );
        }
      }
    }
  }

  updateBoxStatus() {
    const { user_settings } = this.props.context.user;
    const { type, boxOpen } = this.state;

    let updatedBoxOpen = true;

    if (user_settings) {
      if (user_settings[type] && user_settings[type].value === "closed") {
        updatedBoxOpen = false;
      }

      if (boxOpen !== updatedBoxOpen) {
        this.setState({ boxOpen: updatedBoxOpen });
      }
    }
  }

  handle__button(action) {
    try {
      const { type } = this.state;

      axios
        .post(
          this.props.context.apiEndpoints.announcementBoxButton,
          {
            type: type,
            action: action,
          },
          {
            headers: this.props.context.headers,
          }
        )
        .then((response) => {
          const { announcementBoxStatus } = response.data;

          if (announcementBoxStatus === "opened") {
            this.setState({ boxOpen: true });
          } else if (announcementBoxStatus === "closed") {
            this.setState({ boxOpen: false });
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } catch {}
  }
}

export default withContext(AnnouncementBox);
