import React, { Component } from "react";
import { Icon } from "../../foundation/_buttons";
import PTPopup from "../../ptPopup/ptPopup";
import Dropzone from "react-dropzone";
import axios from "axios";
import { isUndefined } from "util";
import withContext from "../../../context/contextHOC";
import madeLogo from "../../../images/made.png";

class VisionboardImage extends Component {
  state = {
    uploadImage: undefined,
    isUploading: false,
    position: this.props.position,
    backgroundImageUrl: this.props.backgroundImageUrl,
    size: this.props.size,
    showMadeLogo: this.props.showMadeLogo
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.backgroundImageUrl !== this.props.backgroundImageUrl) {
      this.setState({ backgroundImageUrl: this.props.backgroundImageUrl });
    }
  }

  render() {
    const { backgroundImageUrl, position } = this.state;
    return (
      <div
        id={"selfmadeGridVisionBoardImage_" + position}
        className={this.get_classes()}
        style={{
          backgroundImage: `url('${backgroundImageUrl}')`
        }}
      >
        <div className="selfmade-grid-item">
          <div className="selfmade-settings-icons">
            <button onClick={this.show__popupImageUpload}>
              <Icon icon="cog" />
            </button>
          </div>

          <PTPopup
            show={this.state.popupImageUpload}
            size="medium"
            nopadding
            handleClose={() => {
              this.hide__popupImageUpload();
            }}
          >
            {this.show__popupContent()}
          </PTPopup>
        </div>
        {this.show__madeLogo()}
      </div>
    );
  }

  get_classes() {
    let classes =
      "selfmade-visionboard-image selfmade-grid-item selfmade-grid-bg";

    if (this.state.size === "small") {
      classes += " selfmade-grid-item-small";
    } else if (this.state.size === "medium") {
      classes += " selfmade-grid-item-medium";
    } else {
      classes += " selfmade-grid-item-big";
    }

    return classes;
  }

  /**
   * Show Upload Dropzone or Please Wait - Uploading
   */
  show__popupContent() {
    const { isUploading } = this.state;

    if (isUploading === true) {
      return (
        <div className="selfmade-visionboard-upload">
          <h2>Bild hochladen</h2>
          <div className="selfmade-visionboard-upload-text">
            <p>
              <Icon icon="circle-o-notch fa-spin" />
            </p>
            <p>
              Bitte warten. Bild wird hochgeladen und verarbeitet ...
              <br />
              Das kann bis zu 30 Sekunden dauern. Bitte habe Geduld.
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <Dropzone
          onDrop={acceptedFiles => {
            const picture = acceptedFiles[0];

            this.setState({ isUploading: true });

            this.setState({ uploadImage: picture }, () => {
              this.handle__uploadAvatar();
            });
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="selfmade-visionboard-upload">
                  <h2>Bild hochladen</h2>
                  <div className="selfmade-visionboard-upload-text">
                    <p>
                      <Icon icon="upload" />
                    </p>
                    <p>
                      Lege in diesem Popup eine Bilddatei (JPG, GIF, PNG) ab
                      oder
                      <br className="hide-for-small-only" />
                      klicke hier, um ein Benutzerbild hochzuladen.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      );
    }
  }

  /**
   * Get Visionboard Image Url and return style object
   */
  show__visionboardImage() {
    const { type, position } = this.state;
    const visionboardImage = this.get__visionboardImage(position);

    let visionboardImageUrl = "";

    if (!isUndefined(visionboardImage)) {
      if (type === "blur") {
        visionboardImageUrl = visionboardImage.url_blur;
      } else {
        visionboardImageUrl = visionboardImage.url;
      }

      return {
        backgroundImage: "url('" + visionboardImageUrl + "')"
      };
    }
  }

  show__madeLogo() {
    if (this.state.position === 1 && this.state.showMadeLogo === true) {
      return (
        <div className="selfmade-qotd-logo-made">
          <img src={madeLogo} alt="" />
        </div>
      );
    }
  }

  show__popupImageUpload = () => {
    this.setState({ popupImageUpload: true });
  };

  hide__popupImageUpload = () => {
    this.setState({ popupImageUpload: false });
  };

  handle__uploadAvatar = () => {
    const { uploadImage, position } = this.state;
    const formData = new FormData();

    formData.append("file", uploadImage);
    formData.append("name", uploadImage.name);
    formData.append("position", position);

    axios
      .post(
        this.props.context.apiEndpoints.selfmadeVisionboardImageUpload,
        formData,
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        //console.log(response.data);
        const { path } = response.data;
        if (path) {
          window.location.reload();
        }
      })
      .catch(error => {
        console.log("ERROR", error);
        this.setState({ isUploading: false });
      });
  };
}

export default withContext(VisionboardImage);
