import React, { Component } from "react";
import AdminLayout from "../AdminLayout";

import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import ReactTooltip from "react-tooltip";

import Avatar from "../../user/_avatar";

class AdminManagersOverview extends Component {
  state = {
    userId: this.props.match.params.userId,
    permissionTypes: {
      full_admin: {
        title: "Volle Administrationsrechte",
        icon: "star",
        show: true
      },
      dashboard: {
        title: "Darf auf das Dashboard zugreifen",
        icon: "th",
        show: true
      },
      announcements: {
        title: "Darf die Ankündigung auf dem Dashboard verwalten",
        icon: "bullhorn",
        show: true
      },
      users: {
        title: "Darf Mitglieder verwalten",
        icon: "users",
        show: true
      },
      courses: {
        title: "Darf Kurse verwalten",
        icon: "graduation-cap",
        show: true
      },
      live: {
        title: "Darf Live Calls verwalten",
        icon: "play",
        show: true
      },

      books: {
        title: "Darf die Bücher der Woche verwalten",
        icon: "book",
        show: true
      },
      quotes: {
        title: "Darf die Zitate des Tages verwalten",
        icon: "comment-o",
        show: true
      },
      youtube: {
        title: "Darf die Youtube Empfehlungen verwalten",
        icon: "youtube",
        show: true
      },
      forum: {
        title: "Darf Themen und Beiträge im Forum administrieren",
        icon: "comments-o",
        show: true
      },
      permissions: {
        title: "Darf die Zugriffsrechte der Administratoren verwalten",
        icon: "key",
        show: true
      }
    },
    managers: []
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    const { permissionTypes, userId } = this.state;

    let backButton;
    if (userId) {
      backButton = (
        <div className="adminback-link">
          <a href={"/admin/permissions/"}>
            <Icon icon="angle-left" left /> Zurück zur Übersicht
          </a>
        </div>
      );
    }

    return (
      <AdminLayout active="permissions">
        <ReactTooltip place="top" type="dark" effect="solid" />
        <Grid>
          <Row>
            <Cell sm={24}>
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={16}>
                    {backButton}
                    <div>
                      <strong>Administratoren</strong>
                    </div>
                    <h1>Berechtigungen</h1>
                  </Cell>
                  <Cell sm={24} md={8} classes="text-right">
                    <a
                      href="/admin/users"
                      className="primary button hide-for-small-only"
                    >
                      <Icon icon="plus" left /> Administrator hinzufügen
                    </a>
                    <a
                      href="/admin/users"
                      className="small primary button show-for-small-only"
                    >
                      <Icon icon="plus" left /> Administrator hinzufügen
                    </a>
                  </Cell>
                </Row>
                <Row>
                  <Cell sm={24}>
                    <div className="small admin-box">
                      <div className="admin-box-content">
                        <ul className="admin-list hide-for-small-only">
                          <li className="admin-list-item admin-list-title">
                            <Grid type="full">
                              <Row>
                                <Cell md={1}>&nbsp;</Cell>
                                <Cell md={1}>&nbsp;</Cell>
                                <Cell md={4}>Name</Cell>
                                {Object.keys(permissionTypes).map(function(
                                  key,
                                  index
                                ) {
                                  let type = permissionTypes[key];
                                  return (
                                    <Cell
                                      key={index}
                                      sm={1}
                                      md={1}
                                      classes="text-center"
                                    >
                                      <span data-tip={type.title}>
                                        <Icon icon={type.icon} />
                                      </span>
                                    </Cell>
                                  );
                                })}
                                <Cell md={3}>&nbsp;</Cell>
                              </Row>
                            </Grid>
                          </li>
                        </ul>
                        <div className="admin-permissions">
                          {this.view__showManagers()}
                        </div>
                      </div>
                    </div>

                    {this.view__showCaptions()}
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showManagers() {
    const { managers, permissionTypes, userId } = this.state;

    return (
      <ul className="striped admin-list">
        {managers.map((manager, index) => {
          if (userId && parseInt(userId) !== manager.id) {
            return null;
          }

          let counter = 1 + index;
          const { permissions } = manager;

          let delButton = (
            <button
              className="tiny primary button"
              onClick={() => this.handle__deleteAdmin(manager)}
              style={{ marginTop: "9px", marginBottom: "0px" }}
              data-tip="Diesem Benutzer den Zugang zum Administrationsbereich und sämtliche Adminrechte entziehen"
            >
              entfernen
            </button>
          );

          let showDelButton = false;
          if (
            this.props.context.user &&
            this.props.context.user.admin &&
            this.props.context.user.admin.permissions
          ) {
            showDelButton = true;
            if (
              manager.id === this.props.context.user.id ||
              (manager.permissions.full_admin === true &&
                this.props.context.user.admin.permissions.full_admin === false)
            ) {
              showDelButton = false;
            }

            if (showDelButton === false) {
              delButton = "";
            }
          }

          return (
            <li
              className="admin-permissions-icon-list-item admin-list-item"
              id={`adminBoxData_${manager.id}`}
              key={index}
            >
              <Grid type="full">
                <Row>
                  <Cell sm={2} md={1}>
                    <div style={{ padding: "10px" }}>{counter}.</div>
                  </Cell>
                  <Cell sm={2} md={1} classes="text-center">
                    <div style={{ paddingTop: "10px" }}>
                      <Avatar user={manager} size="mainTopbar" />
                    </div>
                  </Cell>
                  <Cell sm={15} md={4}>
                    <div style={{ padding: "10px" }}>
                      <div>
                        <a href={`/admin/users/${manager.id}`} className="link">
                          {manager.lastname || ""}, {manager.firstname || ""}
                        </a>
                      </div>
                    </div>
                  </Cell>
                  {Object.keys(permissionTypes).map((key, index) => {
                    let button;

                    if (manager.id === this.props.context.user.id) {
                      button = (
                        <button className="admin-permissions-icon admin-permission-self">
                          <span>{this.permissionIcon(permissions, key)}</span>
                        </button>
                      );
                    } else {
                      button = (
                        <button
                          className="admin-permissions-icon"
                          onClick={() => {
                            this.handle__changePermission(
                              manager,
                              permissions,
                              key
                            );
                          }}
                        >
                          <span>{this.permissionIcon(permissions, key)}</span>
                        </button>
                      );
                    }

                    return (
                      <Cell
                        key={index}
                        sm={1}
                        md={1}
                        classes="text-center hide-for-small-only"
                      >
                        {button}
                      </Cell>
                    );
                  })}
                  <Cell sm={3} md={3} classes="text-right medium-text-left">
                    {delButton}
                  </Cell>
                </Row>
                <Row classes="show-for-small-only">
                  <Cell sm={2}>
                    <div style={{ padding: "10px" }}>&nbsp;</div>
                  </Cell>
                  <Cell sm={22}>
                    <Grid type="full">
                      <Row>
                        {Object.keys(permissionTypes).map(function(key, index) {
                          let type = permissionTypes[key];
                          return (
                            <Cell key={index} sm={2} classes="text-center">
                              <span data-tip={type.title}>
                                <Icon icon={type.icon} />
                              </span>
                            </Cell>
                          );
                        })}
                      </Row>
                      <Row>
                        {Object.keys(permissionTypes).map((key, index) => {
                          let button;

                          if (manager.id === this.props.context.user.id) {
                            button = (
                              <button className="admin-permissions-icon admin-permission-self">
                                <span>
                                  {this.permissionIcon(permissions, key)}
                                </span>
                              </button>
                            );
                          } else {
                            button = (
                              <button
                                className="admin-permissions-icon"
                                onClick={() => {
                                  this.handle__changePermission(
                                    manager,
                                    permissions,
                                    key
                                  );
                                }}
                              >
                                <span>
                                  {this.permissionIcon(permissions, key)}
                                </span>
                              </button>
                            );
                          }

                          return (
                            <Cell key={index} sm={2} classes="text-center">
                              {button}
                            </Cell>
                          );
                        })}
                      </Row>
                    </Grid>
                  </Cell>
                </Row>
              </Grid>
            </li>
          );
        })}
      </ul>
    );
  }

  view__showCaptions() {
    const { permissionTypes } = this.state;

    return (
      <div>
        <ul className="admin-permissions-captions">
          {Object.keys(permissionTypes).map((key, index) => {
            return (
              <li key={index}>
                <Grid type="full">
                  <Row>
                    <Cell sm={2} md={1} classes="text-right">
                      <Icon icon={permissionTypes[key].icon} />
                    </Cell>
                    <Cell sm={22} md={23}>
                      {permissionTypes[key].title}
                    </Cell>
                  </Row>
                </Grid>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  handle__changePermission(user, permissions, type) {
    document
      .getElementById("adminBoxData_" + user.id)
      .classList.remove("background-saved");

    axios
      .post(
        this.props.context.apiEndpoints.adminPermissionsUpdate +
          "/" +
          user.id +
          "/" +
          type,
        {},
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const { statusType, status, managers } = response.data;

        if (statusType === "ERROR") {
          if (status === "MANAGER_NOT_FOUND") {
            window.alert(
              "Fehler beim Finden des Administrators (" + status + ")"
            );
          } else if (status === "NO_FULL_ADMIN") {
            window.alert(
              'Du kannst die Rechte eines "Full Admins" nur bearbeiten bzw. einen neuen "Full Admin" hinzufügen, wenn Du selbst "Full Admin"-Rechte hast.'
            );
          }
        } else if (statusType === "SUCCESS") {
          if (status === "FULL_ADMIN_REMOVED") {
            /*window.alert(
              "Du hast dem Benutzer " +
                user.firstname +
                " " +
                user.lastname +
                ' die "Full Admin"-Rechte erfolgreich entzogen.'
            );*/
          } else if (status === "FULL_ADMIN_ADDED") {
            /*window.alert(
              "Der Benutzer " +
                user.firstname +
                " " +
                user.lastname +
                ' hat jetzt vollständige Administratorenrechte ("Full Admin")'
            );*/
          }

          this.setState({ managers });
          document
            .getElementById("adminBoxData_" + user.id)
            .classList.add("background-saved");
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  }

  permissionIcon(permissions, type) {
    if (permissions && permissions[type] && permissions[type] === true) {
      return (
        <span className="purchase-active">
          <Icon icon="check" />
        </span>
      );
    } else {
      return (
        <span className="purchase-inactive">
          <Icon icon="minus" />
        </span>
      );
    }
  }

  handle__deleteAdmin(manager) {
    if (
      window.confirm(
        "Möchtest Du " +
          manager.firstname +
          " " +
          manager.lastname +
          " den Zugang zum Administrationsbereich sowie sämtliche Adminrechte entziehen?\r\n\r\nDu kannst dem Benutzer natürlich jederzeit wieder Adminrechte verleihen."
      )
    ) {
      axios
        .delete(
          this.props.context.apiEndpoints.adminRemoveAdmin + "/" + manager.id,
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          window.location.href = "/admin/permissions";
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  }

  /**
   * Init
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.adminPermissions, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { managers } = response.data;
        this.setState({ managers });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminManagersOverview);
