import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";

class AdminQuotesOverview extends Component {
  state = {
    quotes: []
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    return (
      <AdminLayout active="quotes">
        <Grid>
          <Row>
            <Cell sm={24}>
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={16}>
                    <h1>Zitate</h1>
                    <p className="lead">
                      Im Workspace ganz oben links wird pro Tag ein Zitat
                      angezeigt. Sollten alle hinterlegten Zitate schon einmal
                      angezeigt worden sein, werden alle Zitate in
                      chronologischer Folge nochmal angezeigt.
                    </p>
                  </Cell>
                  <Cell sm={24} md={8} classes="text-right">
                    <a href="/admin/quotes/new" className="primary button">
                      <Icon icon="plus" left /> Zitat hinzufügen
                    </a>
                  </Cell>
                </Row>
              </Grid>

              <div className="small admin-box">
                <div className="admin-box-content">
                  <ul className="admin-list hide-for-small-only">
                    <li className="admin-list-item admin-list-title">
                      <Grid type="full">
                        <Row>
                          <Cell md={1} classes="hide-for-small-only">
                            &nbsp;
                          </Cell>
                          <Cell sm={24} md={13}>
                            Zitat
                          </Cell>
                          <Cell sm={24} md={6}>
                            Autor
                          </Cell>
                          <Cell sm={24} md={4} classes="text-center">
                            Zuletzt gezeigt
                          </Cell>
                        </Row>
                      </Grid>
                    </li>
                  </ul>
                  {this.view__showQuotes()}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showQuotes() {
    const { quotes } = this.state;

    if (quotes && quotes.length > 0) {
      return (
        <ul className="striped admin-list">
          {quotes.map((quote, index) => {
            let quoteDate = this.props.context.textDate(quote.last_showed);
            if (!quoteDate) {
              quoteDate = quote.last_showed_f;
            }

            return (
              <li className="clickable admin-list-item" key={index}>
                <a href={`/admin/quotes/${quote.id}`}>
                  <Grid type="full">
                    <Row>
                      <Cell sm={2} md={1} classes="text-center">
                        <Icon icon="comment-o" />
                      </Cell>
                      <Cell sm={22} md={13}>
                        <div>
                          {quote.quote}
                          <em className="show-for-small-only">
                            {quote.author ? ` - ${quote.author}` : ""}
                          </em>
                        </div>
                        <div className="show-for-small-only">
                          <small>Zuletzt angezeigt: {quoteDate}</small>
                        </div>
                      </Cell>
                      <Cell md={6} classes="hide-for-small-only">
                        {quote.author}
                      </Cell>
                      <Cell md={4} classes="text-center hide-for-small-only">
                        {quoteDate}
                      </Cell>
                    </Row>
                  </Grid>
                </a>
              </li>
            );
          })}
        </ul>
      );
    }

    return null;
  }

  /**
   * Init
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.adminQuotes, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { quotes } = response.data;
        this.setState({ quotes });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminQuotesOverview);
