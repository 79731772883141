import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";

class AdminBooksOverview extends Component {
  state = {
    books: []
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    return (
      <AdminLayout active="books">
        <Grid>
          <Row>
            <Cell sm={24}>
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={20}>
                    <h1>Bücher der Woche</h1>
                    <p className="lead">
                      Jede Kalenderwoche kann eine neue Buchempfehlung im
                      Workspace angezeigt werden. Sollte es für die aktuelle
                      Kalenderwoche keine Buch-Eintragung geben, wird
                      automatisch weiterhin das Buch der vorherigen Woche
                      angezeigt.
                    </p>
                  </Cell>
                  <Cell sm={24} md={4} classes="text-right">
                    <a href="/admin/books/new" className="primary button">
                      <Icon icon="plus" left /> Buch hinzufügen
                    </a>
                  </Cell>
                </Row>
              </Grid>

              <div className="small admin-box">
                <div className="admin-box-content">
                  <ul className="admin-list hide-for-small-only">
                    <li className="admin-list-item admin-list-title">
                      <Grid type="full">
                        <Row>
                          <Cell sm={2} md={2} classes="text-center">
                            Jahr
                          </Cell>
                          <Cell sm={2} md={2} classes="text-center">
                            <span title="Kalenderwoche">Woche</span>
                          </Cell>
                          <Cell sm={20} md={4}>
                            &nbsp;
                          </Cell>
                          <Cell sm={24} md={5}>
                            Autor
                          </Cell>
                          <Cell sm={24} md={7}>
                            Titel
                          </Cell>
                          <Cell sm={12} md={2} classes="text-center">
                            <span title="Bild-URL hinterlegt?">
                              <Icon icon="picture-o" />
                            </span>
                          </Cell>
                          <Cell sm={12} md={2} classes="text-center">
                            <span title="URL zur Verkaufsseite hinterlegt?">
                              <Icon icon="link" />
                            </span>
                          </Cell>
                        </Row>
                      </Grid>
                    </li>
                  </ul>
                  {this.view__showBooks()}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showBooks() {
    const { books } = this.state;

    if (books && books.length > 0) {
      return (
        <ul className="striped admin-list">
          {books.map((book, index) => {
            let url = "/admin/books/" + book.id;
            return (
              <li className="clickable admin-list-item" key={index}>
                <a href={url}>
                  <Grid type="full">
                    <Row>
                      <Cell md={2} classes="text-center hide-for-small-only">
                        {book.year}
                      </Cell>
                      <Cell md={2} classes="text-center hide-for-small-only">
                        {book.week}
                      </Cell>
                      <Cell md={4} classes=" hide-for-small-only">
                        ({book.firstDay} - {book.lastDay})
                      </Cell>
                      <Cell sm={20} md={5}>
                        <div className="hide-for-small-only">{book.author}</div>
                        <div className="show-for-small-only">
                          <div>
                            <small>
                              KW {book.week}, {book.year} ({book.firstDay} -{" "}
                              {book.lastDay})
                            </small>
                          </div>
                          <div>{book.name}</div>
                          <div>
                            <small>
                              <em>{book.author}</em>
                            </small>
                          </div>
                        </div>
                      </Cell>
                      <Cell md={7} classes="hide-for-small-only">
                        {book.name}
                      </Cell>
                      <Cell
                        sm={4}
                        classes="show-for-small-only"
                        style={{ paddingLeft: "10px" }}
                      >
                        <div>{this.view__showHasPicture(book)} Bild</div>
                        <div>{this.view__showHasUrl(book)} URL</div>
                      </Cell>
                      <Cell md={2} classes="text-center hide-for-small-only">
                        {this.view__showHasPicture(book)}
                      </Cell>
                      <Cell md={2} classes="text-center hide-for-small-only">
                        {this.view__showHasUrl(book)}
                      </Cell>
                    </Row>
                  </Grid>
                </a>
              </li>
            );
          })}
        </ul>
      );
    }

    return null;
  }

  view__showHasPicture(book) {
    if (book && book.image) {
      return (
        <span className="purchase-active">
          <Icon icon="check" />
        </span>
      );
    } else {
      return (
        <span className="purchase-inactive">
          <Icon icon="minus" />
        </span>
      );
    }
  }

  view__showHasUrl(book) {
    if (book && book.url) {
      return (
        <span className="purchase-active">
          <Icon icon="check" />
        </span>
      );
    } else {
      return (
        <span className="purchase-inactive">
          <Icon icon="minus" />
        </span>
      );
    }
  }

  /**
   * Init
   * Load Books of the Week
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.adminBooks, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { books } = response.data;
        this.setState({ books });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminBooksOverview);
