import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import PTPopup from "../../ptPopup/ptPopup";
import axios from "axios";

class AdminYoutubeOverview extends Component {
  state = {
    channels: [],
    showEditPopup: false,
    editChannel: null,
    editUrl: ""
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    return (
      <AdminLayout active="youtube">
        <Grid>
          <Row>
            <Cell sm={24}>
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={24}>
                    <h1>YouTube Empfehlungen</h1>
                    <p className="lead">
                      Im Workspace können bis zu drei empfohlene
                      Youtube-Accounts angezeigt werden. Es genügt, einfach nur
                      die URL zum Kanal anzugeben (z.B.{" "}
                      <em>https://www.youtube.com/user/GaryVaynerchuk</em>), den
                      Rest zieht sich das System mit Hilfe der Youtube-API
                      automatisch.
                    </p>
                  </Cell>
                </Row>
              </Grid>
              <div className="admin-youtube-channels">
                <Grid type="full">
                  <Row margin="xy">{this.view__showChannels()}</Row>
                </Grid>
              </div>
            </Cell>
          </Row>
        </Grid>
        {this.view__showEditPopup()}
      </AdminLayout>
    );
  }

  view__showChannels() {
    const { channels } = this.state;

    if (!channels || channels.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        {channels.map((channel, index) => {
          let lastVideos = (
            <Cell sm={24}>
              <div className="text-center">
                <small>Keine letzten Videos geladen.</small>
              </div>
            </Cell>
          );
          if (channel.videos && channel.videos.length > 0) {
            lastVideos = (
              <React.Fragment>
                {channel.videos.map((video, index) => {
                  return (
                    <Cell key={index} sm={8} md={8}>
                      <a
                        href={`https://www.youtube.com/watch?v=${video.video_id}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img src={video.thumbnail} alt="" />
                      </a>
                    </Cell>
                  );
                })}
              </React.Fragment>
            );
          }

          let boxContent;
          if (channel.url && !channel.channel_id && !channel.user_name) {
            boxContent = (
              <React.Fragment>
                <div className="admin-youtube-channel-loading">
                  <h3>Daten werden geladen ...</h3>
                  <div>
                    Bitte einen kleinen Moment Geduld. Die Daten werden von
                    Youtube abgerufen.
                  </div>
                </div>
              </React.Fragment>
            );

            window.setTimeout(() => {
              this.init__load();
            }, 5000);
          } else {
            boxContent = (
              <React.Fragment>
                <h3>
                  {channel.channel_title || "Kanalname noch nicht geladen"}
                </h3>
                <div className="admin-youtube-channel-url">
                  <a
                    href={channel.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {channel.url}
                  </a>
                </div>
                <div className="admin-youtube-channel-lastvideos">
                  <Grid type="full">
                    <Row>{lastVideos}</Row>
                  </Grid>
                </div>
                <div className="admin-youtube-channel-button">
                  <button
                    className="small primary button"
                    onClick={() => {
                      this.setState({
                        showEditPopup: true,
                        editChannel: index,
                        editUrl: channel.url
                      });
                    }}
                  >
                    <Icon icon="exchange" left /> YouTube Channel austauschen
                  </button>
                </div>
              </React.Fragment>
            );
          }

          return (
            <Cell sm={24} md={8} key={index}>
              <div className="admin-youtube-channel">
                <div className="small admin-box">
                  <div className="admin-box-content">{boxContent}</div>
                </div>
              </div>
            </Cell>
          );
        })}
      </React.Fragment>
    );
  }

  view__showEditPopup() {
    const { showEditPopup, editChannel, editUrl, channels } = this.state;

    if (
      showEditPopup === false ||
      editChannel === null ||
      !channels[editChannel]
    ) {
      return null;
    }

    const channel = channels[editChannel];

    return (
      <div className="selfmade-popup">
        <PTPopup
          show={showEditPopup}
          size="medium"
          handleClose={() => {
            this.setState({ showEditPopup: false });
          }}
        >
          <Grid type="full">
            <Row>
              <Cell sm={24}>
                <h2>YouTube Channel austauschen</h2>
                <p>
                  Wenn Du den YouTube Channel{" "}
                  <em>{channel.channel_title || "unbekannt"}</em> ersetzen
                  möchtest, gib einfach die URL des neuen Channels ein:
                </p>
                <Grid type="full">
                  <Row>
                    <Cell sm={24} md={18} lg={18}>
                      <input
                        type="text"
                        value={editUrl || ""}
                        placeholder="z.B. https://www.youtube.com/user/GaryVaynerchuk"
                        onChange={event => {
                          this.setState({ editUrl: event.target.value });
                        }}
                        onKeyUp={e => {
                          if (e.keyCode === 13) {
                            this.handle__saveChannelUrl();
                          }
                        }}
                      />
                    </Cell>
                    <Cell sm={24} md={6} lg={6}>
                      <button
                        className="primary button"
                        style={{ width: "100%" }}
                        onClick={this.handle__saveChannelUrl}
                      >
                        <Icon icon="check" left />
                        Speichern
                      </button>
                    </Cell>
                  </Row>
                </Grid>
              </Cell>
            </Row>
          </Grid>
        </PTPopup>
      </div>
    );
  }

  handle__saveChannelUrl = () => {
    const { editChannel, editUrl, channels } = this.state;

    const channel = channels[editChannel];

    axios
      .post(
        this.props.context.apiEndpoints.adminYoutube + "/" + channel.id,
        {
          url: editUrl
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const { channels } = response.data;
        this.setState({
          channels,
          editChannel: null,
          editUrl: "",
          showEditPopup: false
        });
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  /**
   * Init
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.adminYoutube, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { channels } = response.data;
        this.setState({ channels });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminYoutubeOverview);
