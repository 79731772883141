import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import "./stylesheets/app.scss";
import Index from "./components/index";
import Dashboard from "./components/dashboard/dashboard";
import MyProfile from "./components/user/myprofile";
import Error404 from "./components/errors/Error404";
import CoursesOverview from "./components/courses/overview";
import Course from "./components/courses/course";
import ChangePassword from "./components/user/password";
import Forum from "./components/forum/forum";
import Board from "./components/forum/board";
import Thread from "./components/forum/thread";
import NewThread from "./components/forum/newThread";
import Routines from "./components/routines/routines";
import SelfmadeIndex from "./components/selfmade";
import TodoOverview from "./components/todo/overview";
import SelfmadeVisionboard from "./components/selfmade/visionboard";
import PasswordReset from "./components/user/passwordReset";
import Live from "./components/live/live";
import Calendar from "./components/calendar/calendar";
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminUsersOverview from "./components/admin/users/AdminUsersOverview";
import AdminUserView from "./components/admin/users/AdminUserView";
import AdminUserPurchase from "./components/admin/users/AdminUserPurchase";
import AdminCoursesOverview from "./components/admin/courses/AdminCoursesOverview";
import AdminCourseView from "./components/admin/courses/AdminCourseView";
import AdminCourseLesson from "./components/admin/courses/AdminCourseLesson";
import AdminLiveQuestions from "./components/admin/live/questions/AdminLiveQuestions";
import AdminBooksOverview from "./components/admin/books/AdminBooksOverview";
import AdminBook from "./components/admin/books/AdminBook";
import AdminLiveOverview from "./components/admin/live/AdminLiveOverview";
import AdminLiveView from "./components/admin/live/AdminLiveView";
import AdminManagersOverview from "./components/admin/permissions/ManagersOverview";
import AdminVideoRequests from "./components/admin/videorequests/AdminVideoRequests";
import AdminAnnouncementsOverview from "./components/admin/announcements/AdminAnnouncementsOverview";
import AdminAnnouncement from "./components/admin/announcements/AdminAnnouncement";
import AdminQuotesOverview from "./components/admin/quotes/AdminQuotesOverview";
import AdminQuote from "./components/admin/quotes/AdminQuote";
import UserProfile from "./components/user/UserProfile";
import ScrollToTop from "./components/ScrollToTop";
import AdminYoutubeOverview from "./components/admin/youtube/AdminYoutubeOverview";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/dashboard" render={props => <Dashboard {...props} />} />
          <Route path="/live/:slug" render={props => <Live {...props} />} />
          <Route path="/live" render={props => <Live {...props} />} />
          <Route
            path="/workspace/visionboard"
            render={props => <SelfmadeVisionboard {...props} />}
          />
          <Route
            path="/workspace"
            render={props => <SelfmadeIndex {...props} />}
          />
          <Route
            path="/todo/:listSlug"
            render={props => <TodoOverview {...props} />}
          />
          <Route path="/todo" render={props => <TodoOverview {...props} />} />
          <Route
            path="/kurse/:courseIdentifier/:lessonIdentifier"
            render={props => <Course {...props} />}
          />
          <Route
            path="/kurse/:courseIdentifier"
            render={props => <Course {...props} />}
          />
          <Route
            path="/kurse"
            render={props => <CoursesOverview {...props} />}
          />
          <Route
            path="/calendar/:year/:month/:new"
            render={props => <Calendar {...props} />}
          />
          <Route
            path="/calendar/:year/:month"
            render={props => <Calendar {...props} />}
          />
          <Route path="/calendar" render={props => <Calendar {...props} />} />
          <Route
            path="/kalender/:year/:month/:new"
            render={props => <Calendar {...props} />}
          />
          <Route
            path="/kalender/:year/:month"
            render={props => <Calendar {...props} />}
          />
          <Route path="/kalender" render={props => <Calendar {...props} />} />
          <Route
            path="/community/@:userUsername"
            render={props => <UserProfile {...props} />}
          />
          <Route
            path="/community/:boardSlug/new"
            render={props => <NewThread {...props} />}
          />
          <Route
            path="/community/:boardSlug/:threadIdentifier"
            render={props => <Thread {...props} />}
          />
          <Route
            path="/community/:boardSlug"
            render={props => <Board {...props} />}
          />
          <Route path="/community" render={props => <Forum {...props} />} />
          <Route
            path="/routinen/:year/:month"
            render={props => <Routines {...props} />}
          />
          <Route path="/routinen" render={props => <Routines {...props} />} />
          <Route
            path="/password/reset"
            render={props => <PasswordReset {...props} />}
          />
          <Route path="/profil" render={props => <MyProfile {...props} />} />
          <Route
            path="/password"
            render={props => <ChangePassword {...props} />}
          />
          <Route
            path="/admin/users/list/:count/:page"
            render={props => <AdminUsersOverview {...props} />}
          />
          <Route
            path="/admin/users/:userId/purchases/:purchaseId"
            render={props => <AdminUserPurchase {...props} />}
          />
          <Route
            path="/admin/users/:userId"
            render={props => <AdminUserView {...props} />}
          />
          <Route
            path="/admin/users"
            render={props => <AdminUsersOverview {...props} />}
          />
          <Route
            path="/admin/courses/:courseId/lesson/:lessonId"
            render={props => <AdminCourseLesson {...props} />}
          />
          <Route
            path="/admin/courses/:courseId"
            render={props => <AdminCourseView {...props} />}
          />
          <Route
            path="/admin/courses"
            render={props => <AdminCoursesOverview {...props} />}
          />
          <Route
            path="/admin/live/questions"
            render={props => <AdminLiveQuestions {...props} />}
          />
          <Route
            path="/admin/live/:eventId"
            render={props => <AdminLiveView {...props} />}
          />
          <Route
            path="/admin/live"
            render={props => <AdminLiveOverview {...props} />}
          />
          <Route
            path="/admin/books/:bookId"
            render={props => <AdminBook {...props} />}
          />
          <Route
            path="/admin/books"
            render={props => <AdminBooksOverview {...props} />}
          />

          <Route
            path="/admin/announcements/:announcementId"
            render={props => <AdminAnnouncement {...props} />}
          />
          <Route
            path="/admin/announcements"
            render={props => <AdminAnnouncementsOverview {...props} />}
          />

          <Route
            path="/admin/quotes/:quoteId"
            render={props => <AdminQuote {...props} />}
          />
          <Route
            path="/admin/quotes"
            render={props => <AdminQuotesOverview {...props} />}
          />

          <Route
            path="/admin/videorequests"
            render={props => <AdminVideoRequests {...props} />}
          />

          <Route
            path="/admin/youtube"
            render={props => <AdminYoutubeOverview {...props} />}
          />

          <Route
            path="/admin/permissions/:userId"
            render={props => <AdminManagersOverview {...props} />}
          />
          <Route
            path="/admin/permissions"
            render={props => <AdminManagersOverview {...props} />}
          />

          <Route
            path="/admin"
            render={props => <AdminDashboard {...props} />}
          />
          <Route path="/" exact render={props => <Index {...props} />} />
          <Route render={props => <Error404 {...props} />} />
        </Switch>
        <ScrollToTop />
      </React.Fragment>
    );
  }
}

export default withRouter(App);
