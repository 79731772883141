import React, { Component } from "react";
import { Grid, Row, Cell } from "../../foundation/foundation.jsx";
import { isUndefined, isNull } from "util";

class BookOfTheWeek extends Component {
  state = {
    book: this.props.book
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.book !== this.props.book) {
      this.setState({ book: this.props.book });
    }
  }

  render() {
    const { book } = this.state;
    if (!isUndefined(book) && !isNull(book)) {
      return (
        <a
          href={book.url}
          target="_blank"
          rel="noopener noreferrer"
          className="selfmade-grid-item selfmade-grid-item-small selfmade-grid-bg-darkgray"
          id="selfmadeGridBookOfTheWeek"
        >
          <Grid>
            <Row>
              <Cell sm={8}>
                <div className="selfmade-grid-item selfmade-grid-item-small">
                  <div className="selfmade-grid-item-content">
                    <div className="selfmade-grid-book-cover">
                      <img
                        src={book.image}
                        alt={"[" + book.author + " - " + book.name + "]"}
                      />
                    </div>
                  </div>
                </div>
              </Cell>
              <Cell sm={14} smo={1}>
                <div className="selfmade-grid-item selfmade-grid-item-small">
                  <div className="selfmade-grid-item-content">
                    <div className="selfmade-grid-title">Buch der Woche</div>
                    <div className="selfmade-grid-text">
                      {book.author} -
                      <br />
                      {book.name}
                    </div>
                  </div>
                </div>
              </Cell>
            </Row>
          </Grid>
        </a>
      );
    } else {
      return null;
    }
  }
}

export default BookOfTheWeek;
