import React, { Component } from "react";
import { Grid, Row, Cell } from "../../foundation/foundation";
import { isArray } from "util";

class AdminNumberList extends Component {
  state = {
    list: this.props.list
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.list !== this.props.list) {
      this.setState({ list: this.props.list });
    }
  }

  render() {
    const { list } = this.state;

    if (isArray(list)) {
      return (
        <div className="admin-number-list">
          <Grid type="full">
            {list.map((item, index) => (
              <Row key={index}>
                <Cell sm={8} classes="text-right">
                  <strong>{item.number || "0"}&nbsp;&nbsp;</strong>
                </Cell>
                <Cell sm={16} classes="text-left">
                  {item.caption || ""}
                </Cell>
              </Row>
            ))}
          </Grid>
        </div>
      );
    } else {
      return <div className="text-center">...</div>;
    }
  }
}

export default AdminNumberList;
