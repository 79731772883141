import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from "react-sortable-hoc";
import arrayMove from "array-move";

class AdminCoursesOverview extends Component {
  state = {
    courses: []
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    return (
      <AdminLayout active="courses">
        <Grid>
          <Row>
            <Cell sm={24}>
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={16}>
                    <h1>Kurse</h1>
                  </Cell>
                  <Cell sm={24} md={8} classes="text-right">
                    <a
                      href="/admin/courses/new"
                      className="small primary button"
                    >
                      <Icon icon="plus" left /> Neuen Kurs hinzufügen
                    </a>
                  </Cell>
                </Row>
              </Grid>

              <div className="small admin-box adminBoxCoursesData">
                <div className="admin-box-content">
                  <ul className="admin-list hide-for-small-only">
                    <li className="admin-list-item admin-list-title">
                      <Grid type="full">
                        <Row>
                          <Cell sm={2} md={1}>
                            &nbsp;
                          </Cell>
                          <Cell sm={2} md={1}>
                            &nbsp;
                          </Cell>
                          <Cell sm={12} md={12}>
                            Name
                          </Cell>
                          <Cell sm={2} md={2} classes="text-center">
                            Lektionen
                          </Cell>
                          <Cell sm={6} md={8}>
                            Digistore IDs
                          </Cell>
                        </Row>
                      </Grid>
                    </li>
                  </ul>
                  {this.view__showCourses()}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showCourses() {
    const { courses } = this.state;

    const DragHandle = sortableHandle(() => (
      <span>
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="striped admin-list">{children}</ul>;
    });

    const SortableItem = sortableElement(({ course, index, digistoreIds }) => (
      <li className="clickable admin-list-item">
        <a href={`/admin/courses/${course.id}`}>
          <Grid type="full">
            <Row>
              <Cell sm={2} md={1}>
                <div className="admin-course-lessons-draghandle">
                  <DragHandle />
                </div>
              </Cell>
              <Cell sm={2} md={1} classes="text-center medium-text-left">
                {index + 1}.
              </Cell>
              <Cell sm={2} md={1} classes="hide-for-small-only">
                &nbsp;
              </Cell>
              <Cell sm={20} md={11}>
                {this.view__showCourseName(course)}
              </Cell>
              <Cell sm={2} md={2} classes="text-center hide-for-small-only">
                {/*{course.lessons.length}*/}
              </Cell>
              <Cell sm={6} md={8} classes="hide-for-small-only">
                {digistoreIds.map((id, index) => {
                  let comma = ", ";
                  if (index === digistoreIds.length - 1) {
                    comma = "";
                  }
                  return (
                    <span key={index}>
                      {id}
                      {comma}
                    </span>
                  );
                })}
              </Cell>
            </Row>
          </Grid>
        </a>
      </li>
    ));

    if (courses && courses.length > 0) {
      return (
        <SortableContainer
          onSortEnd={this.onSortEnd}
          useDragHandle
          helperContainer={document.getElementById("adminCourseLessonsForDrag")}
        >
          {courses.map((course, index) => {
            let digistoreIds = [];
            if (
              course.digistore_product_ids &&
              course.digistore_product_ids.length > 0
            ) {
              digistoreIds = course.digistore_product_ids;
            }
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                course={course}
                digistoreIds={digistoreIds}
              />
            );
          })}
        </SortableContainer>
        /*
        <ul className="striped admin-list">
          {courses.map((course, index) => {
            let digistoreIds = [];
            if (
              course.digistore_product_ids &&
              course.digistore_product_ids.length > 0
            ) {
              digistoreIds = course.digistore_product_ids;
            }

            return (
              <li className="clickable admin-list-item" key={index}>
                <a href={`/admin/courses/${course.id}`}>
                  <Grid type="full">
                    <Row>
                      <Cell sm={2} md={1}>
                        {index + 1}.
                      </Cell>
                      <Cell sm={2} md={1}>
                        &nbsp;
                      </Cell>
                      <Cell sm={12} md={12}>
                        {this.view__showCourseName(course)}
                      </Cell>
                      <Cell sm={2} md={2} classes="text-center">
                        {course.lessons.length}
                      </Cell>
                      <Cell sm={6} md={8}>
                        {digistoreIds.map((id, index) => {
                          let comma = ", ";
                          if (index === digistoreIds.length - 1) {
                            comma = "";
                          }
                          return (
                            <span key={index}>
                              {id}
                              {comma}
                            </span>
                          );
                        })}
                      </Cell>
                    </Row>
                  </Grid>
                </a>
              </li>
            );
          })}
        </ul>*/
      );
    }

    return null;
  }

  view__showCourseName(course) {
    if (course.draft === true) {
      return (
        <span className="admin-courses-draft">
          ({course.name}) <em>** Entwurf **</em>
        </span>
      );
    } else {
      return course.name;
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    Array.from(document.getElementsByClassName("adminBoxCoursesData")).forEach(
      element => {
        element.classList.remove("background-saved");
      }
    );

    this.setState(
      ({ courses }) => ({
        courses: arrayMove(courses, oldIndex, newIndex)
      }),
      () => {
        const { courses } = this.state;
        const newOrder = [];

        courses.map(course => {
          return newOrder.push(course.id);
        });

        axios
          .post(
            this.props.context.apiEndpoints.adminCoursesOrder,
            { newOrder },
            {
              headers: this.props.context.headers
            }
          )
          .then(response => {
            Array.from(
              document.getElementsByClassName("adminBoxCoursesData")
            ).forEach(element => {
              element.classList.add("background-saved");
            });
          })
          .catch(error => {
            console.log("Error", error);
          });
      }
    );
  };

  /**
   * Init
   * Load User Info
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.adminCourses, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { courses } = response.data;

        this.setState({ courses });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminCoursesOverview);
