import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import Breadcrumbs from "../topbars/breadcrumbs";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import Task from "./task";
import withContext from "../../context/contextHOC";
import axios from "axios";
import { isUndefined, isNull } from "util";
import Footer from "../layout/footer";

class TodoOverview extends Component {
  state = {
    lists: [],
    listsLoaded: false,
    listSlug: this.props.match.params.listSlug,
    selectedList: undefined,
    showTasks: {},
    showNewTask: false,
    newTask: {
      id: "new",
      due: null,
      finished: null,
      todolist: {}
    }
  };

  componentDidUpdate(prevProps, prevState) {
    /*console.log(
      this.props.match.params.listSlug,
      prevProps.match.params.listSlug,
      this.props.match.params.listSlug,
      this.state.selectedList
    );*/

    if (this.state.listsLoaded === true) {
      if (
        this.props.match.params.listSlug !== prevProps.match.params.listSlug ||
        (!isUndefined(this.props.match.params.listSlug) &&
          isUndefined(this.state.selectedList))
      ) {
        this.setState({ listSlug: this.props.match.params.listSlug }, () => {
          this.handle__selectList();
        });
      }
    }
  }

  componentDidMount() {
    this.init__loadLists();
  }

  render() {
    return (
      <React.Fragment>
        <div
          id="pageTodo"
          className="darkmode page-with-topbar-and-breadcrumbs default-page"
        >
          <MainTopbar />
          <Breadcrumbs breadcrumbs={["dashboard", "todo"]} />
          <section className="content">
            <Grid>
              <Row>
                <Cell sm={24}>
                  <Grid>
                    <Row>
                      <Cell sm={24} md={6} id="todoOverviewLists">
                        <h3>Todo Listen</h3>
                        {this.show__lists()}
                      </Cell>
                      <Cell sm={24} md={18} id="todoOverviewTasks">
                        <Grid type="full">
                          <Row>
                            <Cell sm={24} md={12}>
                              {this.show__tasks_title()}
                            </Cell>
                            <Cell sm={24} md={12} classes="text-right">
                              <button
                                className="primary button"
                                onClick={() => {
                                  this.handle__newTask();
                                }}
                              >
                                <Icon icon="plus" left /> Neue Aufgabe
                              </button>
                            </Cell>
                          </Row>
                        </Grid>

                        <Task
                          task={this.state.newTask}
                          selectedList={this.state.selectedList}
                          show={this.state.showNewTask}
                          onClose={() => {
                            this.handle__closeTaskPopup(this.state.newTask.id);
                          }}
                          onUpdate={this.handle__onUpdate}
                          onListsUpdate={this.handle__onListsUpdate}
                        />

                        {this.show__tasks()}
                      </Cell>
                    </Row>
                  </Grid>
                </Cell>
              </Row>
            </Grid>
          </section>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  /**
   * Show Todo Lists (Left side)
   */
  show__lists() {
    const { lists, listSlug } = this.state;
    if (lists.length > 0) {
      return (
        <ul className="todo-lists">
          {lists.map((list, index) => {
            let classes = "todo-lists-list";
            if (list.slug === listSlug) {
              classes += " selected";
            }
            return (
              <li
                key={index}
                className={classes}
                onClick={() => {
                  const url = this.props.context.generateTodoListUrl(list.slug);
                  this.props.history.push(url);
                }}
              >
                <div
                  className="todo-list-color"
                  style={{ backgroundColor: list.color }}
                />
                {list.name}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return null;
    }
  }

  /**
   * Show Tasks List Title
   */
  show__tasks_title() {
    const { selectedList } = this.state;

    if (isUndefined(selectedList)) {
      return <h3>Meine Aufgaben</h3>;
    } else {
      return (
        <h3>
          Aufgaben in <em>{selectedList.name}</em>
        </h3>
      );
    }
  }

  /**
   * Show Tasks of a todo list
   */
  show__tasks() {
    const { selectedList, showTasks } = this.state;

    if (!isUndefined(selectedList) && !isUndefined(selectedList.tasks)) {
      return (
        <React.Fragment>
          <div className="todo-tasks-title">
            <Grid type="full">
              <Row>
                <Cell sm={18} md={20}>
                  <div className="todo-tasks-title-task">Aufgabe</div>
                </Cell>
                <Cell sm={6} md={4} classes="text-center">
                  <div className="todo-tasks-title-due">Fälligkeit</div>
                </Cell>
              </Row>
            </Grid>
          </div>
          <ul className="todo-tasks">
            {selectedList.tasks.map((task, index) => {
              let dueClass = "task-due-" + task.due_status;
              let taskClass = "";
              if (this.check__isTaskFinished(task) === true) {
                taskClass = "task-finished";
              }
              return (
                <React.Fragment key={index}>
                  <li
                    onClick={() => {
                      this.handle__selectTask(task.id);
                    }}
                    className={taskClass}
                  >
                    <Grid type="full">
                      <Row>
                        <Cell sm={18} md={20}>
                          <span className="task-is-finished">
                            <Icon icon="check" />
                            &nbsp;&nbsp;
                          </span>
                          {task.name}
                        </Cell>
                        <Cell sm={6} md={4} classes="text-center">
                          <div className={dueClass}>
                            {this.show__task_due(task)}
                          </div>
                        </Cell>
                      </Row>
                    </Grid>
                  </li>
                  <Task
                    task={task}
                    show={showTasks[task.id]}
                    onClose={() => {
                      this.handle__closeTaskPopup(task.id);
                    }}
                    onUpdate={this.handle__onUpdate}
                    onListsUpdate={this.handle__onListsUpdate}
                  />
                </React.Fragment>
              );
            })}
          </ul>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  /**
   * Show task due
   */
  show__task_due(task) {
    if (task.due_date) {
      return <span>{task.due_date}</span>;
    } else {
      return null;
    }
  }

  /**
   * Select Tasks if User selects Todo List
   */
  handle__selectList() {
    const { lists, listSlug } = this.state;

    let selectedList = {};

    lists.map(list => {
      //console.log("list.slug", list.slug, "listSlug", listSlug);
      if (list.slug === listSlug) {
        selectedList = list;
      }
      return null;
    });

    this.setState({ selectedList });
  }

  /**
   * Show Task Popup
   */
  handle__selectTask(taskId) {
    const { showTasks } = this.state;
    showTasks[taskId] = true;
    this.setState({ showTasks });
  }

  /**
   * Show New Task Popup
   */
  handle__newTask() {
    this.setState({ showNewTask: true });
  }

  /**
   * Close Task Popup
   */
  handle__closeTaskPopup = taskId => {
    if (taskId === "new") {
      this.setState({ showNewTask: false });
    } else {
      const { showTasks } = this.state;
      showTasks[taskId] = false;
      this.setState({ showTasks });
    }
  };

  /**
   * Handle On Update from Task Popup
   */
  handle__onUpdate = updatedTask => {
    window.location.reload();
    /*
    let { lists } = this.state;

    lists = lists.map(list => {
      list.tasks = list.tasks.map(task => {
        if (task.id === updatedTask.id) {
          task = updatedTask;
        }
        return task;
      });
      return list;
    });
    this.setState({ lists });*/
  };

  /**
   * Handle On Lists Update from Task Popup
   */
  handle__onListsUpdate = lists => {
    const selectedListId = this.state.selectedList.id;
    let selectedList = {};

    lists.map(list => {
      if (list.id === selectedListId) {
        selectedList = list;
      }
      return null;
    });

    this.setState({
      lists,
      selectedList
    });
  };

  /**
   * Check if Task is already finished
   */
  check__isTaskFinished(task) {
    if (isNull(task.finished)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Load Todo Lists with Tasks
   */
  init__loadLists(callback) {
    axios
      .get(this.props.context.apiEndpoints.todoLists, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { showTasks } = this.state;
        const { lists } = response.data;

        let defaultListSlug = "";

        lists.map(list => {
          if (list.default === true) {
            defaultListSlug = list.slug;
          }
          list.tasks.map(task => {
            showTasks[task.id] = false;
            return null;
          });

          return null;
        });
        this.setState({ lists, listsLoaded: true, showTasks });

        if (isUndefined(this.state.listSlug)) {
          this.props.history.replace(
            this.props.context.generateTodoListUrl(defaultListSlug)
          );
        }

        if (callback) {
          callback();
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(TodoOverview);
