import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import Switch from "react-switch";
import PTTabs from "../../ptTabs/ptTabs";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import PTPopup from "../../ptPopup/ptPopup";
import Dropzone from "react-dropzone";

class AdminCourseView extends Component {
  state = {
    courseId: this.props.match.params.courseId,
    course: {},
    originalCourse: {},
    lessons: [],
    categories: [],
    editMode: false,
    courseTypes: {
      single: "Einzellektion",
      multi: "Mehrere Lektionen",
      timed: "Zeitlich gesteuert",
    },
    lessonTypes: {
      vimeo: "Vimeo",
      live: "Live Call",
    },
    newDigistoreId: "",
    tabs: {
      course: {
        name: "Kursinformationen",
        icon: "graduation-cap",
        selected: true,
      },
      lessons: {
        name: "Kategorien & Lektionen",
        icon: "youtube-play",
        selected: false,
      },
      downloads: {
        name: "Downloads",
        icon: "download",
        selected: false,
      },
    },
    selectedTab: "course",
    showCategoryEditPopup: false,
    editCategory: {},
    selectedCategory: 0,
    showCategorySwitchPopup: false,
    categorySwitchLesson: {},
    editDownload: 0,
    selectedDownload: null,
    uploadFile: null,
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    return (
      <AdminLayout active="courses">
        <Grid>
          <Row>
            <Cell sm={24}>
              <p>
                <a href="/admin/courses">
                  <small>
                    <Icon icon="angle-double-left" left /> Zurück zur Übersicht
                  </small>
                </a>
              </p>
              <h1>{this.view__showTitle()}</h1>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24}>
              <PTTabs
                tabs={this.state.tabs}
                onUpdate={(tabs) => {
                  let selectedTab = "";
                  Object.keys(tabs).map((keyName, index) => {
                    if (tabs[keyName].selected === true) {
                      selectedTab = keyName;
                    }
                    return null;
                  });
                  this.setState({ tabs, selectedTab });
                }}
              />
              <div>&nbsp;</div>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24} lg={24}>
              {this.view__showTabCourse()}
              {this.view__showTabLessons()}
              {this.view__showTabDownloads()}

              <div style={this.view__showWhenNew()}>
                <div style={this.view__visible("edit")} className="text-right">
                  <a href="/admin/courses" className="primary hollow button">
                    Abbrechen
                  </a>
                  <button
                    onClick={this.handle__editSave}
                    className="primary button"
                  >
                    Speichern <Icon icon="angle-right" />
                  </button>
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
        <ul
          id="adminCourseLessonsForDrag"
          className="admin-course-lessons admin-list"
        />
        <ul
          id="adminCourseCategoriesForDrag"
          className="admin-course-lessons admin-list"
        />
        <ul
          id="adminCourseDownloadsForDrag"
          className="admin-course-lessons admin-list"
        />
      </AdminLayout>
    );
  }

  view__showTitle() {
    const { courseId, course } = this.state;

    if (courseId === "new") {
      return "Neuer Kurs";
    } else {
      if (course && course.name) {
        return course.name;
      } else {
        return "Wird geladen...";
      }
    }
  }

  view__showTabCourse() {
    const { selectedTab, course } = this.state;
    if (selectedTab !== "course") {
      return;
    }

    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={12} lg={12}>
            <div className="admin-box adminBoxCourseData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Kursdaten</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <div style={this.view__visible("view")}>
                        <button
                          className="admin-icon-button"
                          onClick={() => {
                            this.setState({ editMode: true });

                            Array.from(
                              document.getElementsByClassName(
                                "adminBoxCourseData"
                              )
                            ).forEach((element) => {
                              element.classList.remove("background-saved");
                            });
                          }}
                        >
                          <Icon icon="cog" />
                        </button>
                        <div>
                          {this.view__showIsPublished()}
                          <Switch
                            onChange={this.handle__activation}
                            checked={!this.state.course.draft}
                            className="react-switch"
                            height={16}
                            width={36}
                          />
                        </div>
                      </div>
                      <div style={this.view__hideWhenNew()}>
                        <div style={this.view__visible("edit")}>
                          <button
                            onClick={this.handle__editCancel}
                            className="small primary hollow button"
                          >
                            Abbrechen
                          </button>
                          <button
                            onClick={this.handle__editSave}
                            className="small primary button"
                          >
                            Speichern
                          </button>
                        </div>
                      </div>
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={24} md={24} classes="text-right">
                      <div style={this.view__visible("view")}>
                        {this.view__showIsVisible()}
                        <Switch
                          onChange={this.handle__activation_visible}
                          checked={this.state.course.visible || false}
                          className="react-switch"
                          height={16}
                          width={36}
                        />
                      </div>
                    </Cell>
                  </Row>
                </Grid>

                <Row margin="x">
                  <Cell sm={24} md={24} lg={24}>
                    <div
                      className="info-content"
                      style={this.view__visible("view")}
                    >
                      {course.name || (
                        <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                      )}
                    </div>
                    <div
                      className="info-content edit-mode"
                      style={this.view__visible("edit")}
                    >
                      <input
                        type="text"
                        value={course.name || ""}
                        onChange={(event) => {
                          this.handle__editChange(event, "name");
                        }}
                      />
                    </div>

                    <div className="info-caption">Kursname</div>
                  </Cell>
                </Row>
                <Row margin="x">
                  <Cell sm={24} md={24} lg={24}>
                    <div
                      className="info-content"
                      style={this.view__visible("view")}
                    >
                      {course.description || (
                        <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                      )}
                    </div>
                    <div
                      className="info-content edit-mode"
                      style={this.view__visible("edit")}
                    >
                      <input
                        type="text"
                        value={course.description || ""}
                        onChange={(event) => {
                          this.handle__editChange(event, "description");
                        }}
                      />
                    </div>

                    <div className="info-caption">Kurzbeschreibung</div>
                  </Cell>
                </Row>
                <Row margin="x">
                  <Cell sm={24} md={24} lg={24}>
                    <div
                      className="info-content"
                      style={this.view__visible("view")}
                    >
                      {course.image || (
                        <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                      )}
                    </div>
                    <div
                      className="info-content edit-mode"
                      style={this.view__visible("edit")}
                    >
                      <input
                        type="text"
                        value={course.image || ""}
                        onChange={(event) => {
                          this.handle__editChange(event, "image");
                        }}
                      />
                    </div>

                    <div className="info-caption">Produktbild-URL</div>
                  </Cell>
                </Row>
              </div>
            </div>

            <div className="admin-box adminBoxCourseData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Kurstyp</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <div style={this.view__visible("view")}>
                        <button
                          className="admin-icon-button"
                          onClick={() => {
                            this.setState({ editMode: true });
                            Array.from(
                              document.getElementsByClassName(
                                "adminBoxCourseData"
                              )
                            ).forEach((element) => {
                              element.classList.remove("background-saved");
                            });
                          }}
                        >
                          <Icon icon="cog" />
                        </button>
                      </div>
                      <div style={this.view__hideWhenNew()}>
                        <div style={this.view__visible("edit")}>
                          <button
                            onClick={this.handle__editCancel}
                            className="small primary hollow button"
                          >
                            Abbrechen
                          </button>
                          <button
                            onClick={this.handle__editSave}
                            className="small primary button"
                          >
                            Speichern
                          </button>
                        </div>
                      </div>
                    </Cell>
                  </Row>
                </Grid>

                <Row margin="x">
                  <Cell sm={24} md={24} lg={24}>
                    <div
                      className="info-content"
                      style={this.view__visible("view")}
                    >
                      {this.view__showCourseType()}
                    </div>
                    <div
                      className="info-content edit-mode"
                      style={this.view__visible("edit")}
                    >
                      <select
                        style={{ marginBottom: 0 }}
                        onChange={(event) =>
                          this.handle__editChange(event, "type")
                        }
                        value={course.type}
                      >
                        <option key={9999} value={0}>
                          --- bitte auswählen ---
                        </option>
                        {Object.keys(this.state.courseTypes).map(
                          (key, index) => {
                            return (
                              <option key={index} value={key}>
                                {this.state.courseTypes[key]}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>

                    <div className="info-caption">Kurstyp</div>

                    <div
                      className="edit-mode"
                      style={this.view__visible("edit")}
                    >
                      <div>&nbsp;</div>
                      <div className="warning callout">
                        <small>
                          <strong>Bitte beachten:</strong> Das nachträgliche
                          Ändern des Kurstyps nach dem Anlegen von Lektionen
                          kann zu unerwarteten Effekten und Datenverlust führen.
                          Daher sollte das ausschließlich in Ausnahmefällen
                          getan werden!
                        </small>
                      </div>
                    </div>
                  </Cell>
                </Row>
              </div>
            </div>

            <div className="admin-box adminBoxCourseData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Countdown</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <div style={this.view__visible("view")}>
                        <button
                          className="admin-icon-button"
                          onClick={() => {
                            this.setState({ editMode: true });
                            Array.from(
                              document.getElementsByClassName(
                                "adminBoxCourseData"
                              )
                            ).forEach((element) => {
                              element.classList.remove("background-saved");
                            });
                          }}
                        >
                          <Icon icon="cog" />
                        </button>
                      </div>
                      <div style={this.view__hideWhenNew()}>
                        <div style={this.view__visible("edit")}>
                          <button
                            onClick={this.handle__editCancel}
                            className="small primary hollow button"
                          >
                            Abbrechen
                          </button>
                          <button
                            onClick={this.handle__editSave}
                            className="small primary button"
                          >
                            Speichern
                          </button>
                        </div>
                      </div>
                    </Cell>
                  </Row>
                </Grid>

                <Row margin="x">
                  <Cell sm={24} md={24} lg={24}>
                    <div
                      className="info-content"
                      style={this.view__visible("view")}
                    >
                      {this.view__showCourseCountdown()}
                    </div>
                    <div
                      className="info-content edit-mode"
                      style={this.view__visible("edit")}
                    >
                      <select
                        style={{ marginBottom: 0 }}
                        onChange={(event) =>
                          this.handle__editChange(event, "countdownable")
                        }
                        value={course.countdownable === true ? "yes" : "no"}
                      >
                        <option value="no">Countdown deaktiviert</option>
                        <option value="yes">
                          Individueller Countdown aktiviert
                        </option>
                      </select>
                    </div>

                    <div className="info-caption">
                      Individuellen Countdown aktivieren?
                    </div>

                    <div
                      className="edit-mode"
                      style={this.view__visible("edit")}
                    >
                      <div>&nbsp;</div>
                      <div className="warning callout">
                        <small>
                          Wenn der individuelle Countdown aktiviert wird, kann
                          bei jeder Lektion eingestellt werden, ob sie bereits
                          vor dem Ablauf des Countdowns oder erst danach
                          angezeigt wird. Der Countdown wird pro Mitglied in den
                          Käufe-Details eingestellt.
                        </small>
                      </div>
                    </div>
                  </Cell>
                </Row>
                <Row margin="x">
                  <Cell sm={24} md={24}>
                    <div
                      className="info-content"
                      style={this.view__visible("view")}
                    >
                      {course.countdown_title || (
                        <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                      )}
                    </div>
                    <div
                      className="info-content edit-mode"
                      style={this.view__visible("edit")}
                    >
                      <input
                        type="text"
                        value={course.countdown_title || ""}
                        onChange={(event) => {
                          this.handle__editChange(event, "countdown_title");
                        }}
                      />
                    </div>

                    <div className="info-caption">
                      Titel über Countdown (optional)
                    </div>
                  </Cell>
                </Row>
                <Row margin="x">
                  <Cell sm={24} md={24}>
                    <div
                      className="info-content"
                      style={this.view__visible("view")}
                    >
                      {course.countdown_text || (
                        <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                      )}
                    </div>
                    <div
                      className="info-content edit-mode"
                      style={this.view__visible("edit")}
                    >
                      <textarea
                        value={course.countdown_text || ""}
                        onChange={(event) => {
                          this.handle__editChange(event, "countdown_text");
                        }}
                      />
                    </div>

                    <div className="info-caption">
                      Text über Countdown (optional)
                    </div>
                  </Cell>
                </Row>
              </div>
            </div>

            <div className="admin-box adminBoxCourseData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Preis</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <div style={this.view__visible("view")}>
                        <button
                          className="admin-icon-button"
                          onClick={() => {
                            this.setState({ editMode: true });
                            Array.from(
                              document.getElementsByClassName(
                                "adminBoxCourseData"
                              )
                            ).forEach((element) => {
                              element.classList.remove("background-saved");
                            });
                          }}
                        >
                          <Icon icon="cog" />
                        </button>
                      </div>
                      <div style={this.view__hideWhenNew()}>
                        <div style={this.view__visible("edit")}>
                          <button
                            onClick={this.handle__editCancel}
                            className="small primary hollow button"
                          >
                            Abbrechen
                          </button>
                          <button
                            onClick={this.handle__editSave}
                            className="small primary button"
                          >
                            Speichern
                          </button>
                        </div>
                      </div>
                    </Cell>
                  </Row>

                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div
                        className="info-content"
                        style={this.view__visible("view")}
                      >
                        {course.price || (
                          <span
                            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                          />
                        )}
                      </div>
                      <div
                        className="info-content edit-mode"
                        style={this.view__visible("edit")}
                      >
                        <input
                          type="text"
                          value={course.price || ""}
                          onChange={(event) => {
                            this.handle__editChange(event, "price");
                          }}
                        />
                      </div>

                      <div className="info-caption">Preis</div>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            </div>
          </Cell>
          <Cell sm={24} md={12} lg={12}>
            <div className="admin-box adminBoxCourseData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Digistore Produkt IDs</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      &nbsp;
                    </Cell>
                  </Row>
                </Grid>

                <Row margin="x">
                  <Cell sm={24} md={24} lg={24}>
                    {this.view__showDigistoreIds()}
                  </Cell>
                </Row>
              </div>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showTabLessons() {
    const { selectedTab } = this.state;

    if (selectedTab !== "lessons") {
      return;
    }

    return (
      <Grid type="full">
        <Row>
          <Cell sm={24}></Cell>
        </Row>
        <Row margin="x">
          <Cell sm={24} md={16} lg={16}>
            <div className="text-right">
              <button
                className="small primary button"
                onClick={() => {
                  this.setState({
                    editCategory: {},
                    showCategoryEditPopup: true,
                  });
                }}
              >
                <Icon icon="plus" left /> Neue Kategorie
              </button>
            </div>
            {this.view__showTabLessons_Categories()}
          </Cell>
          <Cell sm={24} md={8} lg={8}>
            {this.view__showTabLessons_Lessons()}
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showTabLessons_Categories() {
    const {
      showCategoryEditPopup,
      editCategory,
      showCategorySwitchPopup,
      categorySwitchLesson,
      categories,
    } = this.state;

    let popupTitle = "Neue Kategorie";
    if (editCategory.id) {
      popupTitle = `${editCategory.name} bearbeiten`;
    }

    return (
      <div style={this.view__hideWhenNew()}>
        {/*<div className="admin-box">
          <div className="admin-box-content">
            <Grid type="full">
              <Row>
                <Cell sm={18} md={12}>
                  <h3 style={{ marginBottom: 0 }}>Kategorien</h3>
                </Cell>
                <Cell sm={6} md={12} classes="text-right">
                  <button
                    className="small primary button"
                    style={{ marginBottom: 0 }}
                    onClick={() => {
                      this.setState({
                        editCategory: {},
                        showCategoryEditPopup: true
                      });
                    }}
                  >
                    <Icon icon="plus" left /> Neue Kategorie
                  </button>
                </Cell>
              </Row>
            </Grid>
          </div>
                  </div>*/}
        {this.view__showCategories()}

        <div className="selfmade-popup">
          <PTPopup
            show={showCategoryEditPopup}
            size="small"
            handleClose={() => {
              this.setState({ showCategoryEditPopup: false });
            }}
          >
            <Grid type="full">
              <Row>
                <Cell sm={24}>
                  <h2>{popupTitle}</h2>
                  <Grid type="full">
                    <Row>
                      <Cell sm={24} md={18} lg={18}>
                        <input
                          type="text"
                          value={editCategory.name || ""}
                          placeholder="Name der Kategorie"
                          onChange={(event) => {
                            editCategory.name = event.target.value;
                            this.setState({ editCategory });
                          }}
                          onKeyUp={(e) => {
                            if (e.keyCode === 13) {
                              this.handle__categorySave();
                            }
                          }}
                        />
                      </Cell>
                      <Cell sm={24} md={6} lg={6}>
                        <button
                          className="primary button"
                          style={{ width: "100%" }}
                          onClick={this.handle__categorySave}
                        >
                          <Icon icon="check" left />
                          Speichern
                        </button>
                      </Cell>
                    </Row>
                  </Grid>
                </Cell>
              </Row>
            </Grid>
          </PTPopup>
        </div>

        <div className="selfmade-popup">
          <PTPopup
            show={showCategorySwitchPopup}
            size="small"
            handleClose={() => {
              this.setState({
                showCategorySwitchPopup: false,
                categorySwitchLesson: {},
              });
            }}
          >
            <Grid type="full">
              <Row>
                <Cell sm={24}>
                  <h2>Kategorie wechseln</h2>
                  <p>
                    Lektion: <strong>{categorySwitchLesson.name}</strong>
                  </p>
                  <Grid type="full">
                    <Row>
                      <Cell sm={24} md={18} lg={18}>
                        <select
                          value={categorySwitchLesson.category}
                          onChange={(event) => {
                            categorySwitchLesson.category = event.target.value;
                            this.setState({ categorySwitchLesson });
                          }}
                        >
                          {categories.map((category, index) => {
                            return (
                              <option value={category.id} key={index}>
                                {category.name}
                              </option>
                            );
                          })}
                        </select>
                      </Cell>
                      <Cell sm={24} md={6} lg={6}>
                        <button
                          className="primary button"
                          style={{ width: "100%" }}
                          onClick={this.handle__categorySwitchSave}
                        >
                          <Icon icon="check" left />
                          Speichern
                        </button>
                      </Cell>
                    </Row>
                  </Grid>
                </Cell>
              </Row>
            </Grid>
          </PTPopup>
        </div>
      </div>
    );
  }

  view__showTabLessons_Lessons() {
    return;

    /*return (
      <div style={this.view__hideWhenNew()}>
        <div className="admin-box">
          <div className="admin-box-content">
            <Grid type="full">
              <Row>
                <Cell sm={18} md={12}>
                  <h3 style={{ marginBottom: 0 }}>Lektionen</h3>
                </Cell>
                <Cell sm={6} md={12} classes="text-right">
                  {this.view__showButtonNewLesson()}
                </Cell>
              </Row>
            </Grid>
          </div>
        </div>
        {this.view__showLessons()}
      </div>
    );*/
  }

  view__showTabDownloads() {
    const { course, selectedTab, editDownload, selectedDownload } = this.state;

    if (selectedTab !== "downloads") {
      return;
    }

    if (!course || !course.downloads) {
      return null;
    }

    const DragHandle = sortableHandle(() => (
      <span>
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-course-downloads admin-list">{children}</ul>;
    });

    const SortableItem = sortableElement(({ download }) => {
      let downloadContent = (
        <Grid type="full">
          <Row>
            <Cell
              sm={3}
              md={2}
              lg={2}
              classes="admin-course-downloads-draghandle text-left medium-text-center"
            >
              <div title="Verschieben">
                <DragHandle />
              </div>
            </Cell>
            <Cell sm={21} md={22} lg={22}>
              {this.view__showDownloadView(download)}
            </Cell>
          </Row>
        </Grid>
      );

      return (
        <div className="admin-box adminBoxCourseData" key={download.id}>
          <div className="admin-box-content">{downloadContent}</div>
        </div>
      );
    });

    let downloadContent;

    // Dont use Sortable when in Download Edit Mode (Cause <input/> will lose focus after change)
    if (editDownload !== 0) {
      downloadContent = (
        <React.Fragment>
          {course.downloads.map((download, index) => {
            if (editDownload === download.id) {
              return (
                <div className="admin-box adminBoxCourseData" key={index}>
                  <div className="admin-box-content">
                    <Grid type="full">
                      <div
                        style={{
                          display:
                            selectedDownload.id === "new" ? "block" : "none",
                        }}
                      >
                        <Row>
                          <Cell sm={24}>
                            <h3>Neuer Download</h3>
                          </Cell>
                        </Row>
                      </div>
                      <Row>
                        <Cell sm={24}>
                          <div className="info-content edit-mode">
                            <input
                              type="text"
                              value={selectedDownload.name || ""}
                              onChange={(event) => {
                                this.handle__editDownloadChange(
                                  event.target.value,
                                  "name"
                                );
                              }}
                            />
                          </div>

                          <div className="info-caption">Angezeigter Name</div>
                        </Cell>
                      </Row>
                      <Row>
                        <Cell sm={24}>
                          <div className="info-content edit-mode">
                            <input
                              type="text"
                              value={selectedDownload.url || ""}
                              onChange={(event) => {
                                this.handle__editDownloadChange(
                                  event.target.value,
                                  "url"
                                );
                              }}
                            />
                          </div>

                          <div className="info-caption">Download-URL</div>
                        </Cell>
                      </Row>

                      <Row>
                        <Cell sm={24}>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              const uploadFile = acceptedFiles[0];

                              this.setState({ uploadFile });

                              const reader = new FileReader();

                              reader.addEventListener(
                                "load",
                                function () {
                                  this.setState({ uploadUrl: reader.result });
                                }.bind(this),
                                false
                              );

                              if (uploadFile) {
                                reader.readAsDataURL(uploadFile);
                                this.handle__uploadCourseDownload();
                              }
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <div className="course-download-upload">
                                    <div>
                                      <strong>Oder Datei hochladen:</strong>
                                    </div>
                                    <div className="course-download-upload-text">
                                      <p>
                                        <Icon icon="upload" />
                                      </p>
                                      <p>
                                        Lege die Datei in diesem Popup ab oder
                                        <br />
                                        klicke hier, um eine Datei hochzuladen.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </Cell>
                      </Row>

                      <Row>
                        <Cell sm={24} classes="text-right">
                          <div style={{ marginTop: "10px" }}>
                            <button
                              className="small primary hollow button"
                              onClick={this.handle__cancelEditDownload}
                            >
                              <Icon icon="times" left /> Abbrechen
                            </button>
                            <button
                              className="small primary button"
                              onClick={this.handle__editDownloadSave}
                            >
                              <Icon icon="check" left /> Speichern
                            </button>
                          </div>
                        </Cell>
                      </Row>
                    </Grid>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className="admin-box adminBoxCourseData"
                  key={`download_view_${download.id}`}
                >
                  <div className="admin-box-content">
                    {this.view__showDownloadView(download)}
                  </div>
                </div>
              );
            }
          })}
        </React.Fragment>
      );
    } else {
      downloadContent = (
        <SortableContainer
          onSortEnd={this.onDownloadsSortEnd}
          useDragHandle
          helperContainer={document.getElementById(
            "adminCourseDownloadsForDrag"
          )}
        >
          {course.downloads.map((download, index) => (
            <SortableItem
              key={`download-${index}`}
              index={index}
              download={download}
            />
          ))}
        </SortableContainer>
      );
    }

    return (
      <React.Fragment>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              <div className="admin-box adminBoxCourseData">
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={10} md={12}>
                        <h3 style={{ marginBottom: "0px" }}>Downloads</h3>
                      </Cell>
                      <Cell sm={14} md={12} classes="text-right">
                        <button
                          className="small primary button"
                          style={{ marginBottom: "0" }}
                          onClick={this.handle__newDownload}
                        >
                          <Icon icon="plus" left /> Download hinzufügen
                        </button>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>
              {downloadContent}
            </Cell>
          </Row>
        </Grid>
      </React.Fragment>
    );
  }

  view__showDownloadView(download) {
    const { editDownload } = this.state;
    return (
      <Grid type="full">
        <Row>
          <Cell sm={19} md={18}>
            <div>
              {this.view__showDownloadType(download)}{" "}
              <strong>{download.name}</strong>
            </div>
          </Cell>
          <Cell sm={5} md={6} classes="text-right">
            <button
              className="tiny primary button"
              title="Download löschen"
              disabled={editDownload !== 0 && editDownload !== download.id}
              onClick={() => {
                this.handle__deleteDownload(download);
              }}
            >
              <Icon icon="trash" />
            </button>
            &nbsp;
            <button
              className="tiny primary button"
              title="Download bearbeiten"
              disabled={editDownload !== 0 && editDownload !== download.id}
              onClick={() => {
                this.handle__selectEditDownload(download);
              }}
            >
              <Icon icon="cog" />
            </button>
          </Cell>
        </Row>
        <Row>
          <Cell sm={24} md={24}>
            <div className="course-download-url">
              <a href={download.url} target="_blank" rel="noopener noreferrer">
                <small>
                  <Icon icon="long-arrow-right" left /> {download.url}
                </small>
              </a>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showDownloadType(download) {
    let text;

    if (download.type === "pdf") {
      text = "PDF";
    } else if (download.type === "image") {
      text = "Bild";
    }

    if (text) {
      return (
        <strong
          className="small primary label"
          style={{ position: "relative", top: "-2px" }}
        >
          {text}
        </strong>
      );
    } else {
      return null;
    }
  }

  view__showCategories() {
    const { categories, course, selectedCategory } = this.state;

    const DragHandle = sortableHandle(() => (
      <span>
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-course-categories admin-list">{children}</ul>;
    });

    const SortableItem = sortableElement(({ category }) => {
      let showLessons;
      if (category.id === selectedCategory) {
        showLessons = (
          <Grid type="full">
            <Row>
              <Cell sm={22} md={22} lg={22} smo={1} mdo={1}>
                <p className="text-left">
                  {this.view__showButtonNewLesson(category.id)}
                </p>
                {this.view__showLessons(category.lessons)}
              </Cell>
            </Row>
          </Grid>
        );
      }

      return (
        <li className="admin-course-categories-category">
          <div className="admin-box adminBoxCategoryData">
            <Grid type="full">
              <Row>
                <Cell
                  sm={2}
                  md={2}
                  lg={2}
                  classes="admin-course-categories-draghandle"
                >
                  <div title="Verschieben" className="text-center">
                    <DragHandle />
                  </div>
                </Cell>
                <Cell sm={19} md={20} lg={20}>
                  <div
                    className="admin-box-content clickable center-vertically"
                    onClick={() => {
                      const categoryClone = JSON.parse(
                        JSON.stringify(category)
                      );
                      this.setState({
                        editCategory: categoryClone,
                        showCategoryEditPopup: true,
                      });
                    }}
                  >
                    <h3>{category.name}</h3>
                  </div>
                </Cell>
                <Cell sm={3} md={2} lg={2}>
                  <div
                    className="admin-box-content clickable text-center center-vertically"
                    onClick={() => {
                      let { selectedCategory } = this.state;
                      if (selectedCategory === category.id) {
                        selectedCategory = 0;
                      } else {
                        selectedCategory = category.id;
                      }
                      this.setState({ selectedCategory });
                    }}
                  >
                    <Icon icon={showLessons ? "caret-down" : "caret-right"} />
                  </div>
                </Cell>
              </Row>
            </Grid>
          </div>
          {showLessons}
        </li>
      );
    });

    if (course && categories) {
      return (
        <React.Fragment>
          <SortableContainer
            onSortEnd={this.onCategoriesSortEnd}
            useDragHandle
            helperContainer={document.getElementById(
              "adminCourseCategoriesForDrag"
            )}
          >
            {categories.map((category, index) => {
              return (
                <SortableItem
                  key={`item-${index}`}
                  index={index}
                  category={category}
                />
              );
            })}
          </SortableContainer>
        </React.Fragment>
      );
    }

    return null;
  }

  view__hideWhenNew() {
    if (this.state.courseId === "new") {
      return { display: "none" };
    }
  }

  view__showWhenNew() {
    if (this.state.courseId !== "new") {
      return { display: "none" };
    }
  }

  view__visible(type) {
    const { editMode } = this.state;

    if (
      (type === "edit" && editMode === true) ||
      (type === "view" && editMode === false)
    ) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  view__showIsPublished() {
    const { course } = this.state;

    if (!course.draft) {
      return (
        <small className="purchase-active next-to-switch">
          &nbsp;aktiv&nbsp;&nbsp;&nbsp;
        </small>
      );
    } else {
      return (
        <small className="purchase-inactive next-to-switch">
          &nbsp;nicht aktiv&nbsp;&nbsp;&nbsp;
        </small>
      );
    }
  }

  view__showIsVisible() {
    const { course } = this.state;

    if (course.visible) {
      return (
        <small className="purchase-active next-to-switch">
          &nbsp;in Kursliste sichtbar&nbsp;&nbsp;&nbsp;
        </small>
      );
    } else {
      return (
        <small className="purchase-inactive next-to-switch">
          &nbsp;in Kursliste nicht sichtbar&nbsp;&nbsp;&nbsp;
        </small>
      );
    }
  }

  view__showCourseType() {
    const { course, courseTypes } = this.state;

    if (course && course.type) {
      if (courseTypes[course.type]) {
        return courseTypes[course.type];
      } else {
        return course.type;
      }
    }

    return <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />;
  }

  view__showCourseCountdown() {
    const { course } = this.state;

    let v__courseCountdown = (
      <span>
        <Icon icon="times" left /> kein Countdown
      </span>
    );

    if (course && course.countdownable === true) {
      v__courseCountdown = (
        <span>
          <Icon icon="check" left /> Individueller Countdown aktiviert
        </span>
      );
    }

    return v__courseCountdown;
  }

  view__showDigistoreIds() {
    const { course, newDigistoreId } = this.state;

    if (course && course.digistore_product_ids) {
      return (
        <ul className="course-digistore-ids">
          {course.digistore_product_ids.map((digistore_id, index) => {
            return (
              <li key={index}>
                <div className="course-digistore-id">
                  <a
                    href={`https://www.digistore24.com/product/${digistore_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {digistore_id}
                  </a>
                  <button
                    className="course-digistore-id-delete-button"
                    onClick={() => {
                      this.handle__deleteDigistoreId(digistore_id);
                    }}
                  >
                    <Icon icon="times" />
                  </button>
                </div>
              </li>
            );
          })}
          <li className="course-digistore-id-new">
            <input
              type="text"
              value={newDigistoreId}
              placeholder="Neue ID..."
              onChange={(event) => {
                this.handle__newDigistoreIdChange(event);
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  this.handle__newDigistoreId();
                }
              }}
            />
          </li>
        </ul>
      );
    }

    return null;
  }

  view__showLessons(lessons) {
    const { course } = this.state;

    if (!lessons || lessons.length === 0) {
      return (
        <div className="text-center">
          <small>Keine Lektionen in dieser Kategorie</small>
        </div>
      );
    }

    const DragHandle = sortableHandle(() => (
      <span>
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-course-lessons admin-list">{children}</ul>;
    });

    const SortableItem = sortableElement(({ lesson }) => (
      <li className="admin-course-lessons-lessons">
        <Grid type="full">
          <Row>
            <Cell sm={22}>
              <a
                href={`/admin/courses/${lesson.course}/lesson/${lesson.id}`}
                className="clickable admin-box adminBoxLessonsData"
              >
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell
                        sm={3}
                        md={2}
                        lg={2}
                        classes="admin-course-lessons-draghandle"
                      >
                        <div title="Verschieben">
                          <DragHandle />
                        </div>
                      </Cell>
                      <Cell sm={21} md={10} lg={12}>
                        <h3>
                          {/*{lesson.position}. */}
                          {lesson.name}
                        </h3>
                        {this.view__showLessonType(lesson)}
                      </Cell>
                      <Cell sm={24} md={12} lg={10} classes="text-right">
                        <Icon icon="info-circle" left />{" "}
                        {this.view__showLessonStatus(lesson)}
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </a>
            </Cell>
            <Cell sm={2} classes="text-right medium-text-center">
              {this.view__showLessonCategorySwitchButton(lesson)}
            </Cell>
          </Row>
        </Grid>
      </li>
    ));

    if (course && lessons) {
      return (
        <React.Fragment>
          <SortableContainer
            onSortEnd={this.onSortEnd}
            useDragHandle
            helperContainer={document.getElementById(
              "adminCourseLessonsForDrag"
            )}
          >
            {lessons.map((lesson, index) => (
              <SortableItem
                key={`item-${index}`}
                index={index}
                lesson={lesson}
              />
            ))}
          </SortableContainer>
          {/*<ul className="admin-course-lessons admin-list">
            {lessons.map((lesson, index) => {
              let editUrl =
                "/admin/courses/" + course.id + "/lesson/" + lesson.id;
              return (
                <li className="admin-course-lessons-lessons" key={index}>
                  <a href={editUrl} className="clickable admin-box">
                    <div className="admin-box-content">
                      <Grid type="full">
                        <Row>
                          <Cell sm={24} md={12} lg={14}>
                            <h3>
                              {lesson.position}. {lesson.name}
                            </h3>
                            {this.view__showLessonType(lesson)}
                          </Cell>
                          <Cell sm={24} md={12} lg={10} classes="text-right">
                            <Icon icon="info-circle" left />{" "}
                            {this.view__showLessonStatus(lesson)}
                          </Cell>
                        </Row>
                      </Grid>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>*/}
        </React.Fragment>
      );
    }

    return null;
  }

  view__showLessonCategorySwitchButton(lesson) {
    const { categories } = this.state;

    let switchCategories;
    if (categories.length > 1) {
      switchCategories = (
        <div className="center-vertically">
          <button
            className="admin-category-switchbutton"
            title="Kategorie wechseln"
            onClick={() => {
              const categorySwitchLesson = JSON.parse(JSON.stringify(lesson));
              this.setState({
                categorySwitchLesson,
                showCategorySwitchPopup: true,
              });
            }}
          >
            <Icon icon="share-square-o" />
          </button>
        </div>
      );
    }

    return switchCategories;
  }

  view__showLessonType(lesson) {
    const { lessonTypes } = this.state;

    if (lesson && lesson.type) {
      let url = "";
      let icon = "";
      let text = "";
      let link = "";

      if (lesson.live && lesson.live === true) {
        text = lessonTypes.live;
      } else if (lessonTypes[lesson.type]) {
        text = lessonTypes[lesson.type];
      } else {
        text = lesson.type;
      }

      if (lesson.type === "vimeo") {
        icon = <Icon icon="youtube-play" left />;

        if (lesson.content) {
          url = "https://vimeo.com/manage/" + lesson.content + "/general";
          link = (
            <button
              onClick={(event) => {
                window.open(url);
                event.preventDefault();
              }}
            >
              {lesson.content}
            </button>
          );
        } else {
          link = (
            <em style={{ color: "red" }}>
              Keine Vimeo-ID vorhanden <Icon icon="warning" right />
            </em>
          );
        }
      }

      return (
        <div>
          {icon} {text}&nbsp; {link}
        </div>
      );
    }

    return "unbekannt";
  }

  view__showLessonStatus(lesson) {
    const { course } = this.state;
    let textDraft = <em>Entwurf</em>;
    let textPublished = (
      <strong className="purchase-active">veröffentlicht</strong>
    );

    let statusText = "";

    if (lesson) {
      // Draft?
      if (lesson.draft === true) {
        statusText = textDraft;
      } else {
        // Published or Auto-publish?
        if (lesson.publish) {
          const lessonPublishDate = new Date(lesson.publish);
          const now = new Date();
          if (lessonPublishDate <= now) {
            statusText = textPublished;
          } else {
            statusText = (
              <strong>
                autom. <span title="Veröffentlichung">VÖ</span>:{" "}
                <span className="primary-color">
                  {lesson.publish_date + " - " + lesson.publish_time}
                </span>
              </strong>
            );
          }
        } else {
          statusText = textPublished;
        }
      }

      if (course.countdownable === true) {
        let v__extraText;
        if (lesson.show_after_countdown === true) {
          v__extraText = (
            <div>
              <strong>
                <em>nach</em> Countdown
              </strong>
            </div>
          );
        } else {
          v__extraText = (
            <div>
              <strong>
                <em>vor</em> Countdown
              </strong>
            </div>
          );
        }

        statusText = (
          <React.Fragment>
            {statusText}
            {v__extraText}
          </React.Fragment>
        );
      }
    }

    return statusText;
  }

  view__showAutoPublish(lesson) {
    let publishDate = "---";

    if (lesson && lesson.publish) {
      publishDate = (
        <em className="primary-color">
          {lesson.publish_date} - {lesson.publish_time}
        </em>
      );
    }

    return (
      <div>
        <Icon icon="calendar" left /> Autom. Veröffentlichung: {publishDate}
      </div>
    );
  }

  view__showButtonNewLesson(categoryId) {
    const { course, lessons } = this.state;

    let newUrl =
      "/admin/courses/" + course.id + "/lesson/new?category=" + categoryId;

    let button = (
      <a
        href={newUrl}
        className="small primary button"
        style={{ marginBottom: 0, color: "white" }}
      >
        <Icon icon="plus" left /> Neue Lektion
      </a>
    );

    if (course.type === "single" && lessons.length >= 1) {
      button = "";
    }

    return button;
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    Array.from(document.getElementsByClassName("adminBoxLessonsData")).forEach(
      (element) => {
        element.classList.remove("background-saved");
      }
    );

    const { selectedCategory, categories } = this.state;
    categories.map((category) => {
      if (category.id === selectedCategory) {
        category.lessons = arrayMove(category.lessons, oldIndex, newIndex);
      }
      return null;
    });

    this.setState({ categories }, () => {
      const { selectedCategory, categories, course } = this.state;
      const newOrder = [];

      let lessons = [];
      categories.map((category) => {
        if (category.id === selectedCategory) {
          lessons = category.lessons;
        }
        return null;
      });

      lessons.map((lesson) => {
        return newOrder.push(lesson.id);
      });

      axios
        .post(
          this.props.context.apiEndpoints.adminCourseLessonsOrder +
            "/" +
            course.id,
          { newOrder },
          {
            headers: this.props.context.headers,
          }
        )
        .then((response) => {
          const { categories } = response.data;
          this.setState({ categories });

          Array.from(
            document.getElementsByClassName("adminBoxLessonsData")
          ).forEach((element) => {
            element.classList.add("background-saved");
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    });
  };

  onCategoriesSortEnd = ({ oldIndex, newIndex }) => {
    Array.from(document.getElementsByClassName("adminBoxCategoryData")).forEach(
      (element) => {
        element.classList.remove("background-saved");
      }
    );

    this.setState(
      ({ categories }) => ({
        categories: arrayMove(categories, oldIndex, newIndex),
      }),
      () => {
        const { categories, course } = this.state;
        const newOrder = [];

        categories.map((lesson) => {
          return newOrder.push(lesson.id);
        });

        axios
          .post(
            this.props.context.apiEndpoints.adminCourseCategoriesOrder +
              "/" +
              course.id,
            { newOrder },
            {
              headers: this.props.context.headers,
            }
          )
          .then((response) => {
            Array.from(
              document.getElementsByClassName("adminBoxCategoryData")
            ).forEach((element) => {
              element.classList.add("background-saved");
            });
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
    );
  };

  onDownloadsSortEnd = ({ oldIndex, newIndex }) => {
    Array.from(document.getElementsByClassName("adminBoxCourseData")).forEach(
      (element) => {
        element.classList.remove("background-saved");
      }
    );

    const { course } = this.state;
    course.downloads = arrayMove(course.downloads, oldIndex, newIndex);

    this.setState({ course }, () => {
      const { course } = this.state;

      axios
        .post(
          this.props.context.apiEndpoints.adminCourseDownloadsOrder +
            "/" +
            course.id,
          { downloads: course.downloads },
          {
            headers: this.props.context.headers,
          }
        )
        .then((response) => {
          const { downloads } = response.data;
          const { course } = this.state;
          course.downloads = downloads;
          const originalCourse = JSON.parse(JSON.stringify(course));
          this.setState({ course, originalCourse });

          Array.from(
            document.getElementsByClassName("adminBoxCourseData")
          ).forEach((element) => {
            element.classList.add("background-saved");
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    });
  };

  handle__newDownload = () => {
    const { course } = this.state;

    const download = {
      id: "new",
      name: "",
      url: "",
      position: 0,
      course: course.id,
    };

    course.downloads = [download, ...course.downloads];

    this.setState({ course });

    this.handle__selectEditDownload(download);
  };

  handle__selectEditDownload(download) {
    this.setState({ editDownload: download.id, selectedDownload: download });
  }

  handle__cancelEditDownload = () => {
    const { course, originalCourse } = this.state;

    course.downloads = JSON.parse(JSON.stringify(originalCourse.downloads));

    this.setState({ editDownload: 0, selectedDownload: null, course });
  };

  handle__editDownloadChange(value, property) {
    const { selectedDownload } = this.state;
    selectedDownload[property] = value;
    this.setState({ selectedDownload });
  }

  handle__editDownloadSave = () => {
    const { selectedDownload } = this.state;

    // Check if required fields are filled in
    if (!selectedDownload.name) {
      window.alert("Bitte gib mindestens den Namen an.");
      return;
    }

    let url = this.props.context.apiEndpoints.adminCourseDownload;

    axios
      .post(
        url,
        { download: selectedDownload },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { downloads } = response.data;
        const { course } = this.state;
        course.downloads = downloads;
        const originalCourse = JSON.parse(JSON.stringify(course));
        this.setState({ course, originalCourse });

        this.handle__cancelEditDownload();

        Array.from(
          document.getElementsByClassName("adminBoxCourseData")
        ).forEach((element) => {
          element.classList.add("background-saved");
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handle__uploadCourseDownload = () => {
    const { uploadFile, selectedDownload } = this.state;
    const formData = new FormData();

    formData.append("file", uploadFile);
    formData.append("name", uploadFile.name);

    axios
      .post(
        this.props.context.apiEndpoints.adminCourseDownloadUpload,
        formData,
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { path, type } = response.data;
        if (path || type) {
          selectedDownload.url = path;
          selectedDownload.type = type;
          this.setState({ selectedDownload });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  handle__deleteDownload = (download) => {
    if (
      window.confirm(
        "Möchtest Du diese Download-Datei wirklich unwiderruflich löschen?\r\n\r\nDiese Aktion kann nicht rückgängig gemacht werden."
      )
    ) {
      let url =
        this.props.context.apiEndpoints.adminCourseDownload + "/" + download.id;

      axios
        .delete(url, {
          headers: this.props.context.headers,
        })
        .then((response) => {
          const { downloads } = response.data;
          const { course } = this.state;
          course.downloads = downloads;
          const originalCourse = JSON.parse(JSON.stringify(course));
          this.setState({ course, originalCourse });

          this.handle__cancelEditDownload();

          Array.from(
            document.getElementsByClassName("adminBoxCourseData")
          ).forEach((element) => {
            element.classList.add("background-saved");
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else {
      return;
    }
  };

  handle__editChange(event, property) {
    const { course } = this.state;

    if (property === "countdownable") {
      if (event.target.value === "yes") {
        course[property] = true;
      } else {
        course[property] = false;
      }
    } else {
      course[property] = event.target.value;
    }

    this.setState({ course });
  }

  handle__editCancel = () => {
    const originalCourse = JSON.parse(
      JSON.stringify(this.state.originalCourse)
    );
    this.setState({
      course: originalCourse,
      editMode: false,
    });
  };

  handle__editSave = () => {
    const { courseId, course } = this.state;

    // Check if required fields are filled in
    if (!course.name || !course.type) {
      window.alert(
        "Bitte gib mindestens den Namen und den Typen des Kurses an."
      );
      return;
    }

    let url = this.props.context.apiEndpoints.adminCourse + "/" + course.id;
    if (courseId === "new") {
      url = this.props.context.apiEndpoints.adminCourse + "/new";
    }

    axios
      .post(
        url,
        { course: course },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        if (courseId === "new") {
          const { course } = response.data;

          //console.log(response.data);
          window.location = "/admin/courses/" + course.id;

          return;
        } else {
          const { course } = response.data;
          const originalCourse = JSON.parse(JSON.stringify(course));
          this.setState({ course, originalCourse, editMode: false });

          Array.from(
            document.getElementsByClassName("adminBoxCourseData")
          ).forEach((element) => {
            element.classList.add("background-saved");
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handle__activation = () => {
    const { course } = this.state;

    Array.from(document.getElementsByClassName("adminBoxCourseData")).forEach(
      (element) => {
        element.classList.remove("background-saved");
      }
    );

    axios
      .post(
        this.props.context.apiEndpoints.adminCourseActivation +
          "/" +
          course.id +
          "/draft",
        {},
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { course } = response.data;
        const originalCourse = JSON.parse(JSON.stringify(course));
        this.setState({ course, originalCourse, editMode: false });

        Array.from(
          document.getElementsByClassName("adminBoxCourseData")
        ).forEach((element) => {
          element.classList.add("background-saved");
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handle__activation_visible = () => {
    const { course } = this.state;

    Array.from(document.getElementsByClassName("adminBoxCourseData")).forEach(
      (element) => {
        element.classList.remove("background-saved");
      }
    );

    axios
      .post(
        this.props.context.apiEndpoints.adminCourseActivation +
          "/" +
          course.id +
          "/visible",
        {},
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { course } = response.data;
        const originalCourse = JSON.parse(JSON.stringify(course));
        this.setState({ course, originalCourse, editMode: false });

        Array.from(
          document.getElementsByClassName("adminBoxCourseData")
        ).forEach((element) => {
          element.classList.add("background-saved");
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handle__newDigistoreIdChange(event, property) {
    const newValue = event.target.value;

    if (this.isNumeric(newValue)) {
      this.setState({ newDigistoreId: newValue });
    }
  }

  handle__newDigistoreId = () => {
    const { course, newDigistoreId } = this.state;

    axios
      .post(
        this.props.context.apiEndpoints.adminCourseNewDigistoreId +
          "/" +
          course.id,
        { id: newDigistoreId },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { course } = this.state;
        const { digistore_ids } = response.data;
        course.digistore_product_ids = digistore_ids;
        this.setState({ course, newDigistoreId: "" });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handle__deleteDigistoreId = (digistoreId) => {
    const { course } = this.state;

    if (
      window.confirm(
        "Möchtest Du die Digistore Produkt ID [" +
          digistoreId +
          "] wirklich löschen?"
      )
    ) {
      axios
        .post(
          this.props.context.apiEndpoints.adminCourseDelDigistoreId +
            "/" +
            course.id,
          { id: digistoreId },
          {
            headers: this.props.context.headers,
          }
        )
        .then((response) => {
          const { course } = this.state;
          const { digistore_ids } = response.data;
          course.digistore_product_ids = digistore_ids;
          this.setState({ course, newDigistoreId: "" });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  handle__categorySave = () => {
    const { editCategory, course } = this.state;

    Array.from(
      document.getElementsByClassName("adminBoxCategoriesData")
    ).forEach((element) => {
      element.classList.remove("background-saved");
    });

    // Check if required fields are filled in
    if (!editCategory.name) {
      window.alert("Bitte gib den Namen der Kategorie an.");
      return;
    }

    let url =
      this.props.context.apiEndpoints.adminCourseCategory +
      "/" +
      editCategory.id;
    if (!editCategory.id) {
      url = this.props.context.apiEndpoints.adminCourseCategory + "/new";
      editCategory.id = "new";
      editCategory.course = course.id;
    }

    axios
      .post(
        url,
        { category: editCategory },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { categories } = response.data;
        //console.log(response.data);
        this.setState({
          categories,
          editCategory: {},
          showCategoryEditPopup: false,
        });

        Array.from(
          document.getElementsByClassName("adminBoxCategoryData")
        ).forEach((element) => {
          element.classList.add("background-saved");
        });

        return;
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handle__categorySwitchSave = () => {
    const { categorySwitchLesson } = this.state;

    Array.from(
      document.getElementsByClassName("adminBoxCategoriesData")
    ).forEach((element) => {
      element.classList.remove("background-saved");
    });

    // Check if required fields are filled in
    if (!categorySwitchLesson.category) {
      window.alert("Bitte gib die Kategorie an.");
      return;
    }

    let url =
      this.props.context.apiEndpoints.adminCourseCategorySwitch +
      "/" +
      categorySwitchLesson.id;

    axios
      .post(
        url,
        { lesson: categorySwitchLesson },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { categories } = response.data;
        //console.log(response.data);
        this.setState({
          categories,
          categorySwitchLesson: {},
          showCategorySwitchPopup: false,
        });

        Array.from(
          document.getElementsByClassName("adminBoxCategoryData")
        ).forEach((element) => {
          element.classList.add("background-saved");
        });

        return;
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  /**
   * Init
   * Load Course Info
   */
  init__load() {
    const { courseId } = this.state;

    if (courseId) {
      axios
        .get(this.props.context.apiEndpoints.adminCourse + "/" + courseId, {
          headers: this.props.context.headers,
        })
        .then((response) => {
          const { course, categories } = response.data;
          const originalCourse = JSON.parse(JSON.stringify(course));

          let selectedCategory = 0;
          if (categories && categories.length > 0) {
            selectedCategory = categories[0].id;
          }

          this.setState({
            course,
            originalCourse,
            lessons: course.lessons,
            categories,
            selectedCategory,
          });
          if (courseId === "new") {
            this.setState({ editMode: true });
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }

  isNumeric(number) {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (number === "" || re.test(number)) {
      return true;
    }

    return false;
  }
}

export default withContext(AdminCourseView);
