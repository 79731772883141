import React, { Component } from "react";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import withContext from "../../../context/contextHOC";
import CalendarEntry from "../../calendar/calendarEntry";

class WorkspaceCalendar extends Component {
  state = {
    now: new Date(),
    dayName: "",
    dayNr: "",
    dayMonth: "",
    months: this.props.context.months,
    weekdays: this.props.context.weekdays,
    entries: this.props.entries,
    selectedCalendarEntry: undefined
  };

  componentDidMount() {
    const { now, weekdays, months } = this.state;

    const dayNr = now.getDate();
    const dayName = weekdays[now.getDay()];
    const dayMonth = months[now.getMonth() + 1];

    this.setState({ dayNr, dayName, dayMonth });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.entries !== this.props.entries) {
      this.setState({ entries: this.props.entries });
    }
  }

  render() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;

    return (
      <React.Fragment>
        {this.show__popupCalendarEntry()}
        <div
          className="selfmade-grid-item selfmade-grid-item-small selfmade-grid-bg-darkgray"
          id="selfmadeGridCalendar"
        >
          <Grid>
            <Row>
              <Cell sm={24} md={12} classes="hide-for-small-only">
                <div className="selfmade-calendar-day">
                  <div className="selfmade-grid-item-content">
                    <div className="day-name">{this.state.dayName}</div>
                    <div className="day-nr">{this.state.dayNr}</div>
                    <div className="day-month">{this.state.dayMonth}</div>
                  </div>
                </div>
              </Cell>
              <Cell sm={24} md={12}>
                <div className="selfmade-calendar-list">
                  <div className="selfmade-grid-item-content">
                    <h3 className="grid-caption">
                      <div className="float-right">
                        <div className="selfmade-settings-button">
                          <a href={"/kalender/" + year + "/" + month + "/new"}>
                            <Icon icon="plus" title="Neuen Termin hinzufügen" />
                          </a>{" "}
                          &nbsp;
                          <a href="/kalender" title="Zur Kalenderansicht">
                            <Icon icon="calendar" />
                          </a>
                        </div>
                      </div>
                      Anstehende Termine
                    </h3>
                    {this.show__list()}
                  </div>
                </div>
              </Cell>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  show__list() {
    const { entries } = this.state;

    if (entries.length > 0) {
      return (
        <ul className="selfmade-calendar-list-list">
          {entries.map((entry, index) => {
            if (index <= 2) {
              return (
                <li key={index}>
                  <button
                    onClick={() => {
                      this.setState({ selectedCalendarEntry: entry });
                    }}
                  >
                    <div className="selfmade-calendar-list-item-time">
                      {entry.start_date_noyear} - {entry.start_time} Uhr
                    </div>
                    <div className="selfmade-calendar-list-item-name">
                      {entry.name}
                    </div>
                  </button>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      );
    }
  }

  show__popupCalendarEntry() {
    return (
      <CalendarEntry
        entry={this.state.selectedCalendarEntry}
        handleClose={() => {
          this.setState({ selectedCalendarEntry: undefined });
        }}
        handleUpdate={this.handle__onUpdate}
        type="grid"
      />
    );
  }

  handle__onUpdate = (days, entry) => {
    window.location.reload();
  };
}

export default withContext(WorkspaceCalendar);
