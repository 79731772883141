import React, { Component } from "react";
import axios from "axios";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation.jsx";
import PTPopup from "../../ptPopup/ptPopup";
import withContext from "../../../context/contextHOC.jsx";

class VideoTopicRequest extends Component {
  state = {
    backgroundImageUrl: this.props.backgroundImageUrl,
    showPopup: false,
    topic: "",
    saved: false,
    selfmadeCoursePurchased: this.props.selfmadeCoursePurchased,
    course: this.props.course,
    type: this.props.type
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.backgroundImageUrl !== this.props.backgroundImageUrl) {
      this.setState({ backgroundImageUrl: this.props.backgroundImageUrl });
    }

    if (
      prevProps.selfmadeCoursePurchased !== this.props.selfmadeCoursePurchased
    ) {
      this.setState({
        selfmadeCoursePurchased: this.props.selfmadeCoursePurchased
      });
    }

    if (prevProps.course !== this.props.course) {
      this.setState({
        course: this.props.course
      });
    }

    if (prevProps.type !== this.props.type) {
      this.setState({
        type: this.props.type
      });
    }
  }

  render() {
    //const { backgroundImageUrl } = this.state;
    return (
      <React.Fragment>
        {this.show__content()}
        {this.show__popup()}
      </React.Fragment>
    );
  }

  show__content() {
    const { type, selfmadeCoursePurchased } = this.state;

    if (type === "workspace") {
      if (selfmadeCoursePurchased === true) {
        return (
          <div
            id="selfmadeGridVideoTopicRequest"
            className="selfmade-grid-item selfmade-grid-item-small selfmade-grid-bg-gray"
            onClick={this.show__popupEdit}
          >
            <div className="selfmade-grid-item selfmade-grid-bg-darker">
              <div className="selfmade-grid-item-content">
                <div className="selfmade-grid-icon">
                  <Icon icon="question-circle" />
                </div>
                <div className="selfmade-grid-caption grid-caption">
                  Dein SELFMADE
                  <br />
                  Video Wunsch
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            id="selfmadeGridVideoTopicRequest"
            className="selfmade-grid-item selfmade-grid-item-small selfmade-grid-bg-dark qft-disabled"
          >
            <div className="selfmade-grid-item selfmade-grid-bg-darker">
              <div className="selfmade-grid-item-content">
                <div className="selfmade-grid-icon">
                  <Icon icon="question-circle" />
                </div>
                <div className="selfmade-grid-caption grid-caption">
                  Dein SELFMADE
                  <br />
                  Video Wunsch
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else if (type === "course") {
      return (
        <button className="tile" onClick={this.show__popupEdit}>
          <Grid type="full">
            <Row>
              <Cell sm={4}>
                <div className="big-icon">
                  <Icon icon="commenting-o" />
                </div>
              </Cell>
              <Cell sm={18}>
                Wünsche Dir jetzt ein Thema für eines der nächsten Videos
                &raquo;
              </Cell>
            </Row>
          </Grid>
        </button>
      );
    }
  }

  show__popup() {
    const { showPopup } = this.state;

    if (showPopup === true) {
      return (
        <div className="selfmade-popup">
          <PTPopup
            show={this.state.showPopup}
            size="medium"
            handleClose={() => {
              this.handle__cancel();
            }}
          >
            {this.show__popupContent()}
          </PTPopup>
        </div>
      );
    } else {
      return null;
    }
  }

  show__popupContent() {
    const { saved, topic } = this.state;
    const { firstname } = this.props.context.user;

    if (saved === true) {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <h2>Dein SELFMADE Video Wunsch</h2>
              <div>&nbsp;</div>
              <p>
                <strong>Hey {firstname},</strong>
              </p>
              <p>
                <strong>
                  vielen Dank für Dein Feedback!
                  <br />
                  Wir haben Deinen Wunsch erhalten und werden schauen, ob wir
                  Dein Thema in einem der nächsten Videos näher betrachten
                  können!
                </strong>
              </p>
              <div>&nbsp;</div>
            </Cell>
          </Row>
        </Grid>
      );
    } else {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <h2>Dein SELFMADE Video Wunsch</h2>

              <p>Hey {firstname},</p>

              <p>
                wie Du weißt erscheint jede Woche ein neues, exklusives Video im
                SELFMADE-Bereich für Dich.
              </p>

              <p>
                Wir möchten den SELFMADE-Kurs natürlich so genau wie möglich auf
                unsere Community, also auf Dich, zuschneiden. Daher hast Du an
                dieser Stelle die Möglichkeit, Dir ein Thema zu wünschen, über
                das Du gerne mehr von Torben lernen würdest.
              </p>

              <p>
                Bitte beachte, dass Torben nicht jedes gewünschte Thema umsetzen
                kann. Daher gibt es keine Garantie, dass Dein Thema dazu gehört.
              </p>

              <p>
                Falls Dir mehrere Themen einfallen, würden wir uns freuen, wenn
                Du jedes Thema einzeln sendest. Das erhöht für uns die
                Übersichtlichkeit und für Dich die Chance, dass Dein Thema in
                einem der nächsten Videos Platz findet.
              </p>

              <Grid type="full">
                <Row>
                  <Cell sm={24}>
                    <label>
                      Dein Themenwunsch:
                      <textarea
                        rows={3}
                        value={topic || ""}
                        onChange={event => {
                          this.handle__editChange(event);
                        }}
                      />
                    </label>
                    <div className="ptpopup-buttons">
                      <button
                        className="primary hollow button"
                        onClick={this.handle__cancel}
                      >
                        <Icon icon="times" left /> Abbrechen
                      </button>
                      <button
                        className="primary button"
                        onClick={this.handle__save}
                      >
                        <Icon icon="check" left /> Absenden
                      </button>
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      );
    }
  }

  show__popupEdit = () => {
    this.setState({ showPopup: true });
  };

  hide__popupEdit = () => {
    this.setState({ showPopup: false });
  };

  /**
   * Handle Changes in Mission Statement Input
   */
  handle__editChange(event) {
    this.setState({ topic: event.target.value });
  }

  /**
   * Hide Popup
   */
  handle__cancel = () => {
    this.setState({ saved: false });
    this.hide__popupEdit();
  };

  handle__save = () => {
    const { topic, course } = this.state;

    axios
      .post(
        this.props.context.apiEndpoints.selfmadeVideoTopicRequest,
        {
          topic: topic,
          course: course
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        this.setState({ saved: true });
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  };
}

export default withContext(VideoTopicRequest);
