import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import { Grid, Row, Cell, Icon, Button } from "../foundation/foundation";
import withContext from "../../context/contextHOC";
import axios from "axios";
import qs from "query-string";
import { isUndefined } from "util";
import Breadcrumbs from "../topbars/breadcrumbs";

import PTPopup from "../ptPopup/ptPopup";
import PTTabs from "../ptTabs/ptTabs";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import PlusCircle from "../../images/plus-circle.png";
import RemoveButton from "../../images/close-narrow-grey.png";
import ReactTooltip from "react-tooltip";
import Footer from "../layout/footer";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);

class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        firstname: "",
        lastname: "",
        gender: "",
        birthday: new Date(),
        street: "",
        zip: "",
        city: "",
        country: ""
      },
      userDetails: {},
      userLoaded: false,
      showMsgSaved: false,
      popupImageUpload: false,
      popupImageCrop: false,
      cropUrl: "",
      cropImage: "",
      crop: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        aspect: 1 / 1
      },
      tabs: {
        profile: {
          name: "Mein Profil",
          icon: "user-circle",
          selected: true
        },

        links: {
          name: "Meine Links",
          icon: "share-alt",
          selected: false
        },
        contact: {
          name: "Meine Kontaktdaten",
          icon: "vcard-o",
          selected: false
        }
      },
      selectedTab: "profile"
    };

    const queryParams = qs.parse(this.props.location.search);
    if (!isUndefined(queryParams.msg) && queryParams.msg === "saved") {
      this.state.showMsgSaved = true;
    } else {
      this.state.showMsgSaved = false;
    }
  }

  componentDidUpdate() {
    if (
      this.props.context.userLoaded === true &&
      this.state.userLoaded === false
    ) {
      const loadedUser = JSON.parse(JSON.stringify(this.props.context.user));
      if (loadedUser.birthday) {
        loadedUser.birthday = new Date(loadedUser.birthday);
      }
      this.setState(
        {
          user: loadedUser,
          userLoaded: true
        },
        () => {
          this.init();
        }
      );
    }
  }

  render() {
    return (
      <div
        id="pageMyProfile"
        className="darkmode page-with-topbar-and-breadcrumbs default-page"
      >
        <MainTopbar />
        <Breadcrumbs breadcrumbs={["dashboard", "myprofile"]} />
        <section className="content">
          <ReactTooltip place="top" type="dark" effect="solid" />
          <Grid>
            <Row>
              <Cell sm={24} md={18} lg={18}>
                <h2>Profil bearbeiten</h2>

                <PTTabs
                  tabs={this.state.tabs}
                  onUpdate={tabs => {
                    let selectedTab = "";
                    Object.keys(tabs).map((keyName, index) => {
                      if (tabs[keyName].selected === true) {
                        selectedTab = keyName;
                      }
                      return null;
                    });
                    this.setState({ tabs, selectedTab });
                  }}
                />
                <div>&nbsp;</div>

                {this.showMsgSaved()}
              </Cell>
            </Row>
          </Grid>
          {this.view__showContent()}
        </section>
        <Footer />

        <PTPopup
          show={this.state.popupImageUpload}
          size="medium"
          nopadding
          handleClose={() => {
            this.hidePopupImageUpload();
          }}
        >
          <Dropzone
            onDrop={acceptedFiles => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function() {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();
                  this.showPopupImageCrop();
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div id="avatarDropZone">
                    <h2>Benutzerbild hochladen</h2>
                    <div id="avatarDropZoneText">
                      <p>
                        <Icon icon="upload" />
                      </p>
                      <p>
                        Lege in diesem Popup eine Bilddatei (JPG, GIF, PNG) ab
                        oder
                        <br />
                        klicke hier, um ein Benutzerbild hochzuladen.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </PTPopup>

        <PTPopup
          show={this.state.popupImageCrop}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h2>Bildausschnitt wählen</h2>
          </div>

          <ReactCrop
            src={this.state.cropUrl}
            crop={this.state.crop}
            onChange={this.onCrop}
          />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="avatar-crop-buttons">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> Abbrechen
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadAvatar}
                  >
                    <Icon icon="check" left /> Speichern
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </PTPopup>
      </div>
    );
  }

  view__showContent() {
    const { tabs } = this.state;

    if (tabs.profile.selected === true) {
      return this.view__showProfile();
    } else if (tabs.links.selected === true) {
      return this.view__showLinks();
    } else if (tabs.contact.selected === true) {
      return this.view__showContactDetails();
    }

    return null;
  }

  view__showProfile() {
    return (
      <Grid>
        <Row>
          <Cell sm={24} md={18}>
            <p className="lead">
              Folgende Informationen werden in Deinem Profil in der Community
              angezeigt und sind für jeden innerhalb des Mitgliederbereichs
              sichtbar.
            </p>
          </Cell>
        </Row>
        <Row margin="x">
          <Cell sm={24} md={12} lg={12}>
            {this.view__showProfileLeftColumn()}
          </Cell>
          <Cell sm={24} md={12} lg={12}>
            {this.view__showProfileRightColumn()}
          </Cell>
        </Row>
        <Row>
          <Cell sm={24} classes="text-right">
            <div className="page-buttons">
              <Button href="/dashboard" type="primary" hollow>
                <Icon icon="times" left /> Abbrechen
              </Button>
              <Button
                type="primary"
                click={() => {
                  this.save();
                }}
              >
                <Icon icon="check" left /> Speichern
              </Button>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showLinks() {
    return (
      <Grid>
        <Row>
          <Cell sm={24} md={12}>
            <p className="lead">
              Hinterlege Links zu Deinen sozialen Profilen, um mit anderen
              SELFMATES in Kontakt zu bleiben.
            </p>
          </Cell>
        </Row>
        <Row margin="x">
          <Cell sm={24} md={18} lg={18}>
            {this.view__showLinksLeftColumn()}
          </Cell>
          <Cell sm={24} md={12} lg={12}>
            {this.view__showLinksRightColumn()}
          </Cell>
        </Row>
        <Row>
          <Cell sm={24} classes="text-right">
            <div className="page-buttons">
              <Button href="/dashboard" type="primary" hollow>
                <Icon icon="times" left /> Abbrechen
              </Button>
              <Button
                type="primary"
                click={() => {
                  this.save();
                }}
              >
                <Icon icon="check" left /> Speichern
              </Button>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showContactDetails() {
    return (
      <Grid>
        <Row margin="x">
          <Cell sm={24} md={12} lg={12}>
            {this.view__showContactLeftColumn()}
          </Cell>
          <Cell sm={24} md={12} lg={12}>
            {this.view__showContactRightColumn()}
          </Cell>
        </Row>
        <Row>
          <Cell sm={24} classes="text-right">
            <div className="page-buttons">
              <Button href="/dashboard" type="primary" hollow>
                <Icon icon="times" left /> Abbrechen
              </Button>
              <Button
                type="primary"
                click={() => {
                  this.save();
                }}
              >
                <Icon icon="check" left /> Speichern
              </Button>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showProfileLeftColumn() {
    const { userDetails } = this.state;

    if (!userDetails || !userDetails.owner) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="box">
          <div className="box-content">
            <h3>Biografie</h3>

            <div className="info-content edit-mode">
              <textarea
                rows={6}
                placeholder="Schreibe einen kurzen Text über Dich."
                value={userDetails.bio || ""}
                onChange={this.handle__detailsChange("bio")}
              />
            </div>
            <div className="info-caption"></div>
          </div>
        </div>

        <div className="box">
          <div className="box-content">
            <h3>Was ich anbiete</h3>

            <div className="info-content edit-mode">
              <textarea
                rows={6}
                placeholder="Was kannst Du anbieten? Wobei kannst Du die Community unterstützen?"
                value={userDetails.offer || ""}
                onChange={this.handle__detailsChange("offer")}
              />
            </div>
            <div className="info-caption"></div>
          </div>
        </div>

        <div className="box">
          <div className="box-content">
            <h3>Was ich suche</h3>

            <div className="info-content edit-mode">
              <textarea
                rows={6}
                placeholder="Brauchst Du Unterstützung bei etwas?"
                value={userDetails.search || ""}
                onChange={this.handle__detailsChange("search")}
              />
            </div>
            <div className="info-caption"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  view__showProfileRightColumn() {
    const { userDetails } = this.state;

    if (!userDetails || !userDetails.owner) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="box">
          <div className="box-content">
            <Grid type="full">
              <Row>
                <Cell sm={24} md={17}>
                  <h3>Benutzerbild</h3>

                  <div
                    style={{
                      display: this.state.user.avatar ? "block" : "none"
                    }}
                  >
                    <button
                      className="profile-delete-avatar"
                      onClick={this.deleteAvatar}
                    >
                      Benutzerbild entfernen
                    </button>
                  </div>

                  <div>
                    <Button
                      type="primary"
                      hollow
                      click={() => {
                        this.showPopupImageUpload();
                      }}
                    >
                      Neues Bild hochladen
                    </Button>
                  </div>
                </Cell>
                <Cell sm={24} md={7}>
                  {this.showUserAvatar()}
                </Cell>
              </Row>
            </Grid>
          </div>
        </div>

        <div className="box">
          <div className="box-content">
            <h3>Details</h3>

            <div className="info-content edit-mode">
              <input
                type="text"
                placeholder="Mein Beruf"
                value={userDetails.profession || ""}
                onChange={this.handle__detailsChange("profession")}
              />
            </div>
            <div className="info-caption">Mein Beruf</div>
            {/*
            <Grid type="full">
              <Row>
                <Cell sm={24} classes="text-center">
                  <label>
                    <input
                      type="checkbox"
                      checked={userDetails.show_birthday}
                      onChange={() => {
                        this.handle__toggleCheckbox("show_birthday");
                      }}
                    />{" "}
                    Geburtstag im Profil anzeigen
                  </label>
                </Cell>
                <Cell sm={24} classes="text-center">
                  <label>
                    <input
                      type="checkbox"
                      checked={userDetails.show_gender}
                      onChange={() => {
                        this.handle__toggleCheckbox("show_gender");
                      }}
                    />{" "}
                    Geschlecht im Profil anzeigen
                  </label>
                </Cell>
              </Row>
                    </Grid>*/}
          </div>
        </div>
      </React.Fragment>
    );
  }

  view__showLinksLeftColumn() {
    const { userDetails } = this.state;

    if (!userDetails || !userDetails.owner) {
      return null;
    }

    const { socials } = userDetails;

    let output;
    if (socials && socials.length > 0) {
      output = (
        <React.Fragment>
          {socials.map((socialLink, index) => {
            return (
              <React.Fragment key={index}>
                {this.view__socialLink(index, socialLink)}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {output}
        {this.view__addSocialLinkButton()}
      </React.Fragment>
    );
  }

  view__socialLink(index, socialLink) {
    const _c = this.props.context;
    const { socialPlatforms } = _c;

    let title;
    if (socialLink.new && socialLink.new === true) {
      title = <h3>Neuer Link</h3>;
    }

    return (
      <div className="box">
        <div className="box-content">
          {title}

          <Grid type="full">
            <Row margin="x">
              <Cell sm={24} md={6}>
                <select
                  value={socialLink.type}
                  style={{ marginBottom: "0" }}
                  onChange={event => {
                    this.handle__changeSocialLink(
                      index,
                      "type",
                      event.target.value
                    );
                  }}
                >
                  <option value=""></option>
                  {Object.keys(socialPlatforms).map((key, platformIndex) => {
                    return (
                      <option key={platformIndex} value={key}>
                        {socialPlatforms[key].name}
                      </option>
                    );
                  })}
                </select>
              </Cell>
              <Cell sm={22} md={16}>
                <input
                  type="text"
                  value={socialLink.url}
                  style={{ marginBottom: "0" }}
                  onChange={event => {
                    this.handle__changeSocialLink(
                      index,
                      "url",
                      event.target.value
                    );
                  }}
                />
              </Cell>
              <Cell sm={2} md={2} classes="text-center">
                <div className="userprofile-remove-sociallink-wrapper">
                  <button
                    onClick={() => {
                      this.handle__removeSocialLink(index);
                    }}
                    className="userprofile-remove-sociallink"
                    data-tip="Diesen Link entfernen"
                  >
                    <img src={RemoveButton} alt="X" />
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }

  view__addSocialLinkButton() {
    return (
      <div className="userprofile-add-sociallink">
        <ReactTooltip place="top" type="dark" effect="solid" />
        <img
          src={PlusCircle}
          alt="+"
          onClick={this.handle__addNewSocialLink}
          data-tip="Neuen Link hinzufügen"
        />
      </div>
    );
  }

  view__showLinksRightColumn() {
    const { userDetails } = this.state;

    if (!userDetails || !userDetails.owner) {
      return null;
    }
  }

  view__showContactLeftColumn() {
    return (
      <React.Fragment>
        <div className="box">
          <div className="box-content">
            <h3>Persönliche Daten</h3>

            <label>
              Vorname
              <input
                type="text"
                placeholder="Dein Vorname"
                value={this.state.user.firstname}
                onChange={this.handle__change("firstname")}
              />
            </label>

            <label>
              Nachname
              <input
                type="text"
                placeholder="Dein Nachname"
                value={this.state.user.lastname}
                onChange={this.handle__change("lastname")}
              />
            </label>

            <label>Geschlecht</label>
            <Row padding="x">
              <Cell sm={24} md={8} classes="text-center">
                <label>
                  <input
                    type="radio"
                    value="f"
                    checked={this.state.user.gender === "f"}
                    onChange={this.handle__change("gender")}
                  />{" "}
                  weiblich
                </label>
              </Cell>
              <Cell sm={24} md={8} classes="text-center">
                <label>
                  <input
                    type="radio"
                    value="m"
                    checked={this.state.user.gender === "m"}
                    onChange={this.handle__change("gender")}
                  />{" "}
                  männlich
                </label>
              </Cell>
              <Cell sm={24} md={8} classes="text-center">
                <label>
                  <input
                    type="radio"
                    value="d"
                    checked={this.state.user.gender === "d"}
                    onChange={this.handle__change("gender")}
                  />{" "}
                  divers
                </label>
              </Cell>
            </Row>

            <label>Geburtstag</label>
            <DatePicker
              selected={this.state.user.birthday}
              onChange={this.handle__birthdayChange}
              dateFormat="dd.MM.yyyy"
              locale="de"
              showMonthDropdown
              showYearDropdown
              maxDate={new Date()}
              dropdownMode="select"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  view__showContactRightColumn() {
    return (
      <React.Fragment>
        <div className="box">
          <div className="box-content">
            <h3>Adresse</h3>

            <label>
              Straße
              <input
                type="text"
                placeholder="Straße &amp; Hausnr."
                value={this.state.user.street || ""}
                onChange={this.handle__change("street")}
              />
            </label>

            <label>PLZ &amp; Ort</label>

            <Row>
              <Cell sm={6}>
                <input
                  type="text"
                  placeholder="PLZ"
                  value={this.state.user.zip || ""}
                  onChange={this.handle__change("zip")}
                />
              </Cell>
              <Cell sm={1}>&nbsp;</Cell>
              <Cell sm={17}>
                <input
                  type="text"
                  placeholder="Ort"
                  value={this.state.user.city || ""}
                  onChange={this.handle__change("city")}
                />
              </Cell>
            </Row>

            <label>
              Land
              <input
                type="text"
                placeholder="Land"
                value={this.state.user.country || ""}
                onChange={this.handle__change("country")}
              />
            </label>
          </div>
        </div>
      </React.Fragment>
    );
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = crop => {
    this.setState({ crop });
  };

  showUserAvatar() {
    const { user } = this.state;
    if (user.avatar) {
      return (
        <img src={user.avatar} alt="" className="profile-avatar-preview" />
      );
    } else {
      return null;
    }
  }

  deleteAvatar = () => {
    if (
      window.confirm(
        "Möchtest Du Dein Benutzerbild wirklich entfernen? Du kannst jederzeit ein neues hochladen."
      )
    ) {
      axios
        .patch(
          this.props.context.apiEndpoints.deleteAvatar,
          {},
          { headers: this.props.context.headers }
        )
        .then(response => {
          if (response.data === "OK") {
            const { user } = this.state;
            user.avatar = null;
            this.setState({ user });
          } else {
            console.log(response);
          }
        })
        .catch(error => {
          console.log("ERROR", error);
        });
    } else {
      return;
    }
  };

  showMsgSaved() {
    if (this.state.showMsgSaved === true) {
      return (
        <div className="success callout">
          <strong>Deine Änderungen wurden gespeichert!</strong>
        </div>
      );
    }
  }

  showPopupImageUpload() {
    this.setState({ popupImageUpload: true });
  }

  hidePopupImageUpload() {
    this.setState({ popupImageUpload: false });
  }

  showPopupImageCrop() {
    this.setState({ popupImageCrop: true });
  }

  hidePopupImageCrop() {
    this.setState({ popupImageCrop: false });
  }

  handle__change = name => event => {
    let targetValue = event.target.value;
    const { user } = this.state;
    user[name] = targetValue;
    this.setState({ user });
  };

  handle__birthdayChange = date => {
    const { user } = this.state;
    user.birthday = date;
    this.setState({
      user
    });
  };

  handle__detailsChange = property => event => {
    let value = event.target.value;
    const { userDetails } = this.state;
    userDetails[property] = value;
    this.setState({ userDetails });
  };

  handle__toggleCheckbox = property => {
    const { userDetails } = this.state;
    if (userDetails[property] === true) {
      userDetails[property] = false;
    } else {
      userDetails[property] = true;
    }
    this.setState({ userDetails });
  };

  handle__changeSocialLink(index, property, value) {
    const { userDetails } = this.state;
    userDetails.socials[index][property] = value;
    this.setState({ userDetails });
  }

  handle__removeSocialLink = selectedIndex => {
    if (window.confirm("Möchtest Du den Link wirklich entfernen?")) {
      const { userDetails } = this.state;
      const { socials } = userDetails;
      let newSocials = [];
      socials.map((social, index) => {
        if (index !== selectedIndex) {
          newSocials.push(social);
        }
        return null;
      });
      userDetails.socials = newSocials;
      this.setState({ userDetails });
    }
  };

  handle__addNewSocialLink = () => {
    const { userDetails } = this.state;
    if (!userDetails.socials) {
      userDetails.socials = [];
    }

    userDetails.socials.push({
      type: "",
      url: "",
      new: true
    });

    this.setState({ userDetails });
  };

  save() {
    const { user, userDetails } = this.state;

    if (user && user.birthday) {
      const birthday =
        ("0" + user.birthday.getDate()).slice(-2) +
        "." +
        ("0" + (user.birthday.getMonth() + 1)).slice(-2) +
        "." +
        user.birthday.getFullYear();
      user.birthday = birthday;
    }
    this.setState({ user });

    if (userDetails && userDetails.socials && userDetails.socials.length > 0) {
      userDetails.socials = userDetails.socials.map(socialLink => {
        socialLink.new = undefined;
        return socialLink;
      });
    }

    axios
      .patch(
        this.props.context.apiEndpoints.saveUser,
        {
          user: user,
          userDetails: userDetails
        },
        { headers: this.props.context.headers }
      )
      .then(response => {
        if (response.data === "OK") {
          window.location = "/profil?msg=saved";
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  }

  uploadAvatar = () => {
    const { cropImage, crop, user } = this.state;
    const formData = new FormData();

    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));

    axios
      .post(this.props.context.apiEndpoints.uploadAvatar, formData, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { path } = response.data;
        if (path) {
          user.avatar = path;
          this.setState({ user });
          this.hidePopupImageCrop();
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  };

  init() {
    axios
      .get(this.props.context.apiEndpoints.userDetails, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { userDetails } = response.data;
        this.setState({ userDetails });

        if (!userDetails.socials) {
          userDetails.socials = [];
          this.handle__addNewSocialLink();
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(MyProfile);
