import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import axios from "axios";
import withContext from "../../context/contextHOC";
import Breadcrumbs from "../topbars/breadcrumbs";
import Lesson from "./lesson";
import { Grid, Row, Cell } from "../foundation/_grid";
import Footer from "../layout/footer";

class Course extends Component {
  state = {
    course: {
      identifier: 0,
      name: "..."
    },
    lesson: {
      identifier: 0
    },
    courseIdentifier: this.props.match.params.courseIdentifier,
    lessonIdentifier: this.props.match.params.lessonIdentifier,
    defaultBreadcrumbs: ["dashboard", "courses"],
    breadcrumbs: [],
    favorite: null,
    forbidden: false
  };

  componentDidMount() {
    this.loadCourse();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      this.setState(
        {
          courseIdentifier: this.props.match.params.courseIdentifier,
          lessonIdentifier: this.props.match.params.lessonIdentifier
        },
        () => {
          this.loadCourse();
        }
      );
    }
  }

  render() {
    return (
      <div
        id="pageCourse"
        className="page-with-topbar-and-breadcrumbs darkmode"
      >
        <MainTopbar />
        <Breadcrumbs
          breadcrumbs={this.state.breadcrumbs}
          favorite={this.state.favorite}
        />
        <Grid>
          <Row>
            <Cell sm={24}>
              <section className="content">{this.showLesson()}</section>
            </Cell>
          </Row>
        </Grid>
        <Footer />
      </div>
    );
  }

  showLesson() {
    const { forbidden } = this.state;

    if (forbidden === true) {
      return (
        <p className="lead text-center">
          Du hast keinen Zugriff auf diesen Kurs.
        </p>
      );
    } else {
      if (
        this.state.course.identifier !== 0 &&
        this.state.lesson.identifier !== 0
      ) {
        return (
          <Lesson
            course={this.state.course}
            lesson={this.state.lesson}
            pathname={this.props.location.pathname}
          />
        );
      }
    }
  }

  loadCourse(callback) {
    const { lessonIdentifier } = this.state;

    let url =
      this.props.context.apiEndpoints.course +
      "/" +
      this.state.courseIdentifier;

    if (lessonIdentifier) {
      url += "/" + lessonIdentifier;
    }

    axios
      .get(url, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { defaultBreadcrumbs } = this.state;

        const breadcrumbs = JSON.parse(JSON.stringify(defaultBreadcrumbs));

        if (response.data.forbidden === true) {
          this.setState({ forbidden: true });
        } else {
          const course = response.data.course;
          const lesson = response.data.lesson;

          //console.log("lesson", lesson.identifier, lesson.last_played);

          let courseTo = undefined;
          if (course.type !== "single") {
            courseTo = "/kurse/" + course.identifier;
          }

          breadcrumbs.push({
            id: "course-" + course.identifier,
            to: courseTo,
            name: course.name
          });

          if (course.type !== "single") {
            breadcrumbs.push({
              id: "lesson-" + lesson.identifier,
              to: undefined,
              name: lesson.name
            });
          }

          const favorite = {
            name: course.name,
            page: courseTo,
            icon: "course",
            text_add: `Den Kurs ${course.name} zu den Favoriten hinzufügen`,
            text_remove: `Den Kurs ${course.name} aus den Favoriten entfernen`
          };

          this.setState({
            course: course,
            lesson: lesson,
            breadcrumbs,
            favorite
          });
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(Course);
