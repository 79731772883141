import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import { isArray } from "util";
import draftToHtml from "draftjs-to-html";

class AdminAnnouncementsOverview extends Component {
  state = {
    announcements: []
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    return (
      <AdminLayout active="announcements">
        <Grid>
          <Row>
            <Cell sm={24}>
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={16}>
                    <h1>Ankündigungen</h1>
                    <p className="lead">
                      Direkt auf dem Dashboard / auf der Home-Seite können den
                      Usern aktuelle Neuigkeiten angezeigt werden. Auf Wunsch
                      mit Einbettung eines Vimeo-Videos.
                    </p>
                  </Cell>
                  <Cell sm={24} md={8} classes="text-center medium-text-right">
                    <a
                      href="/admin/announcements/new"
                      className="primary button"
                    >
                      <Icon icon="plus" left /> Ankündigung hinzufügen
                    </a>
                  </Cell>
                </Row>
              </Grid>

              <div className="small admin-box">
                <div className="admin-box-content">
                  <ul className="admin-list">
                    <li className="admin-list-item admin-list-title hide-for-small-only">
                      <Grid type="full">
                        <Row>
                          <Cell md={4}>Anzeigen ab</Cell>
                          <Cell md={5}>Name</Cell>
                          <Cell md={5}>Inhaltsvorschau</Cell>
                          <Cell md={4} classes="text-center">
                            Typ
                          </Cell>
                          <Cell md={6} classes="text-center">
                            Wo anzeigen?
                          </Cell>
                        </Row>
                      </Grid>
                    </li>
                  </ul>
                  {this.view__showviewAnnouncements()}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showviewAnnouncements() {
    const { announcements } = this.state;

    if (announcements && announcements.length > 0) {
      return (
        <ul className="striped admin-list">
          {announcements.map((announcement, index) => {
            let url = "/admin/announcements/" + announcement.id;
            return (
              <li className="clickable admin-list-item" key={index}>
                <a href={url}>
                  <Grid type="full">
                    <Row>
                      <Cell sm={12} md={4}>
                        {announcement.show_date_date} -{" "}
                        {announcement.show_date_time} Uhr
                      </Cell>
                      <Cell sm={20} md={5}>
                        <div className="hide-for-small-only">
                          {announcement.name || <em>Ohne Namen</em>}
                        </div>
                        <div className="show-for-small-only">
                          <strong>
                            {announcement.name || <em>Ohne Namen</em>}
                          </strong>
                        </div>
                      </Cell>
                      <Cell sm={24} md={5}>
                        {announcement.content_preview.substring(0, 50)}...
                      </Cell>
                      <Cell
                        sm={12}
                        md={4}
                        classes="text-center hide-for-small-only"
                      >
                        {this.view__showType(announcement)}
                      </Cell>
                      <Cell
                        sm={12}
                        md={6}
                        classes="text-center hide-for-small-only"
                      >
                        {this.view__showShowOn(announcement)}
                      </Cell>
                    </Row>
                  </Grid>
                </a>
              </li>
            );
          })}
        </ul>
      );
    }

    return null;
  }

  view__showType(announcement) {
    const { announcementTypes } = this.props.context;

    if (announcementTypes[announcement.type]) {
      return announcementTypes[announcement.type];
    } else {
      return "";
    }
  }

  view__showShowOn(announcement) {
    const { announcementShowOns } = this.props.context;

    if (isArray(announcement.show_on)) {
      let output = "";

      announcement.show_on.map((showOn, index) => {
        let showOnOutput;
        if (announcementShowOns[announcement.type]) {
          showOnOutput = announcementShowOns[showOn];
        } else {
          showOnOutput = announcementShowOns[showOn];
        }

        if (index + 1 !== announcement.show_on.length) {
          showOnOutput += ", ";
        }
        output += showOnOutput;
        return null;
      });

      return output;
    } else {
      return "";
    }
  }

  /**
   * Init
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.adminAnnouncements, {
        headers: this.props.context.headers
      })
      .then(response => {
        let { announcements } = response.data;

        announcements = announcements.map(announcement => {
          announcement.content_preview = draftToHtml(
            announcement.content_preview
          ).replace(/<\/?[^>]+>/gi, "");
          return announcement;
        });

        this.setState({ announcements });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminAnnouncementsOverview);
