import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";

class AdminLiveOverview extends Component {
  state = {
    upcomingLiveEvents: [],
    pastLiveEvents: [],
    courses: {}
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { upcomingLiveEvents, pastLiveEvents } = this.state;

    return (
      <AdminLayout active="live">
        <Grid>
          <Row>
            <Cell sm={24}>
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={18}>
                    <h1>Live Events</h1>
                  </Cell>
                  <Cell sm={24} md={6}>
                    <div className="hide-for-small-only text-right">
                      <a href="/admin/live/new" className="primary button">
                        <Icon icon="plus" left /> Live Event hinzufügen
                      </a>
                    </div>
                    <div className="show-for-small-only text-center">
                      <a
                        href="/admin/live/new"
                        className="small primary button"
                      >
                        <Icon icon="plus" left /> Live Event hinzufügen
                      </a>
                    </div>
                  </Cell>
                </Row>
              </Grid>

              <Grid type="full">
                <Row>
                  <Cell sm={24} md={24}>
                    <h3>Kommende Live Events</h3>
                    {this.view__showLiveEvents(upcomingLiveEvents, "upcoming")}
                  </Cell>
                </Row>
                <Row>
                  <Cell sm={24} md={24}>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <h3>Vergangene Live Events</h3>
                    {this.view__showLiveEvents(pastLiveEvents, "past")}
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showLiveEvents(events, view) {
    if (!events || events.length === 0) {
      return null;
    }

    let md = 8;
    if (view === "past") {
      md = 6;
    }

    return (
      <Grid type="full">
        <Row margin="xy">
          {events.map((event, index) => {
            let imageUrl = "/images/no-image.jpg";
            if (event.image) {
              imageUrl = event.image;
            }

            return (
              <Cell sm={24} md={md} key={index}>
                <a
                  href={`/admin/live/${event.id}`}
                  className="admin-liveevent-event"
                >
                  <div className="small admin-box">
                    <div className="admin-liveevent-picture">
                      <img src={imageUrl} alt="" />
                    </div>
                    <div className="admin-box-content">
                      <div className="admin-liveevent-info">
                        <div className="admin-liveevent-date">
                          <span>
                            {event.live_date} um {event.live_time}
                          </span>
                        </div>
                        <div className="admin-liveevent-name">{event.name}</div>
                      </div>
                    </div>
                  </div>
                </a>
              </Cell>
            );
          })}
        </Row>
      </Grid>
    );
  }

  init() {
    axios
      .get(this.props.context.apiEndpoints.adminLiveEvents, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { upcomingLiveEvents, pastLiveEvents, courses } = response.data;
        this.setState({ upcomingLiveEvents, pastLiveEvents, courses });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminLiveOverview);
