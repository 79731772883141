import React, { Component } from "react";
import ForumLayout from "./forumLayout";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell } from "../foundation/_grid";
import { Link } from "react-router-dom";
import { Icon } from "../foundation/_buttons";

class Forum extends Component {
  state = {
    boards: []
  };

  componentDidMount() {
    this.loadBoards();
  }

  render() {
    return (
      <ForumLayout breadcrumbs={["dashboard", "community"]}>
        <Grid>
          <Row padding="y">
            <Cell sm={24} md={18}>
              <h2>SELFMADE Community</h2>
              <p className="lead">
                Tausche Dich hier mit allen anderen Mitgliedern über die Kurse,
                Dein Business, Deine Personal Brand uvm. aus und lass Dich von
                den anderen inspirieren und motivieren.
              </p>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24}>{this.view__showBoards()}</Cell>
          </Row>
        </Grid>
      </ForumLayout>
    );
  }

  view__showBoards() {
    const { boards } = this.state;

    if (!boards || boards.length === 0) {
      return (
        <p className="loading-spinner">
          <Icon icon="spin fa-circle-o-notch" />
        </p>
      );
    }

    return (
      <div className="forum-boards-list">
        {boards.map(board => {
          return this.showBoardEntry(board);
        })}
      </div>
    );
  }

  showBoardEntry(board) {
    return (
      <Link
        to={this.props.context.generateForumBoardUrl(board.slug)}
        key={board.id}
      >
        <div className="list-item">
          <Grid type="full">
            <Row>
              <Cell sm={24} md={14}>
                <div className="board-name">{board.name}</div>
                <div className="board-description">{board.description}</div>
              </Cell>
              <Cell classes="hide-for-small-only" md={3}>
                <div className="board-counter-name">Themen</div>
                <div className="board-counter-number">{board.threads}</div>
              </Cell>
              <Cell classes="hide-for-small-only" md={3}>
                <div className="board-counter-name">Beiträge</div>
                <div className="board-counter-number">{board.posts}</div>
              </Cell>
              <Cell sm={24} md={4}>
                <div className="board-counter-name">Neuester Beitrag</div>
                <div className="board-counter-number">
                  {board.last_post_date + " - " + board.last_post_time || "---"}
                </div>
              </Cell>
            </Row>
          </Grid>
        </div>
      </Link>
    );
  }

  loadBoards() {
    axios
      .get(this.props.context.apiEndpoints.forumBoards, {
        headers: this.props.context.headers
      })
      .then(response => {
        this.setState({ boards: response.data });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(Forum);
