import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { isUndefined, isNull } from "util";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation.jsx";
import PTPopup from "../../ptPopup/ptPopup";
import axios from "axios";

class MissionStatement extends Component {
  state = {
    missionStatement: undefined,
    originalMissionStatement: undefined,
    backgroundImageUrl: this.props.backgroundImageUrl,
    showPopup: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.backgroundImageUrl !== this.props.backgroundImageUrl) {
      this.setState({ backgroundImageUrl: this.props.backgroundImageUrl });
    }

    if (prevProps.missionStatement !== this.props.missionStatement) {
      this.setState({
        missionStatement: this.props.missionStatement,
        originalMissionStatement: this.props.missionStatement
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="selfmadeGridMissionStatement" className="selfmade-grid-bg-red">
          <div className="selfmade-grid-item selfmade-grid-item-small">
            <div className="selfmade-settings-icons">
              <button onClick={this.show__popupEdit}>
                <Icon icon="cog" />
              </button>
            </div>
            {this.show__missionStatement()}
          </div>
        </div>
        {this.show__popup()}
      </React.Fragment>
    );
  }

  /**
   * Show Mission Statement
   */
  show__missionStatement() {
    const { missionStatement } = this.state;

    let text = "";
    if (
      !isUndefined(missionStatement) &&
      !isNull(missionStatement) &&
      missionStatement
    ) {
      text = missionStatement;
    } else {
      text = "Was ist Dein Mission Statement?";
    }

    return (
      <React.Fragment>
        <div className="selfmade-grid-item-content">
          <div className="selfmade-grid-title">Mein Mission Statement</div>
          <div className="selfmade-grid-text">{text}</div>
        </div>
      </React.Fragment>
    );
  }

  show__popup() {
    const { showPopup, missionStatement } = this.state;

    if (showPopup === true) {
      return (
        <div className="selfmade-popup">
          <PTPopup
            show={this.state.showPopup}
            size="small"
            handleClose={() => {
              this.handle__cancel();
            }}
          >
            <Grid type="full">
              <Row>
                <Cell sm={24}>
                  <h2>Mein Mission Statement</h2>

                  <Grid type="full">
                    <Row>
                      <Cell sm={24}>
                        <label>
                          Mein Mission Statement:
                          <input
                            type="text"
                            value={missionStatement || ""}
                            onChange={event => {
                              this.handle__missionStatementEditChange(event);
                            }}
                          />
                        </label>
                        <div className="ptpopup-buttons">
                          <button
                            className="primary hollow button"
                            onClick={this.handle__cancel}
                          >
                            <Icon icon="times" left /> Abbrechen
                          </button>
                          <button
                            className="primary button"
                            onClick={this.handle__save}
                          >
                            <Icon icon="check" left /> Speichern
                          </button>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </Cell>
              </Row>
            </Grid>
          </PTPopup>
        </div>
      );
    } else {
      return null;
    }
  }

  show__popupEdit = () => {
    this.setState({ showPopup: true });
  };

  hide__popupEdit = () => {
    this.setState({ showPopup: false });
  };

  /**
   * Handle Changes in Mission Statement Input
   */
  handle__missionStatementEditChange(event) {
    this.setState({ missionStatement: event.target.value });
  }

  /**
   * Hide Popup
   */
  handle__cancel = () => {
    const { originalMissionStatement } = this.state;
    this.setState({ missionStatement: originalMissionStatement });
    this.hide__popupEdit();
  };

  handle__save = () => {
    const { missionStatement } = this.state;

    axios
      .patch(
        this.props.context.apiEndpoints.selfmadeMissionStatement,
        {
          missionStatement: missionStatement
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        //console.log(response.data);
        if (response.data.user) {
          this.props.context.handleUpdateUser(response.data.user);
        }
        this.hide__popupEdit();
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  };
}

export default withContext(MissionStatement);
