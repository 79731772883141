import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import MainTopbar from "../topbars/mainTopbar";
import Breadcrumbs from "../topbars/breadcrumbs";
import { Grid, Row, Cell } from "../foundation/_grid";

class Error404 extends Component {
  state = {};
  render() {
    this.props.context.hideLoadingScreen();
    return (
      <div id="pageError404" className="page-with-topbar-and-breadcrumbs">
        <MainTopbar />
        <Breadcrumbs breadcrumbs={["dashboard", "404"]} />
        <section className="content">
          <Grid>
            <Row>
              <Cell sm={24} md={18}>
                <h3>Fehler 404</h3>
                <h2>Seite nicht gefunden</h2>

                <p className="lead">
                  Leider konnten wir die von Dir aufgerufene Seite nicht finden.
                  Bitte nutze die Zurück-Funktion Deines Browsers und versuche
                  es erneut.
                </p>

                <p className="lead">
                  Sollte der Fehler wiederholt auftreten, schreibe bitte eine
                  kurze E-Mail an{" "}
                  <a href="mailto:support@torbenplatzer.com">
                    support@torbenplatzer.com
                  </a>{" "}
                  mit einer möglichst genauen Beschreibung, welchen Link auf
                  welcher Seite Du angeklickt hast.
                </p>

                <p className="lead">
                  Herzlichen Dank!
                  <br />
                  Dein TPA Media Team
                </p>
              </Cell>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

export default withContext(Error404);
