import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import Breadcrumbs from "../topbars/breadcrumbs";
import Footer from "../layout/footer";

class SelfmadeLayout extends Component {
  state = {
    breadcrumbs: this.props.breadcrumbs,
    favorite: this.props.favorite
  };

  render() {
    return (
      <div
        id="pageSelfmade"
        className="page-with-topbar-and-breadcrumbs darkmode"
      >
        <MainTopbar />
        <Breadcrumbs
          breadcrumbs={this.state.breadcrumbs}
          favorite={this.state.favorite}
        />
        <section className="content">{this.props.children}</section>
        <Footer />
      </div>
    );
  }
}

export default SelfmadeLayout;
