import React, { Component } from "react";
import AdminLayout from "../../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../../foundation/foundation";
import axios from "axios";
import withContext from "../../../../context/contextHOC";
import PTTabs from "../../../ptTabs/ptTabs";
import qs from "query-string";
import { isUndefined } from "util";

class AdminLiveQuestions extends Component {
  state = {
    questions: {},
    tabs: {
      4: {
        name: "SELFMADE",
        icon: "",
        selected: true
      }
    },
    selectedTab: 4,
    viewPrint: false
  };

  componentDidMount() {
    this.init__load();

    const queryParams = qs.parse(this.props.location.search);
    if (
      !isUndefined(queryParams.view) &&
      queryParams.view === "print" &&
      !isUndefined(queryParams.course)
    ) {
      this.setState({
        viewPrint: true,
        selectedTab: parseInt(queryParams.course)
      });
    }
  }

  render() {
    const { viewPrint } = this.state;

    if (viewPrint === true) {
      return this.view__showPrintView();
    } else {
      return (
        <AdminLayout active="livequestions">
          {this.view__showQuestions()}
        </AdminLayout>
      );
    }
  }

  view__showQuestions() {
    const { selectedTab, questions } = this.state;

    let courseQuestions = [];

    if (questions && questions[selectedTab]) {
      courseQuestions = questions[selectedTab];
    } else {
      return null;
    }

    const printViewUrl = "?view=print&course=" + selectedTab;

    return (
      <Grid>
        <Row>
          <Cell sm={24}>
            <h1>Fragen an Torben</h1>

            <PTTabs
              tabs={this.state.tabs}
              onUpdate={tabs => {
                let selectedTab = "";
                Object.keys(tabs).map((keyName, index) => {
                  if (tabs[keyName].selected === true) {
                    selectedTab = keyName;
                  }
                  return null;
                });
                this.setState({ tabs, selectedTab });
              }}
            />
            <div>&nbsp;</div>

            <div className="admin-box">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={24} md={18}>
                      <h3>Fragen seit dem letzten Live Call</h3>
                    </Cell>
                    <Cell sm={24} md={6} classes="text-right">
                      <a
                        href={printViewUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="small primary button"
                      >
                        <Icon icon="print" left /> Druckansicht
                      </a>
                    </Cell>
                  </Row>
                </Grid>

                {this.view__showQuestionList(courseQuestions)}
              </div>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showQuestionList(questions) {
    if (questions && questions.length > 0) {
      return (
        <React.Fragment>
          <ul className="admin-list">
            <li className="admin-list-item admin-list-title">
              <Grid type="full">
                <Row>
                  <Cell sm={6} md={1}>
                    &nbsp;
                  </Cell>
                  <Cell sm={18} md={4}>
                    Datum
                  </Cell>
                  <Cell sm={24} md={4}>
                    Name
                  </Cell>
                  <Cell sm={24} md={15}>
                    Frage
                  </Cell>
                </Row>
              </Grid>
            </li>
          </ul>
          <ul className="striped admin-list">
            {questions.map((question, index) => {
              return (
                <li className="admin-list-item" key={index}>
                  <Grid type="full">
                    <Row>
                      <Cell sm={6} md={1}>
                        {index + 1}.
                      </Cell>
                      <Cell sm={18} md={4}>
                        {question.question_date}
                        <br />
                        {question.question_time} Uhr
                      </Cell>
                      <Cell sm={24} md={4}>
                        {question.firstname}
                      </Cell>
                      <Cell sm={24} md={15}>
                        {question.question}
                      </Cell>
                    </Row>
                  </Grid>
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      );
    } else {
      return (
        <p className="text-center">
          <em>
            <small>Keine neuen Fragen seit dem letzten Live Call</small>
          </em>
        </p>
      );
    }
  }

  view__showPrintView() {
    const { selectedTab, questions } = this.state;

    let courseQuestions = [];

    if (questions && questions[selectedTab]) {
      courseQuestions = JSON.parse(JSON.stringify(questions[selectedTab]));
      courseQuestions = courseQuestions.reverse();
    } else {
      return null;
    }

    return (
      <div className="print-view">
        <h3>Live Call Fragen für [{this.state.tabs[selectedTab].name}]</h3>
        <p>&nbsp;</p>
        {courseQuestions.map((question, index) => {
          return (
            <React.Fragment key={index}>
              <div className="admin-list-item" key={index}>
                <Grid type="full">
                  <Row>
                    <Cell sm={6} md={1}>
                      {index + 1}.
                    </Cell>
                    <Cell sm={24} md={5}>
                      {question.firstname}
                    </Cell>
                    <Cell sm={24} md={18}>
                      {question.question}
                    </Cell>
                  </Row>
                </Grid>
              </div>
              <hr />
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  /**
   * Init
   * Load Questions
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.adminLiveQuestions, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { questions } = response.data;
        this.setState({ questions }, () => {
          if (this.state.viewPrint === true) {
            setTimeout(() => {
              window.print();
            }, 500);
          }
        });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminLiveQuestions);
