import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell } from "../../foundation/_grid";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import { Icon } from "../../foundation/_buttons";
import Avatar from "../../user/_avatar";

class AdminUserView extends Component {
  state = {
    userId: this.props.match.params.userId,
    user: {},
    originalUser: {},
    editMode: false,
    hasPermission: false,
    actualUser: this.props.context.user,
  };

  componentDidMount() {
    this.init__load();
  }

  componentDidUpdate(prevProps) {
    if (this.props.context.user !== prevProps.context.user) {
      this.setState(
        {
          actualUser: this.props.context.user,
        },
        () => {
          const { actualUser } = this.state;
          if (actualUser && actualUser.admin) {
            if (actualUser.admin.permissions["permissions"] === true) {
              this.setState({ hasPermission: true });
            } else {
              this.setState({ hasPermission: false });
            }
          }
        }
      );
    }
  }

  render() {
    const { user } = this.state;
    return (
      <AdminLayout active="users">
        <Grid>
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showTitle()}
            </Cell>
            <Cell sm={24} md={12} classes="text-right">
              <button
                onClick={this.handle__delete}
                className="primary hollow button"
              >
                <Icon icon="trash" left /> Mitglied löschen
              </button>
              &nbsp;
              {this.view__showAdminButton()}
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={12} lg={12}>
              <div className="admin-box adminBoxUserData">
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3>Persönliche Daten</h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <div style={this.view__visible("view")}>
                          <button
                            className="admin-icon-button"
                            onClick={() => {
                              this.setState({ editMode: true });
                              Array.from(
                                document.getElementsByClassName(
                                  "adminBoxUserData"
                                )
                              ).forEach((element) => {
                                element.classList.remove("background-saved");
                              });
                            }}
                          >
                            <Icon icon="cog" />
                          </button>
                        </div>
                        <div style={this.view__hideWhenNew()}>
                          <div style={this.view__visible("edit")}>
                            <button
                              onClick={this.handle__editCancel}
                              className="small primary hollow button"
                            >
                              Abbrechen
                            </button>
                            <button
                              onClick={this.handle__editSave}
                              className="small primary button"
                            >
                              Speichern
                            </button>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>

                  <Grid type="full">
                    <Row>
                      <Cell sm={24} md={18} lg={18}>
                        <Grid type="full">
                          <Row margin="x">
                            <Cell sm={24} md={24} lg={24}>
                              <div
                                className="info-content"
                                style={this.view__visible("view")}
                              >
                                {user.email || (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: "&nbsp;",
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                className="info-content edit-mode"
                                style={this.view__visible("edit")}
                              >
                                <input
                                  type="text"
                                  value={user.email || ""}
                                  onChange={(event) => {
                                    this.handle__editChange(event, "email");
                                  }}
                                />
                              </div>

                              <div className="info-caption">E-Mail-Adresse</div>
                              <div style={this.view__emailAlert()}>
                                &nbsp;
                                <div className="alert callout small">
                                  <small>
                                    <strong>Achtung!</strong> Wenn Du die
                                    E-Mail-Adresse änderst, kann sich der User
                                    anschließend nur noch über die geänderte und
                                    nicht mehr über die alte einloggen. Bitte
                                    informiere den User entsprechend!
                                    <br />
                                    Änderungen an der E-Mail-Adresse direkt im
                                    Mitgliederbereich haben keinen Einfluss auf
                                    Eintragungen bei Digistore!
                                  </small>
                                </div>
                              </div>
                            </Cell>
                          </Row>
                          <Row margin="x">
                            <Cell sm={24} md={24} lg={24}>
                              <div
                                className="info-content"
                                style={this.view__visible("view")}
                              >
                                {user.lastname || (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: "&nbsp;",
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                className="info-content edit-mode"
                                style={this.view__visible("edit")}
                              >
                                <input
                                  type="text"
                                  value={user.lastname || ""}
                                  onChange={(event) => {
                                    this.handle__editChange(event, "lastname");
                                  }}
                                />
                              </div>
                              <div className="info-caption">Nachname</div>
                            </Cell>
                          </Row>
                        </Grid>
                      </Cell>
                      <Cell
                        md={6}
                        lg={6}
                        classes="text-right hide-for-small-only"
                      >
                        <Avatar user={user} size="adminUserView" />
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {user.firstname || (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: "&nbsp;",
                              }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={user.firstname || ""}
                            onChange={(event) => {
                              this.handle__editChange(event, "firstname");
                            }}
                          />
                        </div>
                        <div className="info-caption">Vorname</div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {user.street || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={user.street || ""}
                            onChange={(event) => {
                              this.handle__editChange(event, "street");
                            }}
                          />
                        </div>

                        <div className="info-caption">Straße &amp; Hausnr.</div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={6} lg={6}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {user.zip || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={user.zip || ""}
                            onChange={(event) => {
                              this.handle__editChange(event, "zip");
                            }}
                          />
                        </div>

                        <div className="info-caption">PLZ</div>
                      </Cell>
                      <Cell sm={24} md={18} lg={18}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {user.city || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={user.city || ""}
                            onChange={(event) => {
                              this.handle__editChange(event, "city");
                            }}
                          />
                        </div>

                        <div className="info-caption">Ort</div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {user.country || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={user.country || ""}
                            onChange={(event) => {
                              this.handle__editChange(event, "country");
                            }}
                          />
                        </div>

                        <div className="info-caption">Land</div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {user.phone || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={user.phone || ""}
                            onChange={(event) => {
                              this.handle__editChange(event, "phone");
                            }}
                          />
                        </div>

                        <div className="info-caption">Telefonnummer</div>
                      </Cell>
                    </Row>

                    {/*<Row margin="x">
                      <Cell sm={24} md={12} lg={12}>
                        <div className="info-content">
                          {this.view__showGender()}
                        </div>
                        <div className="info-caption">Geschlecht</div>
                      </Cell>
                      <Cell sm={24} md={12} lg={12}>
                        <div className="info-content">
                          {user.birthday || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div className="info-caption">Geburtstag</div>
                      </Cell>
                          </Row>*/}
                  </Grid>
                </div>
              </div>

              <div style={this.view__showWhenNew()}>
                <p className="text-center">
                  Der Kauf eines Kurses kann im nächsten Schritt hinzugefügt
                  werden.
                </p>
                <div style={this.view__visible("edit")} className="text-right">
                  <a href="/admin/users" className="primary hollow button">
                    Abbrechen
                  </a>
                  <button
                    onClick={this.handle__editSave}
                    className="primary button"
                  >
                    Speichern &amp; Weiter <Icon icon="angle-right" />
                  </button>
                </div>
              </div>

              <div className="admin-box" style={this.view__hideWhenNew()}>
                <div className="admin-box-content">
                  <h3>Benutzerkonto</h3>

                  <Grid type="full">
                    <Row margin="x">
                      <Cell sm={24} md={12} lg={12}>
                        <div className="info-content">
                          {user.created_date || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div className="info-caption">
                          Benutzerkonto erstellt
                        </div>
                      </Cell>
                      <Cell sm={24} md={12} lg={12}>
                        <div className="info-content">
                          {user.updated_date || (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: "<small>unbekannt</small>",
                              }}
                            />
                          )}
                        </div>
                        <div className="info-caption">Letzte Bearbeitung</div>
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={12} lg={12}>
                        <div className="info-content">
                          {user.lastVisit || (
                            <small
                              dangerouslySetInnerHTML={{
                                __html: "<em>unbekannt</em>",
                              }}
                            />
                          )}
                        </div>
                        <div className="info-caption">Letzter Login</div>
                      </Cell>
                      <Cell sm={24} md={12} lg={12}>
                        &nbsp;
                      </Cell>
                    </Row>
                    <Row margin="x">
                      <Cell sm={24} md={12} lg={12}>
                        <div className="info-content">
                          {this.view__isPasswordSet()}
                        </div>
                        <div className="info-caption">Passwort festgelegt</div>
                      </Cell>
                      <Cell sm={24} md={12} lg={12}>
                        <div className="info-content text-center">
                          {this.view__showEmailLink()}
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>
            </Cell>
            <Cell sm={24} md={12} lg={12}>
              <div className="admin-box adminBoxUserData">
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3>Mitgliedsnummer</h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <div style={this.view__visible("view")}>
                          <button
                            className="admin-icon-button"
                            onClick={() => {
                              this.setState({ editMode: true });
                              Array.from(
                                document.getElementsByClassName(
                                  "adminBoxUserData"
                                )
                              ).forEach((element) => {
                                element.classList.remove("background-saved");
                              });
                            }}
                          >
                            <Icon icon="cog" />
                          </button>
                        </div>
                        <div style={this.view__hideWhenNew()}>
                          <div style={this.view__visible("edit")}>
                            <button
                              onClick={this.handle__editCancel}
                              className="small primary hollow button"
                            >
                              Abbrechen
                            </button>
                            <button
                              onClick={this.handle__editSave}
                              className="small primary button"
                            >
                              Speichern
                            </button>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>

                  <Grid type="full">
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {user.member_id || (
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  "<em><small>keine Mitgliedsnummer vergeben</small></em>",
                              }}
                            />
                          )}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <input
                            type="text"
                            value={user.member_id || ""}
                            onChange={(event) => {
                              this.handle__editChange(event, "member_id");
                            }}
                          />
                        </div>

                        <div className="info-caption">Mitgliedsnummer</div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>

              <div className="admin-box" style={this.view__hideWhenNew()}>
                <div className="admin-box-content">
                  <Grid type="full">
                    <Row>
                      <Cell sm={18} md={12}>
                        <h3>Käufe</h3>
                      </Cell>
                      <Cell sm={6} md={12} classes="text-right">
                        <a
                          href={"/admin/users/" + user.id + "/purchases/new"}
                          title="Kauf hunzufügen"
                        >
                          <Icon icon="plus" />
                        </a>
                      </Cell>
                    </Row>
                  </Grid>
                  <ul className="striped admin-list">
                    {this.view__showPurchases()}
                  </ul>
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__hideWhenNew() {
    if (this.state.userId === "new") {
      return { display: "none" };
    }
  }

  view__showWhenNew() {
    if (this.state.userId !== "new") {
      return { display: "none" };
    }
  }

  view__visible(type) {
    const { editMode } = this.state;

    if (
      (type === "edit" && editMode === true) ||
      (type === "view" && editMode === false)
    ) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  view__emailAlert() {
    const { user, originalUser } = this.state;

    if (user.email && originalUser.email && user.email !== originalUser.email) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  view__isPasswordSet() {
    const { user } = this.state;

    if (user.isPasswordSet === true) {
      return (
        <span className="password-set">
          <Icon icon="check" left /> ja
        </span>
      );
    } else {
      return (
        <span className="password-notset">
          <Icon icon="times" left /> nein
        </span>
      );
    }
  }

  view__showEmailLink() {
    const { user } = this.state;

    if (user.isPasswordSet === false) {
      return (
        <button
          className="link"
          onClick={() => {
            if (
              window.confirm(
                "Soll dem Benutzer die E-Mail mit dem Link zum Passwort-Festlegen erneut zugesendet werden?"
              )
            ) {
              this.handle__sendWelcomeMail();
            }
          }}
        >
          <Icon icon="envelope" left /> Zugangsdaten zusenden
        </button>
      );
    }
  }

  view__showTitle() {
    const user = this.state.originalUser;
    const { userId } = this.state;

    let title = "";

    if (user) {
      if (user.firstname && user.lastname) {
        title = user.lastname + ", " + user.firstname;
      } else if (user.firstname && !user.lastname) {
        title = user.firstname;
      } else if (!user.firstname && user.lastname) {
        title = user.lastname;
      } else {
        if (userId === "new") {
          title = "Neuer Benutzer";
        } else {
          title = "Wird geladen...";
        }
      }
    } else {
      title = "Wird geladen...";
    }

    return (
      <h1>
        {title}
        {/*<div className="user-title-roles">{this.view__showRoles()}</div>*/}
      </h1>
    );
  }

  view__showAdminButton() {
    const { hasPermission } = this.state;
    const user = this.state.originalUser;
    if (
      !user ||
      !user.admin ||
      user.id === this.props.context.user.id ||
      !hasPermission
    ) {
      return;
    }

    let result;
    if (user.admin.access === true) {
      result = (
        <React.Fragment>
          <a
            className="primary hollow button hide-for-small-only"
            href={`/admin/permissions/${user.id}`}
          >
            <Icon icon="vcard-o" left /> Adminrechte bearbeiten
          </a>
          <a
            className="small primary hollow button show-for-small-only"
            href={`/admin/permissions/${user.id}`}
          >
            <Icon icon="vcard-o" left /> Adminrechte bearbeiten
          </a>
        </React.Fragment>
      );
    } else {
      result = (
        <React.Fragment>
          <button
            className="primary button hide-for-small-only"
            onClick={this.handle__addAdmin}
          >
            <Icon icon="vcard-o" left /> Adminrechte vergeben
          </button>
          <button
            className="small primary button show-for-small-only"
            onClick={this.handle__addAdmin}
          >
            <Icon icon="vcard-o" left /> Adminrechte vergeben
          </button>
        </React.Fragment>
      );
    }

    return result;
  }

  view__showRoles() {
    const { user } = this.state;

    if (user && user.roles) {
      return user.roles.map((role, index) => {
        return (
          <span className="label" key={index}>
            {role}
          </span>
        );
      });
    }

    return null;
  }

  view__showGender() {
    const { user } = this.state;

    if (user && user.gender) {
      if (user.gender === "m") {
        return "männlich";
      } else if (user.gender === "f") {
        return "weiblich";
      }
    }

    return <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />;
  }

  view__showPurchases() {
    const { user } = this.state;

    if (user && user.purchases) {
      return user.purchases.map((purchase, index) => {
        return (
          <li
            className="admin-list-item clickable"
            key={index}
            onClick={() => {
              this.handle__viewPurchase(user.id, purchase.id);
            }}
          >
            <Grid type="full">
              <Row>
                <Cell sm={1} md={1} lg={1}>
                  {index + 1}.
                </Cell>
                <Cell sm={23} md={12} lg={12}>
                  <strong>{purchase.courseName}</strong>
                  <div>{this.view__showPurchaseType(purchase)}</div>
                </Cell>
                <Cell sm={23} md={11} lg={11} classes="text-right">
                  {purchase.paymentDate}
                  {this.view__showIsActive(purchase)}
                </Cell>
              </Row>
            </Grid>
          </li>
        );
      });
    }

    return null;
  }

  view__showIsActive(purchase) {
    if (purchase) {
      if (purchase.active) {
        return (
          <div className="purchase-active">
            <Icon icon="check" left /> aktiv
          </div>
        );
      } else {
        return (
          <div className="purchase-inactive">
            <Icon icon="times" left /> inaktiv
          </div>
        );
      }
    }
    return null;
  }

  view__showPurchaseType(purchase) {
    if (purchase) {
      if (purchase.type) {
        const { purchaseTypes } = this.props.context;
        let purchaseType = <em>unbekannt</em>;
        if (purchaseTypes[purchase.type]) {
          purchaseType = <span>{purchaseTypes[purchase.type]}</span>;
        }
        return (
          <React.Fragment>
            <span className="purchase-type">{purchaseType}</span>
            {this.view__showCode()}
          </React.Fragment>
        );
      }
    }
    return null;
  }

  view__showCode(purchase) {
    if (purchase) {
      if (purchase.code && purchase.code !== "U18") {
        return <span className="purchase-code">(Code: {purchase.code})</span>;
      }
    }
    return null;
  }

  handle__delete = () => {
    const { user } = this.state;

    if (
      window.confirm(
        "Möchtest Du dieses Mitglied wirklich unwiderruflich löschen?\r\n\r\n1)Sämtliche Inhalte (Notizen, Visionboard-Bilder, Umsätze, Routinen, Todos etc.) dieses Mitglieds werden ebenfalls unwiderruflich gelöscht.\r\n2) Diese Aktion kann NICHT rückgängig gemacht werden!\r\n3) Die Daten bei Digistore, beim Newsletter-Tool etc. werden durch diese Aktion NICHT gelöscht!"
      )
    ) {
      let url = this.props.context.apiEndpoints.adminUser + "/" + user.id;

      axios
        .delete(url, {
          headers: this.props.context.headers,
        })
        .then((response) => {
          window.location = "/admin/users";
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  handle__editChange(event, property) {
    const { user } = this.state;

    user[property] = event.target.value;

    this.setState({ user });
  }

  handle__editCancel = () => {
    const originalUser = JSON.parse(JSON.stringify(this.state.originalUser));
    this.setState({
      user: originalUser,
      editMode: false,
    });
  };

  handle__editSave = () => {
    const { userId, user } = this.state;

    // Check if first and last name are given
    if (!user.email || !user.firstname || !user.lastname) {
      window.alert(
        "Bitte gib mindestens eine E-Mail-Adresse, einen Vor- und einen Nachnamen an."
      );
      return;
    }

    let url = this.props.context.apiEndpoints.adminUser + "/" + user.id;
    if (userId === "new") {
      url = this.props.context.apiEndpoints.adminUser + "/new";
    }

    axios
      .post(
        url,
        { user: user },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        if (userId === "new") {
          const { user } = response.data;

          if (user === "emailexists") {
            window.alert(
              "Mit dieser E-Mail-Adresse existiert bereits ein Benutzerkonto."
            );
          } else {
            //console.log(response.data);
            window.location = "/admin/users/" + user.id + "/purchases/new";
          }

          return;
        } else {
          const { user } = response.data;
          const originalUser = JSON.parse(JSON.stringify(user));
          this.setState({ user, originalUser, editMode: false });
          Array.from(
            document.getElementsByClassName("adminBoxUserData")
          ).forEach((element) => {
            element.classList.add("background-saved");
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handle__viewPurchase = (userId, purchaseId) => {
    window.location = "/admin/users/" + userId + "/purchases/" + purchaseId;
  };

  handle__sendWelcomeMail() {
    const { userId } = this.state;

    if (userId) {
      axios
        .get(
          this.props.context.apiEndpoints.adminUser +
            "/" +
            userId +
            "/welcomemail",
          {
            headers: this.props.context.headers,
          }
        )
        .then((response) => {
          if (response.data === "OK") {
            window.alert(
              "Die E-Mail mit dem Link zum Erstellen eines Passworts wurde erneut an den Benutzer gesendet."
            );
          } else {
            window.alert(
              "Leider ist ein Fehler beim Versenden der E-Mail aufgetreten."
            );
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }

  handle__addAdmin = () => {
    const { user } = this.state;

    axios
      .post(
        this.props.context.apiEndpoints.adminAddAdmin + "/" + user.id,
        {},
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { statusType } = response.data;
        if (statusType === "SUCCESS") {
          window.location.href = "/admin/permissions/" + user.id;
        } else if (statusType === "ERROR") {
          window.location.href = "/admin/permissions/" + user.id;
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  /**
   * Init
   * Load User Info
   */
  init__load() {
    const { userId } = this.state;

    if (userId) {
      axios
        .get(this.props.context.apiEndpoints.adminUser + "/" + userId, {
          headers: this.props.context.headers,
        })
        .then((response) => {
          const { user } = response.data;
          const originalUser = JSON.parse(JSON.stringify(user));
          this.setState({ user, originalUser });
          if (userId === "new") {
            this.setState({ editMode: true });
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }
}

export default withContext(AdminUserView);
