import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import Breadcrumbs from "../topbars/breadcrumbs";
import Footer from "../layout/footer";
import withContext from "../../context/contextHOC";
import { isUndefined } from "util";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import PtCountdown from "../ptCountdown/ptCountdown";
import axios from "axios";
import { DateTime } from "luxon";

class Live extends Component {
  state = {
    now: new Date(),
    date: null,
    event: this.props.event,
    type: this.props.type,
    breadcrumbs: ["dashboard", "workspace", "live"],
    liveViewMode: "vertical"
  };

  componentDidMount() {
    if (!this.props.event) {
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.slug
      ) {
        this.init__loadLiveEvent(this.props.match.params.slug);
      } else {
        this.init__loadLiveEvent("selfmade");
      }
    } else {
      let event = this.props.event;
      event = this.prepareEvent(event);
      this.setState({ event });
    }

    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event) {
      const livestream = this.props.event;
      if (livestream && livestream.date != null) {
        livestream.date = new Date(livestream.date);
      }
      this.setState({ livestream });
    }

    if (prevProps.context.user !== this.props.context.user) {
      this.init();
    }

    if (!this.state.livestream && this.state.type !== "course") {
      if (prevProps.context.user !== this.props.context.user) {
        if (!isUndefined(this.props.context.user.nextLiveEvent)) {
          const livestream = this.props.context.user.nextLiveEvent;
          if (livestream && livestream.date != null) {
            livestream.date = new Date(livestream.date);
          }
          this.setState({ livestream });
        }
      }
    }
  }

  render() {
    return <React.Fragment>{this.view__showContent()}</React.Fragment>;
  }

  view__showContent() {
    const { type } = this.state;

    if (type === "grid") {
      return this.view__showGrid();
    } else if (type === "course") {
      return this.view__showInCourse();
    } else {
      return this.view__showLiveRoom();
    }
  }

  view__showGrid() {
    const { event } = this.state;
    const _c = this.props.context;

    if (!event) {
      return (
        <div
          id="selfmadeGridNextLive"
          className="selfmade-grid-bg linkbox selfmade-grid-item-small selfmade-grid-bg-gray"
        >
          <div className="selfmade-grid-item-content">
            <div className="selfmade-grid-caption grid-caption">
              {this.view__showBoxContent()}
            </div>
          </div>
        </div>
      );
    }

    let selfmadeStatus = {
      active: false,
      url: null
    };

    if (this.props.context.user && this.props.context.user.selfmadeStatus) {
      selfmadeStatus = this.props.context.user.selfmadeStatus;
    }

    let liveUrl = "/live";

    if (event.liveStatus === "past") {
      // Replay
      liveUrl = _c.generateLessonUrl(
        event.course_identifier,
        event.lesson_identifier
      );
      return (
        <a
          href={liveUrl}
          id="selfmadeGridNextLive"
          className="selfmade-grid-bg linkbox selfmade-grid-item-small selfmade-grid-bg-red"
        >
          <div className="selfmade-grid-item-content">
            <div className="selfmade-grid-caption grid-caption">
              {this.view__showBoxContent()}
            </div>
          </div>
        </a>
      );
    } else {
      if (selfmadeStatus.active === true) {
        liveUrl = "/live";

        return (
          <a
            href={liveUrl}
            id="selfmadeGridNextLive"
            className="selfmade-grid-bg linkbox selfmade-grid-item-small selfmade-grid-bg-red"
          >
            <div className="selfmade-grid-item-content">
              <div className="selfmade-grid-caption grid-caption">
                {this.view__showBoxContent()}
              </div>
            </div>
          </a>
        );
      } else {
        liveUrl = selfmadeStatus.url;

        return (
          <a
            href={liveUrl}
            target="_blank"
            rel="noopener noreferrer"
            id="selfmadeGridNextLive"
            className="selfmade-grid-bg linkbox selfmade-grid-item-small selfmade-grid-bg-red"
          >
            <div className="selfmade-grid-item-content">
              <div className="selfmade-grid-caption grid-caption">
                {this.view__showBoxContent()}
              </div>
            </div>
          </a>
        );
      }
    }
  }

  view__showInCourse() {
    const { event } = this.state;

    if (event && event.lesson_identifier === null) {
      let liveUrl = "/";
      liveUrl = "/live/" + event.course.slug;

      return <a href={liveUrl}>{this.view__showBoxContent()}</a>;
    } else {
      return null;
    }
  }

  view__showLiveRoom() {
    return (
      <div id="pageLive" className="page-with-topbar-and-breadcrumbs darkmode">
        <MainTopbar />
        <Breadcrumbs
          breadcrumbs={this.state.breadcrumbs}
          favorite={{ name: "Live Room", page: "/live", icon: "live" }}
        />
        <section id="pageLiveWrapper" className="content">
          <Grid>
            <Row>
              <Cell sm={24}>
                <div>&nbsp;</div>
              </Cell>{" "}
            </Row>
            <Row>
              <Cell sm={24}>{this.view__showLiveStream()}</Cell>
            </Row>
          </Grid>
        </section>
        <Footer />
      </div>
    );
  }

  view__showBoxContent() {
    const { event } = this.state;

    if (!event) {
      return this.view__showNoNextLivestream();
    } else if (event.liveStatus === "onair") {
      return this.view__showOnAir();
    } else if (event.liveStatus === "future") {
      return this.view__showCountdown();
    } else if (event.liveStatus === "past") {
      return (
        <div>
          Zur SELFMADE
          <br />
          Live Call
          <br />
          Aufzeichnung
        </div>
      );
    }
  }

  view__showOnAir() {
    const { event } = this.state;

    if (!event) {
      return null;
    }

    return (
      <React.Fragment>
        <div
          className="text"
          style={{ paddingBottom: "20px", fontSize: "1.1rem" }}
        />
        Torben
        <br />
        ist live!
        <div
          className="text"
          style={{ paddingTop: "20px", fontSize: "1.1rem" }}
        >
          Zum Live Room <Icon icon="angle-double-right" />
        </div>
      </React.Fragment>
    );
  }

  view__showLiveStream() {
    const { event } = this.state;

    if (!event) {
      return;
    }

    let selfmadeStatus = this.props.context.getUserSelfmadeStatus();

    if (selfmadeStatus.active === false) {
      let image = "";
      if (event.image) {
        image = (
          <div className="live-image">
            <img src={event.image} alt="" />
          </div>
        );
      }

      return (
        <div className="live-message">
          <div className="text">
            {image}
            <div className="live-title">{event.name}</div>
            <div>&nbsp;</div>
            <p>Der Livestream steht nur SELFMADE Mitgliedern zur Verfügung.</p>
            <p>
              Werde jetzt SELFMADE Mitglied, um diesen Livestream anzuschauen
              <br />
              und von vielen Vorteilen zu profitieren!
            </p>
            <p>
              <a
                href={selfmadeStatus.url}
                className="primary button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Jetzt SELFMADE Mitglied werden
              </a>
            </p>
          </div>
        </div>
      );
    }

    if (!event) {
      return this.view__showNoNextLivestream();
    } else if (event.liveStatus === "future") {
      return this.view__showCountdown();
    } else if (event.liveStatus === "onair") {
      if (event.type === "youtube") {
        return this.show__youtube_livestream();
      } else {
        return this.show__vimeo_livestream();
      }
    }
  }

  view__showNoNextLivestream() {
    return (
      <div className="live-message">
        <div className="text">Aktuell ist kein Livestream geplant.</div>
      </div>
    );
  }

  view__showCountdown() {
    const { event, type } = this.state;

    if (!event) {
      return null;
    }

    let countdownType = "live";
    let title = <div className="text">Der nächste Livestream beginnt in</div>;
    let title2 = "";

    let image = "";
    if (event.image) {
      image = (
        <div className="live-image">
          <img src={event.image} alt="" />
        </div>
      );
    }

    if (type === "grid") {
      countdownType = "live-grid";
      title = (
        <div className="text">
          <u>Nächster Livestream</u>
        </div>
      );
      title2 = (
        <div className="text" style={{ paddingBottom: "5px" }}>
          {event.live_date} - {event.live_time} Uhr
        </div>
      );

      return (
        <div className="live-message">
          <div
            className="text"
            style={{ paddingBottom: "20px", fontSize: "1.1rem" }}
          />
          {title}
          {title2}
          <div className="countdown">
            <PtCountdown
              date={event.date}
              type={countdownType}
              onZero={this.check__countdownEnd}
            />
          </div>
          <div
            className="text"
            style={{ paddingTop: "20px", fontSize: "1.1rem" }}
          >
            Zum Live Room <Icon icon="angle-double-right" />
          </div>
        </div>
      );
    } else if (type === "course") {
      countdownType = "live-grid";
      title = <h2>Nächster Livestream</h2>;
      title2 = (
        <div
          className="text"
          style={{ paddingBottom: "5px", fontSize: "0.9rem" }}
        >
          {event.live_date} - {event.live_time} Uhr
        </div>
      );

      return (
        <div className="live-message">
          {title}
          {title2}
          <div className="countdown">
            <PtCountdown
              date={event.date}
              type={countdownType}
              onZero={this.check__countdownEnd}
            />
          </div>
          <div
            className="text"
            style={{ paddingTop: "10px", fontSize: "0.9rem" }}
          >
            Zum Live Room <Icon icon="angle-double-right" />
          </div>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {image}
          <div className="live-title">{event.name}</div>
          <div className="live-message">
            <div className="text">{title}</div>
            <div className="countdown">
              <PtCountdown
                date={event.date}
                type={countdownType}
                onZero={this.check__countdownEnd}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  show__youtube_livestream() {
    const { livestream } = this.state;

    if (livestream) {
      const livestreamUrl = livestream.vimeo_live_id;

      let chat;
      if (livestream.vimeo_chat_id) {
        chat = (
          <iframe
            title="Youtube Live Chat"
            src={livestream.vimeo_chat_id}
            frameBorder="0"
            style={{ minWidth: "600px", width: "100%;", minHeight: "600px" }}
          />
        );
      }

      return (
        <React.Fragment>
          <div>
            <strong>
              Live Call am {livestream.live_date} um {livestream.live_time} Uhr
            </strong>
          </div>
          <h2>{livestream.name}</h2>
          <div id="livestream">
            <iframe
              title="Youtube Live"
              src={livestreamUrl}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div className="livestream-reload-message">
            <strong>
              <Icon icon="exclamation-triangle" left /> Hinweis:
            </strong>{" "}
            Falls der Livestream nicht starten sollte, bitte kurz die Seite neu
            laden.
          </div>
          {chat}
        </React.Fragment>
      );
    }
  }

  show__vimeo_livestream() {
    const { event, liveViewMode } = this.state;

    if (event) {
      const livestreamUrl =
        "https://player.vimeo.com/video/" + event.vimeo_live_id;

      let classesHorizontal = "live-viewmode";
      let classesVertical = "live-viewmode";
      let output;
      if (liveViewMode === "horizontal") {
        classesHorizontal += " selected";
        output = this.view__showHorizontal(livestreamUrl);
      } else if (liveViewMode === "vertical") {
        classesVertical += " selected";
        output = this.view__showVertical(livestreamUrl);
      }

      return (
        <React.Fragment>
          <div className="text-right" style={{ marginBottom: "20px" }}>
            <button
              onClick={() => {
                this.setState({ liveViewMode: "vertical" }, () => {
                  this.handle__updateViewMode();
                });
              }}
              className={classesVertical}
              title="Livestream und Chat nebeneinander"
            >
              <img src="/images/live-vertical.png" alt="vert" />
            </button>
            <button
              onClick={() => {
                this.setState({ liveViewMode: "horizontal" }, () => {
                  this.handle__updateViewMode();
                });
              }}
              className={classesHorizontal}
              title="Livestream und Chat übereinander"
            >
              <img src="/images/live-horizontal.png" alt="hor" />
            </button>
          </div>
          {output}
        </React.Fragment>
      );
    }
  }

  view__showVertical(livestreamUrl) {
    return (
      <Grid type="full">
        <Row>
          <Cell sm={24} md={24}>
            <div id="livestream">
              <iframe title="Vimeo Live" src={livestreamUrl} />
            </div>
          </Cell>
        </Row>
        <Row>
          <Cell sm={24} md={24}>
            <div className="livestream-reload-message">
              <strong>
                <Icon icon="exclamation-triangle" left /> Hinweis:
              </strong>{" "}
              Falls der Livestream nicht starten sollte, bitte kurz die Seite
              neu laden.
            </div>
          </Cell>
        </Row>
        <Row>
          <Cell sm={24} md={24}>
            {this.show__vimeo_chat()}
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showHorizontal(livestreamUrl) {
    return (
      <Grid type="full">
        <Row>
          <Cell sm={24} md={12}>
            <div id="livestream">
              <iframe title="Vimeo Live" src={livestreamUrl} />
            </div>
            <div className="livestream-reload-message">
              <strong>
                <Icon icon="exclamation-triangle" left /> Hinweis:
              </strong>{" "}
              Falls der Livestream nicht starten sollte, bitte kurz die Seite
              neu laden.
            </div>
          </Cell>
          <Cell sm={24} md={12}>
            {this.show__vimeo_chat()}
          </Cell>
        </Row>
      </Grid>
    );
  }

  show__vimeo_chat() {
    const { event, liveViewMode } = this.state;

    if (event && event.show_chat === true) {
      const chatUrl = "https://vimeo.com/live-chat/" + event.vimeo_live_id;

      let height = 600;
      if (liveViewMode === "horizontal") {
        height = 330;
      }

      return (
        <div id="livestreamChat">
          <iframe
            title="Chat"
            src={chatUrl}
            width="100%"
            height={height}
            frameBorder="0"
          />
        </div>
      );
    }
  }

  check__countdownEnd = () => {
    const { event } = this.state;
    const now = DateTime.local();

    if (now >= event.date) {
      event.liveStatus = "onair";
      this.setState({ event });
    }
  };

  init__loadLiveEvent(slug) {
    axios
      .get(this.props.context.apiEndpoints.courseLive + "/" + slug, {
        headers: this.props.context.headers
      })
      .then(response => {
        const _c = this.props.context;
        if (_c.isDebug()) {
          console.log("DEBUG LIVE", response.data);
        }

        let { event } = response.data;

        if (event) {
          event = this.prepareEvent(event);
          /*const liveEventDate = liveEvent.date.replace(" ", "T");

          liveEvent.date = new Date(liveEventDate);
          liveEvent.date.setTime(
            liveEvent.date.getTime() +
              liveEvent.date.getTimezoneOffset() * 60 * 1000
          );*/

          this.setState({ event });
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  }

  prepareEvent(event) {
    if (event.date) {
      event.originalDate = event.date;
      event.date = DateTime.fromISO(event.date.replace(" ", "T"));
    }

    return event;
  }

  handle__updateViewMode() {
    const { liveViewMode } = this.state;

    axios
      .post(
        this.props.context.apiEndpoints.liveUpdateViewMode + "/" + liveViewMode,
        {},
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        //
      })
      .catch(error => {
        console.log("Error", error);
      });
  }

  init() {
    const _c = this.props.context;

    if (
      _c &&
      _c.user &&
      _c.user.user_settings &&
      _c.user.user_settings.live_view_mode
    ) {
      this.setState({
        liveViewMode: _c.user.user_settings.live_view_mode.value
      });
    }
  }
}

export default withContext(Live);
