import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { isUndefined } from "util";
import { Grid, Row, Cell } from "../foundation/_grid";
import { Icon, Button } from "../foundation/_buttons";
import Avatar from "../user/_avatar";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import UserInfo from "./_userinfo";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: this.props.answer,
      answerArea: "reply_" + props.post.id,
      selectedTextareaLastPosition: 0,
      lastKey: "",
      thread: this.props.thread,
      post: this.props.post,
      showPost: true,
      editPost: null
    };

    this.answerRef = React.createRef();
  }

  componentDidMount() {
    var getCaretCoordinates = require("textarea-caret");

    const element = "#" + this.state.answerArea;

    document.querySelector(element).addEventListener("input", function() {
      var caret = getCaretCoordinates(this, this.selectionEnd);
      /*console.log(
        "post " + element,
        "(top, left, height) = (%s, %s, %s)",
        caret.top,
        caret.left,
        caret.height
      );*/

      // Put Autosuggest Popup to Caret Position in textarea
      const textarea = document.querySelector(element);
      const autosuggestPopup = document.getElementById("autosuggestPopup");

      autosuggestPopup.style.top =
        parseInt(textarea.offsetTop + caret.top - 5) + "px";
      autosuggestPopup.style.left =
        parseInt(textarea.offsetLeft + caret.left) + "px";
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.answer !== prevProps.answer) {
      this.setState({ answer: this.props.answer });
    }

    if (this.props.thread !== prevProps.thread) {
      this.setState({ thread: this.props.thread });
    }

    if (this.props.post !== prevProps.post) {
      this.setState({ post: this.props.post });
    }
  }

  render() {
    const { index } = this.props;
    const { showPost, post } = this.state;

    if (showPost === true) {
      return this.showPost(post, index);
    } else {
      return null;
    }
  }

  showPost(post, index) {
    const { thread, editPost } = this.state;
    const { user } = this.props.context;
    let forumTitle = "";
    if (post.author !== null && post.author.forum_title !== null) {
      forumTitle = (
        <div className="post-author-forumtitle">
          <span className="primary label">{post.author.forum_title}</span>
        </div>
      );
    }

    let postCount = 0;
    if (post && post.author && post.author.forum_posts) {
      postCount = post.author.forum_posts;
    }
    postCount = (
      <div className="post-author-postcount">Beiträge: {postCount}</div>
    );

    let postAuthor = {};
    if (post.author === null) {
      postAuthor.name = "Gelöschter Benutzer";
      postAuthor.username = "deleted";
    } else {
      postAuthor = post.author;
      postAuthor.name = postAuthor.firstname + " " + postAuthor.lastname;
    }

    let replyButton = "";
    if (thread && thread.closed && thread.closed === true) {
      replyButton = "";
    } else {
      replyButton = (
        <button
          onClick={() => {
            this.answer();
          }}
        >
          <Icon icon="angle-right" left /> Antworten
        </button>
      );
    }

    let deleteButton = "";
    if (
      user &&
      user.admin &&
      user.admin.permissions &&
      user.admin.permissions.forum &&
      user.admin.permissions.forum === true
    ) {
      deleteButton = (
        <button
          className="tiny primary button"
          onClick={this.handle__deletePost}
        >
          <Icon icon="times" left /> Löschen
        </button>
      );
    }

    return (
      <React.Fragment key={post.id}>
        <div className="post" id={"post_" + this.state.post.identifier}>
          <Grid type="full">
            <Row>
              <Cell sm={24} md={4}>
                <ReactTooltip place="top" type="dark" effect="solid" />
                <Link
                  to={`/community/@${post.author.username}`}
                  className="post-author"
                  data-tip={`Profil von ${postAuthor.name} aufrufen`}
                >
                  <div className="post-author-avatar">
                    <Avatar user={post.author} size="forumPost" />
                    <div className="post-author-name">{postAuthor.name}</div>
                    <div className="post-author-username">
                      @{postAuthor.username}
                    </div>
                    {forumTitle}
                    {postCount}
                  </div>
                </Link>
              </Cell>
              <Cell sm={24} md={20}>
                <div className="post-content">
                  <div className="post-info">
                    <Grid>
                      <Row>
                        <Cell sm={12}>
                          {/*#{index + 1} &nbsp;*/}
                          <Icon icon="comment-o" />
                          &nbsp;
                          {post.post_date} - {post.post_time}
                        </Cell>
                        <Cell sm={12} classes="text-right">
                          {this.view__showIsEdited(post)}
                        </Cell>
                      </Row>
                    </Grid>
                  </div>
                  <div className="post-text">
                    <div
                      style={{
                        display: editPost === post.id ? "none" : "block"
                      }}
                    >
                      <div className="float-right hide-for-small-only">
                        {deleteButton}
                      </div>
                      <div className="text-right show-for-small-only">
                        {deleteButton}
                      </div>
                      {ReactHtmlParser(post.text, {
                        transform: this.replaceLinkedUser
                      })}
                    </div>
                    <div
                      className="post-edit"
                      style={{
                        display: editPost === post.id ? "block" : "none"
                      }}
                    >
                      <textarea
                        rows="8"
                        value={post.plain_text}
                        onChange={e => {
                          post.plain_text = e.target.value;
                          this.setState({ thread });
                        }}
                      />
                      <div className="text-right">
                        <button
                          className="small primary hollow button"
                          onClick={() => {
                            post.plain_text = post.original_text;
                            this.setState({ editPost: null });
                          }}
                        >
                          Abbrechen
                        </button>
                        <button
                          className="small primary button"
                          onClick={() => {
                            this.handle__saveEditPost(post);
                          }}
                        >
                          Speichern
                        </button>
                      </div>
                    </div>

                    <div className="post-reply-buttons">
                      {this.view__showEditButton(post)}
                      {this.showPostLikes(post)}
                      {this.showPostLikeButton(post)} &nbsp;&nbsp;&nbsp;
                      {replyButton}
                    </div>
                    {this.showReplies(post)}
                    <div
                      className="post-reply-textarea"
                      id={"textarea_" + this.state.answerArea}
                    >
                      <Grid type="full">
                        <Row>
                          <Cell sm={24} md={23}>
                            <textarea
                              id={this.state.answerArea}
                              placeholder="Schreibe Deine Antwort"
                              rows="1"
                              value={this.state.answer}
                              onChange={this.handleAnswerChange}
                              ref={this.answerRef}
                              onKeyUp={this.onKeyUp}
                            />
                          </Cell>
                          <Cell sm={24} md={1}>
                            <Button
                              click={this.sendAnswer}
                              type="primary"
                              title="Nachricht absenden"
                            >
                              <Icon icon="check" />
                            </Button>
                          </Cell>
                        </Row>
                      </Grid>
                    </div>
                  </div>
                </div>
              </Cell>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  replaceLinkedUser = (node, index) => {
    if (node.type === "tag" && node.name === "userinfo") {
      const childNode = node.children[0];
      if (childNode.type === "text") {
        return (
          <UserInfo key={index} people={this.props.people}>
            {childNode.data}
          </UserInfo>
        );
      }
    }
  };

  view__showIsEdited(post) {
    let message;

    if (post.edit) {
      let userText = "unbekannt";
      if (post.edit.user && post.edit.user.id) {
        userText = (
          <Link to={`/community/@${post.edit.user.username}`}>
            {post.edit.user.firstname} {post.edit.user.lastname}
          </Link>
        );
      }

      if (post.edit.type === "admin") {
        message = (
          <span className="post-is-edited">
            <em>
              &nbsp;-&nbsp;zuletzt bearbeitet am {post.edit.date} vom Moderator{" "}
              {userText}
            </em>
          </span>
        );
      } else if (post.edit.type === "own") {
        message = (
          <span className="post-is-edited">
            <em>
              &nbsp;-&nbsp;zuletzt bearbeitet am {post.edit.date} von {userText}
            </em>
          </span>
        );
      }
    }

    return message;
  }

  showPostLikes(post) {
    if (post.likes.length === 1) {
      return (
        <span className="post-likes">
          <Icon icon="thumbs-o-up" left /> Gefällt {post.likes.length} Person
        </span>
      );
    } else if (post.likes.length > 1) {
      return (
        <span className="post-likes">
          <Icon icon="thumbs-o-up" left /> Gefällt {post.likes.length} Personen
        </span>
      );
    } else {
      return;
    }
  }

  showPostLikeButton(post) {
    if (this.doesUserLikePost(post)) {
      return (
        <button
          onClick={() => {
            this.likePost(post);
          }}
          className="like-button-unlike"
        >
          <Icon icon="angle-right" left /> Gefällt mir nicht mehr
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            this.likePost(post);
          }}
          className="like-button-like"
        >
          <Icon icon="angle-right" left /> Gefällt mir
        </button>
      );
    }
  }

  view__showEditButton(post) {
    const { user } = this.props.context;

    if (user && user.id && post && post.id) {
      if (
        post.owner === user.id ||
        (post.owner !== user.id &&
          user.admin &&
          user.admin.access === true &&
          user.admin.permissions &&
          user.admin.permissions.forum === true)
      ) {
        return (
          <React.Fragment>
            <button
              className="post-edit-button"
              onClick={() => {
                this.setState({ editPost: post.id });
              }}
            >
              <Icon icon="angle-right" left /> Bearbeiten
            </button>
            &nbsp;&nbsp;&nbsp;
          </React.Fragment>
        );
      }
    }
  }

  showReplies(post) {
    const { editPost } = this.state;

    if (!isUndefined(post.replies) && post.replies.length > 0) {
      return (
        <div className="post-replies">
          {post.replies.map((reply, index) => {
            let forumTitle = "";
            if (reply.author !== null && reply.author.forum_title !== null) {
              forumTitle = (
                <span className="post-author-forumtitle">
                  &nbsp;&nbsp;
                  <span className="primary label">
                    {reply.author.forum_title}
                  </span>
                </span>
              );
            }

            let replyAuthor = {};
            if (reply.author === null) {
              replyAuthor.name = "Gelöschter Benutzer";
              replyAuthor.username = "deleted";
            } else {
              replyAuthor = reply.author;
              replyAuthor.name =
                replyAuthor.firstname + " " + replyAuthor.lastname;
            }

            return (
              <div
                className="post-reply"
                key={index}
                id={"post_" + reply.identifier}
              >
                <Grid>
                  <Row>
                    <Cell md={2} classes="hide-for-small-only">
                      <Link
                        to={`/community/@${replyAuthor.username}`}
                        className="reply-author-avatar"
                        data-tip={`Profil von ${replyAuthor.name} aufrufen`}
                      >
                        <Avatar user={replyAuthor} size="forumReply" />
                      </Link>
                    </Cell>
                    <Cell sm={24} md={22}>
                      <Grid type="full">
                        <Row>
                          <Cell sm={4} classes="show-for-small-only">
                            <Link
                              to={`/community/@${replyAuthor.username}`}
                              className="reply-author-avatar"
                              data-tip={`Profil von ${replyAuthor.name} aufrufen`}
                            >
                              <Avatar user={replyAuthor} size="forumReply" />
                            </Link>
                          </Cell>
                          <Cell sm={20} md={24}>
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="solid"
                            />
                            <Link
                              to={`/community/@${replyAuthor.username}`}
                              className="reply-author-name"
                              data-tip={`Profil von ${replyAuthor.name} aufrufen`}
                            >
                              {replyAuthor.name}{" "}
                              <span className="reply-author-username">
                                @{replyAuthor.username}
                              </span>
                              {forumTitle}
                            </Link>
                            <div className="reply-info">
                              <Icon icon="comments-o" />
                              &nbsp;{reply.post_date} - {reply.post_time}
                              {this.view__showIsEdited(reply)}
                            </div>
                          </Cell>
                        </Row>
                      </Grid>
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={22} smo={1}>
                      {/*<div dangerouslySetInnerHTML={{ __html: reply.text }} />*/}
                      <div
                        style={{
                          display: editPost === reply.id ? "none" : "block"
                        }}
                      >
                        {ReactHtmlParser(reply.text, {
                          transform: this.replaceLinkedUser
                        })}
                      </div>
                      <div
                        className="post-edit"
                        style={{
                          display: editPost === reply.id ? "block" : "none"
                        }}
                      >
                        <textarea
                          rows="4"
                          value={reply.plain_text}
                          onChange={e => {
                            reply.plain_text = e.target.value;
                            this.setState({ post });
                          }}
                        />
                        <div className="text-right">
                          <button
                            className="small primary hollow button"
                            onClick={() => {
                              reply.plain_text = reply.original_text;
                              this.setState({ editPost: null });
                            }}
                          >
                            Abbrechen
                          </button>
                          <button
                            className="small primary button"
                            onClick={() => {
                              this.handle__saveEditPost(reply);
                            }}
                          >
                            Speichern
                          </button>
                        </div>
                      </div>

                      <div className="post-reply-buttons">
                        {this.view__showEditButton(reply)}
                        {this.showPostLikes(reply)}
                        {this.showPostLikeButton(reply)} &nbsp;&nbsp;&nbsp;
                        <button
                          onClick={() => {
                            this.answerToReply(replyAuthor.username);
                          }}
                        >
                          <Icon icon="angle-right" left /> Antworten
                        </button>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            );
          })}
        </div>
      );
    }
  }

  doesUserLikePost(post) {
    let likeFound = false;
    if (post.likes.length > 0) {
      post.likes.map(like => {
        if (like.user === this.props.context.user.id) {
          likeFound = true;
        }
        return null;
      });
    }

    return likeFound;
  }

  likePost(post) {
    axios
      .patch(
        this.props.context.apiEndpoints.forumLikePost,
        {
          post_id: post.id,
          type: "like"
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const updatedPost = response.data;
        this.props.handleUpdatePosts(updatedPost);
      })
      .catch(error => {
        console.log("Error", error);
      });
  }

  answer = () => {
    const { post, thread } = this.state;

    if (thread && thread.closed && thread.closed === true) {
      return null;
    }

    if (post.first === true) {
      document.getElementById("main_answer").focus();
    } else {
      this.showReplyTextarea();
    }
  };

  answerToReply = username => {
    let { answer } = this.state;

    if (answer) {
      if (
        window.confirm(
          "Du hast bereits etwas in das Antwortfeld eingegeben. Soll dieser Text gelöscht werden?"
        )
      ) {
        answer = "@" + username + " ";
        this.setState({ answer });
        this.showReplyTextarea();
      } else {
        return false;
      }
    } else {
      answer = "@" + username + " ";
      this.setState({ answer });
      this.showReplyTextarea();
    }
  };

  showReplyTextarea = () => {
    document.getElementById("textarea_" + this.state.answerArea).style.display =
      "block";
    document.getElementById(this.state.answerArea).focus();
  };

  hideReplyTextarea = () => {
    document.getElementById("textarea_" + this.state.answerArea).style.display =
      "none";
  };

  onKeyUp = event => {
    const { key } = event;
    if (key === "Escape") {
      this.hideReplyTextarea();
    }
  };

  handleAnswerChange = event => {
    let { answer, answerArea } = this.state;

    const lastPosition = event.target.selectionStart;
    const lastInputChar = event.target.value[event.target.selectionStart - 1];

    /*if (lastInputChar === "@") {
      const autosuggestValue = "@";
      this.props.handleUpdateAutosuggestValue(autosuggestValue);
    } else {
      answer = event.target.value;
    }*/

    answer = event.target.value;

    this.setState({
      answer,
      selectedTextareaLastPosition: lastPosition
    });

    // Send Answer To Parent Thread Component
    this.props.handleUpdateAnswer(
      answer,
      answerArea,
      lastPosition,
      lastInputChar
    );

    if (lastInputChar === "@") {
      //console.log("@ detected");
    }
  };

  sendAnswer = () => {
    this.props.handleSendAnswer(this.state.answerArea);
  };

  handle__deletePost = () => {
    const { post } = this.state;

    let text =
      "Soll dieser Beitrag unwiderruflich gelöscht werden?\r\n\r\nDiese Aktion kann nicht rückgängig gemacht werden.";
    if (post.replies && post.replies.length > 0) {
      text =
        "Soll dieser Beitrag und alle direkten Antworten auf diesen Beitrag unwiderruflich gelöscht werden?\r\n\r\nDiese Aktion kann nicht rückgängig gemacht werden.";
    }

    if (window.confirm(text)) {
      axios
        .delete(
          this.props.context.apiEndpoints.adminForumPostDelete + "/" + post.id,
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          window.location.reload();
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  };

  handle__saveEditPost(post) {
    const { user } = this.props.context;

    if (user) {
      let type;
      if (user.id === post.owner) {
        type = "own";
      } else if (
        user.id !== post.author &&
        user.admin &&
        user.admin.access === true &&
        user.admin.permissions &&
        user.admin.permissions.forum === true
      ) {
        type = "admin";
      } else {
        return;
      }

      axios
        .post(
          this.props.context.apiEndpoints.forumPostEdit + "/" + post.id,
          {
            post,
            type
          },
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          const { post } = response.data;
          this.setState({ post, editPost: null });
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  }
}

export default withContext(Post);
