import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import AdminLayout from "./AdminLayout";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import axios from "axios";
import AdminNumberList from "./parts/adminNumberList";
import AdminBigNumber from "./parts/adminBigNumber";
import Avatar from "../user/_avatar";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

class AdminDashboard extends Component {
  state = {
    breadcrumbs: ["dashboard", "admin-dashboard"],
    selfmadeUserCount: [],
    webinarBundleUserCount: [],
    latestUsers: [],
    statsUsersByWeek: [],
    nextLiveCall: {},
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    return (
      <AdminLayout active="dashboard">
        <React.Fragment>
          <Grid type="full">
            <Row>
              <Cell sm={24}>
                <h1>Dashboard</h1>
              </Cell>
            </Row>
          </Grid>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={12}>
                <Grid type="full">
                  <Row margin="xy">
                    <Cell sm={24} md={24} lg={24}>
                      <div className="small admin-box">
                        <div className="admin-box-title">SELFMADE</div>
                        <div className="admin-box-content">
                          <Grid type="full">
                            <Row>
                              <Cell sm={24} md={12}>
                                <div className="center-vertically">
                                  <AdminBigNumber
                                    number={this.view__selfmadeUsersBigNumber()}
                                  />
                                  <div className="text-center">
                                    <small>
                                      davon{" "}
                                      <strong>
                                        {
                                          this.state.selfmadeUserCount
                                            .really_active
                                        }
                                      </strong>{" "}
                                      in den letzen 4 Wochen eingeloggt
                                    </small>
                                  </div>
                                </div>
                              </Cell>
                              <Cell sm={24} md={12}>
                                <div className="show-for-small-only">
                                  &nbsp;
                                </div>
                                <AdminNumberList
                                  list={this.view__selfmadeUsersList()}
                                />
                              </Cell>
                            </Row>
                          </Grid>
                        </div>
                      </div>
                    </Cell>
                    {/*<Cell sm={12} md={8} lg={8}>
                      <div className="small admin-box">
                        <div className="admin-box-title">WEBINAR BUNDLE</div>
                        <div className="admin-box-content">
                          <Grid type="full">
                            <Row>
                              <Cell sm={24}>
                                <AdminBigNumber
                                  number={this.view__webinarBundleUsersBigNumber()}
                                />
                              </Cell>
                            </Row>
                          </Grid>
                        </div>
                      </div>
    </Cell>*/}
                  </Row>
                  <Row margin="xy">
                    <Cell sm={24} md={24} lg={24}>
                      <div className="small admin-box">
                        <div className="admin-box-title">Neue Mitglieder</div>
                        <div className="admin-box-content">
                          {this.view__showStatsUsersByWeek()}
                        </div>
                      </div>
                    </Cell>
                  </Row>
                  <Row margin="xy">
                    <Cell sm={24} md={24} lg={24}>
                      <div className="small admin-box">
                        <div className="admin-box-title">
                          Neueste Mitglieder
                        </div>
                        <div className="admin-box-content">
                          {this.view__showUserList(this.state.latestUsers)}
                        </div>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </Cell>
              <Cell sm={24} md={12}>
                <Grid type="full">
                  <Row margin="xy">
                    <Cell sm={24}>
                      <Row margin="xy">
                        <Cell sm={24} md={24} lg={24}>
                          <div className="admin-box admin-news">
                            <div className="admin-box-title">
                              Nächster Live Call
                            </div>
                            <div className="admin-box-content">
                              {this.view__showNextLiveCall()}
                            </div>
                          </div>

                          <div className="admin-box admin-news">
                            <div className="admin-box-title">
                              Nächstes SELFMADE Video
                            </div>
                            <div className="admin-box-content">
                              {this.view__showNextSelfmadeVideo()}
                            </div>
                          </div>

                          <div className="admin-box admin-news">
                            <div className="admin-box-title">
                              Administration Neuigkeiten
                            </div>
                            <div className="admin-box-content">
                              <h3>04.09.2020 [v0.3.7]</h3>
                              <ul>
                                <li>
                                  Ankündigungen nun auf Kurs-Seite möglich
                                </li>
                                <li>
                                  Kurse mit individuellem Countdown integriert
                                </li>
                              </ul>
                              <h3>30.10.2019 [v0.3.6.2]</h3>
                              <ul>
                                <li>YouTube Empfehlungen verwalten</li>
                              </ul>
                              <h3>24.10.2019 [v0.3.4]</h3>
                              <ul>
                                <li>Administrationsbereich mobil zugänglich</li>
                              </ul>
                              <h3>23.10.2019 [v0.3.3]</h3>
                              <ul>
                                <li>
                                  Ankündigungen für Dashboard anlegen,
                                  bearbeiten &amp; löschen
                                </li>
                                <li>
                                  Zitate des Tages anlegen, bearbeiten &amp;
                                  löschen
                                </li>
                                <li>
                                  Kurse: Hinzufügen, bearbeiten und löschen von
                                  Downloads (z.B. PDFs)
                                </li>
                              </ul>

                              <h3>04.10.2019 [v0.3.2.3]</h3>
                              <ul>
                                <li>Videowünsche anzeigen</li>
                                <li>&quot;Fragen an Torben&quot; entfernt</li>
                              </ul>

                              <h3>02.10.2019 [v0.3.2.2]</h3>
                              <ul>
                                <li>
                                  Administratoren: hinzufügen, bearbeiten &amp;
                                  entfernen
                                </li>
                              </ul>

                              <h3>06.09.2019 [v0.3.1.5]</h3>
                              <ul>
                                <li>Live Calls: hinzufügen &amp; bearbeiten</li>
                              </ul>

                              <h3>01.08.2019 [v0.3.0.8]</h3>
                              <ul>
                                <li>Kurse: hinzufügen &amp; bearbeiten</li>
                                <li>
                                  Kurse: Lektionen hinzufügen, bearbeiten,
                                  löschen
                                </li>
                              </ul>

                              <h3>29.07.2019 [v0.3.0.7]</h3>
                              <ul>
                                <li>Bücher der Woche: Auflistung</li>
                                <li>Bücher der Woche: Bearbeiten</li>
                                <li>Bücher der Woche: Neu anlegen</li>
                              </ul>

                              <h3>28.07.2019 [v0.3.0.6]</h3>
                              <ul>
                                <li>
                                  Forum: Thema schließen &amp; wieder öffnen
                                </li>
                                <li>Forum: Thema löschen</li>
                                <li>Forum: Beitrag löschen</li>
                              </ul>

                              <h3>25.07.2019 [v0.3.0.4]</h3>
                              <ul>
                                <li>Live Calls: Fragen an Torben</li>
                                <li>
                                  Live Calls: Fragen an Torben Druckansicht
                                </li>
                              </ul>

                              <h3>23.07.2019 [v0.3.0.3]</h3>
                              <ul>
                                <li>Dashboard: Chart Neue Mitglieder</li>
                              </ul>

                              <h3>09.07.2019 [v0.3]</h3>
                              <ul>
                                <li>Administrationsbereich eingerichtet</li>
                                <li>Dashboard: Anzahl Benutzer</li>
                                <li>Dashboard: Neueste Mitglieder</li>
                                <li>Benutzeranzeige</li>
                                <li>Benutzersuche</li>
                                <li>Benutzer anlegen &amp; bearbeiten</li>
                                <li>Benutzer-Käufe anzeigen &amp; anlegen</li>
                              </ul>
                            </div>
                          </div>
                        </Cell>
                      </Row>
                    </Cell>
                  </Row>
                </Grid>
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      </AdminLayout>
    );
  }

  view__selfmadeUsersBigNumber() {
    const { selfmadeUserCount } = this.state;
    const number = {
      caption: "aktive Benutzerkonten",
      number: selfmadeUserCount.active,
    };
    return number;
  }

  view__selfmadeUsersList() {
    const { selfmadeUserCount } = this.state;
    const list = [
      {
        caption: "Jahresmitgliedschaften",
        number: selfmadeUserCount.annually_total,
      },
      {
        caption: "davon durch Upgrade",
        number: selfmadeUserCount.annually_upgraded,
      },
      { caption: "Monatsmitgliedschaften", number: selfmadeUserCount.monthly },
      { caption: "U18", number: selfmadeUserCount.u18 },
      { caption: "mentored", number: selfmadeUserCount.mentored },
    ];
    return list;
  }

  view__webinarBundleUsersBigNumber() {
    const { webinarBundleUserCount } = this.state;
    const number = {
      caption: "Nutzer",
      number: webinarBundleUserCount.active,
    };
    return number;
  }

  view__showUserList(users) {
    return (
      <ul className="admin-list">
        {users.map((user, index) => {
          return (
            <li className="clickable admin-list-item" key={index}>
              <a href={`/admin/users/${user.id}`}>
                <Grid type="full">
                  <Row>
                    <Cell sm={4} md={2}>
                      <div className="admin-dashboard-userlist-avatar">
                        <Avatar user={user} size="mainTopbar" />
                      </div>
                    </Cell>
                    <Cell sm={20} md={12}>
                      <div>
                        {user.lastname}, {user.firstname}
                      </div>
                      <div
                        className="show-for-small-only"
                        style={{ marginTop: "-5px" }}
                      >
                        <small>{user.created_date}</small>
                      </div>
                    </Cell>
                    <Cell
                      sm={24}
                      md={10}
                      classes="text-right hide-for-small-only"
                    >
                      {user.created_date}
                    </Cell>
                  </Row>
                </Grid>
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  view__showStatsUsersByWeek() {
    const data = this.state.statsUsersByWeek;

    return (
      <React.Fragment>
        <div
          className="show-for-small-only"
          style={{ position: "relative", left: "-30px" }}
        >
          <BarChart width={330} height={160} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="new" fill="#b00606" />
          </BarChart>
        </div>
        <div className="hide-for-small-only">
          <div>&nbsp;</div>
          <BarChart width={500} height={200} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="new" fill="#b00606" />
          </BarChart>
        </div>
      </React.Fragment>
    );
  }

  view__showNextSelfmadeVideo() {
    const video = this.state.nextSelfmadeVideo;

    if (video) {
      let lessonUrl =
        this.props.context.hostUrl +
        "/kurse/" +
        video.course.identifier +
        "/" +
        video.identifier;

      let editUrl = "/admin/courses/" + video.course.id + "/lesson/" + video.id;

      let videoText = (
        <div className="admin-dashboard-video-missing">
          Video-Typ konnte nicht erkannt werden.
        </div>
      );
      if (video.type === "vimeo") {
        if (video.content) {
          let vimeoPreviewUrl =
            "https://player.vimeo.com/video/" + video.content;
          videoText = (
            <div className="responsive-embed widescreen">
              <iframe
                src={vimeoPreviewUrl}
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Vorschau"
              />
            </div>
          );
        } else {
          videoText = (
            <div className="admin-dashboard-video-missing">
              Keine
              <br />
              Vimeo-Id
              <br />
              hinterlegt!
            </div>
          );
        }
      }

      return (
        <React.Fragment>
          <Grid type="full">
            <Row>
              <Cell sm={24} md={14}>
                <h3>
                  <a href={editUrl}>{video.name}</a>
                </h3>
                <p>
                  <strong>
                    <Icon icon="clock-o" left />
                    {video.publish_date} - {video.publish_time} Uhr
                  </strong>
                </p>
                <p>
                  <a href={editUrl}>
                    <small>
                      <Icon icon="edit" left /> Lektion bearbeiten
                    </small>
                  </a>
                </p>
              </Cell>
              <Cell sm={24} md={10}>
                {videoText}
              </Cell>
            </Row>
          </Grid>
          <div className="text-center">
            <span className="admin-courses-lesson-url">
              <Icon icon="long-arrow-right" left />
              &nbsp;
              <a href={lessonUrl} target="_blank" rel="noopener noreferrer">
                {lessonUrl}
              </a>
            </span>
          </div>
        </React.Fragment>
      );
    }
  }

  view__showNextLiveCall() {
    const _c = this.props.context;

    if (!_c) {
      return null;
    }

    const livecall = _c.user.nextLiveEvent;
    let livecallDate;
    if (livecall && livecall.date) {
      livecallDate = new Date(livecall.date);
    }

    const now = new Date();

    if (!livecall || !livecall.id || livecallDate < now) {
      return (
        <React.Fragment>
          <p className="text-center">Kein anstehender Live Call</p>
          <div className="text-center">
            <a href="/admin/live/new">
              <small>
                <Icon icon="plus" left /> Neuen Live Call anlegen
              </small>
            </a>
          </div>
        </React.Fragment>
      );
    }

    let vimeoText = "";
    if (livecall.type === "vimeo" || livecall.type === null) {
      vimeoText = "Vimeo-Id: ";
      if (livecall.vimeo_live_id) {
        vimeoText += livecall.vimeo_live_id;
      } else {
        vimeoText += "Keine Vimeo-ID hinterlegt";
      }
    }

    let imageUrl = "/images/no-image.jpg";
    if (livecall.image) {
      imageUrl = livecall.image;
    }

    return (
      <React.Fragment>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={14}>
              <h3>
                <a href={`/admin/live/${livecall.id}`}>{livecall.name}</a>
              </h3>
              <p>
                <strong>
                  <Icon icon="clock-o" left />
                  {livecall.live_date} - {livecall.live_time} Uhr
                </strong>
                <br />
                <small>{vimeoText}</small>
              </p>
              <p>
                <a href={`/admin/live/${livecall.id}`}>
                  <small>
                    <Icon icon="edit" left /> Live Call bearbeiten
                  </small>
                </a>
              </p>
            </Cell>
            <Cell sm={24} md={10}>
              <img src={imageUrl} alt="Live Call" />
            </Cell>
          </Row>
        </Grid>
      </React.Fragment>
    );
  }

  /**
   * Init
   * Load Dashboard Information
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.adminDashboard, {
        headers: this.props.context.headers,
      })
      .then((response) => {
        const {
          selfmadeUserCount,
          webinarBundleUserCount,
          latestUsers,
          statsUsersByWeek,
          nextSelfmadeVideo,
        } = response.data;
        this.setState({
          selfmadeUserCount,
          webinarBundleUserCount,
          latestUsers,
          statsUsersByWeek,
          nextSelfmadeVideo,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminDashboard);
