import React, { Component } from "react";
import axios from "axios";

let hostUrl = "https://member.tpa-media.com";

let apiUrl = "https://api.tpa-media.com";
let apiVersion = "/api/1.0";
let env = "prod";

/*if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  apiUrl = "https://tpa_api.ptwd";
  //apiUrl = "http://127.0.0.1:8000";
  //apiUrl = "https://api.tpa-media.com";
  apiVersion = "/api/1.0";
  hostUrl = "http://localhost:3000";
  env = "dev";
}*/

const apiEndpoints = {
  login: apiUrl + "/oauth/token",
  passwordForgotten: apiUrl + apiVersion + "/password/forgotten",
  passwordReset: apiUrl + apiVersion + "/password/reset",
  passwordCheckToken: apiUrl + apiVersion + "/password/token",
  user: apiUrl + apiVersion + "/user",
  saveUser: apiUrl + apiVersion + "/user",
  userDetails: apiUrl + apiVersion + "/user/details",
  uploadAvatar: apiUrl + apiVersion + "/user/avatar/upload",
  deleteAvatar: apiUrl + apiVersion + "/user/avatar/delete",
  password: apiUrl + apiVersion + "/user/password",
  toggleFavorite: apiUrl + apiVersion + "/user/favorite",
  announcementBoxButton: apiUrl + apiVersion + "/announcementbox/button",
  dashboard: apiUrl + apiVersion + "/dashboard",
  dashboardClick: apiUrl + apiVersion + "/dashboard/click",
  liveUpdateViewMode: apiUrl + apiVersion + "/live/updateviewmode",
  courses: apiUrl + apiVersion + "/courses",
  coursesIndex: apiUrl + apiVersion + "/index/courses",
  course: apiUrl + apiVersion + "/courses",
  courseLive: apiUrl + apiVersion + "/courses/live",
  courseUpdateStatus: apiUrl + apiVersion + "/courses/updatestatus",
  courseUpdateViewMode: apiUrl + apiVersion + "/courses/updateviewmode",
  selfmadeCourseUrl: apiUrl + apiVersion + "/courses/selfmadecourseurl",
  lesson: apiUrl + apiVersion + "/lesson",
  note: apiUrl + apiVersion + "/courses/note",
  forumBoard: apiUrl + apiVersion + "/community/board",
  forumBoards: apiUrl + apiVersion + "/community/boards",
  forumThreads: apiUrl + apiVersion + "/community/threads",
  forumPosts: apiUrl + apiVersion + "/community/posts",
  forumPostEdit: apiUrl + apiVersion + "/community/posts/edit",
  forumLikePost: apiUrl + apiVersion + "/community/posts/like",
  forumPostNewReply: apiUrl + apiVersion + "/community/posts/reply",
  forumNewThread: apiUrl + apiVersion + "/community/thread/new",
  userProfile: apiUrl + apiVersion + "/community/profile",
  userProfileClick: apiUrl + apiVersion + "/community/profile/click",
  routines: apiUrl + apiVersion + "/routines",
  routinesDayFinish: apiUrl + apiVersion + "/routines/day/finish",
  routinesDaySave: apiUrl + apiVersion + "/routines/day/save",
  routinesSave: apiUrl + apiVersion + "/routines/save",
  routinesDelete: apiUrl + apiVersion + "/routines/delete",
  todoLists: apiUrl + apiVersion + "/todo/lists",
  todoTaskFinish: apiUrl + apiVersion + "/todo/task/finish",
  todoTaskSave: apiUrl + apiVersion + "/todo/task/save",
  todoTaskDelete: apiUrl + apiVersion + "/todo/task/delete",
  selfmadeLoad: apiUrl + apiVersion + "/selfmade/load",
  selfmadeQotd: apiUrl + apiVersion + "/selfmade/qotd",
  selfmadeMissionStatement: apiUrl + apiVersion + "/selfmade/missionstatement",
  selfmadeTodo: apiUrl + apiVersion + "/selfmade/todo",
  selfmadeVisionboardImages: apiUrl + apiVersion + "/selfmade/visionboard",
  selfmadeVisionboardImageUpload:
    apiUrl + apiVersion + "/selfmade/visionboard/upload",
  selfmadeRevenueGoalsSaveIncome:
    apiUrl + apiVersion + "/selfmade/revenuegoals/income",
  selfmadeRevenueGoalsUpdate:
    apiUrl + apiVersion + "/selfmade/revenuegoals/update",
  selfmadeRevenueGoalsDeleteIncome:
    apiUrl + apiVersion + "/selfmade/revenuegoals/income/delete",
  selfmadeQuestionForTorben:
    apiUrl + apiVersion + "/selfmade/questionfortorben",
  selfmadeVideoTopicRequest:
    apiUrl + apiVersion + "/selfmade/videotopicrequest",
  calendar: apiUrl + apiVersion + "/calendar",
  calendarEntrySave: apiUrl + apiVersion + "/calendar/entry/save",
  calendarEntryDelete: apiUrl + apiVersion + "/calendar/entry",
  adminDashboard: apiUrl + apiVersion + "/admin/dashboard",
  adminUsers: apiUrl + apiVersion + "/admin/users",
  adminUser: apiUrl + apiVersion + "/admin/user",
  adminUserPurchase: apiUrl + apiVersion + "/admin/user/purchase",
  adminUserPurchaseActivation:
    apiUrl + apiVersion + "/admin/user/purchase/activation",
  adminCourses: apiUrl + apiVersion + "/admin/courses",
  adminCoursesOrder: apiUrl + apiVersion + "/admin/courses/order",
  adminCourse: apiUrl + apiVersion + "/admin/course",
  adminCourseDownload: apiUrl + apiVersion + "/admin/course/download",
  adminCourseDownloadsOrder:
    apiUrl + apiVersion + "/admin/course/download/order",
  adminCourseDownloadUpload:
    apiUrl + apiVersion + "/admin/course/download/upload",
  adminCourseActivation: apiUrl + apiVersion + "/admin/course/activation",
  adminCourseNewDigistoreId:
    apiUrl + apiVersion + "/admin/course/digistoreid/new",
  adminCourseDelDigistoreId:
    apiUrl + apiVersion + "/admin/course/digistoreid/del",
  adminCourseCategory: apiUrl + apiVersion + "/admin/course/category",
  adminCourseCategorySwitch:
    apiUrl + apiVersion + "/admin/course/categoryswitch",
  adminCourseCategoriesOrder:
    apiUrl + apiVersion + "/admin/course/category/order",
  adminCourseLessonsOrder: apiUrl + apiVersion + "/admin/course/lesson/order",
  adminLesson: apiUrl + apiVersion + "/admin/course/lesson",
  adminNewLesson: apiUrl + apiVersion + "/admin/course/newlesson",
  adminLessonActivation:
    apiUrl + apiVersion + "/admin/course/lesson/activation",
  adminLessonDelete: apiUrl + apiVersion + "/admin/course/lesson/delete",
  adminLiveEvents: apiUrl + apiVersion + "/admin/live/events",
  adminLiveEventsDeleteImage:
    apiUrl + apiVersion + "/admin/live/events/deleteimage",
  adminLiveEventsUploadImage:
    apiUrl + apiVersion + "/admin/live/events/uploadimage",
  adminLiveQuestions: apiUrl + apiVersion + "/admin/live/questions",
  adminForumThreadClose: apiUrl + apiVersion + "/admin/forum/thread/close",
  adminForumThreadDelete: apiUrl + apiVersion + "/admin/forum/thread",
  adminForumThreadMove: apiUrl + apiVersion + "/admin/forum/thread/move",
  adminForumPostDelete: apiUrl + apiVersion + "/admin/forum/post",
  adminAnnouncements: apiUrl + apiVersion + "/admin/announcements",
  adminBooks: apiUrl + apiVersion + "/admin/books",
  adminPermissions: apiUrl + apiVersion + "/admin/permissions",
  adminPermissionsUpdate: apiUrl + apiVersion + "/admin/permissions/update",
  adminAddAdmin: apiUrl + apiVersion + "/admin/permissions/add",
  adminRemoveAdmin: apiUrl + apiVersion + "/admin/permissions/remove",
  adminVideoRequests: apiUrl + apiVersion + "/admin/videorequests",
  adminQuotes: apiUrl + apiVersion + "/admin/quotes",
  adminYoutube: apiUrl + apiVersion + "/admin/youtube",
};

export const TpaContext = React.createContext();

export default class TpaProvider extends Component {
  constructor() {
    super();
    this.state = {
      version: "0.3.8.2",
      env: env,
      user: {},
      userLoaded: false,
      hostUrl: hostUrl,
      apiEndpoints: apiEndpoints,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      defaultPageTitle: "TPA Member Area",
      pageTitle: " | TPA Member Area",
      redirectIfNotLoggedIn: "/",
      redirectAfterLogin: "/dashboard",
      redirectAfterLogout: "/",
      showWASupportPopup: false,
      whatsappNr: "0173 - 705 82 15",
      urls: {
        impressum: "https://torbenplatzer.com/impressum",
        datenschutz: "https://torbenplatzer.com/privacy-policy",
        courses: "/kurse",
        course: "/kurse/:course",
        lesson: "/kurse/:course/:lesson",
        forumBoard: "/community/:board",
        forumThread: "/community/:board/:thread",
        forumNewThread: "/community/:board/new",
        routines: "/routinen/:year/:month",
        todolist: "/todo/:list",
        calendar: "/kalender/:year/:month",
      },
      months: {
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
      },
      monthsShort: {
        1: "Jan",
        2: "Feb",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "Aug",
        9: "Sep",
        10: "Okt",
        11: "Nov",
        12: "Dez",
      },
      weekdays: {
        1: "Montag",
        2: "Dienstag",
        3: "Mittwoch",
        4: "Donnerstag",
        5: "Freitag",
        6: "Samstag",
        7: "Sonntag",
      },
      weekdaysShort: {
        1: "Mo",
        2: "Di",
        3: "Mi",
        4: "Do",
        5: "Fr",
        6: "Sa",
        7: "So",
      },
      purchaseTypes: {
        annually: "Jährliche Mitgliedschaft",
        annually_upgraded: "Jährliche Mitgliedschaft (Upgrade)",
        monthly: "Monatliche Mitgliedschaft",
        free: "Kostenlos",
        mentored: "Mentored Group",
        u18: "U18",
      },
      announcementTypes: {
        admin: "Administrator",
        dev: "Entwickler",
      },
      announcementShowOns: {
        dashboard: "Dashboard",
      },
      socialPlatforms: {
        website: {
          name: "Website",
          icon: "globe",
        },
        instagram: {
          name: "Instagram",
          icon: "instagram",
        },
        facebook: {
          name: "Facebook",
          icon: "facebook-official",
        },
        youtube: {
          name: "Youtube",
          icon: "youtube",
        },
        twitter: {
          name: "Twitter",
          icon: "twitter-square",
        },
        linkedin: {
          name: "LinkedIn",
          icon: "linkedin-square",
        },
        xing: {
          name: "Xing",
          icon: "xing",
        },
        podcast: {
          name: "Podcast",
          icon: "podcast",
        },
      },
      showMobileAdminNav: false,
    };
  }

  render() {
    return (
      <TpaContext.Provider
        value={{
          ...this.state,
          initAuth: this.initAuth,
          handleUpdateUser: this.handleUpdateUser,
          handleLogout: this.handleLogout,
          checkPurchased: this.checkPurchased,
          hideLoadingScreen: this.hideLoadingScreen,
          getUser: this.getUser,
          convertSeconds: this.convertSeconds,
          convertTimeToSeconds: this.convertTimeToSeconds,
          generateCourseUrl: this.generateCourseUrl,
          generateLessonUrl: this.generateLessonUrl,
          generateForumBoardUrl: this.generateForumBoardUrl,
          generateForumThreadUrl: this.generateForumThreadUrl,
          generateForumNewThreadUrl: this.generateForumNewThreadUrl,
          generateRoutinesUrl: this.generateRoutinesUrl,
          generateTodoListUrl: this.generateTodoListUrl,
          generateCalendarUrl: this.generateCalendarUrl,
          pageTitle: this.pageTitle,
          showSupportPopup: this.showSupportPopup,
          hideSupportPopup: this.hideSupportPopup,
          getSupportPopupStatus: this.getSupportPopupStatus,
          userHasSelfmade: this.userHasSelfmade,
          userHasSelfmadeVIP: this.userHasSelfmadeVIP,
          getUserSelfmadeStatus: this.getUserSelfmadeStatus,
          textDate: this.textDate,
          toggleAdminNav: this.toggleAdminNav,
          getVersion: this.getVersion,
          isDebug: this.isDebug,
        }}
      >
        {this.props.children}
      </TpaContext.Provider>
    );
  }

  initAuth = (origin, callback) => {
    this.loadUser(origin, (user) => {
      if (callback) {
        callback(user);
      }
    });
  };

  loadUser = (origin, callback) => {
    if (localStorage.getItem("access_token")) {
      this.handleUpdateAccessToken();
      axios
        .get(this.state.apiEndpoints.user, {
          headers: this.state.headers,
        })
        .then((response) => {
          let userInfo = response.data;
          userInfo.isloggedIn = true;
          if (userInfo.endOfTrial !== null) {
            userInfo.endOfTrial = new Date(userInfo.endOfTrial);
          }
          this.setState({ user: userInfo, userLoaded: true });

          //console.log(response.data);
          //return;

          if (origin === "index") {
            window.location = this.state.redirectAfterLogin;
          }

          if (callback) {
            callback(userInfo);
          }

          this.hideLoadingScreen();
        })
        .catch((error) => {
          console.log("Error", error);
          //return;
          if (this.env === "prod") {
            localStorage.removeItem("access_token");
            window.location = this.state.redirectIfNotLoggedIn;
          }
          return null;
        });
    } else {
      if (origin !== "index") {
        window.location = this.state.redirectIfNotLoggedIn;
      } else {
        this.hideLoadingScreen();
      }
    }
  };

  handleUpdateUser = (user) => {
    this.setState({ user });
  };

  handleLogout = () => {
    localStorage.removeItem("access_token");
    window.location = this.state.redirectAfterLogout;
  };

  handleUpdateAccessToken = () => {
    const { headers } = this.state;
    headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    this.setState({ headers: headers });
  };

  hideLoadingScreen = () => {
    document.getElementById("loadingScreen").style.display = "none";
  };

  showLoadingScreen = () => {
    document.getElementById("loadingScreen").style.display = "block";
  };

  getVersion = () => {
    const { version } = this.state;
    return version;
  };

  isDebug = () => {
    const { env } = this.state;
    if (env === "dev") {
      return true;
    } else {
      return false;
    }
  };

  checkPurchased = (course) => {
    const { user } = this.state;
    let hasPurchased = false;

    if (course === "selfmade") {
    } else {
      user.purchases.map((purchase) => {
        if (purchase.type === "course" && purchase.product === course) {
          hasPurchased = true;
        }
        return null;
      });
    }

    return hasPurchased;
  };

  pageTitle = (title) => {
    const { pageTitle } = this.state;
    const newPageTitle = title + pageTitle;
    return newPageTitle;
  };

  convertSeconds(seconds) {
    const time = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    time.hours = Math.floor(seconds / 60 / 60);
    seconds -= time.hours * 60 * 60;

    time.minutes = Math.floor(seconds / 60);
    seconds -= time.minutes * 60;

    time.seconds = Math.floor(seconds);

    return time;
  }

  convertTimeToSeconds(time) {
    let seconds = 0;

    seconds += parseInt(time.hours * 60 * 60);
    seconds += parseInt(time.minutes * 60);
    seconds += parseInt(time.seconds);

    return seconds;
  }

  generateCourseUrl = (courseIdentifier) => {
    return this.state.urls.course.replace(":course", courseIdentifier);
  };

  generateLessonUrl = (courseIdentifier, lessonIdentifier) => {
    return this.state.urls.lesson
      .replace(":course", courseIdentifier)
      .replace(":lesson", lessonIdentifier);
  };

  generateForumBoardUrl = (boardSlug) => {
    return this.state.urls.forumBoard.replace(":board", boardSlug);
  };

  generateForumThreadUrl = (boardSlug, threadIdentifier) => {
    return this.state.urls.forumThread
      .replace(":board", boardSlug)
      .replace(":thread", threadIdentifier);
  };

  generateForumNewThreadUrl = (boardSlug) => {
    return this.state.urls.forumNewThread.replace(":board", boardSlug);
  };

  generateRoutinesUrl = (year, month) => {
    return this.state.urls.routines
      .replace(":year", year)
      .replace(":month", month);
  };

  generateTodoListUrl = (listSlug) => {
    return this.state.urls.todolist.replace(":list", listSlug);
  };

  generateCalendarUrl = (year, month) => {
    return this.state.urls.calendar
      .replace(":year", year)
      .replace(":month", month);
  };

  showSupportPopup = () => {
    this.setState({ showWASupportPopup: true });
  };

  hideSupportPopup = () => {
    this.setState({ showWASupportPopup: false });
  };

  getSupportPopupStatus = () => {
    return this.state.showWASupportPopup;
  };

  userHasSelfmade = () => {
    const { user } = this.state;

    let selfmadeStatus = {
      active: false,
      url: null,
    };

    if (user && user.selfmadeStatus) {
      selfmadeStatus = user.selfmadeStatus;
    }

    return selfmadeStatus.active;
  };

  userHasSelfmadeVIP = () => {
    const { user } = this.state;

    let selfmadeStatus = {
      active: false,
      url: null,
    };

    if (user && user.selfmadeStatus) {
      selfmadeStatus = user.selfmadeStatus;
    }

    if (
      selfmadeStatus.active === true &&
      (selfmadeStatus.type === "annually" ||
        selfmadeStatus.type === "annually_upgraded")
    ) {
      return true;
    } else {
      return false;
    }
  };

  getUserSelfmadeStatus = () => {
    const { user } = this.state;

    let selfmadeStatus = {
      active: false,
      url: null,
    };

    if (user && user.selfmadeStatus) {
      selfmadeStatus = user.selfmadeStatus;
    }

    return selfmadeStatus;
  };

  getUser = () => {
    const { user } = this.state;
    return user;
  };

  textDate = (date) => {
    try {
      let dateObject = new Date(date);
      let today = new Date();

      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      let day_before_yesterday = new Date();
      day_before_yesterday.setDate(day_before_yesterday.getDate() - 2);

      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      let day_after_tomorrow = new Date();
      day_after_tomorrow.setDate(day_after_tomorrow.getDate() + 2);

      if (
        dateObject.getDate() === day_before_yesterday.getDate() &&
        dateObject.getMonth() === day_before_yesterday.getMonth() &&
        dateObject.getFullYear() === day_before_yesterday.getFullYear()
      ) {
        return "Vorgestern";
      } else if (
        dateObject.getDate() === yesterday.getDate() &&
        dateObject.getMonth() === yesterday.getMonth() &&
        dateObject.getFullYear() === yesterday.getFullYear()
      ) {
        return "Gestern";
      } else if (
        dateObject.getDate() === today.getDate() &&
        dateObject.getMonth() === today.getMonth() &&
        dateObject.getFullYear() === today.getFullYear()
      ) {
        return "Heute";
      } else if (
        dateObject.getDate() === tomorrow.getDate() &&
        dateObject.getMonth() === tomorrow.getMonth() &&
        dateObject.getFullYear() === tomorrow.getFullYear()
      ) {
        return "Morgen";
      } else if (
        dateObject.getDate() === day_after_tomorrow.getDate() &&
        dateObject.getMonth() === day_after_tomorrow.getMonth() &&
        dateObject.getFullYear() === day_after_tomorrow.getFullYear()
      ) {
        return "Übermorgen";
      } else {
        return null;
      }
    } catch {
      return null;
    }
  };

  toggleAdminNav = () => {
    const { showMobileAdminNav } = this.state;

    if (showMobileAdminNav === true) {
      this.setState({ showMobileAdminNav: false });
      document
        .getElementById("adminNavigationWrapper")
        .classList.remove("open");
      document.getElementById("adminNavigationWrapper").classList.add("closed");
    } else if (showMobileAdminNav === false) {
      this.setState({ showMobileAdminNav: true });
      document
        .getElementById("adminNavigationWrapper")
        .classList.remove("closed");
      document.getElementById("adminNavigationWrapper").classList.add("open");
    }
  };
}
