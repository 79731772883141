import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import Breadcrumbs from "../topbars/breadcrumbs";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { isUndefined } from "util";
import CalendarEntry from "./calendarEntry";

class Calendar extends Component {
  state = {
    days: [],
    now: new Date(),
    selectedCalendarEntry: undefined,
    month: 0,
    year: 0,
    new: this.props.match.params.new,
    view: "month",
    years: [2019, 2020]
  };

  componentDidMount() {
    const startYear = 2019;
    const now = new Date();
    const thisYear = now.getFullYear();
    const yearsInFuture = 10;
    let years = [];

    for (let i = startYear; i <= thisYear + yearsInFuture; i++) {
      years.push(i);
    }

    this.setState({ years });
    //console.log(startYear, thisYear, years);

    this.init__checkDate();

    if (this.state.new === "new") {
      this.handle__newEntry();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.month !== prevProps.match.params.month ||
      this.props.match.params.year !== prevProps.match.params.year
    ) {
      this.init__checkDate();
    }
  }

  render() {
    const { weekdaysShort } = this.props.context;
    const { days } = this.state;
    return (
      <div
        id="pageCalendar"
        className="page-with-topbar-and-breadcrumbs darkmode"
      >
        <MainTopbar />
        <Breadcrumbs breadcrumbs={["dashboard", "calendar"]} />
        <section className="content">
          {this.show__popupCalendarEntry()}
          <Grid>
            <Row>
              <Cell sm={24} md={12}>
                <h2>Mein Kalender</h2>
              </Cell>
              <Cell sm={24} md={12} classes="text-right">
                <button
                  className="primary button"
                  onClick={this.handle__newEntry}
                >
                  <Icon icon="plus" left /> Eintrag hinzufügen
                </button>
              </Cell>
            </Row>
            <Row>
              <Cell sm={24} md={24}>
                {this.show__monthSelection()}
              </Cell>
            </Row>
            <Row>
              <Cell sm={24}>
                <div className="calendar-days">
                  <div className="calendar-days-header">
                    {Object.keys(weekdaysShort).map(key => {
                      return (
                        <div className="calendar-days-weekday" key={key}>
                          {weekdaysShort[key]}
                        </div>
                      );
                    })}
                  </div>
                  {days.map((day, index) => {
                    let classes = "calendar-day";
                    if (!day.dayNr) {
                      classes += " no-day";
                    }
                    if (day.today) {
                      classes += " today";
                    }

                    let lineBreak = "";
                    if (day.weekday === 7) {
                      lineBreak = <div className="clearfix" />;
                    }

                    return (
                      <React.Fragment key={index}>
                        <div
                          className={classes}
                          onDoubleClick={() => {
                            const now = new Date();
                            this.handle__newEntry(
                              new Date(
                                this.state.year,
                                parseInt(this.state.month - 1),
                                day.dayNr,
                                now.getHours(),
                                now.getMinutes()
                              )
                            );
                          }}
                        >
                          <div className="calendar-day-inner">
                            <div className="calendar-day-nr">
                              {day.dayNr || ""}
                            </div>
                            <div className="calendar-day-content">
                              {this.show__calendarEntries(day)}
                            </div>
                          </div>
                        </div>
                        {lineBreak}
                      </React.Fragment>
                    );
                  })}
                </div>
              </Cell>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }

  show__calendarEntries(day) {
    if (!isUndefined(day.entries)) {
      const entries = day.entries;

      return (
        <ul className="calendar-day-entries">
          {entries.map((entry, index) => {
            let entryName = entry.name;
            if (entryName.length > 22) {
              entryName = entryName.slice(0, 20) + "...";
            }
            return (
              <li key={index} className="calendar-day-entry">
                <button
                  onClick={() => {
                    this.setState({ selectedCalendarEntry: entry });
                  }}
                >
                  {entry.start_time} <strong>{entryName}</strong>
                </button>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return null;
    }
  }

  show__popupCalendarEntry() {
    return (
      <CalendarEntry
        entry={this.state.selectedCalendarEntry}
        month={this.state.month}
        year={this.state.year}
        handleClose={() => {
          this.setState({ selectedCalendarEntry: undefined });
        }}
        handleUpdate={this.handle__onUpdate}
        type="calendar"
      />
    );
  }

  /**
   * Show Month Selection
   */
  show__monthSelection() {
    const { months } = this.props.context;
    const { years } = this.state;

    return (
      <div className="routines-month-selection">
        <Grid>
          <Row>
            <Cell sm={24} md={8} mdo={4}>
              <Grid type="full">
                <Row>
                  <Cell sm={2} classes="routines-month-selection-prev">
                    {this.show__prevMonthButton()}
                  </Cell>
                  <Cell sm={12} classes="routines-month-selection-month">
                    <select
                      value={this.state.month}
                      onChange={event => {
                        this.handle__monthSelection(event, "month");
                      }}
                    >
                      {Object.keys(months).map(key => {
                        return (
                          <option value={key} key={key}>
                            {months[key]}
                          </option>
                        );
                      })}
                    </select>
                  </Cell>
                  <Cell sm={8} classes="routines-month-selection-year">
                    <select
                      value={this.state.year}
                      onChange={event => {
                        this.handle__monthSelection(event, "year");
                      }}
                    >
                      {years.map(year => {
                        return (
                          <option value={year} key={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </Cell>
                  <Cell sm={2} classes="routines-month-selection-next">
                    {this.show__nextMonthButton()}
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  /**
   * Show Button for previous month
   */
  show__prevMonthButton() {
    if (this.check__isPrevMonthAvailable() !== false) {
      return (
        <button onClick={this.handle__prevMonth}>
          <Icon icon="angle-left" />
        </button>
      );
    } else {
      return null;
    }
  }

  /**
   * Show Button for next month
   */
  show__nextMonthButton() {
    if (this.check__isNextMonthAvailable() !== false) {
      return (
        <button onClick={this.handle__nextMonth}>
          <Icon icon="angle-right" />
        </button>
      );
    } else {
      return null;
    }
  }

  handle__onUpdate = (days, entry) => {
    this.setState({ days, selectedCalendarEntry: entry });
  };

  handle__newEntry = date => {
    let now = new Date();
    let then = new Date();

    if (date && typeof date.getMonth === "function") {
      //console.log(date);
      now = date;
      then = date;
    }

    const newEntry = {
      name: "Neuer Eintrag",
      start: now,
      end: then.setHours(now.getHours() + 1)
    };

    this.setState({ selectedCalendarEntry: newEntry });
  };

  /**
   * Handle Changes on Month Selection
   */
  handle__monthSelection(event, type) {
    const newValue = event.target.value;

    if (type === "month") {
      this.setState({ month: newValue }, () => {
        // Switch Month
        this.handle__switchMonth();
      });
    } else if (type === "year") {
      this.setState({ year: newValue }, () => {
        // Switch Month
        this.handle__switchMonth();
      });
    }
  }

  /**
   * Handle click on left arrow for previous month
   */
  handle__prevMonth = () => {
    const result = this.check__isPrevMonthAvailable();

    if (result !== false) {
      this.setState({ year: result.year, month: result.month }, () => {
        // Switch Month
        this.handle__switchMonth();
      });
    }
  };

  /**
   * Handle click on right arrow for next month
   */
  handle__nextMonth = () => {
    const result = this.check__isNextMonthAvailable();

    if (result !== false) {
      this.setState({ year: result.year, month: result.month }, () => {
        // Switch Month
        this.handle__switchMonth();
      });
    }
  };

  /**
   * Switch Month
   */
  handle__switchMonth() {
    const { year, month } = this.state;
    const url = this.props.context.generateCalendarUrl(year, month);
    this.props.history.push(url);
  }

  /**
   * Check if next month is available
   */
  check__isPrevMonthAvailable() {
    let { years, year, month } = this.state;
    month--;
    if (month === 0) {
      if (years.includes(parseInt(year) - 1)) {
        month = 12;
        year--;
      } else {
        return false;
      }
    }
    return { month: month, year: year };
  }

  /**
   * Check if next month is available
   */
  check__isNextMonthAvailable() {
    let { years, year, month } = this.state;
    month++;
    if (month === 13) {
      if (years.includes(parseInt(year) + 1)) {
        month = 1;
        year++;
      } else {
        return false;
      }
    }
    return { month: month, year: year };
  }

  /**
   * Init
   * Check Month & Year
   */
  init__checkDate() {
    // Check for month and year
    const now = new Date();

    let year = 0;
    if (!this.props.match.params.year) {
      year = now.getFullYear();
    } else {
      year = this.props.match.params.year;
    }

    let month = 0;
    if (!this.props.match.params.month) {
      month = now.getMonth() + 1;
    } else {
      month = this.props.match.params.month;
    }

    // Save month and year to state and load calendar days afterwards
    this.setState({ year, month }, () => {
      // Load Calendar Days
      this.init__load();
    });
  }

  /**
   * Init
   * Load Calendar
   */
  init__load() {
    const { year, month } = this.state;
    axios
      .get(
        this.props.context.apiEndpoints.calendar + "/" + year + "/" + month,
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const { days } = response.data;
        this.setState({ days });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(Calendar);
