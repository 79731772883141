import React, { Component } from "react";
import ForumLayout from "./forumLayout";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell } from "../foundation/_grid";
import { Icon, Button } from "../foundation/_buttons";
import Avatar from "../user/_avatar";
import Post from "./_post";
import { isUndefined } from "util";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import PTPopup from "../ptPopup/ptPopup";

class Thread extends Component {
  constructor(props) {
    super(props);

    this.state = {
      board: {
        name: "",
        slug: "",
        description: ""
      },
      thread: {
        name: ""
      },
      boards: [],
      posts: [],
      boardSlug: this.props.match.params.boardSlug,
      threadIdentifier: this.props.match.params.threadIdentifier,
      selectedTextarea: undefined,
      selectedTextareaLastPosition: 0,
      answers: {
        main_answer: ""
      },
      prevValue: "",
      value: "",
      suggestions: [],
      people: [],
      peopleByUsername: [],
      showMovePopup: false,
      moveSelectedBoard: 0
    };

    this.newestPostRef = React.createRef();
    this.mainAnswerRef = React.createRef();
  }

  componentDidMount() {
    this.loadPosts();

    var getCaretCoordinates = require("textarea-caret");

    document
      .querySelector("#main_answer")
      .addEventListener("input", function() {
        var caret = getCaretCoordinates(this, this.selectionEnd);
        /*console.log(
        "thread",
        "(top, left, height) = (%s, %s, %s)",
        caret.top,
        caret.left,
        caret.height
      );*/

        // Put Autosuggest Popup to Caret Position in textarea
        const textarea = document.getElementById("main_answer");
        const autosuggestPopup = document.getElementById("autosuggestPopup");

        autosuggestPopup.style.top =
          parseInt(textarea.offsetTop + caret.top - 5) + "px";
        autosuggestPopup.style.left =
          parseInt(textarea.offsetLeft + caret.left) + "px";
      });
  }

  componentDidUpdate() {
    /*if (process.env.NODE_ENV !== "production") {
      const { whyDidYouUpdate } = require("why-did-you-update");
      whyDidYouUpdate(React);
    }*/
  }

  render() {
    const { value, suggestions, thread } = this.state;
    const inputProps = {
      placeholder: "Benutzernamen suchen",
      value,
      onChange: this.onChange,
      onKeyUp: this.onKeyUp
    };

    return (
      <ForumLayout
        breadcrumbs={[
          "dashboard",
          "community",
          {
            id: "board",
            to: this.props.context.generateForumBoardUrl(this.state.board.slug),
            name: this.state.board.name
          },
          { id: "thread", to: undefined, name: this.state.thread.name }
        ]}
      >
        <div id="autosuggestPopup">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            highlightFirstSuggestion={true}
            inputProps={inputProps}
            ref={this.storeInputReference}
          />
        </div>
        <Grid>
          <Row>
            <Cell sm={24} md={12}>
              <h2>{this.state.thread.name}</h2>
              <div>&nbsp;</div>
            </Cell>
            <Cell sm={24} md={12} classes="text-right">
              {this.view__showAdminButtons()}
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              {this.showFirstPost()}
              <hr ref={this.newestPostRef} className="first-post-separator" />
              {this.state.posts.map((post, index) => {
                if (index > 0) {
                  return (
                    <Post
                      post={post}
                      thread={thread}
                      index={index}
                      key={index}
                      answer={this.getAnswer(post)}
                      handleUpdatePosts={this.handleUpdatePosts}
                      handleUpdateAnswer={this.updateAnswer}
                      handleSendAnswer={this.sendAnswer}
                      handleUpdateAutosuggestValue={this.updateAutosuggestValue}
                      people={this.state.peopleByUsername}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Cell>
          </Row>
          {this.showMainReplyForm()}
        </Grid>
      </ForumLayout>
    );
  }

  updateAutosuggestValue = value => {
    this.setState({
      value
    });
  };

  getAnswer(post) {
    const { answers } = this.state;
    return answers["reply_" + post.id];
  }

  showFirstPost() {
    const { posts, thread } = this.state;
    if (!isUndefined(posts[0])) {
      return (
        <Post
          post={posts[0]}
          thread={thread}
          index={0}
          answer={this.getAnswer(posts[0])}
          handleUpdatePosts={this.handleUpdatePosts}
          handleUpdateAnswer={this.updateAnswer}
          handleSendAnswer={this.sendAnswer}
          people={this.state.peopleByUsername}
        />
      );
    } else {
      return (
        <p className="loading-spinner">
          <Icon icon="spin fa-circle-o-notch" />
        </p>
      );
    }
  }

  showMainReplyForm() {
    const { thread } = this.state;

    if (thread && thread.closed && thread.closed === true) {
      return (
        <p className="text-center">
          &nbsp;
          <br />
          <em>
            Dieses Thema wurde einem Moderator geschlossen. Antworten ist nicht
            mehr möglich.
          </em>
          <br />
          &nbsp;
        </p>
      );
    }

    return (
      <div id="mainReplyForm">
        <Row>
          <Cell classes="hide-for-small-only" md={4}>
            <div className="post-author">
              <div className="post-author-avatar">
                <Avatar user={this.props.context.user} size="forumPost" />
                <div className="post-author-name">
                  {this.props.context.user.firstname}{" "}
                  {this.props.context.user.lastname}
                </div>
              </div>
            </div>
          </Cell>
          <Cell sm={24} md={20}>
            <h4>Antwort erstellen</h4>
            <div className="box" id="mainReplyTextarea">
              <textarea
                id="main_answer"
                placeholder="Schreibe Deine Nachricht"
                rows="10"
                value={this.state.answers.main_answer}
                onChange={event => {
                  this.handleAnswerChange(event, "main_answer");
                }}
                onClick={this.hideAutosuggestPopup}
                ref={this.mainAnswerRef}
              />
            </div>
            <div className="buttons">
              <Button
                type="primary"
                click={() => {
                  this.sendAnswer("main_answer");
                }}
              >
                <Icon icon="check" left /> Absenden
              </Button>
            </div>
          </Cell>
        </Row>
      </div>
    );
  }

  view__showAdminButtons() {
    const { user } = this.props.context;
    const { thread, moveSelectedBoard, boards } = this.state;

    if (
      user &&
      user.admin &&
      user.admin.permissions &&
      user.admin.permissions.forum &&
      user.admin.permissions.forum === true
    ) {
      let closeButton = "";
      if (thread && thread.closed && thread.closed === true) {
        closeButton = (
          <button className="primary button" onClick={this.handle__openThread}>
            <Icon icon="unlock" left /> Thema wieder öffnen
          </button>
        );
      } else {
        closeButton = (
          <button className="primary button" onClick={this.handle__closeThread}>
            <Icon icon="lock" left /> Thema schließen
          </button>
        );
      }

      let moveButton = (
        <button
          className="primary button"
          onClick={() => {
            this.setState({ showMovePopup: true });
          }}
        >
          <Icon icon="exchange" left /> Thema verschieben
        </button>
      );

      let deleteButton = (
        <button className="primary button" onClick={this.handle__deleteThread}>
          <Icon icon="times" left /> Thema löschen
        </button>
      );

      return (
        <React.Fragment>
          <div className="float-right">
            <div className="small button-group">
              {moveButton} {closeButton} {deleteButton}
            </div>
            <div className="selfmade-popup"></div>
          </div>
          <PTPopup
            show={this.state.showMovePopup}
            size="small"
            handleClose={() => {
              this.setState({
                showMovePopup: false
              });
            }}
          >
            <Grid type="full">
              <Row>
                <Cell sm={24}>
                  <div className="text-left">
                    <h2>Thema verschieben</h2>
                    <p>
                      In welches Board möchtest Du den Thread{" "}
                      <strong>{thread.name}</strong> verschieben?
                    </p>

                    <Grid type="full">
                      <Row>
                        <Cell sm={24} md={18} lg={18}>
                          <select
                            value={moveSelectedBoard}
                            onChange={event => {
                              this.handle__changeMoveBoard(event.target.value);
                            }}
                          >
                            <option value="">-- Bitte auswählen --</option>
                            {boards.map((board, index) => {
                              if (board.id === thread.board) {
                                return null;
                              }

                              return (
                                <option key={index} value={board.id}>
                                  {board.name}
                                </option>
                              );
                            })}
                          </select>
                        </Cell>
                        <Cell sm={24} md={6} lg={6}>
                          <button
                            className="primary button"
                            disabled={!moveSelectedBoard ? true : false}
                            style={{ width: "100%" }}
                            onClick={this.handle__moveThread}
                          >
                            Verschieben <Icon icon="angle-right" right />
                          </button>
                        </Cell>
                      </Row>
                    </Grid>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </PTPopup>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  sendAnswer = answerArea => {
    const { answers, thread } = this.state;

    const reply = answers[answerArea];

    // Check if main Answer or reply -> reply: get post id
    let postId = null;
    let answerInfo = answerArea.split("_");
    if (answerInfo[0] === "reply") {
      postId = answerInfo[1];
    }

    if (reply) {
      axios
        .post(
          this.props.context.apiEndpoints.forumPostNewReply,
          {
            thread_id: thread.id,
            post_id: postId,
            reply: reply
          },
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          const { posts, selectedTextarea } = response.data;

          // Create Answers Object with empty answers for every Post
          let { answers } = this.state;
          posts.map(post => {
            const key = "reply_" + post.id;
            answers[key] = "";
            return null;
          });
          answers["main_answer"] = "";

          answers[selectedTextarea] = "";

          this.setState({ posts, answers });

          /*let { posts, answers, selectedTextarea } = this.state;
          const newPost = response.data;

          const firstPost = posts[0];
          posts.splice(0, 1);

          answers[selectedTextarea] = "";

          this.setState({
            posts: [firstPost, newPost, ...posts],
            answers
          });*/

          this.scrollToNewestPost();
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  };

  handleAnswerChange = (event, answerArea) => {
    const answer = event.target.value;
    const lastPosition = event.target.selectionStart;
    const lastInputChar = event.target.value[event.target.selectionStart - 1];

    this.updateAnswer(answer, answerArea, lastPosition, lastInputChar);
  };

  updateAnswer = (answer, answerArea, lastPosition, lastInputChar) => {
    const { answers } = this.state;

    answers[answerArea] = answer;

    this.setState({
      answers,
      selectedTextarea: answerArea,
      selectedTextareaLastPosition: lastPosition
    });

    if (lastInputChar === "@") {
      this.showAutosuggestPopup();
      this.input.focus();
    }
  };

  handle__closeThread = () => {
    if (
      window.confirm(
        "Soll das Thema wirklich geschlossen werden? Antworten sind dann nicht mehr möglich, die bisher verfassten Beiträge können aber weiterhin von allen gelesen werden."
      )
    ) {
      const { thread } = this.state;

      axios
        .post(
          this.props.context.apiEndpoints.adminForumThreadClose +
            "/" +
            thread.id,
          {},
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          const { threadClosedStatus } = response.data;
          if (threadClosedStatus === true || threadClosedStatus === false) {
            thread.closed = threadClosedStatus;
            this.setState({ thread });
          }
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  };

  handle__openThread = () => {
    if (
      window.confirm(
        "Soll das Thema wirklich wieder geöffnet werden? Ab diesem Zeitpunkt sind Antworten wieder möglich."
      )
    ) {
      const { thread } = this.state;

      axios
        .post(
          this.props.context.apiEndpoints.adminForumThreadClose +
            "/" +
            thread.id,
          {},
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          const { threadClosedStatus } = response.data;
          if (threadClosedStatus === true || threadClosedStatus === false) {
            thread.closed = threadClosedStatus;
            this.setState({ thread });
          }
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  };

  handle__deleteThread = () => {
    if (
      window.confirm(
        "Soll das Thema wirklich gelöscht werden? Diese Aktion kann nicht rückgängig gemacht werden.\r\n\r\nFalls Du nur weitere Antworten verbieten möchtest, kannst Du den Thread (über den entsprechenden Button) auch einfach nur schließen."
      )
    ) {
      const { thread } = this.state;

      axios
        .delete(
          this.props.context.apiEndpoints.adminForumThreadDelete +
            "/" +
            thread.id,
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          const { threadBoardSlug } = response.data;
          if (threadBoardSlug) {
            window.location.href = "/community/" + threadBoardSlug;
          } else {
            window.location.href = "/community/";
          }
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  };

  handle__moveThread = () => {
    const { thread, moveSelectedBoard } = this.state;

    axios
      .patch(
        this.props.context.apiEndpoints.adminForumThreadMove +
          "/" +
          thread.id +
          "/" +
          moveSelectedBoard,
        {},
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const { threadBoardSlug } = response.data;
        if (threadBoardSlug) {
          window.location.href =
            "/community/" + threadBoardSlug + "/" + thread.identifier;
        } else {
          window.location.href = "/community/";
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  /**
   * BEGINN AUTO SUGGEST
   */

  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  showAutosuggestPopup() {
    const autosuggestPopup = document.getElementById("autosuggestPopup");
    autosuggestPopup.style.display = "block";
  }

  hideAutosuggestPopup() {
    const autosuggestPopup = document.getElementById("autosuggestPopup");
    autosuggestPopup.style.display = "none";
  }

  onKeyUp = event => {
    //console.log(event.key, this.state.value);
    const { value, selectedTextarea, answers } = this.state;
    if (event.key === "Escape" || (event.key === "Backspace" && value === "")) {
      this.hideAutosuggestPopup();
      const textareaElement = document.getElementById(selectedTextarea);
      textareaElement.focus();

      // Delete @
      const textareaCursorPosition = textareaElement.selectionStart;
      const textareaContent = answers[selectedTextarea];
      const firstPart = textareaContent.slice(0, textareaCursorPosition - 1);
      const secondPart = textareaContent.slice(textareaCursorPosition);
      const deletePart = textareaContent.slice(
        textareaCursorPosition - 1,
        textareaCursorPosition
      );
      /*console.log("first", firstPart);
      console.log("seconds", secondPart);
      console.log("delete", deletePart);*/
      if (deletePart === "@") {
        const newTextareaContent = firstPart + secondPart;
        answers[selectedTextarea] = newTextareaContent;
        this.setState({ answers });
      }
    }
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });

    if (method === "escape") {
      this.hideAutosuggestPopup();
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, method }) => {
    const {
      answers,
      selectedTextarea,
      selectedTextareaLastPosition
    } = this.state;

    const answer = answers[selectedTextarea];

    const newText =
      answer.substring(0, selectedTextareaLastPosition) +
      suggestion.username +
      " " +
      answer.substring(selectedTextareaLastPosition);

    answers[selectedTextarea] = newText;

    this.setState({ answers, value: "" });
    this.hideAutosuggestPopup();

    // Timeout to prevent adding new line into textarea
    setTimeout(() => {
      document.getElementById(selectedTextarea).focus();
    }, 50);
  };

  // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp("\\b" + escapedValue, "i");

    const { people } = this.state;

    return people.filter(person => regex.test(this.getSuggestionValue(person)));
  }

  getSuggestionValue = suggestion => {
    return `${suggestion.username}`;
  };

  renderSuggestion = (suggestion, { query }) => {
    //const suggestionText = `${suggestion.firstname} ${suggestion.lastname}`;
    const suggestionText = `${suggestion.username}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <span className={"suggestion-content " + suggestion.twitter}>
        <span className="name">
          @
          {parts.map((part, index) => {
            const className = part.highlight ? "highlight" : null;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
      </span>
    );
  };

  /**
   * ENDE AUTOSUGGEST
   */

  handle__changeMoveBoard(value) {
    let { moveSelectedBoard } = this.state;
    moveSelectedBoard = value;
    this.setState({ moveSelectedBoard });
  }

  scrollToNewestPost = () =>
    window.scrollTo(0, this.newestPostRef.current.offsetTop - 105);

  handleUpdatePosts = updatedPost => {
    // Post or Reply?
    if (updatedPost.reply_to) {
      const posts = JSON.parse(JSON.stringify(this.state.posts));
      let replies = [];

      posts.map(post => {
        if (post.id === updatedPost.reply_to) {
          replies = post.replies;
        }
        return null;
      });

      const updatedReplies = replies.map(reply => {
        if (reply.id === updatedPost.id) {
          reply.likes = updatedPost.likes;
          return reply;
        } else {
          return reply;
        }
      });

      const updatedPosts = posts.map(post => {
        if (post.id === updatedPost.reply_to) {
          post.replies = updatedReplies;
          return post;
        } else {
          return post;
        }
      });

      this.setState({ posts: updatedPosts });
    } else {
      const posts = JSON.parse(JSON.stringify(this.state.posts));
      const updatedPosts = posts.map(post => {
        if (post.id === updatedPost.id) {
          post.likes = updatedPost.likes;
          return post;
        } else {
          return post;
        }
      });

      this.setState({ posts: updatedPosts });
    }
  };

  loadPosts() {
    const { boardSlug, threadIdentifier } = this.state;

    axios
      .get(
        this.props.context.apiEndpoints.forumPosts +
          "/" +
          boardSlug +
          "/" +
          threadIdentifier,
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const { boards, board, thread, posts, people } = response.data;

        // Sort People by Username
        let peopleByUsername = {};
        people.map(user => {
          peopleByUsername[user.username] = user;
          return user;
        });

        // Create Answers Object with empty answers for every Post
        let { answers } = this.state;
        posts.map(post => {
          const key = "reply_" + post.id;
          answers[key] = "";
          return null;
        });
        answers["main_answer"] = "";

        this.setState({
          boards,
          board,
          thread,
          posts,
          people,
          peopleByUsername,
          answers
        });

        // If # hash is given, scroll there
        if (this.props.location.hash) {
          const hash = this.props.location.hash.replace("#", "");
          window.scrollTo(0, document.getElementById(hash).offsetTop - 125);
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(Thread);
