import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import Breadcrumbs from "../topbars/breadcrumbs";
import { Grid, Row, Cell, Button, Icon } from "../foundation/foundation";
import axios from "axios";
import withContext from "../../context/contextHOC";
import qs from "query-string";
import { isUndefined } from "util";
import Footer from "../layout/footer";

class ChangePassword extends Component {
  state = {
    showMsgSaved: false,
    showErrorEmpty: false,
    showErrorOldWrong: false,
    showErrorNewWrong: false,
    oldPassword: "",
    newPassword: "",
    newPassword2: ""
  };

  componentDidMount() {
    const queryParams = qs.parse(this.props.location.search);
    if (!isUndefined(queryParams.msg) && queryParams.msg === "saved") {
      this.setState({ showMsgSaved: true });
    } else {
      this.setState({ showMsgSaved: false });
    }
  }

  render() {
    return (
      <div
        id="pagePassword"
        className="darkmode page-with-topbar-and-breadcrumbs default-page"
      >
        <MainTopbar />
        <Breadcrumbs breadcrumbs={["dashboard", "myprofile", "password"]} />
        <section className="content">
          <Grid>
            <Row>
              <Cell sm={24} md={14}>
                <h2>Passwort ändern</h2>
                <p className="lead">
                  Hier kannst Du Dein Passwort ändern. Gib dazu einfach Dein
                  aktuelles Passwort und das anschließend das neue Passwort
                  zweimal ein.
                </p>
                {this.showMsgSaved()}
                {this.showErrorEmpty()}
                {this.showErrorOldWrong()}
                {this.showErrorNewWrong()}
              </Cell>
            </Row>
            <Row>
              <Cell sm={24} md={14}>
                <div className="box">
                  <div className="box-content">
                    <label>
                      Altes Passwort
                      <input
                        type="password"
                        placeholder="Dein altes Passwort"
                        value={this.state.oldPassword}
                        onChange={this.handleChange("oldPassword")}
                      />
                    </label>
                  </div>
                </div>

                <div className="box">
                  <div className="box-content">
                    <label>
                      Neues Passwort
                      <input
                        type="password"
                        placeholder="Dein neues Passwort"
                        value={this.state.newPassword}
                        onChange={this.handleChange("newPassword")}
                      />
                    </label>

                    <label>
                      Neues Passwort wiederholen
                      <input
                        type="password"
                        placeholder="Dein neues Passwort wiederholen"
                        value={this.state.newPassword2}
                        onChange={this.handleChange("newPassword2")}
                      />
                    </label>
                  </div>
                </div>
              </Cell>
            </Row>
            <Row>
              <Cell sm={24} md={14}>
                <div className="page-buttons">
                  <Button href="/dashboard" type="primary" hollow>
                    <Icon icon="times" left /> Abbrechen
                  </Button>
                  <Button
                    type="primary"
                    click={() => {
                      this.checkUserInput();
                    }}
                  >
                    <Icon icon="check" left /> Speichern
                  </Button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </section>
        <Footer />
      </div>
    );
  }

  showMsgSaved() {
    if (this.state.showMsgSaved === true) {
      return (
        <div className="success callout">
          <strong>Dein Passwort wurde geändert!</strong>
        </div>
      );
    }
  }

  showErrorEmpty() {
    if (this.state.showErrorEmpty === true) {
      return (
        <div className="alert callout">
          <strong>Bitte fülle alle Felder aus.</strong>
        </div>
      );
    }
  }

  showErrorOldWrong() {
    if (this.state.showErrorOldWrong === true) {
      return (
        <div className="alert callout">
          <strong>
            Das von Dir eingegebene alte Passwort ist nicht korrekt.
          </strong>
        </div>
      );
    }
  }

  showErrorNewWrong() {
    if (this.state.showErrorNewWrong === true) {
      return (
        <div className="alert callout">
          <strong>Die beiden neuen Passwörter stimmen nicht überein.</strong>
        </div>
      );
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  resetMessages(callback) {
    this.setState({
      showErrorEmpty: false,
      showErrorOldWrong: false,
      showErrorNewWrong: false,
      showMsgSaved: false
    });

    callback();
  }

  checkUserInput() {
    const { oldPassword, newPassword, newPassword2 } = this.state;

    if (!oldPassword || !newPassword || !newPassword2) {
      this.resetMessages(() => {
        this.setState({ showErrorEmpty: true });
      });

      return;
    }

    if (newPassword !== newPassword2) {
      this.resetMessages(() => {
        this.setState({ showErrorNewWrong: true });
      });
      return;
    }

    // If Old Password is correct will be checked in save()
    this.save();
  }

  save() {
    const { oldPassword, newPassword } = this.state;

    axios
      .patch(
        this.props.context.apiEndpoints.password,
        {
          oldPassword: oldPassword,
          newPassword: newPassword
        },
        { headers: this.props.context.headers }
      )
      .then(response => {
        if (response.data === "oldWrong") {
          this.resetMessages(() => {
            this.setState({ showErrorOldWrong: true });
          });
        } else if (response.data === "OK") {
          window.location = "/password?msg=saved";
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(ChangePassword);
