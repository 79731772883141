import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell } from "../../foundation/_grid";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import { Icon } from "../../foundation/_buttons";
import Switch from "react-switch";
import DatePicker from "react-datepicker";

class AdminUserPurchase extends Component {
  state = {
    userId: this.props.match.params.userId,
    purchaseId: this.props.match.params.purchaseId,
    purchase: {},
    courses: [],
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    const { purchaseId, purchase } = this.state;
    let output = "";
    if (purchaseId === "new") {
      output = this.view__newPurchase();
    } else if (purchase && purchase.course && purchase.owner_info) {
      output = this.view__showPurchase();
    } else {
      output = <p>Wird geladen...</p>;
    }

    return <AdminLayout active="users">{output}</AdminLayout>;
  }

  view__newPurchase() {
    const { purchase, courses } = this.state;

    if (!purchase || !purchase.owner_info) {
      return <p>Wird geladen...</p>;
    }

    const user = purchase.owner_info;

    return (
      <Grid>
        <Row>
          <Cell sm={24}>
            <div className="adminback-link">
              <a href={"/admin/users/" + user.id}>
                <Icon icon="angle-left" left /> Zurück zum Benutzerprofil
              </a>
            </div>
            <h1>Kauf hinzufügen</h1>
            <Grid type="full">
              <Row margin="x">
                <Cell sm={24} md={12}>
                  <div className="admin-box" id="adminBoxUserData">
                    <div className="admin-box-content">
                      <Grid type="full">
                        <Row>
                          <Cell sm={18} md={12}>
                            <h3>Käufer</h3>
                          </Cell>
                          <Cell sm={6} md={12} classes="text-right">
                            &nbsp;
                          </Cell>
                        </Row>
                      </Grid>
                      <Grid type="full">
                        <Row margin="x">
                          <Cell sm={24} md={12} lg={12}>
                            <div className="info-content">
                              {user.lastname || (
                                <span
                                  dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                                />
                              )}
                            </div>

                            <div className="info-caption">Nachname</div>
                          </Cell>
                          <Cell sm={24} md={12} lg={12}>
                            <div className="info-content">
                              {user.firstname || (
                                <span
                                  dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                                />
                              )}
                            </div>

                            <div className="info-caption">Vorname</div>
                          </Cell>
                        </Row>
                        <Row margin="x">
                          <Cell sm={24} md={12} lg={12}>
                            <div className="info-content">
                              {user.email || (
                                <span
                                  dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                                />
                              )}
                            </div>

                            <div className="info-caption">E-Mail</div>
                          </Cell>
                          <Cell sm={24} md={12} lg={12}>
                            &nbsp;
                          </Cell>
                        </Row>
                      </Grid>
                    </div>
                  </div>

                  <div className="admin-box" id="adminBoxUserData">
                    <div className="admin-box-content">
                      <Grid type="full">
                        <Row>
                          <Cell sm={18} md={12}>
                            <h3>Kurs</h3>
                          </Cell>
                          <Cell sm={6} md={12} classes="text-right">
                            &nbsp;
                          </Cell>
                        </Row>
                      </Grid>
                      <Grid type="full">
                        <Row margin="x">
                          <Cell sm={24} md={24} lg={24}>
                            <div className="info-content">
                              <select
                                style={{ marginBottom: 0 }}
                                onChange={(event) =>
                                  this.handle__editChange(event, "course")
                                }
                              >
                                <option key={9999} value={0}>
                                  --- bitte auswählen ---
                                </option>
                                {courses.map((course, index) => {
                                  return (
                                    <option key={index} value={course.id}>
                                      {course.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="info-caption">Kurs</div>
                          </Cell>
                        </Row>
                        {this.view__showCourseTypes()}
                        {this.view__showCourseCountdown()}
                      </Grid>
                    </div>
                  </div>

                  <div className="text-right">
                    <a
                      href={"/admin/users/" + user.id}
                      className="primary hollow button"
                    >
                      Abbrechen
                    </a>
                    <button
                      className="primary button"
                      onClick={this.handle__editSave}
                    >
                      Speichern
                    </button>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showPurchase() {
    const { purchase } = this.state;
    const user = purchase.owner_info;
    const course = purchase.course_info;
    return (
      <Grid>
        <Row>
          <Cell sm={24}>
            <div className="adminback-link">
              <a href={"/admin/users/" + user.id}>
                <Icon icon="angle-left" left /> Zurück zum Benutzerprofil
              </a>
            </div>
            <h1>Kauf: {course.name || ""}</h1>
          </Cell>
        </Row>
        <Row margin="x">
          <Cell sm={24} md={12} lg={12}>
            <div className="admin-box" id="adminBoxPurchaseData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Kaufinformationen</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <Switch
                        onChange={this.handle__activation}
                        checked={this.state.purchase.active}
                        className="react-switch"
                        height={16}
                        width={36}
                      />{" "}
                      {this.view__showIsActive()}
                    </Cell>
                  </Row>
                </Grid>
                <Grid type="full">
                  <Row
                    margin="x"
                    style={{
                      display: purchase.course === 4 ? "block" : "none",
                    }}
                  >
                    <Cell sm={24} md={24} lg={24}>
                      <div className="info-content">
                        {this.view__showPurchaseType()}
                      </div>
                      <div className="info-caption">Mitgliedschaft</div>
                    </Cell>
                  </Row>
                  <Row margin="x">
                    <Cell sm={24} md={12} lg={12}>
                      <div className="info-content">
                        {purchase.paymentDate || (
                          <span
                            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                          />
                        )}
                      </div>
                      <div className="info-caption">Kaufdatum</div>
                    </Cell>
                    <Cell sm={24} md={12} lg={12}>
                      <div className="info-content">
                        {purchase.code || (
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                "<small><em>Kein Code genutzt</em></small>",
                            }}
                          />
                        )}
                      </div>
                      <div className="info-caption">Eingesetzter Code</div>
                    </Cell>
                  </Row>
                  <div
                    style={{ display: purchase.countdown ? "block" : "none" }}
                  >
                    <Row margin="x">
                      <Cell sm={24} md={12} lg={12}>
                        <div className="info-content">
                          {purchase.countdownDate || (
                            <span
                              dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                            />
                          )}
                        </div>
                        <div className="info-caption">Countdown bis</div>
                      </Cell>
                      <Cell sm={24} md={12} lg={12}>
                        &nbsp;
                      </Cell>
                    </Row>
                  </div>
                </Grid>
              </div>
            </div>

            <div className="admin-box" id="adminBoxPurchaseData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={24} md={24}>
                      <h3>Digistore-Links</h3>
                    </Cell>
                  </Row>
                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div className="info-content">
                        {this.view__showLinkInvoice()}
                      </div>

                      <div className="info-caption">Link zur Rechnung</div>
                    </Cell>
                  </Row>
                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div className="info-content">
                        {this.view__showLinkReceipt()}
                      </div>

                      <div className="info-caption">
                        Link zur Bestellübersicht
                      </div>
                    </Cell>
                  </Row>
                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div className="info-content">
                        {this.view__showLinkRebillingStop()}
                      </div>

                      <div className="info-caption">Link zur Kündigung</div>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            </div>
          </Cell>
          <Cell sm={24} md={12} lg={12}>
            <div className="admin-box" id="adminBoxUserData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Kurs</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      {/*<a
                        href={"/admin/users/" + user.id}
                        title="Zum Benutzerprofil"
                      >
                        <Icon icon="user" />
                      </a>*/}
                    </Cell>
                  </Row>
                </Grid>
                <Grid type="full">
                  <Row margin="x">
                    <Cell sm={24} md={6} lg={6}>
                      <img src={course.image_purchased} alt="Kurs" />
                    </Cell>
                    <Cell sm={24} md={16} lg={16}>
                      <p>
                        <strong>{course.name}</strong>
                      </p>
                      <div
                        dangerouslySetInnerHTML={{ __html: course.description }}
                      />
                    </Cell>
                  </Row>
                </Grid>
              </div>
            </div>

            <div className="admin-box" id="adminBoxUserData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Käufer</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <a
                        href={"/admin/users/" + user.id}
                        title="Zum Benutzerprofil"
                      >
                        <Icon icon="user" />
                      </a>
                    </Cell>
                  </Row>
                </Grid>
                <Grid type="full">
                  <Row margin="x">
                    <Cell sm={24} md={12} lg={12}>
                      <div className="info-content">
                        {user.lastname || (
                          <span
                            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                          />
                        )}
                      </div>

                      <div className="info-caption">Nachname</div>
                    </Cell>
                    <Cell sm={24} md={12} lg={12}>
                      <div className="info-content">
                        {user.firstname || (
                          <span
                            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                          />
                        )}
                      </div>

                      <div className="info-caption">Vorname</div>
                    </Cell>
                  </Row>
                  <Row margin="x">
                    <Cell sm={24} md={12} lg={12}>
                      <div className="info-content">
                        {user.email || (
                          <span
                            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                          />
                        )}
                      </div>

                      <div className="info-caption">E-Mail</div>
                    </Cell>
                    <Cell sm={24} md={12} lg={12}>
                      &nbsp;
                    </Cell>
                  </Row>
                </Grid>
              </div>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showCourseTypes() {
    const { purchase } = this.state;
    const { purchaseTypes } = this.props.context;

    // SELFMADE
    if (purchase.course === 4) {
      return (
        <Row margin="x">
          <Cell sm={24} md={24} lg={24}>
            <div className="info-content">
              <select
                style={{ marginBottom: 0 }}
                onChange={(event) => this.handle__editChange(event, "type")}
              >
                <option value={""}>&nbsp;</option>
                {Object.keys(purchaseTypes).map(function (type, index) {
                  return (
                    <option value={type} key={index}>
                      {purchaseTypes[type]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="info-caption">Typ (falls zutreffend)</div>
            <div
              className="small warning callout"
              style={{ marginTop: "1rem", fontSize: "0.9rem" }}
            >
              <strong>Achtung:</strong> Die Auswahl des Typs hat keinerlei
              Auswirkungen auf Digistore. Damit werden keinerlei Zahlungen
              ausgelöst oder abgebrochen. Das muss immer direkt über Digistore
              laufen.
            </div>
          </Cell>
        </Row>
      );
    }
  }

  view__showCourseCountdown() {
    const { purchase, courses } = this.state;
    const { purchaseTypes } = this.props.context;

    let countdownable = false;
    courses.map((course) => {
      if (
        parseInt(course.id) === parseInt(purchase.course) &&
        course.countdownable === true
      ) {
        countdownable = true;
      }
      return null;
    });

    if (countdownable === true) {
      return (
        <Row margin="x">
          <Cell sm={24} md={24} lg={24}>
            <div className="info-content">
              <DatePicker
                selected={purchase.countdown || new Date()}
                onChange={(value) => {
                  this.handle__editCountdownDate(value);
                }}
                dateFormat="dd.MM.yyyy - HH:mm"
                locale="de"
                showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                timeCaption="Uhrzeit"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
            <div className="info-caption">Countdown bis</div>
            <div
              className="small warning callout"
              style={{ marginTop: "1rem", fontSize: "0.9rem" }}
            >
              Für diesen Kurs wurde der individuelle Countdown aktiviert. Hier
              kannst Du einstellen, ab welchem Zeitpunkt die zusätzlichen
              Lektionen angezeigt werden sollen.
            </div>
          </Cell>
        </Row>
      );
    }
  }

  view__showPurchaseType() {
    const { purchase } = this.state;

    if (purchase) {
      if (purchase.type) {
        const { purchaseTypes } = this.props.context;
        let purchaseType = <em>unbekannt</em>;
        if (purchaseTypes[purchase.type]) {
          purchaseType = <span>{purchaseTypes[purchase.type]}</span>;
        }
        return <React.Fragment>{purchaseType}</React.Fragment>;
      }
    }
    return null;
  }

  view__showIsActive() {
    const { purchase } = this.state;

    if (purchase.active === true) {
      return (
        <small className="purchase-active next-to-switch">&nbsp;aktiv</small>
      );
    } else {
      return (
        <small className="purchase-inactive next-to-switch">
          &nbsp;inaktiv
        </small>
      );
    }
  }

  view__showLinkInvoice() {
    const { purchase } = this.state;

    if (
      purchase &&
      purchase.lastTransaction &&
      purchase.lastTransaction.invoice_url
    ) {
      return (
        <a
          href={purchase.lastTransaction.invoice_url}
          target="_blank"
          rel="noreferrer noopener"
        >
          <small>{purchase.lastTransaction.invoice_url}</small>
        </a>
      );
    } else {
      return (
        <span>
          <small>unbekannt</small>
        </span>
      );
    }
  }

  view__showLinkReceipt() {
    const { purchase } = this.state;

    if (
      purchase &&
      purchase.lastTransaction &&
      purchase.lastTransaction.receipt_url
    ) {
      return (
        <a
          href={purchase.lastTransaction.receipt_url}
          target="_blank"
          rel="noreferrer noopener"
        >
          <small>{purchase.lastTransaction.receipt_url}</small>
        </a>
      );
    } else {
      return (
        <span>
          <small>unbekannt</small>
        </span>
      );
    }
  }

  view__showLinkRebillingStop() {
    const { purchase } = this.state;

    if (
      purchase &&
      purchase.lastTransaction &&
      purchase.lastTransaction.rebilling_stop_url
    ) {
      return (
        <a
          href={purchase.lastTransaction.rebilling_stop_url}
          target="_blank"
          rel="noreferrer noopener"
        >
          <small>{purchase.lastTransaction.rebilling_stop_url}</small>
        </a>
      );
    } else {
      return (
        <span>
          <small>unbekannt</small>
        </span>
      );
    }
  }

  handle__editCountdownDate(value) {
    const { purchase } = this.state;
    purchase.countdown = new Date(value);
    this.setState({ purchase });
  }

  handle__editChange(event, property) {
    const { purchase } = this.state;
    if (property === "course") {
      purchase[property] = parseInt(event.target.value);
      purchase.code = "";
    } else {
      purchase[property] = event.target.value;
    }
    this.setState({ purchase });
  }

  handle__editSave = () => {
    const { userId, purchase } = this.state;

    if (!purchase.course) {
      window.alert("Bitte wähle einen Kurs aus.");
      return false;
    }

    if (purchase.countdown) {
      purchase.countdown = new Date(purchase.countdown);

      const countdownDate =
        ("0" + purchase.countdown.getDate()).slice(-2) +
        "." +
        ("0" + (purchase.countdown.getMonth() + 1)).slice(-2) +
        "." +
        purchase.countdown.getFullYear() +
        " - " +
        ("0" + purchase.countdown.getHours()).slice(-2) +
        ":" +
        ("0" + purchase.countdown.getMinutes()).slice(-2);

      purchase.countdown = countdownDate;
    }

    axios
      .post(
        this.props.context.apiEndpoints.adminUserPurchase,
        { purchase: purchase },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        if (response.data === "OK") {
          window.location = "/admin/users/" + userId;
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handle__activation = () => {
    const { purchase } = this.state;

    document
      .getElementById("adminBoxPurchaseData")
      .classList.remove("background-saved");

    axios
      .post(
        this.props.context.apiEndpoints.adminUserPurchaseActivation +
          "/" +
          purchase.id,
        {},
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        const { purchase } = response.data;
        this.setState({ purchase });
        document
          .getElementById("adminBoxPurchaseData")
          .classList.add("background-saved");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  /**
   * Init
   * Load Purchase Info
   */
  init__load() {
    const { userId, purchaseId } = this.state;

    if (purchaseId) {
      let url =
        this.props.context.apiEndpoints.adminUserPurchase + "/" + purchaseId;
      if (purchaseId === "new") {
        url =
          this.props.context.apiEndpoints.adminUserPurchase + "/new/" + userId;
      }

      axios
        .get(url, {
          headers: this.props.context.headers,
        })
        .then((response) => {
          const { purchase, courses } = response.data;
          this.setState({ purchase, courses });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }
}

export default withContext(AdminUserPurchase);
