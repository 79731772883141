import React, { Component } from "react";
import { Icon } from "../../foundation/foundation";
import withContext from "../../../context/contextHOC";
import { isUndefined, isNull } from "util";
import selfLogo from "../../../images/self.png";

class QuoteOfTheDay extends Component {
  state = {
    quote: this.props.quote,
    backgroundImageUrl: this.props.backgroundImageUrl
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.quote !== this.props.quote) {
      this.setState({ quote: this.props.quote });
    }

    if (prevProps.backgroundImageUrl !== this.props.backgroundImageUrl) {
      this.setState({ backgroundImageUrl: this.props.backgroundImageUrl });
    }
  }

  render() {
    const { backgroundImageUrl } = this.state;
    return (
      <div
        id="selfmadeGridQuote"
        className="selfmade-grid-bg-blur"
        style={{
          backgroundImage: `url('${backgroundImageUrl}')`
        }}
      >
        <div className="selfmade-grid-item selfmade-grid-bg-dark">
          <div className="selfmade-grid-item-content grid-caption">
            {this.show__qotd()}
          </div>
          {this.show__selfLogo()}
        </div>
      </div>
    );
  }

  /**
   * Show Quote of the day
   */
  show__qotd() {
    const { quote } = this.state;

    if (!isUndefined(quote) && !isNull(quote) && quote.quote) {
      let author = "";
      if (quote.author) {
        author = <div className="quote-author">{quote.author}</div>;
      }
      return (
        <div>
          <div className="quote-quote">{quote.quote}</div>
          {author}
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <Icon icon="circle-o-notch fa-spin" />
        </div>
      );
    }
  }

  show__selfLogo() {
    return (
      <div className="selfmade-qotd-logo-self">
        <img src={selfLogo} alt="" />
      </div>
    );
  }
}

export default withContext(QuoteOfTheDay);
