import React, { Component } from "react";
import { isUndefined } from "util";

class AdminBigNumber extends Component {
  state = { number: this.props.number };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.number !== this.props.number) {
      this.setState({ number: this.props.number });
    }
  }

  render() {
    const { number } = this.state;

    if (!isUndefined(number) && !isUndefined(number.number)) {
      return (
        <div className="admin-big-number">
          <div className="admin-big-number-number">{number.number}</div>
          <div className="admin-big-number-caption">{number.caption || ""}</div>
        </div>
      );
    } else {
      return <div className="text-center">...</div>;
    }
  }
}

export default AdminBigNumber;
