import React, { Component } from "react";
import { isUndefined } from "util";
import { Icon } from "../foundation/_buttons";

class PTTabs extends Component {
  state = { tabs: this.props.tabs };

  render() {
    const { tabs } = this.state;
    return (
      <div className="pttabs">
        <div className="pttabs-inner">
          {Object.keys(tabs).map((keyName, index) => {
            return (
              <div
                className={this.isSelectedClasses(tabs[keyName])}
                key={index}
                onClick={() => {
                  this.select(keyName);
                }}
              >
                {this.icon(tabs[keyName])}
                {tabs[keyName].name}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  isSelectedClasses(tab) {
    if (tab.selected === true) {
      return "pttab selected";
    } else {
      return "pttab";
    }
  }

  icon(tab) {
    if (!isUndefined(tab.icon) && tab.icon) {
      return (
        <span>
          <Icon icon={tab.icon} left />
          &nbsp;
        </span>
      );
    } else {
      return null;
    }
  }

  select = selectedKeyName => {
    const { tabs } = this.state;

    Object.keys(tabs).map(keyName => {
      if (keyName === selectedKeyName) {
        return (tabs[keyName].selected = true);
      } else {
        return (tabs[keyName].selected = false);
      }
    });

    this.setState({ tabs });

    this.props.onUpdate(tabs);
  };
}

export default PTTabs;
