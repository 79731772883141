import React, { Component } from "react";
import PTPopup from "../ptPopup/ptPopup";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import { isUndefined, isNull } from "util";
import withContext from "../../context/contextHOC";
import Switch from "react-switch";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);

class Task extends Component {
  state = {
    task: { name: "", due: new Date() },
    editMode: false
  };

  componentDidMount() {
    let task = JSON.parse(JSON.stringify(this.props.task));
    task = this.init__dueDate(task);

    if (this.props.selectedList) {
      task.todolist = this.props.selectedList;
    }

    if (task.id === "new") {
      this.setState({ task, editMode: true });
    } else {
      this.setState({ task });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.task !== this.props.task) {
      let task = JSON.parse(JSON.stringify(this.props.task));
      task = this.init__dueDate(task);

      if (this.props.selectedList) {
        task.todolist = this.props.selectedList;
      }

      this.setState({ task });
    }

    if (prevProps.selectedList !== this.props.selectedList) {
      const { task } = this.state;
      task.todolist = this.props.selectedList;
      this.setState({ task });
    }
  }

  render() {
    const { task } = this.state;
    return (
      <div className="selfmade-popup">
        <PTPopup
          show={this.props.show}
          size="medium"
          handleClose={() => {
            this.props.onClose();
          }}
        >
          <Grid type="full">
            <Row>
              <Cell sm={24}>
                <h2>{task.name || "Neue Aufgabe"}</h2>
                {this.show__popup_content()}
              </Cell>
            </Row>
          </Grid>
        </PTPopup>
      </div>
    );
  }

  /**
   * Show task or show edit form
   */
  show__popup_content() {
    const { editMode } = this.state;

    if (editMode === true) {
      return this.show__edit_task();
    } else {
      return this.show__task();
    }
  }

  /**
   * Show Task Information
   */
  show__task() {
    const { task } = this.state;
    if (!isUndefined(task) && task.id) {
      let taskFinishClass = "";
      if (this.check__isTaskFinished(task) === true) {
        taskFinishClass = "task-finished";
      }

      return (
        <React.Fragment>
          <div className="task-popup-infoline">
            <Grid type="full">
              <Row>
                <Cell sm={24} md={8}>
                  &nbsp;
                  <div
                    className="todo-list-color"
                    style={{ backgroundColor: task.todolist.color }}
                  />{" "}
                  <strong>{task.todolist.name}</strong>
                </Cell>
                <Cell sm={24} md={8} classes="text-center">
                  <div className={taskFinishClass}>{this.show__task_due()}</div>
                </Cell>
                <Cell sm={24} md={8} classes="text-right">
                  <button
                    className="primary button icon-button button-no-margin"
                    title="Aufgabe löschen"
                    onClick={this.handle__taskDelete}
                  >
                    <Icon icon="trash" />
                  </button>
                  <button
                    className="primary button icon-button button-no-margin"
                    title="Aufgabe bearbeiten"
                    onClick={() => {
                      this.setState({ editMode: true });
                    }}
                  >
                    <Icon icon="cog" />
                  </button>
                </Cell>
              </Row>
            </Grid>
          </div>
          <div className="task-popup-finish-switch">
            <Grid type="full">
              <Row>
                <Cell sm={3} smo={4}>
                  <strong>Erledigt:</strong>
                </Cell>
                <Cell sm={3} classes="text-right">
                  <Switch
                    onChange={this.handle__taskFinishedSwitch}
                    checked={this.check__isTaskFinished()}
                    className="react-switch"
                  />
                </Cell>
              </Row>
            </Grid>
          </div>
          <div className="task-popup-text">{this.show__task_text()}</div>
        </React.Fragment>
      );
    } else {
      return <p className="text-center">Keine Aufgabe ausgewählt.</p>;
    }
  }

  /**
   * Show task due
   */
  show__task_due() {
    const { task } = this.state;
    if (task.due_date) {
      let dueClass = "task-due-" + task.due_status;
      return (
        <React.Fragment>
          <Icon icon="clock-o" left />
          <strong className={dueClass}>
            <span>{task.due_date}</span>
          </strong>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  /**
   * Show Task Text if exists
   */
  show__task_text() {
    const { task } = this.state;

    if (task.text) {
      return <div>{task.text}</div>;
    } else {
      return (
        <div className="text-center">
          <em>Keine weiteren Notizen vorhanden.</em>
        </div>
      );
    }
  }

  /**
   * Show Edit Form
   */
  show__edit_task() {
    const { task } = this.state;
    return (
      <div className="task-popup-edit-form">
        <label>
          Aufgabe:
          <input
            type="text"
            value={task.name || ""}
            onChange={event => {
              this.handle__taskEditChange(event, "name");
            }}
          />
        </label>
        <label>
          Fälligkeit:
          <br />
          <DatePicker
            selected={task.due}
            onChange={this.handle__taskEditChangeDue}
            dateFormat="dd.MM.yyyy"
            locale="de"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </label>
        <label>
          Beschreibung:
          <textarea
            rows={6}
            value={task.text || ""}
            onChange={event => {
              this.handle__taskEditChange(event, "text");
            }}
          />
        </label>
        <div className="ptpopup-buttons">
          <button
            className="primary hollow button"
            onClick={this.handle__taskEditCancel}
          >
            <Icon icon="times" left /> Abbrechen
          </button>
          <button
            className="primary button"
            onClick={this.handle__taskEditSave}
          >
            <Icon icon="check" left /> Speichern
          </button>
        </div>
      </div>
    );
  }

  /**
   * Handle Finish Switch
   */
  handle__taskFinishedSwitch = () => {
    const { task } = this.state;

    if (isNull(task.finished)) {
      task.finished = true;
    } else {
      task.finished = null;
    }

    this.setState({ task });

    let type = "";
    if (this.props.type) {
      type = this.props.type;
    } else {
      type = "todo";
    }

    // Update in Database
    axios
      .patch(
        this.props.context.apiEndpoints.todoTaskFinish,
        {
          task_id: task.id,
          type: type
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const { task } = response.data;

        if (type === "selfmade") {
          this.props.onUpdateSelfmade(response.data);
        } else {
          this.props.onUpdate(task);
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  /**
   * Handle Changes in Inputs and Textareas
   */
  handle__taskEditChange(event, property) {
    const { task } = this.state;

    task[property] = event.target.value;

    this.setState({ task });
  }

  /**
   * Handle Due Changes
   */
  handle__taskEditChangeDue = date => {
    const { task } = this.state;
    task.due = date;
    this.setState({
      task
    });
  };

  /**
   * Handle Task Edit Cancel Button
   */
  handle__taskEditCancel = () => {
    // Check For Changes
    // If Changes have been detected -> warn user
    // If not -> Cancel
    const originalTask = JSON.parse(JSON.stringify(this.props.task));
    const updatedTask = JSON.parse(JSON.stringify(this.state.task));

    let originalTaskDue = "";

    if (originalTask.due) {
      originalTask.due = new Date(originalTask.due);
      originalTaskDue =
        ("0" + originalTask.due.getDate()).slice(-2) +
        "." +
        ("0" + (originalTask.due.getMonth() + 1)).slice(-2) +
        "." +
        originalTask.due.getFullYear();
    }

    let updatedTaskDue = "";

    if (updatedTask.due) {
      updatedTask.due = new Date(updatedTask.due);

      updatedTaskDue =
        ("0" + updatedTask.due.getDate()).slice(-2) +
        "." +
        ("0" + (updatedTask.due.getMonth() + 1)).slice(-2) +
        "." +
        updatedTask.due.getFullYear();
    }

    if (
      originalTask.name !== updatedTask.name ||
      originalTask.text !== updatedTask.text ||
      originalTaskDue !== updatedTaskDue
    ) {
      if (
        window.confirm(
          "Deine Änderungen gehen unwiderruflich verloren, wenn Du ohne Speichern abbrichst. Möchtest Du abbrechen?"
        )
      ) {
        this.reset__task();
        if (updatedTask.id === "new") {
          this.props.onClose();
        } else {
          this.setState({ editMode: false });
        }
      } else {
        // Do nothing
      }
    } else {
      this.reset__task();
      if (updatedTask.id === "new") {
        this.props.onClose();
      } else {
        this.setState({ editMode: false });
      }
    }
  };

  handle__taskEditSave = () => {
    const { task } = this.state;

    if (task.due) {
      const taskDue =
        ("0" + task.due.getDate()).slice(-2) +
        "." +
        ("0" + (task.due.getMonth() + 1)).slice(-2) +
        "." +
        task.due.getFullYear();

      task.due = taskDue;
    }

    const taskId = task.id;

    axios
      .post(
        this.props.context.apiEndpoints.todoTaskSave,
        {
          task_id: task.id,
          task: task,
          list: task.todolist.id
        },
        { headers: this.props.context.headers }
      )
      .then(response => {
        const { task } = response.data;

        if (taskId === "new") {
          window.location.reload();
        } else {
          this.props.onUpdate(task);
          this.setState({ editMode: false });
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  };

  handle__taskDelete = () => {
    if (
      window.confirm("Möchtest Du die Aufgabe wirklich unwiderruflich löschen?")
    ) {
      const { task } = this.state;

      let type = "";
      if (this.props.type) {
        type = this.props.type;
      } else {
        type = "todo";
      }

      axios
        .delete(
          this.props.context.apiEndpoints.todoTaskDelete +
            "/" +
            task.id +
            "/" +
            type,
          { headers: this.props.context.headers }
        )
        .then(response => {
          if (type === "selfmade") {
            this.props.onUpdateSelfmade(response.data);
          } else {
            const { lists } = response.data;
            this.props.onListsUpdate(lists);
          }
        })
        .catch(error => {
          console.log("ERROR", error);
        });
    } else {
      // Do nothing
    }
  };

  /**
   * Reset Task after Cancelling
   */
  reset__task() {
    let task = JSON.parse(JSON.stringify(this.props.task));
    task = this.init__dueDate(task);
    this.setState({ task });
  }

  /**
   * Check if Task is already finished
   */
  check__isTaskFinished() {
    const { task } = this.state;

    if (isNull(task.finished)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Convert Due Date String into JS Date Object
   */
  init__dueDate(task) {
    if (task.due) {
      task.due = new Date(task.due);
    }

    return task;
  }
}

export default withContext(Task);
