import React, { Component } from "react";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { Link } from "react-router-dom";
import { isUndefined } from "util";
import AnnouncementBox from "../selfmade/AnnouncementBox";

class Courses extends Component {
  state = {
    purchasedCourses: undefined,
    availableCourses: [],
  };

  componentDidMount() {
    this.loadCourses();
  }

  render() {
    if (this.props.page === "index") {
      return <React.Fragment>{this.showIndexCourses()}</React.Fragment>;
    } else {
      return <React.Fragment>{this.showCourseOverview()}</React.Fragment>;
    }
  }

  showIndexCourses() {
    return (
      <Grid>
        <Row>
          <Cell sm={24}>{this.showAvailableCourses()}</Cell>
        </Row>
      </Grid>
    );
  }

  showCourseOverview() {
    return (
      <React.Fragment>
        <div className="bg-caro">
          <Grid type="full">
            <Row>
              <Cell sm={24} md={24} lg={24}>
                <Grid>
                  <Row>
                    <Cell sm={24} md={24} lg={24}>
                      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <AnnouncementBox type="selfmade_relaunch_august_2019" />
                      </div>
                      <section className="content">
                        <h2>Deine Kurse</h2>
                        <p className="lead">
                          Alle Kurse, die Du bereits freigeschaltet hast,
                          findest Du hier.
                        </p>
                        <div>&nbsp;</div>

                        {this.showPurchasedCourses()}
                      </section>
                    </Cell>
                  </Row>
                </Grid>
              </Cell>
            </Row>
          </Grid>
        </div>
        {/*<Grid type="full">
          <Row>
            <Cell sm={24} md={24} lg={24}>
              <section className="content bg-black">
                <Grid>
                  <Row>
                    <Cell sm={24} md={24} lg={24}>
                      <h2>Verfügbare Kurse</h2>
                      <p className="lead">
                        Nachfolgend findest Du alle Kurse, die Du bisher noch
                        nicht freigeschaltet hast. Klicke auf den entsprechenden
                        Kurs für weitere Details.
                      </p>
                      <div>&nbsp;</div>
                      {this.showAvailableCourses()}
                    </Cell>
                  </Row>
                </Grid>
              </section>
            </Cell>
          </Row>
    </Grid>*/}
      </React.Fragment>
    );
  }

  showPurchasedCourses() {
    if (isUndefined(this.state.purchasedCourses)) {
      return (
        <p className="text-center" style={{ marginTop: "2rem" }}>
          Kurse werden geladen...
        </p>
      );
    } else if (this.state.purchasedCourses.length === 0) {
      return (
        <p className="text-center" style={{ marginTop: "2rem" }}>
          <strong>Bisher hast Du noch keine Kurs freigeschaltet.</strong>
        </p>
      );
    } else {
      return (
        <Grid>
          <Row margin="xy">
            {this.state.purchasedCourses.map((course) => {
              return this.showPurchasedCourse(course);
            })}
          </Row>
        </Grid>
      );
    }
  }

  showAvailableCourses() {
    if (isUndefined(this.state.availableCourses)) {
      return <p className="text-center">Kurse werden geladen...</p>;
    } else if (this.state.availableCourses.length === 0) {
      return (
        <p className="text-center" style={{ marginTop: "2rem" }}>
          <strong>
            Herzlichen Glückwunsch! Du hast alle verfügbaren Kurse bereits
            freigeschaltet!
          </strong>
        </p>
      );
    } else {
      return (
        <Grid>
          <Row margin="xy">
            {this.state.availableCourses.map((course) => {
              return this.showAvailableCourse(course);
            })}
          </Row>
        </Grid>
      );
    }
  }

  showPurchasedCourse(course) {
    const link = this.courseUrl(course, true);

    let outputLink = "";
    if (link.external === true) {
      outputLink = (
        <a
          className="primary button"
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Zum Kurs <Icon icon="angle-right" right />
        </a>
      );
    } else {
      outputLink = (
        <Link className="primary button" to={link.url}>
          Zum Kurs <Icon icon="angle-right" right />
        </Link>
      );
    }

    return (
      <Cell sm={24} md={8} lg={8} key={course.id}>
        <div className="course">
          <div className="course-image">
            {this.showCourseImage(course, true)}
          </div>
          <h3 className="course-name">{course.name}</h3>
          <div
            className="course-description"
            dangerouslySetInnerHTML={{ __html: course.description }}
          />
          <div className="course-button purchased-course-button">
            {outputLink}
          </div>
        </div>
      </Cell>
    );
  }

  showAvailableCourse(course) {
    const link = this.courseUrl(course, false);

    let outputLink = "";
    if (link.external === true) {
      outputLink = (
        <a
          className="primary button"
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Jetzt freischalten <Icon icon="angle-right" right />
        </a>
      );
    } else {
      outputLink = (
        <Link className="primary button" to={link.url}>
          Jetzt freischalten <Icon icon="angle-right" right />
        </Link>
      );
    }

    return (
      <Cell sm={24} md={8} lg={8} key={course.id}>
        <div className="course">
          <div className="course-image">
            {this.showCourseImage(course, false)}
          </div>
          <h3 className="course-name">{course.name}</h3>
          <div
            className="course-description"
            dangerouslySetInnerHTML={{ __html: course.description }}
          />
          <div className="course-price">{this.showCoursePrice(course)}</div>
          <div className="course-button">{outputLink}</div>
        </div>
      </Cell>
    );
  }

  showCourseImage(course, purchased) {
    const link = this.courseUrl(course, purchased);

    if (course.image) {
      if (link.external === true) {
        return (
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            <img src={course.image} alt={course.name} />
          </a>
        );
      } else {
        return (
          <Link to={link.url}>
            <img src={course.image} alt={course.name} />
          </Link>
        );
      }
    } else {
      if (link.external === true) {
        return (
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            <p className="text-center">
              <span>[Kein Bild]</span>
            </p>
          </a>
        );
      } else {
        return (
          <Link to={link.url}>
            <p className="text-center">
              <span>[Kein Bild]</span>
            </p>
          </Link>
        );
      }
    }
  }

  courseUrl(course, purchased) {
    let url = "";
    let external = false;

    if (
      purchased === true ||
      !course.digistore_product_ids ||
      course.digistore_product_ids.length === 0
    ) {
      url = "/kurse/" + course.identifier;
      external = false;
    } else {
      let digistoreId = course.digistore_product_ids[0];
      url = "https://www.digistore24.com/product/" + digistoreId;
      external = true;
    }

    return { url: url, external: external };
  }

  showCoursePrice(course) {
    if (course.usual_price != null) {
      return (
        <React.Fragment>
          <div className="usual-price">
            <em>statt {course.usual_price}&nbsp;&euro;</em>
          </div>
          <div className="actual-price">{course.actual_price}&nbsp;&euro;</div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="usual-price not-defined">&nbsp;</div>
          <div className="actual-price">{course.actual_price}&nbsp;&euro;</div>
        </React.Fragment>
      );
    }
  }

  loadCourses() {
    if (this.props.page === "index") {
      axios
        .get(this.props.context.apiEndpoints.coursesIndex)
        .then((response) => {
          let availableCourses = response.data.availableCourses;
          this.setState({
            availableCourses: availableCourses,
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else {
      axios
        .get(this.props.context.apiEndpoints.courses, {
          headers: this.props.context.headers,
        })
        .then((response) => {
          let purchasedCourses = response.data.purchasedCourses;
          let availableCourses = response.data.availableCourses;
          this.setState({
            purchasedCourses: purchasedCourses,
            availableCourses: availableCourses,
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }
}

export default withContext(Courses);
