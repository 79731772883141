import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";

import PTPopup from "../../ptPopup/ptPopup";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";

registerLocale("de", de);

class AdminLive extends Component {
  state = {
    event: {},
    originalEvent: {},
    eventId: this.props.match.params.eventId,
    editMode: false,
    types: {
      vimeo: "Vimeo"
    },
    courses: [
      {
        identifier: "238554",
        name: "SELFMADE"
      }
    ],
    popupImageUpload: false,
    popupImageCrop: false,
    cropUrl: "",
    cropImage: "",
    crop: {
      x: 0,
      y: 0,
      width: 800,
      height: 450,
      aspect: 800 / 450
    }
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { originalEvent: event } = this.state;

    return (
      <AdminLayout active="live">
        <Grid>
          <Row>
            <Cell sm={24} md={12}>
              <p>
                <a href="/admin/live">
                  <small>
                    <Icon icon="angle-double-left" left /> Zurück zur Übersicht
                  </small>
                </a>
              </p>

              <strong>Live Event</strong>
              <h1>{event.name || "Neues Live Event"}</h1>
            </Cell>
            <Cell sm={24} md={12} classes="text-right">
              <div style={this.view__hideWhenNew()}>
                <button
                  className="small primary button"
                  onClick={this.handle__delete}
                >
                  <Icon icon="trash" left /> Live Event löschen
                </button>
              </div>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24}>{this.view__showLiveEvent()}</Cell>
          </Row>
          <Row>
            <Cell sm={24}>
              <div style={this.view__showWhenNew()}>
                <div style={this.view__visible("edit")} className="text-right">
                  <a href="/admin/live" className="primary hollow button">
                    Abbrechen
                  </a>
                  <button
                    onClick={this.handle__editSave}
                    className="primary button"
                  >
                    Speichern <Icon icon="angle-right" />
                  </button>
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>

        <PTPopup
          show={this.state.popupImageUpload}
          size="medium"
          nopadding
          handleClose={() => {
            this.hidePopupImageUpload();
          }}
        >
          <Dropzone
            onDrop={acceptedFiles => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function() {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();
                  this.showPopupImageCrop();
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div id="avatarDropZone">
                    <h2>Vorschaubild hochladen</h2>
                    <div id="avatarDropZoneText">
                      <p>
                        <Icon icon="upload" />
                      </p>
                      <p>
                        Lege in diesem Popup eine Bilddatei (JPG, GIF, PNG) ab
                        oder
                        <br />
                        klicke hier, um ein Vorschaubild hochzuladen.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </PTPopup>

        <PTPopup
          show={this.state.popupImageCrop}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h2>Bildausschnitt wählen</h2>
          </div>

          <ReactCrop
            src={this.state.cropUrl}
            crop={this.state.crop}
            onChange={this.onCrop}
          />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="avatar-crop-buttons">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> Abbrechen
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadAvatar}
                  >
                    <Icon icon="check" left /> Speichern
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </PTPopup>
      </AdminLayout>
    );
  }

  view__showLiveEvent() {
    const { event } = this.state;

    if (!event) {
      return null;
    }

    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={12} lg={12}>
            {this.view__leftColumn()}
          </Cell>
          <Cell sm={24} md={12} lg={12}>
            {this.view__rightColumn()}
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__rightColumn() {
    const { event } = this.state;

    let imageUrl = "/images/no-image.jpg";
    if (event.image) {
      imageUrl = event.image;
    }

    return (
      <React.Fragment>
        <div className="admin-box adminBoxData" style={{ marginBottom: "0px" }}>
          <Row>
            <Cell sm={24}>
              <div className="admin-liveevent-picture">
                <img src={imageUrl} alt="" />
              </div>
            </Cell>
          </Row>
        </div>

        <div className="admin-box adminBoxData">
          <div className="admin-box-content">
            <Grid type="full">
              <Row>
                <Cell sm={24} md={12}>
                  <h3 style={{ marginBottom: 0 }}>Vorschaubild</h3>
                  <p>
                    <small>Empfohlene Maße: 800 x 450 Pixel</small>
                  </p>
                </Cell>
                <Cell sm={24} md={12} className="text-right">
                  <div
                    style={{
                      display: event.image ? "block" : "none"
                    }}
                  >
                    <button
                      className="profile-delete-avatar float-right"
                      onClick={this.handle__deleteImage}
                    >
                      Vorschaubild entfernen
                    </button>
                  </div>
                  <div>
                    <button
                      className="primary hollow button float-right"
                      onClick={() => {
                        this.showPopupImageUpload();
                      }}
                      style={{ marginBottom: "0px" }}
                    >
                      Neues Bild hochladen
                    </button>
                  </div>
                </Cell>
              </Row>

              <Row margin="x">
                <Cell sm={24} md={24} lg={24}></Cell>
              </Row>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }

  view__leftColumn() {
    const { event, courses } = this.state;

    if (event.type === null) {
      event.type = "vimeo";
    }

    let liveDate = new Date();
    if (event.date) {
      liveDate = new Date(event.date);
    }

    let eventCourse = {
      identifier: null,
      name: "-- kein Kurs ausgewählt --"
    };
    courses.map(course => {
      if (event.course_identifier === course.identifier) {
        eventCourse = course;
      }
      return null;
    });

    return (
      <React.Fragment>
        <div className="admin-box adminBoxData">
          <div className="admin-box-content">
            <Grid type="full">
              <Row>
                <Cell sm={18} md={12}>
                  <h3 style={{ marginBottom: 0 }}>Datum &amp; Uhrzeit</h3>
                </Cell>
                <Cell sm={6} md={12} classes="text-right">
                  <div style={this.view__visible("view")}>
                    <button
                      className="admin-icon-button"
                      onClick={() => {
                        this.setState({ editMode: true });

                        Array.from(
                          document.getElementsByClassName("adminBoxData")
                        ).forEach(element => {
                          element.classList.remove("background-saved");
                        });
                      }}
                    >
                      <Icon icon="cog" />
                    </button>
                  </div>
                  <div style={this.view__hideWhenNew()}>
                    <div style={this.view__visible("edit")}>
                      <button
                        onClick={this.handle__editCancel}
                        className="small primary hollow button"
                      >
                        Abbrechen
                      </button>
                      <button
                        onClick={this.handle__editSave}
                        className="small primary button"
                      >
                        Speichern
                      </button>
                    </div>
                  </div>
                </Cell>
              </Row>

              <Row margin="x">
                <Cell sm={24} md={24} lg={24}>
                  <div
                    className="info-content"
                    style={this.view__visible("view")}
                  >
                    {`${event.live_date} - ${event.live_time}` || (
                      <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                    )}
                  </div>
                  <div
                    className="info-content edit-mode"
                    style={this.view__visible("edit")}
                  >
                    <DatePicker
                      selected={liveDate || new Date()}
                      onChange={value => {
                        this.handle__editDate(value);
                      }}
                      dateFormat="dd.MM.yyyy - HH:mm"
                      locale="de"
                      showTimeSelect
                      timeIntervals={15}
                      timeFormat="HH:mm"
                      timeCaption="Uhrzeit"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>

                  <div className="info-caption">Datum &amp; Zeit</div>
                </Cell>
              </Row>
            </Grid>
          </div>
        </div>

        <div className="admin-box adminBoxData">
          <div className="admin-box-content">
            <Grid type="full">
              <Row>
                <Cell sm={18} md={12}>
                  <h3 style={{ marginBottom: 0 }}>Details</h3>
                </Cell>
                <Cell sm={6} md={12} classes="text-right">
                  <div style={this.view__visible("view")}>
                    <button
                      className="admin-icon-button"
                      onClick={() => {
                        this.setState({ editMode: true });

                        Array.from(
                          document.getElementsByClassName("adminBoxData")
                        ).forEach(element => {
                          element.classList.remove("background-saved");
                        });
                      }}
                    >
                      <Icon icon="cog" />
                    </button>
                  </div>
                  <div style={this.view__hideWhenNew()}>
                    <div style={this.view__visible("edit")}>
                      <button
                        onClick={this.handle__editCancel}
                        className="small primary hollow button"
                      >
                        Abbrechen
                      </button>
                      <button
                        onClick={this.handle__editSave}
                        className="small primary button"
                      >
                        Speichern
                      </button>
                    </div>
                  </div>
                </Cell>
              </Row>

              <Row margin="x">
                <Cell sm={24} md={24} lg={24}>
                  <div
                    className="info-content"
                    style={this.view__visible("view")}
                  >
                    {event.name || (
                      <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                    )}
                  </div>
                  <div
                    className="info-content edit-mode"
                    style={this.view__visible("edit")}
                  >
                    <input
                      type="text"
                      value={event.name || ""}
                      onChange={event => {
                        this.handle__edit(event, "name");
                      }}
                    />
                  </div>
                  <div className="info-caption">
                    Titel / Thema des Live Calls
                  </div>
                </Cell>
              </Row>
              <Row margin="x">
                <Cell sm={24} md={24} lg={24}>
                  <div
                    className="info-content"
                    style={this.view__visible("view")}
                  >
                    {event.type || (
                      <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                    )}
                  </div>
                  <div
                    className="info-content edit-mode"
                    style={this.view__visible("edit")}
                  >
                    <select
                      style={{ marginBottom: 0 }}
                      onChange={e => this.handle__edit(e, "type")}
                      value={event.type}
                    >
                      {/*<option key={9999} value={0}>
                --- bitte auswählen ---
            </option>*
              {Object.keys(this.state.courseTypes).map(
                (key, index) => {
                  return (
                    <option key={index} value={key}>
                      {this.state.courseTypes[key]}
                    </option>
                  );
                }
            )}*/}
                      <option value="vimeo">Vimeo</option>
                    </select>
                  </div>

                  <div className="info-caption">Typ</div>
                </Cell>
              </Row>
              <div style={this.view__showVimeoContent()}>
                <Row margin="x">
                  <Cell sm={24} md={24} lg={24}>
                    <div
                      className="info-content"
                      style={this.view__visible("view")}
                    >
                      {event.vimeo_live_id || (
                        <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                      )}
                    </div>
                    <div
                      className="info-content edit-mode"
                      style={this.view__visible("edit")}
                    >
                      <input
                        type="text"
                        value={event.vimeo_live_id || ""}
                        onChange={event => {
                          this.handle__edit(event, "vimeo_live_id");
                        }}
                      />
                    </div>

                    <div className="info-caption">Vimeo-Live-Id</div>
                  </Cell>
                </Row>
              </div>
            </Grid>
          </div>
        </div>

        <div className="admin-box adminBoxData">
          <div className="admin-box-content">
            <Grid type="full">
              <Row>
                <Cell sm={18} md={12}>
                  <h3 style={{ marginBottom: 0 }}>Kurs</h3>
                </Cell>
                <Cell sm={6} md={12} classes="text-right">
                  <div style={this.view__visible("view")}>
                    <button
                      className="admin-icon-button"
                      onClick={() => {
                        this.setState({ editMode: true });

                        Array.from(
                          document.getElementsByClassName("adminBoxData")
                        ).forEach(element => {
                          element.classList.remove("background-saved");
                        });
                      }}
                    >
                      <Icon icon="cog" />
                    </button>
                  </div>
                  <div style={this.view__hideWhenNew()}>
                    <div style={this.view__visible("edit")}>
                      <button
                        onClick={this.handle__editCancel}
                        className="small primary hollow button"
                      >
                        Abbrechen
                      </button>
                      <button
                        onClick={this.handle__editSave}
                        className="small primary button"
                      >
                        Speichern
                      </button>
                    </div>
                  </div>
                </Cell>
              </Row>

              <Row margin="x">
                <Cell sm={24} md={24} lg={24}>
                  <div
                    className="info-content"
                    style={this.view__visible("view")}
                  >
                    {eventCourse.name || (
                      <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                    )}
                  </div>
                  <div
                    className="info-content edit-mode"
                    style={this.view__visible("edit")}
                  >
                    <select
                      value={event.course_identifier}
                      onChange={event => {
                        this.handle__edit(event, "course_identifier");
                      }}
                    >
                      {courses.map((course, index) => {
                        return (
                          <option key={index} value={course.identifier}>
                            {course.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="info-caption">Zugehöriger Kurs</div>
                </Cell>
              </Row>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }

  view__visible(type) {
    const { editMode } = this.state;

    if (
      (type === "edit" && editMode === true) ||
      (type === "view" && editMode === false)
    ) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  view__showVimeoContent() {
    const { event } = this.state;

    if (event && event.type === "vimeo") {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  view__hideWhenNew() {
    if (this.state.eventId === "new") {
      return { display: "none" };
    }
  }

  view__showWhenNew() {
    if (this.state.eventId !== "new") {
      return { display: "none" };
    }
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = crop => {
    this.setState({ crop });
  };

  uploadAvatar = () => {
    const { cropImage, crop, event } = this.state;
    const formData = new FormData();

    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));

    axios
      .post(
        this.props.context.apiEndpoints.adminLiveEventsUploadImage +
          "/" +
          event.id,
        formData,
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const { path } = response.data;
        if (path) {
          event.image = path;
          this.setState({ event });
          this.hidePopupImageCrop();
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  };

  showPopupImageUpload() {
    this.setState({ popupImageUpload: true });
  }

  hidePopupImageUpload() {
    this.setState({ popupImageUpload: false });
  }

  showPopupImageCrop() {
    this.setState({ popupImageCrop: true });
  }

  hidePopupImageCrop() {
    this.setState({ popupImageCrop: false });
  }

  handle__edit(event, property) {
    const { event: liveEvent } = this.state;

    liveEvent[property] = event.target.value;

    this.setState({ liveEvent });
  }

  handle__editDate(value) {
    const { event } = this.state;
    event.date = new Date(value);
    this.setState({ event });
  }

  handle__editCancel = () => {
    const originalEvent = JSON.parse(JSON.stringify(this.state.originalEvent));
    this.setState({
      event: originalEvent,
      editMode: false
    });
  };

  handle__editSave = () => {
    const { eventId, event } = this.state;

    // Check if first and last name are given
    if (!event.name) {
      window.alert("Bitte gib mindestens einen Namen an.");
      return;
    }

    let url = this.props.context.apiEndpoints.adminLiveEvents + "/" + event.id;
    if (eventId === "new") {
      url = this.props.context.apiEndpoints.adminLiveEvents + "/new";
    }

    if (event.date !== null) {
      event.date = new Date(event.date);

      const eventDate =
        ("0" + event.date.getDate()).slice(-2) +
        "." +
        ("0" + (event.date.getMonth() + 1)).slice(-2) +
        "." +
        event.date.getFullYear() +
        " - " +
        ("0" + event.date.getHours()).slice(-2) +
        ":" +
        ("0" + event.date.getMinutes()).slice(-2);

      event.date = eventDate;
    }

    axios
      .post(
        url,
        { event: event },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        if (eventId === "new") {
          const { event } = response.data;

          //console.log(response.data);
          window.location = "/admin/live/" + event.id;

          return;
        } else {
          const { event } = response.data;
          const originalEvent = JSON.parse(JSON.stringify(event));
          this.setState({ event, originalEvent, editMode: false });
          Array.from(document.getElementsByClassName("adminBoxData")).forEach(
            element => {
              element.classList.add("background-saved");
            }
          );
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  handle__deleteImage = () => {
    const { event } = this.state;

    if (
      window.confirm(
        "Möchtest Du das Vorschaubild für diesen Live Call wirklich entfernen? Du kannst jederzeit ein neues hochladen."
      )
    ) {
      axios
        .delete(
          this.props.context.apiEndpoints.adminLiveEventsDeleteImage +
            "/" +
            event.id,
          { headers: this.props.context.headers }
        )
        .then(response => {
          if (response.data === "OK") {
            const { event } = this.state;
            event.image = null;
            this.setState({ event });
          } else {
            console.log(response);
          }
        })
        .catch(error => {
          console.log("ERROR", error);
        });
    } else {
      return;
    }
  };

  handle__delete = () => {
    const { event } = this.state;

    if (
      window.confirm(
        "Möchtest Du dieses Live Event wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!"
      )
    ) {
      axios
        .delete(
          this.props.context.apiEndpoints.adminLiveEvents + "/" + event.id,
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          window.location.href = "/admin/live/";
        })
        .catch(error => {
          console.log("Error", error);
        });
    }

    return;
  };

  init() {
    const { eventId } = this.state;
    let { editMode } = this.state;

    axios
      .get(this.props.context.apiEndpoints.adminLiveEvents + "/" + eventId, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { event } = response.data;
        const originalEvent = JSON.parse(JSON.stringify(event));

        if (eventId === "new") {
          editMode = true;
        }

        this.setState({ event, originalEvent, editMode });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminLive);
