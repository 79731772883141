import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";

registerLocale("de", de);

//const EditorComponent = () => <Editor />;

class AdminAnnouncement extends Component {
  state = {
    announcement: {},
    originalAnnouncement: {},
    announcementId: this.props.match.params.announcementId,
    courses: [],
    editMode: false,
    editorState: undefined,
    announcementTypes: {
      admin: {
        name: "Administrator",
      },
      dev: {
        name: "Developer (bei Bugs etc.)",
      },
    },
    announcementPlaces: {
      dashboard: {
        name: "Dashboard",
      },
    },
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    return (
      <AdminLayout active="announcements">
        {this.view__showContent()}
      </AdminLayout>
    );
  }

  view__showContent() {
    const {
      announcement,
      editorState,
      announcementTypes,
      announcementPlaces,
    } = this.state;

    if (!announcement || !announcement.type) {
      return null;
    }

    let showOns = announcement.show_on.map((showOnKey, index) => {
      let showOnName = showOnKey;
      if (announcementPlaces && announcementPlaces[showOnKey]) {
        showOnName = announcementPlaces[showOnKey].name;
      }
      return (
        <div key={index}>
          <Icon icon="check" left /> {showOnName}
        </div>
      );
    });

    let typeText = announcement.type;
    if (announcementTypes && announcementTypes[announcement.type]) {
      typeText = announcementTypes[announcement.type].name;
    }

    let videoPreview;
    if (announcement.image) {
      videoPreview = (
        <React.Fragment>
          <div>&nbsp;</div>
          <div className="responsive-embed widescreen">
            <iframe
              src={`https://player.vimeo.com/video/${announcement.image}`}
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Vorschau"
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <Grid>
        <Row>
          <Cell sm={24} md={18}>
            <p>
              <a href="/admin/announcements">
                <small>
                  <Icon icon="angle-double-left" left /> Zurück zur Übersicht
                </small>
              </a>
            </p>
            {this.view__showTitle()}
          </Cell>
          <Cell sm={24} md={6} classes="text-right">
            <div style={this.view__visible("view")}>
              <div style={this.view__hideWhenNew()}>
                <button
                  className="primary button hide-for-small-only"
                  onClick={this.handle__delete}
                >
                  <Icon icon="trash" left /> Ankündigung löschen
                </button>
                <button
                  className="small primary button show-for-small-only"
                  onClick={this.handle__delete}
                >
                  <Icon icon="trash" left /> Ankündigung löschen
                </button>
              </div>
            </div>
          </Cell>
        </Row>
        <Row margin="x">
          <Cell sm={24} md={12} lg={12}>
            <div className="admin-box adminBoxData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Informationen & Inhalt</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <div style={this.view__visible("view")}>
                        <button
                          className="admin-icon-button"
                          onClick={() => {
                            this.setState({ editMode: true });
                            Array.from(
                              document.getElementsByClassName("adminBoxData")
                            ).forEach((element) => {
                              element.classList.remove("background-saved");
                            });
                          }}
                        >
                          <Icon icon="cog" />
                        </button>
                      </div>
                      <div style={this.view__hideWhenNew()}>
                        <div style={this.view__visible("edit")}>
                          <button
                            onClick={this.handle__editCancel}
                            className="small primary hollow button"
                          >
                            Abbrechen
                          </button>
                          <button
                            onClick={this.handle__editSave}
                            className="small primary button"
                          >
                            Speichern
                          </button>
                        </div>
                      </div>
                    </Cell>
                  </Row>

                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div
                        className="info-content"
                        style={this.view__visible("view")}
                      >
                        {announcement.name || (
                          <span
                            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                          />
                        )}
                      </div>
                      <div
                        className="info-content edit-mode"
                        style={this.view__visible("edit")}
                      >
                        <input
                          type="text"
                          value={announcement.name || ""}
                          onChange={(event) => {
                            this.handle__editChange(event.target.value, "name");
                          }}
                        />
                      </div>

                      <div className="info-caption">
                        Interner Name (wird nicht öffentlich angezeigt)
                      </div>
                    </Cell>
                  </Row>
                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div
                        className="info-content"
                        style={this.view__visible("view")}
                      >
                        {announcement.content_preview || (
                          <span
                            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                          />
                        )}
                      </div>
                      <div
                        className="info-content edit-mode no-edit-bg-color"
                        style={this.view__visible("edit")}
                      >
                        <Editor
                          editorState={editorState}
                          wrapperClassName="editor-wrapper"
                          editorClassName="editor-editor"
                          onEditorStateChange={this.onEditorStateChange}
                          localization={{
                            locale: "de",
                          }}
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              "fontSize",
                              "fontFamily",
                              "list",
                              "textAlign",
                              "colorPicker",
                              "link",
                              "emoji",
                              "remove",
                              "history",
                            ],
                          }}
                        />
                      </div>

                      <div className="info-caption">Inhalt</div>
                    </Cell>
                  </Row>
                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div
                        className="info-content"
                        style={this.view__visible("view")}
                      >
                        {typeText || (
                          <span
                            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
                          />
                        )}
                      </div>
                      <div
                        className="info-content edit-mode"
                        style={this.view__visible("edit")}
                      >
                        <select
                          value={announcement.type || "admin"}
                          onChange={(event) => {
                            this.handle__editChange(event.target.value, "type");
                          }}
                        >
                          {Object.keys(announcementTypes).map(
                            (typeKey, index) => {
                              return (
                                <option key={index} value={typeKey}>
                                  {announcementTypes[typeKey].name}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>

                      <div className="info-caption">Art der Ankündigung</div>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            </div>
          </Cell>
          <Cell sm={24} md={12} lg={12}>
            <div className="admin-box adminBoxData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Video</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <div style={this.view__visible("view")}>
                        <button
                          className="admin-icon-button"
                          onClick={() => {
                            this.setState({ editMode: true });
                            Array.from(
                              document.getElementsByClassName("adminBoxData")
                            ).forEach((element) => {
                              element.classList.remove("background-saved");
                            });
                          }}
                        >
                          <Icon icon="cog" />
                        </button>
                      </div>
                      <div style={this.view__hideWhenNew()}>
                        <div style={this.view__visible("edit")}>
                          <button
                            onClick={this.handle__editCancel}
                            className="small primary hollow button"
                          >
                            Abbrechen
                          </button>
                          <button
                            onClick={this.handle__editSave}
                            className="small primary button"
                          >
                            Speichern
                          </button>
                        </div>
                      </div>
                    </Cell>
                  </Row>

                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div
                        className="info-content"
                        style={this.view__visible("view")}
                      >
                        {announcement.image || (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: "<em>-- kein Video --</em>",
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="info-content edit-mode"
                        style={this.view__visible("edit")}
                      >
                        <input
                          type="text"
                          value={announcement.image || ""}
                          onChange={(event) => {
                            this.handle__editChange(
                              event.target.value,
                              "image"
                            );
                          }}
                        />
                      </div>

                      <div className="info-caption">Vimeo Video ID</div>
                    </Cell>
                  </Row>
                </Grid>

                {videoPreview}
              </div>
            </div>
            <div className="admin-box adminBoxData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Zeitliche Steuerung</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <div style={this.view__visible("view")}>
                        <button
                          className="admin-icon-button"
                          onClick={() => {
                            this.setState({ editMode: true });
                            Array.from(
                              document.getElementsByClassName("adminBoxData")
                            ).forEach((element) => {
                              element.classList.remove("background-saved");
                            });
                          }}
                        >
                          <Icon icon="cog" />
                        </button>
                      </div>
                      <div style={this.view__hideWhenNew()}>
                        <div style={this.view__visible("edit")}>
                          <button
                            onClick={this.handle__editCancel}
                            className="small primary hollow button"
                          >
                            Abbrechen
                          </button>
                          <button
                            onClick={this.handle__editSave}
                            className="small primary button"
                          >
                            Speichern
                          </button>
                        </div>
                      </div>
                    </Cell>
                  </Row>
                  <div style={this.view__visible("edit")}>
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div className="text-center">
                          <label style={{ cursor: "pointer" }}>
                            <input
                              type="checkbox"
                              checked={announcement.show_date === null}
                              onChange={this.handle__toggleShowDate}
                            />{" "}
                            Ankündigung ab sofort anzeigen
                          </label>
                        </div>
                      </Cell>
                    </Row>
                  </div>
                  <div
                    style={{
                      display:
                        announcement.show_date === null ? "none" : "block",
                    }}
                  >
                    <Row margin="x">
                      <Cell sm={24} md={24} lg={24}>
                        <div
                          className="info-content"
                          style={this.view__visible("view")}
                        >
                          {announcement.show_date === null
                            ? `sofort`
                            : `${announcement.show_date_date} - ${announcement.show_date_time} Uhr`}
                        </div>
                        <div
                          className="info-content edit-mode"
                          style={this.view__visible("edit")}
                        >
                          <DatePicker
                            selected={announcement.show_date || new Date()}
                            onChange={(value) => {
                              this.handle__editChange(value, "show_date");
                            }}
                            dateFormat="dd.MM.yyyy - HH:mm"
                            locale="de"
                            showTimeSelect
                            timeIntervals={5}
                            timeFormat="HH:mm"
                            timeCaption="Uhrzeit"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </div>

                        <div className="info-caption">Anzeigen ab</div>
                      </Cell>
                    </Row>
                  </div>
                </Grid>
              </div>
            </div>

            <div className="admin-box adminBoxData">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={18} md={12}>
                      <h3>Wo soll die Ankündigung angezeigt werden?</h3>
                    </Cell>
                    <Cell sm={6} md={12} classes="text-right">
                      <div style={this.view__visible("view")}>
                        <button
                          className="admin-icon-button"
                          onClick={() => {
                            this.setState({ editMode: true });
                            Array.from(
                              document.getElementsByClassName("adminBoxData")
                            ).forEach((element) => {
                              element.classList.remove("background-saved");
                            });
                          }}
                        >
                          <Icon icon="cog" />
                        </button>
                      </div>
                      <div style={this.view__hideWhenNew()}>
                        <div style={this.view__visible("edit")}>
                          <button
                            onClick={this.handle__editCancel}
                            className="small primary hollow button"
                          >
                            Abbrechen
                          </button>
                          <button
                            onClick={this.handle__editSave}
                            className="small primary button"
                          >
                            Speichern
                          </button>
                        </div>
                      </div>
                    </Cell>
                  </Row>
                  <Row margin="x">
                    <Cell sm={24} md={24} lg={24}>
                      <div
                        className="info-content"
                        style={this.view__visible("view")}
                      >
                        {showOns}
                      </div>
                      <div
                        className="info-content edit-mode"
                        style={this.view__visible("edit")}
                      >
                        <Grid type="full">
                          <Row>
                            <Cell sm={24} classes="text-center">
                              {Object.keys(announcementPlaces).map(
                                (placeKey, index) => {
                                  return (
                                    <div key={index}>
                                      <label style={{ cursor: "pointer" }}>
                                        <input
                                          type="checkbox"
                                          checked={announcement.show_on.includes(
                                            placeKey
                                          )}
                                          onChange={() => {
                                            this.handle__selectShowOn(placeKey);
                                          }}
                                        />{" "}
                                        {announcementPlaces[placeKey].name}
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                            </Cell>
                          </Row>
                        </Grid>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            </div>

            <div style={this.view__showWhenNew()}>
              <div style={this.view__visible("edit")} className="text-right">
                <a href="/admin/books" className="primary hollow button">
                  Abbrechen
                </a>
                <button
                  onClick={this.handle__editSave}
                  className="primary button"
                >
                  Speichern <Icon icon="angle-right" />
                </button>
              </div>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showTitle() {
    const announcement = this.state.originalAnnouncement;
    const { announcementId } = this.state;

    let title = "";

    if (announcement) {
      if (announcementId === "new") {
        title = "Neue Ankündigung";
      } else {
        title = announcement.name;
      }
    } else {
      title = "Wird geladen...";
    }

    return <h1>{title}</h1>;
  }

  view__hideWhenNew() {
    if (this.state.announcementId === "new") {
      return { display: "none" };
    }
  }

  view__showWhenNew() {
    if (this.state.announcementId !== "new") {
      return { display: "none" };
    }
  }

  view__visible(type) {
    const { editMode } = this.state;

    if (
      (type === "edit" && editMode === true) ||
      (type === "view" && editMode === false)
    ) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  handle__delete = () => {
    const { announcement } = this.state;

    if (
      window.confirm(
        "Möchtest Du diese Ankündigung wirklich unwiderruflich löschen?\r\n\r\nDiese Aktion kann nicht rückgängig gemacht werden."
      )
    ) {
      let url =
        this.props.context.apiEndpoints.adminAnnouncements +
        "/" +
        announcement.id;

      axios
        .delete(url, {
          headers: this.props.context.headers,
        })
        .then((response) => {
          window.location = "/admin/announcements";
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else {
      return;
    }
  };

  handle__toggleShowDate = () => {
    const { announcement } = this.state;
    if (announcement.show_date === null) {
      announcement.show_date = new Date();
    } else {
      announcement.show_date = null;
    }
    this.setState({ announcement });
  };

  handle__editChange(value, property) {
    const { announcement } = this.state;
    announcement[property] = value;
    this.setState({ announcement });
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  handle__selectShowOn(showOnKey) {
    let { announcement } = this.state;

    if (announcement.show_on.includes(showOnKey)) {
      for (let i = 0; i < announcement.show_on.length; i++) {
        if (announcement.show_on[i] === showOnKey) {
          announcement.show_on.splice(i, 1);
        }
      }
    } else {
      announcement.show_on.push(showOnKey);
    }

    this.setState({ announcement });
  }

  handle__editCancel = () => {
    let announcement = JSON.parse(
      JSON.stringify(this.state.originalAnnouncement)
    );

    announcement = this.init_announcement(announcement);

    this.setState({
      announcement,
      editMode: false,
    });
  };

  handle__editSave = () => {
    const { announcementId, announcement, editorState } = this.state;

    // Check if first and last name are given
    if (!announcement.name) {
      window.alert("Bitte gib Deiner Ankündigung einen internen Namen.");
      return;
    }

    let url =
      this.props.context.apiEndpoints.adminAnnouncements +
      "/" +
      announcement.id;
    if (announcementId === "new") {
      url = this.props.context.apiEndpoints.adminAnnouncements + "/new";
    }

    announcement.content = convertToRaw(editorState.getCurrentContent());

    if (announcement.show_date !== null) {
      let showDate = new Date(announcement.show_date);

      showDate =
        ("0" + showDate.getDate()).slice(-2) +
        "." +
        ("0" + (showDate.getMonth() + 1)).slice(-2) +
        "." +
        showDate.getFullYear() +
        " - " +
        ("0" + showDate.getHours()).slice(-2) +
        ":" +
        ("0" + showDate.getMinutes()).slice(-2);

      announcement.show_date = showDate;
    }

    axios
      .post(
        url,
        { announcement: announcement },
        {
          headers: this.props.context.headers,
        }
      )
      .then((response) => {
        if (announcementId === "new") {
          const { announcement } = response.data;

          //console.log(response.data);
          window.location = "/admin/announcements/" + announcement.id;

          return;
        } else {
          let { announcement } = response.data;
          const originalAnnouncement = JSON.parse(JSON.stringify(announcement));

          window.location = "/admin/announcements/" + announcement.id;

          announcement = this.init_announcement(announcement);

          this.setState({
            announcement,
            originalAnnouncement,
            editMode: false,
          });
          Array.from(document.getElementsByClassName("adminBoxData")).forEach(
            (element) => {
              element.classList.add("background-saved");
            }
          );
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  /**
   * Init
   */
  init__load() {
    const { announcementId, announcementPlaces } = this.state;

    if (announcementId) {
      axios
        .get(
          this.props.context.apiEndpoints.adminAnnouncements +
            "/" +
            announcementId,
          {
            headers: this.props.context.headers,
          }
        )
        .then((response) => {
          let { announcement, courses } = response.data;
          if (announcementId === "new") {
            announcement.show_on = ["dashboard"];
            announcement.show_date = null;
            this.setState({ editMode: true });
          }
          const originalAnnouncement = JSON.parse(JSON.stringify(announcement));
          announcement = this.init_announcement(announcement);

          courses.map((course) => {
            let placeKey = `course_${course.id}`;

            announcementPlaces[placeKey] = {
              name: course.name,
            };
          });

          this.setState({
            announcement,
            originalAnnouncement,
            courses,
            announcementPlaces,
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }

  init_announcement(announcement) {
    let editorState = EditorState.createEmpty();

    if (announcement.content) {
      editorState = EditorState.createWithContent(
        convertFromRaw(announcement.content)
      );
    }

    this.setState({ editorState });

    announcement.content_preview = draftToHtml(
      announcement.content_preview
    ).replace(/<\/?[^>]+>/gi, "");

    if (announcement.show_date !== null) {
      announcement.show_date = new Date(announcement.show_date);
    }

    return announcement;
  }
}

export default withContext(AdminAnnouncement);
