import React, { Component } from "react";
import { Grid, Row, Cell } from "../foundation/_grid";
import { Button, Icon } from "../foundation/_buttons";
import axios from "axios";
import withContext from "../../context/contextHOC";

class LoginPopup extends Component {
  state = {
    inputEmail: "",
    inputPassword: "",
    isLoading: false,
    hasErrorEmpty: false,
    hasErrorWrong: false,
    hasErrorEmailNotFound: false,
    msgSuccess: false,
    showPasswordForgotten: false,
  };
  render() {
    return (
      <Grid id="loginForm">
        <Row>
          <Cell sm={24}>{this.show__content()}</Cell>
        </Row>
      </Grid>
    );
  }

  show__content() {
    const { showPasswordForgotten } = this.state;

    if (showPasswordForgotten === true) {
      return (
        <React.Fragment>
          <h2>Passwort vergessen</h2>

          <p className="lead">
            Hast Du Dein Passwort für den TPA Mitgliederbereich vergessen? Kein
            Problem!
          </p>

          <p>
            Gib einfach nachfolgend Deine E-Mail-Adresse an. Anschließend
            erhältst Du eine E-Mail mit einem Link, über den Du ein neues
            Passwort festlegen kannst.
          </p>

          {this.showErrorEmpty()}
          {this.showErrorWrong()}
          {this.showErrorEmailNotFound()}

          {this.showPasswordForgottenForm()}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h2>
            Anmelden im
            <br />
            TPA Mitgliederbereich
          </h2>

          <p className="lead">
            Hast Du bereits einen Zugang zum Mitgliederbereich? Dann logge Dich
            jetzt mit Deiner E-Mail-Adresse und Deinem Passwort ein.
          </p>

          {this.showErrorEmpty()}
          {this.showErrorWrong()}
          {this.showErrorEmailNotFound()}

          {this.showLoginForm()}

          {/*
          <h2>Noch keinen Zugang?</h2>

          <p className="lead">
            Dann wähle jetzt einen Kurs aus und in ein paar Minuten bist Du
            dabei!
          </p>

          <p className="text-center">
            <Button
              href="/#kurse"
              type="primary"
              hollow
              click={() => this.props.handleClose()}
            >
              <Icon icon="play-circle" left /> Zur Kursübersicht
            </Button>
</p>*/}
        </React.Fragment>
      );
    }
  }

  showLoginForm() {
    if (this.state.isLoading) {
      return (
        <div id="loginFormLoading">
          <Icon icon="spin fa-spinner" />
          <br />
          Bitte warten ...
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <label>
            <span className="hide-for-small-only">E-Mail-Adresse:</span>
            <input
              type="email"
              placeholder="Deine E-Mail-Adresse"
              value={this.state.inputEmail}
              onChange={this.handleChange("inputEmail")}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  this.tryLogin();
                }
              }}
            />
          </label>
          <label>
            <span className="hide-for-small-only">Passwort</span>
            <input
              type="password"
              placeholder="Dein Passwort"
              value={this.state.inputPassword}
              onChange={this.handleChange("inputPassword")}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  this.tryLogin();
                }
              }}
            />
          </label>
          <p className="text-center">
            <button
              className="login-password-forgotten"
              onClick={() => {
                this.setState({ showPasswordForgotten: true });
              }}
            >
              <small>Passwort vergessen?</small>
            </button>
          </p>
          <p className="text-right">
            <Button
              type="primary"
              click={() => {
                this.tryLogin();
              }}
            >
              Anmelden <Icon icon="angle-right" right />
            </Button>
          </p>
        </React.Fragment>
      );
    }
  }

  showPasswordForgottenForm() {
    if (this.state.isLoading) {
      return (
        <div id="loginFormLoading">
          <Icon icon="spin fa-spinner" />
          <br />
          Bitte warten ...
        </div>
      );
    } else if (this.state.msgSuccess === true) {
      return (
        <React.Fragment>
          <div className="success callout">
            <p>
              Vielen Dank! Wir haben Dir eine E-Mail mit einem Link zum
              Zurücksetzen Deines Passworts zugesendet.
            </p>
          </div>
          <div>&nbsp;</div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <label>
            E-Mail-Adresse:
            <input
              type="email"
              placeholder="Deine E-Mail-Adresse"
              value={this.state.inputEmail}
              onChange={this.handleChange("inputEmail")}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  this.tryReset();
                }
              }}
            />
          </label>
          <p className="text-right">
            <Button
              type="primary"
              hollow
              click={() => {
                this.setState({ showPasswordForgotten: false });
              }}
            >
              <Icon icon="angle-left" left />
              Zurück
            </Button>
            <Button
              type="primary"
              click={() => {
                this.tryReset();
              }}
            >
              Passwort zurücksetzen <Icon icon="angle-right" right />
            </Button>
          </p>
        </React.Fragment>
      );
    }
  }

  showErrorEmpty() {
    if (this.state.hasErrorEmpty === true) {
      return (
        <div className="alert callout">
          <p>Bitte gib Deine E-Mail-Adresse und Dein Passwort ein.</p>
        </div>
      );
    }
  }

  showErrorWrong() {
    if (this.state.hasErrorWrong === true) {
      return (
        <div className="alert callout">
          <p>Das von Dir eingegebene Passwort ist leider nicht korrekt.</p>
          <p>
            Bitte probiere es erneut oder nutze die Passwort vergessen Funktion.
          </p>
        </div>
      );
    }
  }

  showErrorEmailNotFound() {
    if (this.state.hasErrorEmailNotFound === true) {
      return (
        <div className="alert callout">
          <p>
            Es konnte kein Benutzerkonto mit dieser E-Mail-Adresse gefunden
            werden.
          </p>
        </div>
      );
    }
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  tryLogin() {
    this.setState({
      isLoading: true,
      hasErrorEmailNotFound: false,
      hasErrorEmpty: false,
      hasErrorWrong: false,
    });
    const { inputEmail, inputPassword } = this.state;

    if (!inputEmail || !inputPassword) {
      this.setState({ hasErrorEmpty: true, isLoading: false });
      return false;
    }

    axios
      .post(this.props.context.apiEndpoints.login, {
        username: inputEmail,
        password: inputPassword,
        grant_type: "password",
        client_id: 1,
        client_secret: "m70XsF10eaGk6fRFOc8QSDhygjaQXJuyE849T8D4",
        scope: "*",
      })
      .then((response) => {
        let statusCode = response.status;
        if (statusCode === 200) {
          if (response.data) {
            let accessToken = response.data.access_token;
            localStorage.setItem("access_token", accessToken);
            window.location = this.props.context.redirectAfterLogin;
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          let statusCode = error.response.status;
          if (statusCode === 401 || statusCode === 400) {
            this.setState({ hasErrorWrong: true, isLoading: false });
          }
        }
      });
  }

  tryReset() {
    this.setState({
      isLoading: true,
      hasErrorEmailNotFound: false,
      hasErrorEmpty: false,
    });
    const { inputEmail } = this.state;

    if (!inputEmail) {
      this.setState({ hasErrorEmpty: true, isLoading: false });
      return false;
    }

    axios
      .post(this.props.context.apiEndpoints.passwordForgotten, {
        email: inputEmail,
      })
      .then((response) => {
        if (response.data === "OK") {
          this.setState({ msgSuccess: true, isLoading: false });
        }
      })
      .catch((error) => {
        if (error.response) {
          let statusCode = error.response.status;
          if (statusCode === 404 && error.response.data === "notfound") {
            this.setState({ hasErrorEmailNotFound: true, isLoading: false });
          }
        }
      });
  }
}

export default withContext(LoginPopup);
