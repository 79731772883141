import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import Breadcrumbs from "../topbars/breadcrumbs";
import { Grid, Row, Cell } from "../foundation/_grid";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { Icon } from "../foundation/_buttons";
import PTPopup from "../ptPopup/ptPopup";
import Switch from "react-switch";
import PTTabs from "../ptTabs/ptTabs";
import { isUndefined } from "util";
import Footer from "../layout/footer";

class Routines extends Component {
  state = {
    routines: [],
    month: 0,
    year: 0,
    years: [2018, 2019],
    selectedDay: null,
    selectedRoutine: null,
    routineBackup: null,
    showDayPopup: false,
    showRoutinePopup: false,
    newRoutine: {
      id: null,
      name: "",
      visible: true
    },
    tabs: {
      default: {
        name: "Meine Routinen",
        icon: "calendar-check-o",
        selected: true
      },
      morning: {
        name: "Meine Morgenroutine",
        icon: "sun-o",
        selected: false
      },
      evening: {
        name: "Meine Abendroutine",
        icon: "moon-o",
        selected: false
      }
    },
    selectedTab: "default",
    morningRoutines: [],
    eveningRoutines: []
  };

  componentDidMount() {
    this.init__checkDate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.month !== prevProps.match.params.month ||
      this.props.match.params.year !== prevProps.match.params.year
    ) {
      this.init__checkDate();
    }
  }

  /**
   * Render
   */
  render() {
    return (
      <React.Fragment>
        <div
          id="pageRoutines"
          className="darkmode page-with-topbar-and-breadcrumbs default-page"
        >
          <MainTopbar />
          <Breadcrumbs
            breadcrumbs={["dashboard", "routines"]}
            favorite={{
              name: "Meine Routinen",
              page: "/routinen",
              icon: "routines"
            }}
          />
          <section className="content">
            <Grid>
              <Row>
                <Cell sm={24} md={12}>
                  <h2>Meine Routinen</h2>
                </Cell>
                <Cell sm={24} md={12} classes="text-right">
                  <button
                    className="primary button"
                    onClick={this.handle__showRoutinePopup}
                  >
                    <Icon icon="plus" left /> Routine hinzufügen
                  </button>
                </Cell>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Cell sm={24}>
                  <PTTabs
                    tabs={this.state.tabs}
                    onUpdate={tabs => {
                      let selectedTab = "";
                      Object.keys(tabs).map((keyName, index) => {
                        if (tabs[keyName].selected === true) {
                          selectedTab = keyName;
                        }
                        return null;
                      });
                      this.setState({ tabs, selectedTab });
                    }}
                  />
                  <div>&nbsp;</div>
                </Cell>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Cell sm={24} md={24}>
                  {this.show__routinesContent()}
                </Cell>
              </Row>
            </Grid>
            {this.show__dayPopup()}
            {this.show__routinePopup()}
          </section>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  show__routinesContent() {
    const { tabs } = this.state;

    if (tabs.default.selected === true) {
      return (
        <React.Fragment>
          {this.show__routinesMonthSelection()}

          <Grid type="full">
            <Row>{this.show__routines("default")}</Row>
          </Grid>
        </React.Fragment>
      );
    } else if (tabs.morning.selected === true) {
      return (
        <Grid type="full">
          <Row>{this.show__routines("morning")}</Row>
        </Grid>
      );
    } else if (tabs.evening.selected === true) {
      return (
        <Grid type="full">
          <Row>{this.show__routines("evening")}</Row>
        </Grid>
      );
    }
  }

  /**
   * Show Month Selection
   */
  show__routinesMonthSelection() {
    const { months } = this.props.context;
    const { years } = this.state;

    return (
      <div className="routines-month-selection">
        <Grid>
          <Row>
            <Cell sm={24} md={8} mdo={4}>
              <Grid type="full">
                <Row>
                  <Cell sm={2} classes="routines-month-selection-prev">
                    {this.show__prevMonthButton()}
                  </Cell>
                  <Cell sm={12} classes="routines-month-selection-month">
                    <select
                      value={this.state.month}
                      onChange={event => {
                        this.handle__monthSelection(event, "month");
                      }}
                    >
                      {Object.keys(months).map(key => {
                        return (
                          <option value={key} key={key}>
                            {months[key]}
                          </option>
                        );
                      })}
                    </select>
                  </Cell>
                  <Cell sm={8} classes="routines-month-selection-year">
                    <select
                      value={this.state.year}
                      onChange={event => {
                        this.handle__monthSelection(event, "year");
                      }}
                    >
                      {years.map(year => {
                        return (
                          <option value={year} key={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </Cell>
                  <Cell sm={2} classes="routines-month-selection-next">
                    {this.show__nextMonthButton()}
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  /**
   * Loop through all Routines
   */
  show__routines(type) {
    const { routines, morningRoutines, eveningRoutines } = this.state;

    let showRoutines = [];
    if (type === "morning") {
      if (!isUndefined(morningRoutines.routines)) {
        showRoutines = morningRoutines.routines;
      }
    } else if (type === "evening") {
      if (!isUndefined(eveningRoutines.routines)) {
        showRoutines = eveningRoutines.routines;
      }
    } else {
      showRoutines = routines;
    }

    return (
      <React.Fragment>
        {showRoutines.map((routine, index) => {
          return (
            <Cell sm={24} md={8} key={index} classes="routine-wrapper">
              {this.show__routine(routine)}
            </Cell>
          );
        })}
      </React.Fragment>
    );
  }

  /**
   * Show Single Routine
   */
  show__routine(routine) {
    const { weekdaysShort } = this.props.context;
    return (
      <div className="box routine">
        <div className="box-content">
          <h3 className="text-center has-hidden-buttons">
            <span
              onClick={() => {
                this.handle__showRoutinePopup(routine);
              }}
            >
              {routine.name}
            </span>
            <button
              className="hidden-button"
              onClick={() => {
                this.handle__showRoutinePopup(routine);
              }}
            >
              <Icon icon="cog" />
            </button>
            <button
              className="hidden-button"
              onClick={() => {
                this.handle__routineConfirmDelete(routine);
              }}
            >
              <Icon icon="trash" />
            </button>
          </h3>
          <div className="routine-days">
            <div className="routine-days-header">
              {Object.keys(weekdaysShort).map(key => {
                return (
                  <div className="routine-days-weekday" key={key}>
                    {weekdaysShort[key]}
                  </div>
                );
              })}
            </div>
            {this.show__routineDays(routine)}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Show Day of Routine
   */
  show__routineDays(routine) {
    const days = routine.days;
    return (
      <React.Fragment>
        {days.map((day, index) => {
          let classes = "routine-day";
          if (!day.dayNr) {
            classes += " no-day";
          }

          if (day.future) {
            classes += " future";
          }

          if (day.today) {
            classes += " today";
          }

          let lineBreak = "";
          if (day.weekday === 7) {
            lineBreak = <div className="clearfix" />;
          }

          let isFinished = "";
          if (day.finished === true) {
            isFinished = <Icon icon="check" />;
          }

          let hasNote = "";
          if (day.note) {
            hasNote = <Icon icon="sticky-note-o" />;
          }

          return (
            <React.Fragment key={index}>
              <div
                className={classes}
                onClick={() => {
                  this.handle__showDayPopup(day, routine);
                }}
              >
                <div className="routine-day-inner">
                  {isFinished}
                  {hasNote}
                  {day.dayNr || ""}
                </div>
              </div>
              {lineBreak}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  /**
   * Show Button for previous month
   */
  show__prevMonthButton() {
    if (this.check__isPrevMonthAvailable() !== false) {
      return (
        <button onClick={this.handle__prevMonth}>
          <Icon icon="angle-left" />
        </button>
      );
    } else {
      return null;
    }
  }

  /**
   * Show Button for next month
   */
  show__nextMonthButton() {
    if (this.check__isNextMonthAvailable() !== false) {
      return (
        <button onClick={this.handle__nextMonth}>
          <Icon icon="angle-right" />
        </button>
      );
    } else {
      return null;
    }
  }

  /**
   * Show Popup for creating a new or editing an existing Routine
   */
  show__routinePopup() {
    const { showRoutinePopup, selectedRoutine, selectedTab } = this.state;

    if (showRoutinePopup === true) {
      let popupTitle = "Neue Routine";
      if (selectedTab === "morning") {
        popupTitle = "Neue Morgenroutine";
      } else if (selectedTab === "evening") {
        popupTitle = "Neue Abendroutine";
      }

      if (selectedRoutine.id) {
        popupTitle = "Routine bearbeiten";
      }

      return (
        <PTPopup
          size="small"
          show={showRoutinePopup}
          handleClose={this.handle__hideRoutinePopup}
        >
          <h2>{popupTitle}</h2>
          <Grid type="full">
            <Row>
              <Cell sm={24}>
                <label>
                  Name:
                  <input
                    id="popupRoutineEditName"
                    type="text"
                    value={this.state.selectedRoutine.name}
                    placeholder="z.B. Sport, Meditieren, 10 Kunden anrufen ..."
                    onChange={this.handle__routineNameChange}
                    onKeyUp={this.handle__routineNameEnter}
                  />
                </label>
              </Cell>
            </Row>
            <Row>
              <Cell sm={24}>
                <div className="ptpopup-buttons">
                  <button
                    className="primary hollow button"
                    onClick={this.handle__hideRoutinePopup}
                  >
                    <Icon icon="times" left /> Abbrechen
                  </button>
                  <button
                    className="primary button"
                    onClick={this.handle__routineSave}
                  >
                    <Icon icon="check" left /> Speichern
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </PTPopup>
      );
    } else {
      return null;
    }
  }

  /**
   * Show Day Popup
   */
  show__dayPopup() {
    const { selectedDay, selectedRoutine, showDayPopup } = this.state;
    if (showDayPopup === true) {
      return (
        <PTPopup
          size="small"
          show={showDayPopup}
          handleClose={this.handle__hideDayPopup}
        >
          <h2>{selectedRoutine.name}</h2>
          <h3>
            {this.props.context.weekdays[selectedDay.weekday]},{" "}
            {selectedDay.date}
          </h3>

          <div className="routine-day-popup-finished-handler">
            <Grid type="full">
              <Row>
                <Cell sm={8} smo={3}>
                  <strong>Erledigt:</strong>
                </Cell>
                <Cell sm={4}>
                  <Switch
                    onChange={this.handle__dayFinishedSwitch}
                    checked={this.state.selectedDay.finished}
                    className="react-switch"
                  />
                </Cell>
              </Row>
              <Row padding="y">
                <Cell sm={24}>
                  <textarea
                    placeholder="Halte Deine Gedanken zu dieser Routine an diesem Tag fest."
                    rows="5"
                    value={this.state.selectedDay.note || ""}
                    onChange={this.handle__dayNotesChanges}
                  />
                </Cell>
              </Row>
              <Row>
                <Cell sm={24}>
                  <div className="ptpopup-buttons">
                    <button
                      className="primary hollow button"
                      onClick={this.handle__hideDayPopup}
                    >
                      <Icon icon="times" left /> Abbrechen
                    </button>
                    <button
                      className="primary button"
                      onClick={this.handle__dayNotesSave}
                    >
                      <Icon icon="check" left /> Speichern
                    </button>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </div>
        </PTPopup>
      );
    } else {
      return null;
    }
  }

  /**
   * Show Popup for commenting and finishing a day
   */
  handle__showRoutinePopup = routine => {
    let selectedRoutine = null;
    if (routine && routine.id) {
      selectedRoutine = JSON.parse(JSON.stringify(routine));
    } else {
      selectedRoutine = JSON.parse(JSON.stringify(this.state.newRoutine));
    }

    this.setState({
      selectedRoutine,
      showRoutinePopup: true
    });
  };

  /**
   * Hide Edit Routine Popup
   */
  handle__hideRoutinePopup = () => {
    this.setState({
      showRoutinePopup: false,
      selectedRoutine: null
    });
  };

  /**
   * Handle Input Change for Routine Name (New and Edit)
   */
  handle__routineNameChange = event => {
    const newValue = event.target.value;
    const { selectedRoutine } = this.state;

    selectedRoutine.name = newValue;

    this.setState({ selectedRoutine });
  };

  /**
   * Save Form when User presses Enter Key
   */
  handle__routineNameEnter = event => {
    if (event.key === "Enter") {
      this.handle__routineSave();
    }
  };

  /**
   * Save new or edited Routine to Database
   */
  handle__routineSave = () => {
    const { selectedRoutine, year, month, selectedTab } = this.state;

    axios
      .post(
        this.props.context.apiEndpoints.routinesSave,
        {
          routine: selectedRoutine,
          year: year,
          month: month,
          type: selectedTab
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const { routines, morningRoutines, eveningRoutines } = response.data;
        this.setState({ routines, morningRoutines, eveningRoutines });
        this.handle__hideRoutinePopup();
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  /**
   * Ask for delete confirmation
   */
  handle__routineConfirmDelete = routine => {
    const { year, month } = this.state;
    const selectedRoutine = JSON.parse(JSON.stringify(routine));
    this.setState({ selectedRoutine });

    if (
      window.confirm(
        "Möchtest Du die ausgewählte Routine wirklich unwiderruflich löschen? Deine Fortschritte in dieser Routine sind dann für immer verloren."
      )
    ) {
      axios
        .post(
          this.props.context.apiEndpoints.routinesDelete,
          {
            routine_id: selectedRoutine.id,
            year: year,
            month: month
          },
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          const { routines, morningRoutines, eveningRoutines } = response.data;
          this.setState({ routines, morningRoutines, eveningRoutines });
        })
        .catch(error => {
          console.log("Error", error);
        });
    } else {
      return false;
    }
  };

  /**
   * Hide Day Popup
   */
  handle__hideDayPopup = () => {
    this.setState({ showDayPopup: false });
  };

  /**
   * Show Popup for commenting and finishing a day
   */
  handle__showDayPopup(day, routine) {
    if (!day.future && day.dayNr) {
      this.setState({
        selectedDay: JSON.parse(JSON.stringify(day)),
        selectedRoutine: routine,
        showDayPopup: true
      });
    }
  }

  /**
   * Hide Day Popup
   */
  handle__hideDayPopup = () => {
    this.setState({
      showDayPopup: false,
      selectedRoutine: null,
      selectedDay: null
    });
  };

  /**
   * Handle Change of Finished Status of Day
   */
  handle__dayFinishedSwitch = () => {
    const { year, month, selectedDay, selectedRoutine } = this.state;

    if (selectedDay.finished === true) {
      selectedDay.finished = false;
    } else {
      selectedDay.finished = true;
    }

    this.setState(selectedDay);

    // Update in Database
    axios
      .patch(
        this.props.context.apiEndpoints.routinesDayFinish,
        {
          routine_day_id: selectedDay.dayId,
          routine_id: selectedRoutine.id,
          year: year,
          month: month,
          day: selectedDay.dayNr
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const {
          routines,
          morningRoutines,
          eveningRoutines,
          dayId
        } = response.data;
        selectedDay.dayId = dayId;
        this.setState({
          routines,
          morningRoutines,
          eveningRoutines,
          selectedDay
        });
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  /**
   * Handle Changes in Notes Field
   */
  handle__dayNotesChanges = event => {
    const newValue = event.target.value;
    const { selectedDay } = this.state;

    selectedDay.note = newValue;

    this.setState({ selectedDay });
  };

  /**
   * Save notes for selected Day
   */
  handle__dayNotesSave = () => {
    const { selectedDay, selectedRoutine, year, month } = this.state;

    axios
      .patch(
        this.props.context.apiEndpoints.routinesDaySave,
        {
          day: selectedDay,
          routine_id: selectedRoutine.id,
          year: year,
          month: month
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const { routines } = response.data;
        this.setState({ routines });
        this.handle__hideDayPopup();
      })
      .catch(error => {
        console.log("Error", error);
      });
  };

  /**
   * Handle Changes on Month Selection
   */
  handle__monthSelection(event, type) {
    const newValue = event.target.value;

    if (type === "month") {
      this.setState({ month: newValue }, () => {
        // Switch Month
        this.handle__switchMonth();
      });
    } else if (type === "year") {
      this.setState({ year: newValue }, () => {
        // Switch Month
        this.handle__switchMonth();
      });
    }
  }

  /**
   * Handle click on left arrow for previous month
   */
  handle__prevMonth = () => {
    const result = this.check__isPrevMonthAvailable();

    if (result !== false) {
      this.setState({ year: result.year, month: result.month }, () => {
        // Switch Month
        this.handle__switchMonth();
      });
    }
  };

  /**
   * Handle click on right arrow for next month
   */
  handle__nextMonth = () => {
    const result = this.check__isNextMonthAvailable();

    if (result !== false) {
      this.setState({ year: result.year, month: result.month }, () => {
        // Switch Month
        this.handle__switchMonth();
      });
    }
  };

  /**
   * Switch Month
   */
  handle__switchMonth() {
    const { year, month } = this.state;
    const url = this.props.context.generateRoutinesUrl(year, month);
    this.props.history.push(url);
  }

  /**
   * Check if next month is available
   */
  check__isPrevMonthAvailable() {
    let { years, year, month } = this.state;
    month--;
    if (month === 0) {
      if (years.includes(parseInt(year) - 1)) {
        month = 12;
        year--;
      } else {
        return false;
      }
    }
    return { month: month, year: year };
  }

  /**
   * Check if next month is available
   */
  check__isNextMonthAvailable() {
    let { years, year, month } = this.state;
    month++;
    if (month === 13) {
      if (years.includes(parseInt(year) + 1)) {
        month = 1;
        year++;
      } else {
        return false;
      }
    }
    return { month: month, year: year };
  }

  /**
   * Init
   * Check Month & Year
   */
  init__checkDate() {
    // Check for month and year
    const now = new Date();

    let year = 0;
    if (!this.props.match.params.year) {
      year = now.getFullYear();
    } else {
      year = this.props.match.params.year;
    }

    let month = 0;
    if (!this.props.match.params.month) {
      month = now.getMonth() + 1;
    } else {
      month = this.props.match.params.month;
    }

    // Save month and year to state and load routines afterwards
    this.setState({ year, month }, () => {
      // Load Routines
      this.init__loadRoutines();
    });
  }

  /**
   * Init
   * Load Routines
   */
  init__loadRoutines() {
    const { year, month } = this.state;
    axios
      .get(
        this.props.context.apiEndpoints.routines + "/" + year + "/" + month,
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        const {
          routines,
          morningRoutines,
          eveningRoutines,
          years
        } = response.data;
        this.setState({ routines, morningRoutines, eveningRoutines, years });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(Routines);
