import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import PTTabs from "../../ptTabs/ptTabs";
import qs from "query-string";
import { isUndefined } from "util";

class AdminVideoRequests extends Component {
  state = {
    videoRequests: {},
    tabs: {
      4: {
        name: "SELFMADE",
        icon: "",
        selected: true
      }
    },
    selectedTab: 4,
    viewPrint: false
  };

  componentDidMount() {
    this.init__load();

    const queryParams = qs.parse(this.props.location.search);
    if (
      !isUndefined(queryParams.view) &&
      queryParams.view === "print" &&
      !isUndefined(queryParams.course)
    ) {
      this.setState({
        viewPrint: true,
        selectedTab: parseInt(queryParams.course)
      });
    }
  }

  render() {
    const { viewPrint } = this.state;

    if (viewPrint === true) {
      return this.view__showPrintView();
    } else {
      return (
        <AdminLayout active="videorequests">
          {this.view__showVideoRequests()}
        </AdminLayout>
      );
    }
  }

  view__showVideoRequests() {
    const { selectedTab, videoRequests } = this.state;

    let courseVideoRequests = [];

    if (videoRequests && videoRequests[selectedTab]) {
      courseVideoRequests = videoRequests[selectedTab];
    } else {
      return null;
    }

    const printViewUrl = "?view=print&course=" + selectedTab;

    return (
      <Grid>
        <Row>
          <Cell sm={24}>
            <h1>Video Themenwünsche</h1>
            <p className="lead">Chronologische Auflistung der Videowünsche.</p>

            <PTTabs
              tabs={this.state.tabs}
              onUpdate={tabs => {
                let selectedTab = "";
                Object.keys(tabs).map((keyName, index) => {
                  if (tabs[keyName].selected === true) {
                    selectedTab = keyName;
                  }
                  return null;
                });
                this.setState({ tabs, selectedTab });
              }}
            />
            <div>&nbsp;</div>

            <div className="admin-box">
              <div className="admin-box-content">
                <Grid type="full">
                  <Row>
                    <Cell sm={24} md={18}>
                      <h3>Folgende Videothemen wurden gewünscht:</h3>
                    </Cell>
                    <Cell sm={24} md={6} classes="text-right">
                      <a
                        href={printViewUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="small primary button"
                      >
                        <Icon icon="print" left /> Druckansicht
                      </a>
                    </Cell>
                  </Row>
                </Grid>

                {this.view__showVideoRequestsList(courseVideoRequests)}
              </div>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showVideoRequestsList(videoRequests) {
    if (videoRequests && videoRequests.length > 0) {
      return (
        <React.Fragment>
          <ul className="admin-list hide-for-small-only">
            <li className="admin-list-item admin-list-title">
              <Grid type="full">
                <Row>
                  <Cell sm={6} md={1}>
                    &nbsp;
                  </Cell>
                  <Cell sm={18} md={4}>
                    Datum
                  </Cell>
                  <Cell sm={24} md={4}>
                    Name
                  </Cell>
                  <Cell sm={24} md={15}>
                    Wunsch
                  </Cell>
                </Row>
              </Grid>
            </li>
          </ul>
          <ul className="striped admin-list">
            {videoRequests.map((videoRequest, index) => {
              return (
                <li className="admin-list-item" key={index}>
                  <Grid type="full">
                    <Row>
                      <Cell sm={2} md={1}>
                        {index + 1}.
                      </Cell>
                      <Cell sm={22} md={4}>
                        <div className="show-for-small-only">
                          <div>
                            <small>
                              <a
                                href={`/admin/users/${videoRequest.owner}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#b00606" }}
                              >
                                {videoRequest.firstname}
                              </a>{" "}
                              @{videoRequest.request_date} -{" "}
                              {videoRequest.request_time} Uhr
                            </small>
                          </div>
                          <div>{videoRequest.topic}</div>
                        </div>
                        <div className="hide-for-small-only">
                          {videoRequest.request_date} -{" "}
                          {videoRequest.request_time} Uhr
                        </div>
                      </Cell>
                      <Cell md={4} classes="hide-for-small-only">
                        <a
                          href={`/admin/users/${videoRequest.owner}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#b00606" }}
                        >
                          {videoRequest.firstname}
                        </a>
                      </Cell>
                      <Cell md={15} classes="hide-for-small-only">
                        {videoRequest.topic}
                      </Cell>
                    </Row>
                  </Grid>
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      );
    } else {
      return (
        <p className="text-center">
          <em>
            <small>Keine Themenwünsche.</small>
          </em>
        </p>
      );
    }
  }

  view__showPrintView() {
    const { selectedTab, videoRequests } = this.state;

    let courseVideoRequests = [];

    if (videoRequests && videoRequests[selectedTab]) {
      courseVideoRequests = JSON.parse(
        JSON.stringify(videoRequests[selectedTab])
      );
      courseVideoRequests = courseVideoRequests.reverse();
    } else {
      return null;
    }

    return (
      <div className="print-view">
        <h3>Videowünsche</h3>
        <p>&nbsp;</p>
        {courseVideoRequests.map((videoRequest, index) => {
          return (
            <React.Fragment key={index}>
              <div className="admin-list-item" key={index}>
                <Grid type="full">
                  <Row>
                    <Cell sm={6} md={1}>
                      {index + 1}.
                    </Cell>
                    <Cell sm={24} md={5}>
                      {videoRequest.firstname}
                    </Cell>
                    <Cell sm={24} md={18}>
                      {videoRequest.topic}
                    </Cell>
                  </Row>
                </Grid>
              </div>
              <hr />
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  /**
   * Init
   * Load Questions
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.adminVideoRequests, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { videoRequests } = response.data;
        this.setState({ videoRequests }, () => {
          if (this.state.viewPrint === true) {
            setTimeout(() => {
              window.print();
            }, 500);
          }
        });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminVideoRequests);
