import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import Breadcrumbs from "../topbars/breadcrumbs";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import axios from "axios";
import withContext from "../../context/contextHOC";
import Avatar from "./_avatar";
import ReactTooltip from "react-tooltip";
import Footer from "../layout/footer";

class UserProfile extends Component {
  state = {
    userUsername: this.props.match.params.userUsername,
    userProfile: {},
    breadcrumbs: ["dashboard", "community"]
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    return (
      <React.Fragment>
        <div
          id="pageUserProfile"
          className="darkmode page-with-topbar-and-breadcrumbs default-page"
        >
          <MainTopbar />
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <section className="content">
            <Grid>
              <Row>
                <Cell sm={24}>{this.view__showUserProfile()}</Cell>
              </Row>
            </Grid>
          </section>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  view__showUserProfile() {
    const { userProfile } = this.state;

    if (!userProfile) {
      return null;
    }

    if (userProfile === "notfound") {
      return <p className="lead text-center">Profil nicht gefunden.</p>;
    }

    if (!userProfile.id) {
      return null;
    }

    return (
      <div>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24} md={6}>
              <div className="userprofile-avatar">
                <Avatar size="userprofile" user={userProfile} />
              </div>
              {this.view__showForumTitle()}
              {this.view__showRegisteredSince()}
              {this.view__showLinks("icons")}
            </Cell>
            <Cell sm={24} md={18}>
              {this.view__showEditButton()}
              <h2>{`${userProfile.firstname} ${userProfile.lastname}`}</h2>
              {this.view__showProfession()}
              <div>&nbsp;</div>
              {this.view__showBio()}
              {this.view__showOffer()}
              {this.view__showSearch()}
              {this.view__showLinks("list")}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showRegisteredSince() {
    const { userProfile } = this.state;

    return (
      <div className="userprofile-registered-since">
        angemeldet seit
        <br />
        <strong>{userProfile.registered_since}</strong>
      </div>
    );
  }

  view__showForumTitle() {
    const { userProfile } = this.state;

    if (userProfile.forum_title) {
      return (
        <div className="userprofile-forumtitle">
          <span className="primary label">{userProfile.forum_title}</span>
        </div>
      );
    }
  }

  view__showEditButton() {
    const { userProfile } = this.state;
    const _c = this.props.context;
    const { user } = _c;

    if (
      user &&
      user.id &&
      userProfile &&
      userProfile.id &&
      user.id === userProfile.id
    ) {
      return (
        <div className="float-right">
          <a href="/profil" className="primary button">
            <Icon icon="edit" left /> Mein Profil bearbeiten
          </a>
        </div>
      );
    }

    return null;
  }

  view__showLinks(type) {
    const { userProfile } = this.state;
    const _c = this.props.context;
    const { socialPlatforms } = _c;

    if (
      !userProfile ||
      !userProfile.user_details ||
      !userProfile.user_details.socials ||
      !userProfile.user_details.socials.length === 0
    ) {
      return null;
    }

    if (type === "icons") {
      return (
        <div className="userprofile-sociallinks">
          <ReactTooltip place="top" type="dark" effect="solid" />
          <Grid type="full">
            <Row>
              <Cell sm={24} md={24} classes="text-center">
                {userProfile.user_details.socials.map((socialLink, index) => {
                  let link;

                  if (socialPlatforms[socialLink.type]) {
                    link = (
                      <a
                        key={index}
                        href={socialLink.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="userprofile-sociallinks-icon"
                        data-tip={socialPlatforms[socialLink.type].name}
                        onClick={() => {
                          this.handle__click(socialLink.type);
                        }}
                      >
                        <Icon icon={socialPlatforms[socialLink.type].icon} />
                      </a>
                    );
                  }

                  return link;
                })}
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    } else if (type === "list") {
      return (
        <div className="userprofile-sociallinks">
          <Grid type="full">
            <Row margin="xy">
              {userProfile.user_details.socials.map((socialLink, index) => {
                let link;

                if (socialPlatforms[socialLink.type]) {
                  link = (
                    <Cell sm={24} md={8} classes="text-center" key={index}>
                      <a
                        href={socialLink.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="primary button userprofile-sociallinks-list-item"
                        onClick={() => {
                          this.handle__click(socialLink.type);
                        }}
                      >
                        <Icon icon={socialPlatforms[socialLink.type].icon} />{" "}
                        <span className="link-title">
                          {socialPlatforms[socialLink.type].name}
                        </span>
                      </a>
                    </Cell>
                  );
                }

                return link;
              })}
            </Row>
          </Grid>
        </div>
      );
    }
  }

  view__showProfession() {
    const { userProfile } = this.state;

    if (userProfile.user_details.profession) {
      return (
        <h3 className="userprofile-profession">
          {userProfile.user_details.profession}
        </h3>
      );
    }
  }

  view__showBio() {
    const { userProfile } = this.state;

    if (userProfile.user_details.bio_f) {
      return (
        <div className="userprofile-text userprofile-bio">
          <h3>Biografie</h3>
          <div
            dangerouslySetInnerHTML={{ __html: userProfile.user_details.bio_f }}
          />
        </div>
      );
    }
  }

  view__showOffer() {
    const { userProfile } = this.state;

    if (userProfile.user_details.offer_f) {
      return (
        <div className="userprofile-text userprofile-offer">
          <h3>Was ich anbiete</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: userProfile.user_details.offer_f
            }}
          />
        </div>
      );
    }
  }

  view__showSearch() {
    const { userProfile } = this.state;

    if (userProfile.user_details.search_f) {
      return (
        <div className="userprofile-text userprofile-search">
          <h3>Was ich suche</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: userProfile.user_details.search_f
            }}
          />
        </div>
      );
    }
  }

  handle__click(type) {
    const { userProfile } = this.state;

    axios
      .post(
        this.props.context.apiEndpoints.userProfileClick +
          "/" +
          userProfile.id +
          "/" +
          type,
        {},
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        //
      })
      .catch(error => {
        console.log("Error", error);
      });
  }

  init() {
    const { userUsername } = this.state;
    axios
      .get(this.props.context.apiEndpoints.userProfile + "/" + userUsername, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { userProfile } = response.data;

        // Breadcrumbs
        let { breadcrumbs } = this.state;
        const bc = {
          id: "userprofile",
          to: "/community",
          name: `${userProfile.firstname} ${userProfile.lastname}`
        };
        breadcrumbs.push(bc);

        this.setState({ userProfile, breadcrumbs });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(UserProfile);
