import React, { Component } from "react";
import PTPopup from "../../ptPopup/ptPopup";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import { isUndefined } from "util";

class RecommendedYoutubeChannels extends Component {
  state = {
    showPopup: false,
    channels: this.props.channels
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.channels !== this.props.channels) {
      this.setState({ channels: this.props.channels });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          id="selfmadeGridRecommendedYoutubeChannels"
          className="selfmade-grid-item selfmade-grid-item-small selfmade-grid-bg-darkgray"
          onClick={this.show__popupEdit}
        >
          <div className="selfmade-grid-item-content">
            <div className="selfmade-grid-icon">
              <Icon icon="youtube-play" />
            </div>
            <div className="selfmade-grid-caption grid-caption">
              Torbens Youtube-
              <br />
              Empfehlungen
            </div>
          </div>
        </div>
        <div className="selfmade-popup">
          <PTPopup
            show={this.state.showPopup}
            size="large"
            handleClose={() => {
              this.hide__popupEdit();
            }}
          >
            {this.show__popupContent()}
          </PTPopup>
        </div>
      </React.Fragment>
    );
  }

  show__popupContent() {
    const { channels } = this.state;

    if (!isUndefined(channels) && channels.length > 0) {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <h2>Torbens Youtube Empfehlungen</h2>
              <div>&nbsp;</div>
            </Cell>
          </Row>
          {channels.map((channel, index) => {
            if (index < 3) {
              return (
                <Row key={index}>
                  <Cell sm={24} md={8}>
                    <div className="youtube-recommendation-caption">
                      Empfehlung #{index + 1}
                    </div>
                    <div className="youtube-recommendation-title">
                      <a
                        href={channel.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {channel.channel_title}
                      </a>
                    </div>
                  </Cell>
                  <Cell sm={24} md={16}>
                    {this.show__videos(channel.videos)}
                  </Cell>
                </Row>
              );
            } else {
              return null;
            }
          })}
        </Grid>
      );
    } else {
      return (
        <p className="text-center">
          Youtube Empfehlungen konnten nicht geladen werden.
        </p>
      );
    }
  }

  show__videos(videos) {
    if (!isUndefined(videos) && videos.length > 0) {
      return (
        <div className="youtube-recommendation-videos">
          <Grid>
            <Row padding="x">
              {videos.map((video, index) => {
                return (
                  <Cell sm={24} md={8} key={index}>
                    <a
                      href={this.getVideoUrl(video)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="youtube-recommendation-video-link"
                    >
                      <div className="youtube-recommendation-video-image">
                        <img src={video.thumbnail} alt="" />
                      </div>
                      <div className="youtube-recommendation-video-title">
                        {this.show__videoTitle(video.title)}
                      </div>
                    </a>
                  </Cell>
                );
              })}
            </Row>
          </Grid>
        </div>
      );
    } else {
      return null;
    }
  }

  show__videoTitle(videoTitle) {
    if (videoTitle.length > 50) {
      return videoTitle.substr(0, 50) + "...";
    } else {
      return videoTitle;
    }
  }

  show__popupEdit = () => {
    this.setState({ showPopup: true });
  };

  hide__popupEdit = () => {
    this.setState({ showPopup: false });
  };

  getVideoUrl(video) {
    if (!isUndefined(video) && video.video_id) {
      return "https://www.youtube.com/watch?v=" + video.video_id;
    }
  }
}

export default RecommendedYoutubeChannels;
