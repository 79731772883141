import React, { Component } from "react";
import SelfmadeLayout from "./selfmadeLayout";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import withContext from "../../context/contextHOC";
import TodoBig from "./elements/todoBig";
import QuoteOfTheDay from "./elements/qotd";
import VisionboardImage from "./elements/visionboardImage";
import axios from "axios";
import LinkBox from "./elements/linkbox";
import MissionStatement from "./elements/missionStatement";
import { isUndefined } from "util";
import RevenueGoals from "./elements/revenueGoals";
import BookOfTheWeek from "./elements/bookOfTheWeek";
import QuestionForTorben from "./elements/questionForTorben";
import ScheduledRoutines from "./elements/scheduledRoutines";
import RecommendedYoutubeChannels from "./elements/recommendedYoutubeChannels";
import Live from "../live/live";
import WorkspaceCalendar from "./elements/calendar";
import VideoTopicRequest from "./elements/videoTopicRequest";
import AnnouncementBox from "../selfmade/AnnouncementBox";

class SelfmadeIndex extends Component {
  state = {
    visionboardImages: [],
    defaultVisionboardImages: [],
    quote: {},
    book: {},
    monthlyRevenueGoal: {},
    weeklyRevenueGoal: {},
    morningRoutines: [],
    eveningRoutines: [],
    recommendedYoutubeChannels: [],
    calendarEntries: []
  };

  componentDidMount() {
    this.init__load();
    //this.init__loadVisionboardImages();
  }

  render() {
    return (
      <SelfmadeLayout
        breadcrumbs={["dashboard", "workspace"]}
        favorite={{ name: "Workspace", page: "/workspace", icon: "workspace" }}
      >
        <Grid>
          <Row>
            <Cell sm={24}>
              <div style={{ marginBottom: "20px" }}>
                <AnnouncementBox type="selfmade_relaunch_august_2019" />
              </div>

              <div id="selfmadeGrid">
                <Grid type="full">
                  <Row>
                    <Cell sm={24} md={10}>
                      <QuoteOfTheDay
                        quote={this.state.quote}
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          1,
                          "blur"
                        )}
                      />
                    </Cell>
                    <Cell sm={24} md={14}>
                      <VisionboardImage
                        position={1}
                        type="normal"
                        showMadeLogo={true}
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          1,
                          "normal"
                        )}
                      />
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={24} md={10}>
                      <TodoBig
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          1,
                          "blur"
                        )}
                      />
                    </Cell>
                    <Cell sm={24} md={14}>
                      <Grid type="full">
                        <Row>
                          <Cell sm={24} md={8}>
                            {this.show__selfmadeCourseLink()}
                          </Cell>
                          <Cell sm={24} md={8}>
                            <RevenueGoals
                              monthlyRevenueGoal={this.state.monthlyRevenueGoal}
                              weeklyRevenueGoal={this.state.weeklyRevenueGoal}
                              onUpdate={(
                                monthlyRevenueGoal,
                                weeklyRevenueGoal
                              ) => {
                                this.setState({
                                  monthlyRevenueGoal,
                                  weeklyRevenueGoal
                                });
                              }}
                            />
                          </Cell>
                          <Cell sm={24} md={8}>
                            <LinkBox
                              link="/workspace/visionboard"
                              id="selfmadeGridVisionBoard"
                              classes=" selfmade-grid-bg-bright"
                              caption="Vision Board"
                              icon="eye"
                              backgroundImageUrl={this.get__visionboardImageUrl(
                                1,
                                "normal"
                              )}
                            />
                          </Cell>
                        </Row>
                      </Grid>
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={24} md={10}>
                      <div
                        id="selfmadeGridMissionStatement"
                        className="selfmade-grid-bg-blur"
                      >
                        <div className="selfmade-grid-item selfmade-grid-bg-darker">
                          <Grid type="full">
                            <Row>
                              <Cell sm={24} md={12}>
                                <Live type="grid" />
                              </Cell>
                              <Cell sm={24} md={12}>
                                {this.show__videoTopicRequest()}
                              </Cell>
                            </Row>
                          </Grid>
                        </div>
                      </div>
                    </Cell>
                    <Cell sm={24} md={14}>
                      <Grid type="full">
                        <Row>
                          <Cell sm={24} md={8}>
                            {this.show__selfmadeCourseNextVideo()}
                          </Cell>
                          <Cell sm={24} md={16}>
                            <MissionStatement
                              missionStatement={
                                this.props.context.user.mission_statement
                              }
                            />
                          </Cell>
                        </Row>
                      </Grid>
                    </Cell>
                  </Row>
                  {/* ROW 4 */}
                  <Row>
                    <Cell sm={24} md={10}>
                      <ScheduledRoutines
                        morningRoutines={this.state.morningRoutines}
                        eveningRoutines={this.state.eveningRoutines}
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          1,
                          "blur"
                        )}
                      />
                    </Cell>
                    <Cell sm={24} md={14}>
                      <Grid type="full">
                        <Row>
                          <Cell sm={24} md={16}>
                            <WorkspaceCalendar
                              entries={this.state.calendarEntries}
                            />
                          </Cell>
                          <Cell sm={24} md={8}>
                            <a
                              href="/community"
                              id="selfmadeGridCommunity"
                              className="selfmade-grid-item selfmade-grid-item-small selfmade-grid-bg-darkergray"
                            >
                              <div className="selfmade-grid-item-content">
                                <div className="selfmade-grid-icon">
                                  <Icon icon="comments-o" />
                                </div>
                                <div className="selfmade-grid-caption grid-caption">
                                  Community
                                </div>
                              </div>
                            </a>
                          </Cell>
                        </Row>
                      </Grid>
                    </Cell>
                  </Row>
                  {/* END ROW 4 */}
                  {/* ROW 5 */}
                  <Row>
                    <Cell sm={24} md={10}>
                      <Grid type="full">
                        <Row>
                          <Cell sm={24} md={12}>
                            <VisionboardImage
                              position={3}
                              type="normal"
                              size="small"
                              showMadeLogo={false}
                              backgroundImageUrl={this.get__visionboardImageUrl(
                                3,
                                "normal"
                              )}
                            />
                          </Cell>
                          <Cell sm={24} md={12}>
                            <RecommendedYoutubeChannels
                              channels={this.state.recommendedYoutubeChannels}
                            />
                          </Cell>
                        </Row>
                      </Grid>
                    </Cell>
                    <Cell sm={24} md={14}>
                      <Grid type="full">
                        <Row>
                          <Cell sm={24} md={8}>
                            <VisionboardImage
                              position={4}
                              type="normal"
                              size="small"
                              showMadeLogo={false}
                              backgroundImageUrl={this.get__visionboardImageUrl(
                                4,
                                "normal"
                              )}
                            />
                          </Cell>
                          <Cell sm={24} md={8}>
                            <a
                              href="http://bit.ly/tpa-innercircle"
                              target="_blank"
                              rel="noopener noreferrer"
                              id="selfmadeGridWhatsApp"
                              className="selfmade-grid-item selfmade-grid-item-small"
                            >
                              <div className="selfmade-grid-item-content">
                                <div className="selfmade-grid-icon">
                                  <Icon icon="telegram" />
                                </div>
                                <div className="selfmade-grid-caption grid-caption">
                                  SELFMADE
                                  <br />
                                  Inner Circle
                                </div>
                              </div>
                            </a>
                          </Cell>
                          <Cell sm={24} md={8}>
                            <VisionboardImage
                              position={5}
                              type="normal"
                              size="small"
                              showMadeLogo={false}
                              backgroundImageUrl={this.get__visionboardImageUrl(
                                5,
                                "normal"
                              )}
                            />
                          </Cell>
                        </Row>
                      </Grid>
                    </Cell>
                  </Row>
                  {/* END ROW 5 */}
                  {/* ROW 6 */}
                  <Row>
                    <Cell sm={24} md={5}>
                      <VisionboardImage
                        position={6}
                        type="normal"
                        size="small"
                        showMadeLogo={false}
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          6,
                          "normal"
                        )}
                      />
                    </Cell>
                    <Cell sm={24} md={5}>
                      <VisionboardImage
                        position={7}
                        type="normal"
                        size="small"
                        showMadeLogo={false}
                        backgroundImageUrl={this.get__visionboardImageUrl(
                          7,
                          "normal"
                        )}
                      />
                    </Cell>
                    <Cell sm={24} md={14}>
                      <Grid type="full">
                        <Row>
                          <Cell sm={24} md={8}>
                            <VisionboardImage
                              position={8}
                              type="normal"
                              size="small"
                              showMadeLogo={false}
                              backgroundImageUrl={this.get__visionboardImageUrl(
                                8,
                                "normal"
                              )}
                            />
                          </Cell>
                          <Cell sm={24} md={16}>
                            <BookOfTheWeek book={this.state.book} />
                          </Cell>
                        </Row>
                      </Grid>
                    </Cell>
                  </Row>
                  {/* END ROW 6 */}
                </Grid>
              </div>
            </Cell>
          </Row>
        </Grid>
      </SelfmadeLayout>
    );
  }

  show__selfmadeCourseLink() {
    let selfmadeStatus = {
      active: false,
      url: null
    };

    if (this.props.context.user && this.props.context.user.selfmadeStatus) {
      selfmadeStatus = this.props.context.user.selfmadeStatus;
    }

    if (selfmadeStatus.active === true) {
      return (
        <LinkBox
          id="selfmadeGridSelfmadeCourse"
          link={selfmadeStatus.url}
          caption="Zum Kurs"
        />
      );
    } else {
      return (
        <LinkBox
          id="selfmadeGridSelfmadeCourse"
          link={selfmadeStatus.url}
          target="_blank"
          rel="noopener noreferrer"
          caption="Zum Kurs"
        />
      );
    }
  }

  show__selfmadeCourseNextVideo() {
    let selfmadeStatus = {
      active: false,
      url: null
    };

    if (this.props.context.user && this.props.context.user.selfmadeStatus) {
      selfmadeStatus = this.props.context.user.selfmadeStatus;
    }

    // Für Freitage
    let title = "<p>Neues Video:<br/>";
    let title2 = "";

    const now = new Date();

    if (now.getDay() === 5) {
      title2 =
        '<span style="background-color: #b00606; color: white; padding: 3px 6px;">Heute, 18 Uhr</span></p>';
    } else if (now.getDay() === 4) {
      title2 = '<span style="color: #b00606">Morgen, 18 Uhr</span></p>';
    } else {
      title2 = '<span style="color: #b00606">Freitag, 18 Uhr</span></p>';
    }

    const caption = title + title2;

    if (selfmadeStatus.active === true) {
      return (
        <LinkBox
          id="selfmadeGridNextVideo"
          link={selfmadeStatus.url}
          icon="play-circle"
          caption={caption}
          backgroundColor="#222"
        />
      );
    } else {
      return (
        <LinkBox
          id="selfmadeGridNextVideo"
          link={selfmadeStatus.url}
          target="_blank"
          rel="noopener noreferrer"
          icon="play-circle"
          caption={caption}
          backgroundColor="#222"
        />
      );
    }
  }

  show__questionForTorben() {
    let selfmadeStatus = {
      active: false,
      url: null
    };

    if (this.props.context.user && this.props.context.user.selfmadeStatus) {
      selfmadeStatus = this.props.context.user.selfmadeStatus;
    }

    return (
      <QuestionForTorben
        type="workspace"
        course={4}
        backgroundImageUrl={this.get__visionboardImageUrl(1, "blur")}
        selfmadeCoursePurchased={selfmadeStatus.active}
      />
    );
  }

  show__videoTopicRequest() {
    let selfmadeStatus = {
      active: false,
      url: null
    };

    if (this.props.context.user && this.props.context.user.selfmadeStatus) {
      selfmadeStatus = this.props.context.user.selfmadeStatus;
    }

    return (
      <VideoTopicRequest
        type="workspace"
        course={4}
        backgroundImageUrl={this.get__visionboardImageUrl(1, "blur")}
        selfmadeCoursePurchased={selfmadeStatus.active}
      />
    );
  }

  /**
   * Get Visionboard Image Url by Position and Type
   */
  get__visionboardImageUrl = (position, type) => {
    const { visionboardImages, defaultVisionboardImages } = this.state;

    let visionboardImage = undefined;

    visionboardImages.map(image => {
      if (image.position && image.position === position) {
        visionboardImage = image;
      }
      return null;
    });

    if (isUndefined(visionboardImage)) {
      defaultVisionboardImages.map(image => {
        if (image.position && image.position === position) {
          visionboardImage = image;
        }
        return null;
      });
    }

    if (isUndefined(visionboardImage)) {
      return null;
    }

    if (type === "blur") {
      return visionboardImage.url_blur;
    } else {
      return visionboardImage.url;
    }
  };

  /**
   * Load Vision Board Images
   */
  init__loadVisionboardImages() {
    axios
      .get(this.props.context.apiEndpoints.selfmadeVisionboardImages, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { visionboardImages, defaultVisionboardImages } = response.data;
        this.setState({ visionboardImages, defaultVisionboardImages });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }

  /**
   * Load Everything
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.selfmadeLoad, {
        headers: this.props.context.headers
      })
      .then(response => {
        const _c = this.props.context;
        if (_c.isDebug()) {
          console.log("WORKSPACE INIT", response.data);
        }

        const {
          quote,
          book,
          monthlyRevenueGoal,
          weeklyRevenueGoal,
          morningRoutines,
          eveningRoutines,
          recommendedYoutubeChannels,
          visionboardImages,
          defaultVisionboardImages,
          calendarEntries
        } = response.data;

        this.setState({
          quote,
          book,
          monthlyRevenueGoal,
          weeklyRevenueGoal,
          morningRoutines,
          eveningRoutines,
          recommendedYoutubeChannels,
          visionboardImages,
          defaultVisionboardImages,
          calendarEntries
        });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(SelfmadeIndex);
