import React, { Component } from "react";
import { isUndefined } from "util";

class Avatar extends Component {
  state = {
    user: this.props.user,
    size: this.props.size
  };

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.setState({
        user: this.props.user
      });
    }
  }

  render() {
    const { user } = this.state;

    if (user) {
      if (user.avatar) {
        return (
          <img src={user.avatar} alt="" className={this.getAvatarClasses()} />
        );
      } else {
        return (
          <div className={this.getAvatarClasses()}>
            {this.getFirstname()}
            {this.getLastname()}
          </div>
        );
      }
    } else {
      return <div className={this.getAvatarClasses()}>&nbsp;&nbsp;</div>;
    }
  }

  getAvatarClasses() {
    let classes = "avatar avatar-no-image ";
    classes += this.getAvatarSize();
    return classes;
  }

  getAvatarSize() {
    const { size } = this.state;

    if (size === "forumPost") {
      return "avatar-size-forum-post";
    } else if (size === "forumReply") {
      return "avatar-size-forum-reply";
    } else if (size === "userinfoTooltip") {
      return "avatar-size-userinfo-tooltip";
    } else if (size === "forumThread") {
      return "avatar-size-forum-thread";
    } else if (size === "userprofile") {
      return "avatar-size-userprofile";
    } else if (size === "topbar") {
      return "avatar-size-topbar";
    } else if (size === "mainTopbar") {
      return "avatar-size-main-topbar";
    } else if (size === "adminUserView") {
      return "avatar-size-admin-user-view";
    }
  }

  getFirstname() {
    const { user } = this.state;

    if (user.firstname) {
      return user.firstname.charAt(0);
    }
  }

  getLastname() {
    const { user } = this.state;

    if (user.lastname) {
      return user.lastname.charAt(0);
    }
  }
}

export default Avatar;
