import React, { Component } from "react";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import { isUndefined } from "util";

import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

class ScheduledRoutines extends Component {
  state = {
    morningRoutines: this.props.morningRoutines,
    eveningRoutines: this.props.eveningRoutines,
    backgroundImageUrl: this.props.backgroundImageUrl,
    viewMode: "morning"
  };

  componentDidMount() {
    // Automatically change to evening routine if time is after 12pm
    const now = new Date();
    if (now.getHours() >= 12) {
      this.setState({ viewMode: "evening" });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.morningRoutines !== this.props.morningRoutines) {
      this.setState({ morningRoutines: this.props.morningRoutines });
    }

    if (prevProps.eveningRoutines !== this.props.eveningRoutines) {
      this.setState({ eveningRoutines: this.props.eveningRoutines });
    }

    if (prevProps.backgroundImageUrl !== this.props.backgroundImageUrl) {
      this.setState({ backgroundImageUrl: this.props.backgroundImageUrl });
    }
  }

  render() {
    //const { backgroundImageUrl } = this.state;
    return (
      <div
        id="selfmadeGridScheduledRoutines"
        className="selfmade-grid-bg-darkergray"
      >
        <div className="selfmade-grid-item">
          <div className="selfmade-grid-item-content">
            {this.show__scheduledRoutines()}
          </div>
        </div>
      </div>
    );
  }

  show__scheduledRoutines() {
    const { viewMode, morningRoutines, eveningRoutines } = this.state;

    let routines = [];
    if (viewMode === "morning") {
      if (!isUndefined(morningRoutines.routines)) {
        routines = morningRoutines.routines;
      }
    } else if (viewMode === "evening") {
      if (!isUndefined(eveningRoutines.routines)) {
        routines = eveningRoutines.routines;
      }
    }

    return (
      <div className="selfmade-grid-scheduled-routines">
        {this.show__scheduledRoutinesTitleBar(viewMode)}
        <Grid type="full">
          <Row>
            <Cell sm={24} md={16}>
              <div className="selfmade-todo-wrapper">
                <div className="selfmade-todo-content">
                  <ul className="selfmade-todo-tasks">
                    {routines.map((routine, index) => {
                      return (
                        <React.Fragment key={index}>
                          <li
                            onClick={() => {
                              this.handle__finishRoutine(
                                routine.today,
                                routine
                              );
                            }}
                          >
                            <Grid type="full">
                              <Row>
                                <Cell sm={2} md={2}>
                                  {this.show__routineFinishedIcon(
                                    routine.today
                                  )}
                                </Cell>
                                <Cell sm={22} md={22}>
                                  {this.show__routineName(routine.name)}
                                </Cell>
                              </Row>
                            </Grid>
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </Cell>
            <Cell md={1} classes="hide-for-small-only" />
            <Cell
              sm={12}
              smo={3}
              md={6}
              classes="text-center medium-offset-0 large-offset-0"
            >
              <div>&nbsp;</div>
              {this.show__finishedPercentage()}
            </Cell>
            <Cell md={1} classes="hide-for-small-only" />
          </Row>
        </Grid>
      </div>
    );
  }

  show__scheduledRoutinesTitleBar(type) {
    let title = "";
    if (type === "morning") {
      title = "Meine Morgenroutine";
    } else if (type === "evening") {
      title = "Meine Abendroutine";
    }

    let leftDisabled = false;
    let rightDisabled = false;

    if (type === "morning") {
      leftDisabled = true;
    } else if (type === "evening") {
      rightDisabled = true;
    }

    return (
      <Grid type="full">
        <Row>
          <Cell sm={12} md={12}>
            <h3 className="grid-caption">{title}</h3>
          </Cell>
          <Cell sm={12} md={12} classes="text-right">
            <a
              href="/routinen"
              className="selfmade-todo-new-task"
              title="Routinen verwalten"
              onClick={this.show__popupEdit}
            >
              <Icon icon="cog" />
            </a>
            <button
              className="selfmade-todo-view-switch"
              onClick={this.handle__switchScheduledRoutinesView}
              disabled={leftDisabled}
            >
              <Icon icon="angle-left" left />
            </button>
            <button
              className="selfmade-todo-view-switch"
              onClick={this.handle__switchScheduledRoutinesView}
              disabled={rightDisabled}
            >
              <Icon icon="angle-right" right />
            </button>
          </Cell>
        </Row>
      </Grid>
    );
  }

  show__routineName(routineName) {
    if (routineName.length > 30) {
      return routineName.substr(0, 30) + "...";
    } else {
      return routineName;
    }
  }

  /**
   * Show Icon for finished and unfinished routines
   */
  show__routineFinishedIcon(routineToday) {
    if (routineToday.finished === true) {
      return (
        <span className="selfmade-grid-scheduled-routines-finished">
          <Icon icon="check-square-o" />
          <Icon icon="square-o" />
        </span>
      );
    } else {
      return (
        <span className="selfmade-grid-scheduled-routines-unfinished">
          <Icon icon="square-o" />
          <Icon icon="check-square-o" />
        </span>
      );
    }
  }

  /**
   * Show circular percentage
   */
  show__finishedPercentage() {
    const { viewMode, morningRoutines, eveningRoutines } = this.state;

    let finished = undefined;
    if (viewMode === "morning") {
      finished = morningRoutines.status;
    } else if (viewMode === "evening") {
      finished = eveningRoutines.status;
    } else {
      return null;
    }

    if (!isUndefined(finished) && finished.total) {
      let text = finished.finished + " / " + finished.total;
      let color = "#b00606";

      if (finished.percentage === 100) {
        color = "green";
      } else if (finished.percentage >= 50) {
        color = "orange";
      }

      return (
        <CircularProgressbar
          percentage={finished.percentage}
          text={text}
          strokeWidth={10}
          styles={{
            path: { stroke: color },
            text: { fill: color, fontSize: "20px", fontFamily: "Oswald" }
          }}
        />
      );
    }
  }

  /**
   * Handle CLick on top right arrow buttons in Scheduled Routines View
   */
  handle__switchScheduledRoutinesView = () => {
    let { viewMode } = this.state;

    if (viewMode === "morning") {
      viewMode = "evening";
    } else if (viewMode === "evening") {
      viewMode = "morning";
    }

    this.setState({ viewMode });
  };

  /**
   * Handle Finish Routine
   */
  handle__finishRoutine = (selectedDay, selectedRoutine) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;

    // Update in Database
    axios
      .patch(
        this.props.context.apiEndpoints.routinesDayFinish,
        {
          routine_day_id: selectedDay.dayId,
          routine_id: selectedRoutine.id,
          year: year,
          month: month,
          day: selectedDay.dayNr,
          type: "scheduled"
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        //console.log(response.data);
        const { morningRoutines, eveningRoutines } = response.data;
        this.setState({ morningRoutines, eveningRoutines });
      })
      .catch(error => {
        console.log("Error", error);
      });
  };
}

export default withContext(ScheduledRoutines);
