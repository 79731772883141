import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import MainTopbar from "../topbars/mainTopbar";
import Footer from "../layout/footer";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import axios from "axios";
import AnnouncementBox from "../selfmade/AnnouncementBox";

class Dashboard extends Component {
  state = {
    selfmade_podcast_url: "",
    selfmade_podcast_image: "",
    selfmade_podcast_last_episode_title: "",
    tp_yt_last_video_image: "",
    tp_yt_last_video_url: "",
    tp_yt_last_video_title: "",
    selfmade_podcast_is_new: false,
    tp_yt_is_new: false,
    socialContentIsLoading: true,
    announcements: [],
    purchasedCourses: [],
  };

  componentDidMount() {
    this.init__load();
  }

  render() {
    const { purchasedCourses } = this.state;

    return (
      <div id="pageDashboard" className="page-with-topbar-and-hero">
        <MainTopbar />
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24} lg={24}>
              <div id="dashboardHero">
                <Grid>
                  <Row>
                    <Cell sm={24} md={24} lg={24}>
                      <div id="dashboardHeroContentWrapper">
                        <div id="dashboardHeroContent">
                          <div className="dashboard-courses">
                            <Grid type="full">
                              <Row margin="xy">
                                {purchasedCourses.map((course, index) => {
                                  return (
                                    <Cell sm={24} md={8} key={index}>
                                      {this.showCourse(course)}
                                    </Cell>
                                  );
                                })}
                              </Row>
                            </Grid>
                          </div>

                          <div style={{ margin: "0 34px" }}>
                            <AnnouncementBox type="brand_building_2020" />
                          </div>
                          {this.view__showAnnouncementBox()}
                          {this.view__showSocialContent()}
                          {this.view__showPurchasedCourses()}
                        </div>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            </Cell>
          </Row>
        </Grid>
        <Footer />
      </div>
    );
  }

  showCourse(course) {
    let courseUrl = "/kurse/" + course.identifier;

    let courseImage;
    if (course.image) {
      courseImage = <img src={course.image} alt={course.name} />;
    } else {
      courseImage = (
        <div className="course-image-text">
          <div className="center-vertically">{course.name}</div>
        </div>
      );
    }

    return (
      <a href={courseUrl} className="dashboard-course">
        {courseImage}
      </a>
    );
  }

  showUserName() {
    const { user } = this.props.context;
    if (user && user.firstname) {
      return user.firstname;
    }
  }

  view__showAnnouncementBox() {
    const { announcements } = this.state;

    if (!announcements) {
      return <hr />;
    }

    if (announcements.admin || announcements.dev) {
      return (
        <React.Fragment>
          <div style={{ margin: "20px 34px" }}>
            <AnnouncementBox type="dashboard" announcements={announcements} />
          </div>
        </React.Fragment>
      );
    }
  }

  view__showPurchasedCourses() {
    const { user } = this.props.context;
    const { purchases } = user;

    if (purchases && purchases.length > 0) {
      return (
        <Grid>
          <Row>
            {purchases.map((purchase, index) => {
              if (
                !purchase.course ||
                purchase.course.id === 4 ||
                purchase.course.draft === true
              ) {
                return null;
              }

              const url = this.props.context.generateCourseUrl(
                purchase.course.identifier
              );

              return (
                <Cell sm={24} md={8} key={index}>
                  <a
                    href={url}
                    className="dashboard-item dashboard-item-course"
                  >
                    <Grid type="full">
                      <Row>
                        <Cell sm={8}>
                          <img src={purchase.course.image_purchased} alt="" />
                        </Cell>
                        <Cell sm={16}>
                          <div className="dashboard-item-course-text">
                            {purchase.course.name}
                          </div>
                        </Cell>
                      </Row>
                    </Grid>
                  </a>
                </Cell>
              );
            })}
          </Row>
        </Grid>
      );
    } else {
      return null;
    }
  }

  view__showSocialContent() {
    try {
      const {
        socialContentIsLoading,
        selfmade_podcast_url,
        selfmade_podcast_image,
        selfmade_podcast_last_episode_title,
        tp_yt_last_video_url,
        tp_yt_last_video_image,
        tp_yt_last_video_title,
      } = this.state;

      if (socialContentIsLoading === true) {
        return (
          <div
            className="text-center"
            style={{ padding: "4rem", fontSize: "2rem" }}
          >
            <Icon icon="spin fa-circle-o-notch" />
          </div>
        );
      }

      const { nextLiveEvent } = this.props.context.user;

      const youtubeButton = (
        <a
          href={tp_yt_last_video_url}
          target="_blank"
          rel="noopener noreferrer"
          className="primary hollow button"
          onClick={(e) => {
            e.preventDefault();
            this.handle__clickSocialContent("tp_yt", tp_yt_last_video_url);
          }}
        >
          <Icon icon="youtube-play" left /> Jetzt anschauen{" "}
          <Icon icon="angle-right" right />
        </a>
      );

      const podcastButton = (
        <a
          href={selfmade_podcast_url}
          target="_blank"
          rel="noopener noreferrer"
          className="primary hollow button"
          onClick={(e) => {
            e.preventDefault();
            this.handle__clickSocialContent(
              "selfmade_podcast",
              selfmade_podcast_url
            );
          }}
        >
          <Icon icon="podcast" left /> Jetzt anhören{" "}
          <Icon icon="angle-right" right />
        </a>
      );

      let liveButton = "";
      let livebuttonUrl = this.view__getLiveUrl();
      if (livebuttonUrl.external === true) {
        liveButton = (
          <a
            href={livebuttonUrl.url}
            target="_blank"
            rel="noopener noreferrer"
            className="primary hollow button"
          >
            <Icon icon="play-circle" left /> SELFMADE freischalten{" "}
            <Icon icon="angle-right" right />
          </a>
        );
      } else {
        liveButton = (
          <a href={livebuttonUrl.url} className="primary hollow button">
            <Icon icon="play-circle" left /> Zum Live-Room{" "}
            <Icon icon="angle-right" right />
          </a>
        );
      }

      let outputNextLiveEvent = "";

      if (nextLiveEvent && nextLiveEvent.status === "active") {
        outputNextLiveEvent = (
          <React.Fragment>
            <h2>Nächster SELFMADE Live Call</h2>
            <a
              href="/live"
              className="dashboard-item"
              style={{ backgroundImage: `url("${nextLiveEvent.image}")` }}
            >
              <img src={selfmade_podcast_image} alt="" />
            </a>
            <div className="dashboard-item-title">
              <Grid type="full">
                <Row>
                  <Cell sm={4} md={4}>
                    <div className="dashboard-item-title-icon">
                      <Icon icon="play-circle" />
                    </div>
                  </Cell>
                  <Cell sm={20} md={20}>
                    <a href="/live" className="dashboard-item-title-text">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            nextLiveEvent.name ||
                            "Titel konnte nicht geladen werden...",
                        }}
                      />
                    </a>
                    <div className="dashboard-item-livecall-time">
                      <span>
                        am <strong>{nextLiveEvent.live_date}</strong> um{" "}
                        <strong>{nextLiveEvent.live_time} Uhr</strong>
                      </span>
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </div>
            <div className="text-center show-for-small-only">{liveButton}</div>
            <hr className="dashboard-social-content-separator" />
          </React.Fragment>
        );
      } else {
        outputNextLiveEvent = (
          <React.Fragment>
            <h2>Nächster SELFMADE Live Call</h2>
            <div
              className="dashboard-item"
              style={{
                backgroundImage:
                  "url('/images/selfmade-livecall-placeholder.jpg')",
              }}
            >
              <img src={selfmade_podcast_image} alt="" />
            </div>
            <div className="dashboard-item-title">
              <p className="text-center">
                Das Datum für den nächsten SELFMADE Live Call steht noch nicht
                fest.
              </p>
            </div>
          </React.Fragment>
        );
      }

      const youtubeIsNew = this.view__showIsNew("tp_yt");
      const podcastIsNew = this.view__showIsNew("selfmade_podcast");

      return (
        <div className="dashboard-social-content">
          <Grid>
            <Row>
              <Cell sm={24} md={8} mdo={2}>
                <h2>Aktuelles YouTube-Video</h2>
                <a
                  href={tp_yt_last_video_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dashboard-item"
                  style={{
                    backgroundImage: `url("${tp_yt_last_video_image}")`,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handle__clickSocialContent(
                      "tp_yt",
                      tp_yt_last_video_url
                    );
                  }}
                >
                  <img src={selfmade_podcast_image} alt="[SELFMADE Podcast]" />
                </a>
                <div className="dashboard-item-title">
                  <Grid type="full">
                    <Row>
                      <Cell sm={4} md={4}>
                        <div className="dashboard-item-title-icon">
                          <Icon icon="youtube-play" />
                        </div>
                      </Cell>
                      <Cell sm={20} md={20}>
                        <a
                          href={tp_yt_last_video_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dashboard-item-title-text"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handle__clickSocialContent(
                              "tp_yt",
                              tp_yt_last_video_url
                            );
                          }}
                        >
                          {youtubeIsNew}{" "}
                          {tp_yt_last_video_title ||
                            "Titel konnte nicht geladen werden..."}
                        </a>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
                <div className="text-center show-for-small-only">
                  {youtubeButton}
                </div>
                <hr className="dashboard-social-content-separator" />
              </Cell>
              <Cell sm={24} md={8}>
                <h2>Aktuelle SELFMADE Podcast-Folge</h2>
                <a
                  href={selfmade_podcast_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dashboard-item"
                  style={{
                    backgroundImage: `url("${selfmade_podcast_image}")`,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handle__clickSocialContent(
                      "selfmade_podcast",
                      selfmade_podcast_url
                    );
                  }}
                >
                  <img src={selfmade_podcast_image} alt="[SELFMADE Podcast]" />
                </a>
                <div className="dashboard-item-title">
                  <Grid type="full">
                    <Row>
                      <Cell sm={4} md={4}>
                        <div className="dashboard-item-title-icon">
                          <Icon icon="podcast" />
                        </div>
                      </Cell>
                      <Cell sm={20} md={20}>
                        <a
                          href={selfmade_podcast_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dashboard-item-title-text"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handle__clickSocialContent(
                              "selfmade_podcast",
                              selfmade_podcast_url
                            );
                          }}
                        >
                          {podcastIsNew}{" "}
                          {selfmade_podcast_last_episode_title ||
                            "Titel konnte nicht geladen werden..."}
                        </a>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
                <div className="text-center show-for-small-only">
                  {podcastButton}
                </div>
              </Cell>
            </Row>
          </Grid>

          <div className="hide-for-small-only">
            <Grid>
              <Row>
                <Cell sm={24} md={8} mdo={2} classes="text-center">
                  {youtubeButton}
                </Cell>
                <Cell sm={24} md={8} classes="text-center">
                  {podcastButton}
                </Cell>
              </Row>
            </Grid>
          </div>
        </div>
      );
    } catch {
      return <p>Error beim Laden des Social Contents.</p>;
    }
  }

  view__showIsNew(type) {
    try {
      const isNew = this.state[type + "_is_new"];
      if (isNew === true) {
        return <span className="primary label new-label">NEW</span>;
      } else {
        return null;
      }
    } catch {}
  }

  view__getLiveUrl() {
    let external = false;
    let selfmadeStatus = {
      active: false,
      url: null,
    };

    if (this.props.context.user && this.props.context.user.selfmadeStatus) {
      selfmadeStatus = this.props.context.user.selfmadeStatus;
    }

    if (selfmadeStatus.active === false) {
      external = true;
    } else {
      selfmadeStatus.url = "/live";
    }

    return {
      url: selfmadeStatus.url,
      external: external,
    };
  }

  show__selfmadeCourseLink() {
    let selfmadeStatus = {
      active: false,
      url: null,
    };

    if (this.props.context.user && this.props.context.user.selfmadeStatus) {
      selfmadeStatus = this.props.context.user.selfmadeStatus;
    }

    const { user } = this.props.context;
    if (!user || !user.purchases) {
      return null;
    }

    let hasBB = false;
    let hasBBLight = false;
    let hasBBSpecial = false;
    Object.keys(user.purchases).map((purchaseCourseId) => {
      if (parseInt(purchaseCourseId) === 8) {
        hasBB = true;
      }
      if (parseInt(purchaseCourseId) === 9) {
        hasBBLight = true;
      }
      if (parseInt(purchaseCourseId) === 10) {
        hasBBSpecial = true;
      }
    });

    if (
      (hasBB === false && hasBBLight === false && hasBBSpecial === false) ||
      selfmadeStatus.active === true
    ) {
      return (
        <a href={selfmadeStatus.url} className="dashboard-item">
          <img src="/images/dashboard/selfmade.jpg" alt="[Selfmade Kurs]" />
        </a>
      );
    } else {
      // Show Banner for Brand Building Workshop if SELFMADE is not purchased

      if (hasBB === true) {
        return (
          <a
            href={`/kurse/335652`}
            rel="noopener noreferrer"
            className="dashboard-item"
          >
            <img
              src="/images/dashboard-brandbuilding.jpg"
              alt="[Brand Building Kurs]"
            />
          </a>
        );
      } else if (hasBBLight === true) {
        return (
          <a
            href={`/kurse/470929`}
            rel="noopener noreferrer"
            className="dashboard-item"
          >
            <img
              src="/images/dashboard-brandbuilding.jpg"
              alt="[Brand Building Light]"
            />
          </a>
        );
      } else if (hasBBSpecial === true) {
        return (
          <a
            href={`/kurse/736451`}
            rel="noopener noreferrer"
            className="dashboard-item"
          >
            <img
              src="/images/dashboard-brandbuilding.jpg"
              alt="[Brand Building Kurs Special Edition]"
            />
          </a>
        );
      }

      /*return (
        <a
          href={selfmadeStatus.url}
          target="_blank"
          rel="noopener noreferrer"
          className="dashboard-item"
        >
          <img src="/images/dashboard-selfmade.jpg" alt="[Selfmade Kurs]" />
        </a>
      );*/
    }
  }

  handle__clickSocialContent(type, url) {
    window.open(url);

    try {
      axios
        .post(
          this.props.context.apiEndpoints.dashboardClick,
          {
            type: type,
          },
          {
            headers: this.props.context.headers,
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.log("Error", error);
        });
    } catch {}
  }

  /**
   * Init
   * Load Data for Podcast, Youtube etc
   */
  init__load() {
    axios
      .get(this.props.context.apiEndpoints.dashboard, {
        headers: this.props.context.headers,
      })
      .then((response) => {
        try {
          const {
            selfmade_podcast_url,
            selfmade_podcast_image,
            selfmade_podcast_last_episode_title,
            tp_yt_last_video_image,
            tp_yt_last_video_url,
            tp_yt_last_video_title,
            selfmade_podcast,
            tp_yt,
            announcements,
            purchasedCourses,
          } = response.data;

          this.setState({
            selfmade_podcast_url,
            selfmade_podcast_image,
            selfmade_podcast_last_episode_title,
            tp_yt_last_video_image,
            tp_yt_last_video_url,
            tp_yt_last_video_title,
            selfmade_podcast_is_new: selfmade_podcast,
            tp_yt_is_new: tp_yt,
            socialContentIsLoading: false,
            announcements,
            purchasedCourses,
          });
        } catch {}
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
}

export default withContext(Dashboard);
