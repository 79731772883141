import React, { Component } from "react";
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import axios from "axios";
import PTPopup from "../../ptPopup/ptPopup";
import withContext from "../../../context/contextHOC";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);

class RevenueGoals extends Component {
  state = {
    monthlyRevenueGoal: {},
    weeklyRevenueGoal: {},
    viewMode: "month",
    showPopup: false,
    editMode: false,
    originalNewIncome: {
      id: "new",
      value: 0.0,
      valuta: new Date(),
      name: ""
    },
    newIncome: {},
    editMonthlyRevenueGoalValue: 0,
    editWeeklyRevenueGoalValue: 0
  };

  componentDidMount() {
    const newIncome = JSON.parse(JSON.stringify(this.state.originalNewIncome));
    newIncome.valuta = new Date();
    this.setState({
      newIncome
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.monthlyRevenueGoal !== this.props.monthlyRevenueGoal) {
      this.setState({
        monthlyRevenueGoal: this.props.monthlyRevenueGoal,
        editMonthlyRevenueGoalValue: this.props.monthlyRevenueGoal.goal_plain
      });
    }

    if (prevProps.weeklyRevenueGoal !== this.props.weeklyRevenueGoal) {
      this.setState({
        weeklyRevenueGoal: this.props.weeklyRevenueGoal,
        editWeeklyRevenueGoalValue: this.props.weeklyRevenueGoal.goal_plain
      });
    }
  }

  render() {
    return (
      <div
        id="selfmadeGridRevenueGoal"
        className="selfmade-grid-item selfmade-grid-item-small selfmade-grid-bg-gray"
      >
        {this.show__content()}
      </div>
    );
  }

  show__content() {
    const { viewMode } = this.state;

    return (
      <React.Fragment>
        {this.show__titleBar(viewMode)}

        <div className="selfmade-grid-item-content-revenuegoals">
          <Grid type="full">
            <Row>
              <Cell sm={12} smo={3} md={12} mdo={3}>
                {this.show__progressCircular(viewMode)}
              </Cell>
            </Row>
            <Row>
              <Cell sm={24} md={24} classes="text-center">
                {this.show__progress(viewMode)}
              </Cell>
            </Row>
          </Grid>
        </div>
        <div className="selfmade-popup">
          <PTPopup
            show={this.state.showPopup}
            size="medium"
            handleClose={() => {
              this.hide__popupEdit();
            }}
          >
            {this.show__popupIncome()}
          </PTPopup>
        </div>
      </React.Fragment>
    );
  }

  show__popupIncome() {
    const {
      viewMode,
      editMode,
      newIncome,
      monthlyRevenueGoal,
      weeklyRevenueGoal,
      editMonthlyRevenueGoalValue,
      editWeeklyRevenueGoalValue
    } = this.state;

    if (editMode === "income") {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <h2>Einnahme hinzufügen</h2>

              <Grid type="full">
                <Row>
                  <Cell sm={24}>
                    <label>
                      Beschreibung:
                      <input
                        type="text"
                        value={newIncome.name || ""}
                        onChange={event => {
                          this.handle__incomeEditChange(event, "name");
                        }}
                      />
                    </label>
                  </Cell>
                </Row>
                <Row>
                  <Cell sm={24} md={12}>
                    <label>
                      Wertstellung:
                      <br />
                      <DatePicker
                        selected={newIncome.valuta}
                        onChange={this.handle__incomeEditChangeValuta}
                        dateFormat="dd.MM.yyyy"
                        locale="de"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </label>
                  </Cell>
                  <Cell sm={24} md={12}>
                    <label>
                      Umsatz (&euro;):
                      <input
                        type="text"
                        value={newIncome.value || ""}
                        onChange={event => {
                          this.handle__incomeEditChange(event, "value");
                        }}
                      />
                    </label>
                  </Cell>
                </Row>
              </Grid>
              <div className="ptpopup-buttons">
                <button
                  className="primary hollow button"
                  onClick={this.handle__cancel}
                >
                  <Icon icon="times" left /> Abbrechen
                </button>
                <button
                  className="primary button"
                  onClick={this.handle__saveIncome}
                >
                  <Icon icon="check" left /> Speichern
                </button>
              </div>
            </Cell>
          </Row>
        </Grid>
      );
    } else if (editMode === "revenue") {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <h2>Umsatzziele bearbeiten</h2>

              <Grid type="full">
                <Row>
                  <Cell sm={24} md={12}>
                    <div style={{ paddingRight: "10px" }}>
                      <label>
                        Monatsziel{" "}
                        {this.props.context.months[monthlyRevenueGoal.month]}{" "}
                        {monthlyRevenueGoal.year} (&euro;):
                        <input
                          type="text"
                          value={editMonthlyRevenueGoalValue || ""}
                          onChange={event => {
                            this.handle__revenueGoalEditChange(event, "month");
                          }}
                        />
                      </label>
                    </div>
                  </Cell>
                  <Cell sm={24} md={12}>
                    <div style={{ paddingLeft: "10px" }}>
                      <label>
                        Wochenziel Kalenderwoche{" "}
                        {this.props.context.months[weeklyRevenueGoal.week]}{" "}
                        {weeklyRevenueGoal.year} (&euro;):
                        <input
                          type="text"
                          value={editWeeklyRevenueGoalValue || ""}
                          onChange={event => {
                            this.handle__revenueGoalEditChange(event, "week");
                          }}
                        />
                      </label>
                    </div>
                  </Cell>
                </Row>
              </Grid>
              <div className="ptpopup-buttons">
                <button
                  className="primary hollow button"
                  onClick={this.handle__cancel}
                >
                  <Icon icon="times" left /> Abbrechen
                </button>
                <button
                  className="primary button"
                  onClick={this.handle__saveRevenueGoals}
                >
                  <Icon icon="check" left /> Speichern
                </button>
              </div>
            </Cell>
          </Row>
        </Grid>
      );
    } else {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              {this.show__popupTitleBar(viewMode)}

              <Grid type="full">
                <Row>
                  <Cell sm={24} md={10}>
                    <div className="selfmade-grid-revenue-popup-progress">
                      <Grid type="full">
                        <Row>
                          <Cell sm={24}>
                            {this.show__progressCircular(viewMode)}
                          </Cell>
                        </Row>
                        <Row>
                          <Cell sm={24} classes="text-center">
                            {this.show__progress(this.state.viewMode)}
                          </Cell>
                        </Row>
                      </Grid>
                      <div>&nbsp;</div>
                      <Grid type="full">
                        <Row>
                          <Cell sm={24}>
                            <div className="text-center">
                              <button
                                className="small primary button"
                                onClick={() => {
                                  this.show__editModeIncome();
                                }}
                              >
                                <Icon icon="plus" left /> Neue Einnahme
                              </button>
                              <button
                                className="small primary button"
                                onClick={() => {
                                  this.show__editModeRevenue();
                                }}
                              >
                                <Icon icon="cog" left /> Umsatzziele
                              </button>
                            </div>
                          </Cell>
                        </Row>
                      </Grid>
                    </div>
                  </Cell>
                  <Cell sm={24} md={14}>
                    {this.show__popupIncomeTitlebar(viewMode)}

                    {this.show__popupIncomes(viewMode)}
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      );
    }
  }

  show__popupIncomeTitlebar(type) {
    const { monthlyRevenueGoal, weeklyRevenueGoal } = this.state;

    if (type === "month") {
      return (
        <h3>
          Einnahmen im {this.props.context.months[monthlyRevenueGoal.month]}{" "}
          {monthlyRevenueGoal.year}
        </h3>
      );
    } else if (type === "week") {
      return (
        <h3>
          Einnahmen in Woche {weeklyRevenueGoal.week} / {weeklyRevenueGoal.year}
        </h3>
      );
    }
  }

  show__popupIncomes(type) {
    const { viewMode, monthlyRevenueGoal, weeklyRevenueGoal } = this.state;

    let revenueGoal = {};
    if (viewMode === "month") {
      revenueGoal = monthlyRevenueGoal;
    } else if (viewMode === "week") {
      revenueGoal = weeklyRevenueGoal;
    }

    if (revenueGoal && revenueGoal.incomes && revenueGoal.incomes.length > 0) {
      return (
        <ul className="selfmade-revenue-incomes">
          {revenueGoal.incomes.map((income, index) => {
            return (
              <li key={index}>
                <Grid type="full">
                  <Row>
                    <Cell sm={24} md={24}>
                      {income.income_date}
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={24} md={16}>
                      {income.name || "Einnahme"}
                    </Cell>
                    <Cell sm={20} md={6} classes="text-right">
                      <span className="selfmade-revenue-incomes-value">
                        {income.value_f}&nbsp;&euro;
                      </span>
                    </Cell>
                    <Cell sm={4} md={2} classes="text-center">
                      <button
                        title="Einnahme löschen"
                        className="selfmade-revenue-delete-icon"
                        onClick={() => {
                          this.handle__incomeDelete(income);
                        }}
                      >
                        <Icon icon="trash" />
                      </button>
                    </Cell>
                  </Row>
                </Grid>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <p className="selfmade-revenue-incomes text-center">
          <em>Keine Einnahmen.</em>
        </p>
      );
    }
  }

  /**
   * Show Titlebar for monthly and weekly revenue goals
   */
  show__titleBar(type) {
    let title = "";
    if (type === "month") {
      title = "Monatsziel<br />Umsatz";
    } else if (type === "week") {
      title = "Wochenziel<br />Umsatz";
    }

    let leftDisabled = false;
    let rightDisabled = false;

    if (type === "month") {
      leftDisabled = true;
    } else if (type === "week") {
      rightDisabled = true;
    }

    return (
      <div className="selfmade-titlebar">
        <Grid type="full">
          <Row>
            <Cell sm={16} md={16} classes="text-left">
              <h3
                className="grid-caption"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Cell>
            <Cell sm={8} md={8} classes="text-right">
              <button
                className="selfmade-todo-new-task"
                title="Umsatzziele bearbeiten und Einnahmen verwalten"
                onClick={this.show__popupEdit}
              >
                <Icon icon="cog" />
              </button>
              <button
                className="selfmade-todo-view-switch"
                onClick={this.handle__switchView}
                disabled={leftDisabled}
              >
                <Icon icon="angle-left" left />
              </button>
              <button
                className="selfmade-todo-view-switch"
                onClick={this.handle__switchView}
                disabled={rightDisabled}
              >
                <Icon icon="angle-right" right />
              </button>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  show__progressCircular(type) {
    const { monthlyRevenueGoal, weeklyRevenueGoal } = this.state;

    let revenueGoal = {};
    if (type === "month") {
      revenueGoal = monthlyRevenueGoal;
    } else if (type === "week") {
      revenueGoal = weeklyRevenueGoal;
    }

    let color = "#b00606";

    if (revenueGoal.percentage >= 100) {
      color = "green";
    } else if (revenueGoal.percentage >= 50) {
      color = "orange";
    }

    let text = revenueGoal.percentage + " %";

    return (
      <CircularProgressbar
        percentage={revenueGoal.percentage}
        text={text}
        strokeWidth={10}
        styles={{
          path: { stroke: color },
          text: { fill: color, fontSize: "20px", fontFamily: "Oswald" }
        }}
      />
    );
  }

  show__progress(type) {
    const { monthlyRevenueGoal, weeklyRevenueGoal } = this.state;

    let revenueGoal = {};
    if (type === "month") {
      revenueGoal = monthlyRevenueGoal;
    } else if (type === "week") {
      revenueGoal = weeklyRevenueGoal;
    }

    return (
      <div className="selfmade-grid-revenue-text">
        {revenueGoal.income} / {revenueGoal.goal} &euro;
      </div>
    );
  }

  /**
   * Show Titlebar for monthly and weekly revenue goals in Popup
   */
  show__popupTitleBar(type) {
    let title = "";
    if (type === "month") {
      title = "Umsatz: Monatsziel";
    } else if (type === "week") {
      title = "Umsatz: Wochenziel";
    }

    let leftDisabled = false;
    let rightDisabled = false;

    if (type === "month") {
      leftDisabled = true;
    } else if (type === "week") {
      rightDisabled = true;
    }

    return (
      <Grid type="full">
        <Row>
          <Cell sm={16} md={16} classes="text-left">
            <h2 className="grid-caption">
              {title}
              <div className="selfmade-revenue-popup-icons">
                <button
                  className="selfmade-todo-view-switch"
                  onClick={this.handle__switchView}
                  disabled={leftDisabled}
                >
                  <Icon icon="angle-left" left />
                </button>
                <button
                  className="selfmade-todo-view-switch"
                  onClick={this.handle__switchView}
                  disabled={rightDisabled}
                >
                  <Icon icon="angle-right" right />
                </button>
              </div>
            </h2>
          </Cell>
          <Cell sm={8} md={8} classes="text-right" />
        </Row>
      </Grid>
    );
  }

  show__popupEdit = () => {
    this.setState({ showPopup: true });
  };

  hide__popupEdit = () => {
    this.setState({ showPopup: false });
  };

  show__editModeIncome = () => {
    this.setState({ editMode: "income" });
  };

  show__editModeRevenue = () => {
    this.setState({ editMode: "revenue" });
  };

  hide__editMode = () => {
    this.setState({ editMode: false });
  };

  /**
   * Handle Click on top right arrow buttons in Title Bar
   */
  handle__switchView = () => {
    let { viewMode } = this.state;

    if (viewMode === "month") {
      viewMode = "week";
    } else if (viewMode === "week") {
      viewMode = "month";
    }

    this.setState({ viewMode });
  };

  /**
   * Handle Changes in Inputs and Textareas
   */
  handle__incomeEditChange(event, property) {
    const { newIncome } = this.state;

    newIncome[property] = event.target.value;

    this.setState({ newIncome });
  }

  /**
   * Handle Valuta Changes
   */
  handle__incomeEditChangeValuta = date => {
    const { newIncome } = this.state;
    newIncome.valuta = date;
    this.setState({
      newIncome
    });
  };

  /**
   * Handle Changes in Revenue Goals Inputs
   */
  handle__revenueGoalEditChange(event, type) {
    console.log(type);
    if (type === "month") {
      this.setState({ editMonthlyRevenueGoalValue: event.target.value });
    } else if (type === "week") {
      this.setState({ editWeeklyRevenueGoalValue: event.target.value });
    }
  }

  /**
   * Hide Popup
   */
  handle__cancel = () => {
    this.reset__newIncome();
    this.reset__newRevenueGoals();
    this.hide__editMode();
  };

  reset__newIncome = () => {
    const newIncome = JSON.parse(JSON.stringify(this.state.originalNewIncome));
    newIncome.valuta = new Date();
    this.setState({
      newIncome
    });
  };

  reset__newRevenueGoals = () => {
    const { monthlyRevenueGoal, weeklyRevenueGoal } = this.state;

    this.setState({
      editMonthlyRevenueGoalValue: monthlyRevenueGoal.goal,
      editWeeklyRevenueGoalValue: weeklyRevenueGoal.goal
    });
  };

  handle__saveIncome = () => {
    const { newIncome } = this.state;

    if (newIncome.valuta) {
      const incomeValuta =
        ("0" + newIncome.valuta.getDate()).slice(-2) +
        "." +
        ("0" + (newIncome.valuta.getMonth() + 1)).slice(-2) +
        "." +
        newIncome.valuta.getFullYear();

      newIncome.valuta = incomeValuta;
    }

    axios
      .post(
        this.props.context.apiEndpoints.selfmadeRevenueGoalsSaveIncome,
        {
          income: newIncome
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        this.reset__newIncome();

        const { monthlyRevenueGoal, weeklyRevenueGoal } = response.data;
        this.setState({ monthlyRevenueGoal, weeklyRevenueGoal });

        this.hide__editMode();
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  };

  handle__saveRevenueGoals = () => {
    const {
      monthlyRevenueGoal,
      weeklyRevenueGoal,
      editMonthlyRevenueGoalValue,
      editWeeklyRevenueGoalValue
    } = this.state;

    axios
      .post(
        this.props.context.apiEndpoints.selfmadeRevenueGoalsUpdate,
        {
          month_id: monthlyRevenueGoal.goalObject.id,
          month_goal: editMonthlyRevenueGoalValue,
          week_id: weeklyRevenueGoal.goalObject.id,
          week_goal: editWeeklyRevenueGoalValue
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        console.log(response.data);

        this.reset__newIncome();
        this.reset__newRevenueGoals();

        const { monthlyRevenueGoal, weeklyRevenueGoal } = response.data;
        this.setState({
          monthlyRevenueGoal,
          weeklyRevenueGoal,
          editMonthlyRevenueGoalValue: monthlyRevenueGoal.goal_plain,
          editWeeklyRevenueGoalValue: weeklyRevenueGoal.goal_plain
        });

        this.hide__editMode();
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  };

  handle__incomeDelete = income => {
    if (
      window.confirm(
        "Möchtest Du diese Einnahme wirklich unwiderruflich löschen?"
      )
    ) {
      axios
        .delete(
          this.props.context.apiEndpoints.selfmadeRevenueGoalsDeleteIncome +
            "/" +
            income.id,
          { headers: this.props.context.headers }
        )
        .then(response => {
          const { monthlyRevenueGoal, weeklyRevenueGoal } = response.data;
          this.setState({ monthlyRevenueGoal, weeklyRevenueGoal });
        })
        .catch(error => {
          console.log("ERROR", error);
        });
    } else {
      // Do nothing
    }
  };
}

export default withContext(RevenueGoals);
