import React, { Component } from "react";
import { Icon } from "../../foundation/foundation.jsx";

class LinkBox extends Component {
  state = {
    id: this.props.id,
    backgroundImageUrl: this.props.backgroundImageUrl,
    backgroundColor: this.props.backgroundColor,
    icon: this.props.icon,
    caption: this.props.caption,
    link: this.props.link,
    classes: this.props.classes
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.backgroundImageUrl !== this.props.backgroundImageUrl) {
      this.setState({ backgroundImageUrl: this.props.backgroundImageUrl });
    }

    if (prevProps.link !== this.props.link) {
      this.setState({ link: this.props.link });
    }
  }

  render() {
    return <React.Fragment>{this.show__box()}</React.Fragment>;
  }

  show__box() {
    if (this.state.link) {
      return (
        <a
          href={this.state.link}
          id={this.props.id}
          className="selfmade-grid-bg linkbox"
          style={this.get__background()}
        >
          {this.show__boxContent()}
        </a>
      );
    } else {
      return (
        <div
          id={this.props.id}
          className="selfmade-grid-bg linkbox"
          style={this.get__background()}
        >
          {this.show__boxContent()}
        </div>
      );
    }
  }

  show__boxContent() {
    return (
      <div className={this.get__mainClasses()}>
        <div className="selfmade-grid-item-content">
          <div className="selfmade-grid-icon">
            <Icon icon={this.state.icon} />
          </div>
          <div
            className="selfmade-grid-caption grid-caption"
            dangerouslySetInnerHTML={{ __html: this.state.caption }}
          />
        </div>
      </div>
    );
  }

  get__mainClasses() {
    let classes =
      "selfmade-grid-linkbox selfmade-grid-item selfmade-grid-item-small";
    if (this.state.classes) {
      classes += " " + this.state.classes;
    }
    return classes;
  }

  get__background() {
    let styleObject = {};
    if (this.state.backgroundImageUrl) {
      styleObject.backgroundImage =
        "url('" + this.state.backgroundImageUrl + "')";
    }
    if (this.state.backgroundColor) {
      styleObject.backgroundColor = this.state.backgroundColor;
    }
    return styleObject;
  }
}

export default LinkBox;
