import React, { Component } from "react";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell } from "../../foundation/_grid";
import { Icon } from "../../foundation/_buttons";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import qs from "query-string";
import { isUndefined } from "util";
import Avatar from "../../user/_avatar";

class AdminUsersOverview extends Component {
  state = {
    users: [],
    search: "",
    totalUsers: null,
    count: parseInt(this.props.match.params.count) || 50,
    page: parseInt(this.props.match.params.page) || 1,
    counterStart: 1
  };

  componentDidMount() {
    //document.title = this.props.context.pageTitle("Benutzerübersicht");

    const queryParams = qs.parse(this.props.location.search);
    if (!isUndefined(queryParams.search)) {
      this.setState({ search: queryParams.search }, () => {
        this.handle__loadUsers();
      });
    } else {
      this.setState({ search: "" }, () => {
        this.handle__loadUsers();
      });
    }

    //this.handle__loadUsers();
  }

  render() {
    return (
      <AdminLayout active="users">
        <Grid>
          <Row>
            <Cell sm={24}>
              <h1>Mitgliederübersicht</h1>

              <div className="small admin-box" id="searchBox">
                <div className="admin-box-content">
                  <div className="search-box">
                    <div className="search-box-icon">
                      <Icon icon="search" />
                    </div>
                    <div className="search-box-button">
                      <button
                        className="small primary button"
                        onClick={this.handle__search}
                      >
                        Suchen
                      </button>
                    </div>
                    <div className="search-box-search">
                      <input
                        type="text"
                        placeholder="Suchen"
                        value={this.state.search}
                        onChange={event => {
                          this.handle__editChange(event);
                        }}
                        onKeyPress={event => {
                          if (event.key === "Enter") {
                            this.handle__search();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {this.view__showTotalUsers()}

              <Grid type="full">
                <Row>
                  <Cell sm={24} md={20}>
                    {this.view__showPages()}
                  </Cell>
                  <Cell sm={24} md={4} classes="text-center medium-text-right">
                    <a href="/admin/users/new" className="primary button">
                      <Icon icon="plus" left /> Neues Mitglied
                    </a>
                  </Cell>
                </Row>
              </Grid>

              <div className="small admin-box">
                <div className="admin-box-content">
                  <ul className="admin-list">
                    <li className="admin-list-item admin-list-title">
                      <Grid type="full">
                        <Row>
                          <Cell sm={2} md={1} classes="text-center">
                            ID
                          </Cell>
                          <Cell md={1} classes="hide-for-small-only">
                            &nbsp;
                          </Cell>
                          <Cell sm={10} md={4}>
                            Name
                          </Cell>
                          <Cell sm={12} md={5}>
                            E-Mail
                          </Cell>
                          <Cell
                            md={4}
                            classes="hide-for-small-only text-center"
                          >
                            erstellt
                          </Cell>
                          <Cell
                            md={2}
                            classes="text-center hide-for-small-only"
                          >
                            <Icon icon="unlock" />
                          </Cell>
                          <Cell md={7} classes="hide-for-small-only">
                            Käufe
                          </Cell>
                        </Row>
                      </Grid>
                    </li>
                  </ul>
                  <ul className="striped admin-list">
                    {this.view__showUsersList()}
                  </ul>
                </div>
              </div>
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={20}>
                    {this.view__showPages()}
                  </Cell>
                  <Cell sm={24} md={4} classes="text-right">
                    <a href="/admin/users/new" className="primary button">
                      <Icon icon="plus" left /> Neuer Benutzer
                    </a>
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showUsersList() {
    const { users, totalUsers } = this.state;

    if (users.length === 0) {
      if (totalUsers === 0) {
        return (
          <div className="text-center">
            <em>Keine Benutzer gefunden.</em>
          </div>
        );
      } else {
        return (
          <div className="admin-list-loading">
            <Icon icon="spin fa-circle-o-notch" />
          </div>
        );
      }
    } else {
      return users.map((user, index) => {
        //let counter = counterStart + index;
        return (
          <li
            className="admin-list-item clickable"
            key={index}
            onClick={() => {
              this.handle__viewUser(user.id);
            }}
          >
            <Grid type="full">
              <Row>
                <Cell md={1} classes="text-center hide-for-small-only">
                  <div className="center-vertically">{user.id}</div>
                </Cell>
                <Cell sm={4} md={1} classes="text-center">
                  <div className="center-vertically">
                    <Avatar user={user} size="mainTopbar" />
                  </div>
                </Cell>
                <Cell sm={20} md={4}>
                  <a href={this.getUserUrl(user.id)}>
                    {user.lastname || ""}, {user.firstname || ""}
                    <div>
                      <small>{user.email || ""}</small>
                    </div>
                  </a>
                </Cell>
                <Cell md={5} classes="hide-for-small-only">
                  {user.email || ""}
                </Cell>
                <Cell md={4} classes="hide-for-small-only text-center">
                  {user.created_date || ""}
                </Cell>
                <Cell md={2} classes="text-center hide-for-small-only">
                  {this.view__show_is_unlocked(user)}
                </Cell>
                <Cell md={7} classes="hide-for-small-only">
                  <span style={{ fontSize: ".8rem" }}>
                    {this.view__show_purchases(user)}
                  </span>
                </Cell>
              </Row>
            </Grid>
          </li>
        );
      });
    }
  }

  view__show_is_unlocked(user) {
    let unlocked = true;

    if (user) {
      if (user.isPasswordSet === false) {
        unlocked = false;
      }
    }

    if (unlocked === true) {
      return <Icon icon="check" />;
    } else {
      return <Icon icon="minus" />;
    }
  }

  view__show_purchases(user) {
    if (user && user.purchases) {
      if (user.purchases.length > 0) {
        return user.purchases.map((purchase, index) => {
          let string = purchase;
          if (index !== user.purchases.length - 1) {
            string = string + ", ";
          }
          return string;
        });
      }
    }
  }

  view__showTotalUsers() {
    let { totalUsers } = this.state;

    if (totalUsers === null) {
      return null;
    } else {
      return (
        <div className="admin-list-total">
          <strong>{totalUsers}</strong> Benutzer gefunden.
        </div>
      );
    }
  }

  view__showPages() {
    let { totalUsers, count, page } = this.state;

    if (totalUsers === null) {
      return null;
    } else {
      if (!count) {
        count = 50;
      }

      if (!page) {
        page = 1;
      }

      let pages = totalUsers / count;
      if (pages !== Math.floor(pages)) {
        pages = Math.floor(pages) + 1;
      }

      let pagesIndex = [];
      for (let i = 1; i <= pages; i++) {
        pagesIndex.push(i);
      }

      return (
        <React.Fragment>
          <ul className="admin-list-pages show-for-small-only">
            {pagesIndex.map(item => {
              let className = "admin-list-pages-page";
              if (item === page) {
                className += " selected";
              }

              let url = "/admin/users/list/" + count + "/" + item;

              return (
                <li key={item} className={className}>
                  <a href={url}>{item}</a>
                </li>
              );
            })}
          </ul>
          <ul className="admin-list-pages hide-for-small-only">
            {pagesIndex.map(item => {
              let className = "admin-list-pages-page";
              if (item === page) {
                className += " selected";
              }

              let url = "/admin/users/list/" + count + "/" + item;

              return (
                <li key={item} className={className}>
                  <a href={url}>{item}</a>
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      );
    }
  }

  handle__viewUser = userId => {
    window.location = this.getUserUrl(userId);
  };

  getUserUrl(userId) {
    return "/admin/users/" + userId;
  }

  handle__editChange(event) {
    let { search } = this.state;
    search = event.target.value;
    this.setState({ search });
  }

  handle__search = () => {
    const { search } = this.state;
    this.props.history.push("/admin/users?search=" + search);
    this.handle__loadUsers();
  };

  /**
   * Init
   * Load User List
   */
  handle__loadUsers() {
    const { search, count, page } = this.state;

    let url = this.props.context.apiEndpoints.adminUsers;
    if (search) {
      url += "?search=" + search;
    } else {
      url += "?search=";
    }

    if (count) {
      url += "&count=" + count;
    }

    if (page) {
      url += "&page=" + page;
    }

    axios
      .get(url, {
        headers: this.props.context.headers
      })
      .then(response => {
        const { users, totalUsers } = response.data;

        const counterStart = 1 + (page - 1) * count;

        this.setState({ users, totalUsers, counterStart });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(AdminUsersOverview);
