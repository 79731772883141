import React, { Component } from "react";
import axios from "axios";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation.jsx";
import PTPopup from "../../ptPopup/ptPopup";
import withContext from "../../../context/contextHOC.jsx";

class QuestionForTorben extends Component {
  state = {
    backgroundImageUrl: this.props.backgroundImageUrl,
    showPopup: false,
    question: "",
    saved: false,
    selfmadeCoursePurchased: this.props.selfmadeCoursePurchased,
    course: this.props.course,
    type: this.props.type
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.backgroundImageUrl !== this.props.backgroundImageUrl) {
      this.setState({ backgroundImageUrl: this.props.backgroundImageUrl });
    }

    if (
      prevProps.selfmadeCoursePurchased !== this.props.selfmadeCoursePurchased
    ) {
      this.setState({
        selfmadeCoursePurchased: this.props.selfmadeCoursePurchased
      });
    }

    if (prevProps.course !== this.props.course) {
      this.setState({
        course: this.props.course
      });
    }

    if (prevProps.type !== this.props.type) {
      this.setState({
        type: this.props.type
      });
    }
  }

  render() {
    //const { backgroundImageUrl } = this.state;
    return (
      <React.Fragment>
        {this.show__content()}
        {this.show__popup()}
      </React.Fragment>
    );
  }

  show__content() {
    const { type, selfmadeCoursePurchased } = this.state;

    if (type === "workspace") {
      if (selfmadeCoursePurchased === true) {
        return (
          <div
            id="selfmadeGridQuestionForTorben"
            className="selfmade-grid-item selfmade-grid-item-small selfmade-grid-bg-gray"
            onClick={this.show__popupEdit}
          >
            <div className="selfmade-grid-item selfmade-grid-bg-darker">
              <div className="selfmade-grid-item-content">
                <div className="selfmade-grid-icon">
                  <Icon icon="question-circle" />
                </div>
                <div className="selfmade-grid-caption grid-caption">
                  Deine Frage an Torben
                  <br />
                  für den nächsten
                  <br />
                  SELFMADE Live Call
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            id="selfmadeGridQuestionForTorben"
            className="selfmade-grid-item selfmade-grid-item-small selfmade-grid-bg-dark qft-disabled"
          >
            <div className="selfmade-grid-item selfmade-grid-bg-darker">
              <div className="selfmade-grid-item-content">
                <div className="selfmade-grid-icon">
                  <Icon icon="question-circle" />
                </div>
                <div className="selfmade-grid-caption grid-caption">
                  Deine Frage
                  <br />
                  an Torben
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else if (type === "course") {
      return (
        <button className="tile" onClick={this.show__popupEdit}>
          <Grid type="full">
            <Row>
              <Cell sm={4}>
                <div className="big-icon">
                  <Icon icon="commenting-o" />
                </div>
              </Cell>
              <Cell sm={18}>
                Stelle Torben jetzt Deine Frage für den nächsten Live Call
                &raquo;
              </Cell>
            </Row>
          </Grid>
        </button>
      );
    }
  }

  show__popup() {
    const { showPopup } = this.state;

    if (showPopup === true) {
      return (
        <div className="selfmade-popup">
          <PTPopup
            show={this.state.showPopup}
            size="medium"
            handleClose={() => {
              this.handle__cancel();
            }}
          >
            {this.show__popupContent()}
          </PTPopup>
        </div>
      );
    } else {
      return null;
    }
  }

  show__popupContent() {
    const { saved, question } = this.state;

    if (saved === true) {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <h2>Deine Frage an Torben</h2>
              <div>&nbsp;</div>
              <p>
                <strong>
                  Vielen Dank,
                  <br />
                  Deine Fragen wurden gespeichert.
                  <br />
                  Wir freuen uns, wenn Du beim nächsten Live Call dabei bist!
                </strong>
              </p>
              <div>&nbsp;</div>
            </Cell>
          </Row>
        </Grid>
      );
    } else {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <h2>Deine Frage an Torben</h2>

              <p>
                In regelmäßigen Abständen beantwortet Torben in einem Q&amp;A
                Call Fragen aus der Community. Hier kannst Du Deine Fragen an
                Torben stellen.
              </p>

              <p>
                <strong>
                  <u>Hinweis:</u>
                </strong>{" "}
                Um die Livestreams aktuell und interessant zu halten, werden
                Fragen, die sich auf die <strong>aktuellen Videos</strong>{" "}
                beziehen, gegenüber allgemeinen Fragen bevorzugt beantwortet.
              </p>

              <p>
                Bitte beachte, dass Torben nicht jede gestellte Frage im Call
                beantworten kann. Daher gibt es keine Garantie, dass Deine
                Fragen dazu gehört.
              </p>

              <Grid type="full">
                <Row>
                  <Cell sm={24}>
                    <label>
                      Deine Frage(n):
                      <textarea
                        rows={5}
                        value={question || ""}
                        onChange={event => {
                          this.handle__editChange(event);
                        }}
                      />
                    </label>
                    <div className="ptpopup-buttons">
                      <button
                        className="primary hollow button"
                        onClick={this.handle__cancel}
                      >
                        <Icon icon="times" left /> Abbrechen
                      </button>
                      <button
                        className="primary button"
                        onClick={this.handle__save}
                      >
                        <Icon icon="check" left /> Absenden
                      </button>
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      );
    }
  }

  show__popupEdit = () => {
    this.setState({ showPopup: true });
  };

  hide__popupEdit = () => {
    this.setState({ showPopup: false });
  };

  /**
   * Handle Changes in Mission Statement Input
   */
  handle__editChange(event) {
    this.setState({ question: event.target.value });
  }

  /**
   * Hide Popup
   */
  handle__cancel = () => {
    this.setState({ saved: false });
    this.hide__popupEdit();
  };

  handle__save = () => {
    const { question, course } = this.state;

    axios
      .post(
        this.props.context.apiEndpoints.selfmadeQuestionForTorben,
        {
          question: question,
          course: course
        },
        {
          headers: this.props.context.headers
        }
      )
      .then(response => {
        this.setState({ saved: true });
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  };
}

export default withContext(QuestionForTorben);
