import React, { Component } from "react";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import Logo from "../../images/torbenplatzer.png";

class IndexTopBar extends Component {
  state = {};
  render() {
    return (
      <div id="top">
        <div id="topInner">
          <Grid>
            <Row>
              <Cell sm={18} md={12}>
                <a href="/">
                  <img src={Logo} alt="Torben Platzer" />
                </a>
              </Cell>
              <Cell sm={6} md={12} classes="text-right">
                <button onClick={this.props.handleLoginOpen}>
                  <Icon icon="user-circle" left /> Login
                </button>
              </Cell>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default IndexTopBar;
