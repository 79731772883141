import React, { Component } from "react";
import ForumLayout from "./forumLayout";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import { Link } from "react-router-dom";
import Avatar from "../user/_avatar";

class Board extends Component {
  state = {
    board: {
      name: "",
      description: ""
    },
    threads: [],
    boardSlug: this.props.match.params.boardSlug
  };

  componentDidMount() {
    this.loadThreads();
  }

  render() {
    return (
      <ForumLayout
        breadcrumbs={[
          "dashboard",
          "community",
          { id: "board", to: undefined, name: this.state.board.name }
        ]}
      >
        <Grid>
          <Row>
            <Cell sm={24} md={18}>
              <h2>{this.state.board.name}</h2>
              <p className="lead">{this.state.board.description}</p>
            </Cell>
          </Row>
        </Grid>

        <Grid>
          <Row>
            <Cell sm={24}>
              <div className="text-right">
                <Link
                  to={this.props.context.generateForumNewThreadUrl(
                    this.state.boardSlug
                  )}
                  className="primary button"
                >
                  <Icon icon="plus" left /> Neues Thema erstellen
                </Link>
              </div>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24}>
              <div className="list-header">
                <Grid>
                  <Row>
                    <Cell sm={18} md={17}>
                      &nbsp;
                    </Cell>
                    <Cell sm={6} md={3}>
                      <div className="board-counter-name">Antworten</div>
                    </Cell>
                    <Cell classes="hide-for-small-only text-center" md={4}>
                      <div className="board-counter-name">Letzte Antwort</div>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            </Cell>
          </Row>
        </Grid>

        <Grid>
          <Row>
            <Cell sm={24}>{this.view__showThreads()}</Cell>
          </Row>
        </Grid>
      </ForumLayout>
    );
  }

  view__showThreads() {
    const { threads } = this.state;

    if (threads.length === 0) {
      return (
        <p className="text-center">
          <em>Keine Themen vorhanden.</em>
        </p>
      );
    }

    return (
      <div className="forum-threads-list">
        {this.state.threads.map(thread => {
          return this.showThread(thread);
        })}
      </div>
    );
  }

  showThread(thread) {
    let closedIcon = "";
    if (thread.closed === true) {
      closedIcon = (
        <span title="Thema ist geschlossen">
          &nbsp;
          <Icon icon="lock" right />
        </span>
      );
    }

    return (
      <Link
        to={this.props.context.generateForumThreadUrl(
          this.state.board.slug,
          thread.identifier
        )}
        key={thread.id}
      >
        <div className="list-item">
          <Grid type="full">
            <Row>
              <Cell sm={3} md={1}>
                <Avatar user={thread.author} size="forumThread" />
              </Cell>
              <Cell sm={15} md={16}>
                <div className="board-name">
                  {thread.name} {closedIcon}
                </div>
                <div className="board-description">
                  von {thread.author.firstname} {thread.author.lastname}
                </div>
              </Cell>
              <Cell sm={6} md={3}>
                <div className="board-counter-number">{thread.posts - 1}</div>
              </Cell>
              <Cell sm={24} md={4}>
                <div className="board-counter-number">
                  {this.view__getLastPost(thread)}
                </div>
              </Cell>
            </Row>
          </Grid>
        </div>
      </Link>
    );
  }

  view__getLastPost(thread) {
    if (thread.posts > 1 && thread.lastPostAuthor) {
      return (
        <React.Fragment>
          <div className="thread-lastreply hide-for-small-only">
            <div className="date">
              {thread.last_post_date + " - " + thread.last_post_time}
            </div>
            <div className="author">
              von{" "}
              <em>
                {thread.lastPostAuthor.firstname}{" "}
                {thread.lastPostAuthor.lastname}
              </em>
            </div>
          </div>
          <div className="thread-lastreply show-for-small-only">
            <div>
              Letzte Antwort:{" "}
              {thread.last_post_date + " - " + thread.last_post_time} von{" "}
              <em>
                {thread.lastPostAuthor.firstname}{" "}
                {thread.lastPostAuthor.lastname}
              </em>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <div style={{ lineHeight: "330%" }}>---</div>;
    }
  }

  loadThreads() {
    const { boardSlug } = this.state;

    axios
      .get(this.props.context.apiEndpoints.forumThreads + "/" + boardSlug, {
        headers: this.props.context.headers
      })
      .then(response => {
        const board = response.data.board;
        const threads = response.data.threads;

        this.setState({ board, threads });
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(Board);
