import React, { Component } from "react";
import "./ptPopup.css";
//import IconClose from "./close.png";
import closeButton from "../../images/close-narrow-white.png";
import KeyboardEventHandler from "react-keyboard-event-handler";

/**
 * show = true / false
 * type = small / medium / large / fullscreen [DEFAULT: medium]
 * nopadding
 */
class PTPopup extends Component {
  state = {};
  render() {
    return (
      <div style={this.isPopupVisible()}>
        <KeyboardEventHandler
          handleKeys={["all"]}
          onKeyEvent={(key, event) => {
            //console.log(key);
            if (key === "esc") {
              this.closePopup();
            }
          }}
          handleFocusableElements={true}
        />
        <div
          className="ptpopup-modal"
          onClick={() => {
            this.closePopup();
          }}
        />
        <div className={this.getClasses()}>
          {this.showCloseButton()}
          {this.props.children}
        </div>
      </div>
    );
  }

  isPopupVisible() {
    if (this.props.show === true) {
      return {
        display: "block"
      };
    } else {
      return {
        display: "none"
      };
    }
  }

  getClasses() {
    let classes = "ptpopup-popup";

    if (this.props.nopadding) {
      classes += " nopadding";
    }

    if (this.props.size === "small") {
      classes += " ptpopup-small";
    } else if (this.props.size === "large") {
      classes += " ptpopup-large";
    } else if (this.props.size === "fullscreen") {
      classes += " ptpopup-fullscreen";
    } else {
      classes += " ptpopup-medium";
    }

    return classes;
  }

  showCloseButton() {
    return (
      <button
        className="ptpopup-close-button"
        onClick={() => {
          this.closePopup();
        }}
      >
        <img src={closeButton} alt="[Close]" />
      </button>
    );
  }

  closePopup() {
    this.props.handleClose();
  }
}

export default PTPopup;
