import React, { Component } from "react";
import Avatar from "../user/_avatar";
import IconClose from "../ptPopup/close.png";
import { Link } from "react-router-dom";

class UserInfo extends Component {
  state = {
    rect: {},
    user: {},
    showTooltip: false
  };

  constructor(props) {
    super(props);
    this.selector = React.createRef();
  }

  componentDidMount() {
    const rect = this.selector.current.getBoundingClientRect();

    const user = this.props.people[this.props.children.replace("@", "")];

    this.setState({ rect, user });
  }

  render() {
    if (!this.state.user) {
      return null;
    }

    return (
      <React.Fragment>
        <span
          className="post-linked-user"
          ref={this.selector}
          onMouseOver={() => {
            setTimeout(() => {
              this.setState({ showTooltip: true });
            }, 200);
          }}
        >
          {this.props.children}
          {this.tooltip()}
        </span>
      </React.Fragment>
    );
  }

  tooltip() {
    const { user } = this.state;
    return (
      <div className="userinfo-tooltip" style={this.getStyles()}>
        <div className="userinfo-tooltip-close">{this.showCloseButton()}</div>
        <Link
          to={`/community/@${user.username}`}
          className="userinfo-tooltip-avatar"
        >
          <Avatar user={user} size="userinfoTooltip" />
        </Link>
        <div className="userinfo-tooltip-content">
          <div>
            <Link
              to={`/community/@${user.username}`}
              className="userinfo-tooltip-name"
            >
              {user.firstname} {user.lastname}{" "}
            </Link>
          </div>
          <div>
            <Link
              to={`/community/@${user.username}`}
              className="userinfo-tooltip-username"
            >
              @{user.username}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  showCloseButton() {
    return (
      <button
        className="ptpopup-close-button"
        onClick={() => {
          this.setState({ showTooltip: false });
        }}
      >
        <img src={IconClose} alt="[Close]" />
      </button>
    );
  }

  getStyles() {
    const { rect } = this.state;

    const styles = {
      left: rect.left,
      display: "none"
    };

    if (this.state.showTooltip === true) {
      styles.display = "block";
    } else {
      styles.display = "none";
    }

    return styles;
  }
}

export default UserInfo;
