import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Row, Cell } from "../foundation/_grid";
import { Icon } from "../foundation/_buttons";
import { isUndefined } from "util";
import withContext from "../../context/contextHOC";
import axios from "axios";
import ReactTooltip from "react-tooltip";

class Breadcrumbs extends Component {
  state = {
    breadcrumbs: this.props.breadcrumbs,
    favorite: this.props.favorite
  };

  componentDidUpdate(prevProps) {
    if (this.props.breadcrumbs !== prevProps.breadcrumbs) {
      this.setState({ breadcrumbs: this.props.breadcrumbs });
    }
    if (this.props.favorite !== prevProps.favorite) {
      this.setState({ favorite: this.props.favorite });
    }
  }

  render() {
    return <div id="breadcrumbs">{this.showBreadcrumbs()}</div>;
  }

  showBreadcrumbs() {
    const breadcrumbs = this.getBreadcrumbs();
    const { favorite } = this.state;

    let favoriteButton;
    if (favorite) {
      const _c = this.props.context;
      const { user } = _c;

      let favoriteButtonTextAdd = "Zu den Favoriten hinzufügen";
      let favoriteButtonTextRemove = "Aus den Favoriten entfernen";

      if (favorite.text_add) {
        favoriteButtonTextAdd = favorite.text_add;
      }

      if (favorite.text_remove) {
        favoriteButtonTextRemove = favorite.text_remove;
      }

      favoriteButton = (
        <button
          onClick={this.handle__toggleFavorite}
          className="breadcrumbs-favorite"
          data-tip={favoriteButtonTextAdd}
        >
          <Icon icon="star-o" />
        </button>
      );

      if (user && user.favorites) {
        user.favorites.map(userFavorite => {
          if (userFavorite.page === favorite.page) {
            favoriteButton = (
              <button
                onClick={this.handle__toggleFavorite}
                className="breadcrumbs-favorite"
                data-tip={favoriteButtonTextRemove}
              >
                <Icon icon="star" />
              </button>
            );
          }
          return null;
        });
      }
    }

    return (
      <Grid>
        <Row>
          <Cell sm={24} md={20}>
            <ReactTooltip place="top" type="dark" effect="solid" />
            {/*<Icon icon="angle-double-right" breadcrumb />*/}
            {breadcrumbs.map((breadcrumb, index) => {
              let link = "";
              if (isUndefined(breadcrumb.to)) {
                link = <span>{breadcrumb.name}</span>;
              } else {
                link = <Link to={breadcrumb.to}>{breadcrumb.name}</Link>;
              }

              if (index !== 0) {
                return (
                  <React.Fragment key={breadcrumb.id}>
                    <Icon icon="angle-right" breadcrumb />
                    {link}
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={breadcrumb.id}>{link}</React.Fragment>
                );
              }
            })}
          </Cell>
          <Cell sm={24} md={4} classes="text-right">
            {favoriteButton}
          </Cell>
        </Row>
      </Grid>
    );
  }

  getBreadcrumbs() {
    const { breadcrumbs } = this.state;

    let bcs = [];
    if (!isUndefined(breadcrumbs) && breadcrumbs.length > 0) {
      breadcrumbs.map((breadcrumb, index) => {
        let bc = undefined;
        if (breadcrumb === "dashboard") {
          bc = {
            id: "dashboard",
            to: "/dashboard",
            name: <Icon icon="home" />
          };
        } else if (breadcrumb === "courses") {
          bc = {
            id: "courses",
            to: "/kurse",
            name: "Kurse"
          };
        } else if (breadcrumb === "courses-active") {
          bc = {
            id: "courses",
            to: undefined,
            name: "Kurse"
          };
        } else if (breadcrumb === "myprofile") {
          bc = {
            id: "myprofile",
            to: "/profil",
            name: "Mein Profil"
          };
        } else if (breadcrumb === "password") {
          bc = {
            id: "password",
            to: "/password",
            name: "Passwort ändern"
          };
        } else if (breadcrumb === "404") {
          bc = {
            id: "404",
            to: undefined,
            name: "Fehler 404 - Seite nicht gefunden"
          };
        } else if (breadcrumb === "forum") {
          bc = {
            id: "forum",
            to: "/community",
            name: "Community"
          };
        } else if (breadcrumb === "community") {
          bc = {
            id: "community",
            to: "/community",
            name: "Community"
          };
        } else if (breadcrumb === "routines") {
          bc = {
            id: "routines",
            to: "/routinen",
            name: "Meine Routinen"
          };
        } else if (breadcrumb === "todo") {
          bc = {
            id: "todo",
            to: "/todo",
            name: "Aufgaben"
          };
        } else if (breadcrumb === "workspace") {
          bc = {
            id: "workspace",
            to: "/workspace",
            name: "Mein Workspace"
          };
        } else if (breadcrumb === "visionboard") {
          bc = {
            id: "visionboard",
            to: "/workspace/visionboard",
            name: "Visionboard"
          };
        } else if (breadcrumb === "live") {
          bc = {
            id: "live",
            to: "/live",
            name: "Live Call"
          };
        } else if (breadcrumb === "calendar") {
          bc = {
            id: "calendar",
            to: "/calendar",
            name: "Kalender"
          };
        } else if (breadcrumb === "admin-dashboard") {
          bc = {
            id: "adminDashboard",
            to: "/admin",
            name: "Administration"
          };
        } else {
          bc = breadcrumb;
        }

        // Show last entry grey / not clickable
        if (index === breadcrumbs.length - 1) {
          bc.to = undefined;
        }

        bcs.push(bc);
        return null;
      });
    }

    return bcs;
  }

  handle__toggleFavorite = () => {
    const { favorite } = this.state;

    if (favorite) {
      axios
        .post(
          this.props.context.apiEndpoints.toggleFavorite,
          { favorite: favorite },
          {
            headers: this.props.context.headers
          }
        )
        .then(response => {
          const { favorites } = response.data;
          const _c = this.props.context;
          const { user } = _c;
          user.favorites = favorites;
          _c.handleUpdateUser(user);
        })
        .catch(error => {
          console.log("Error", error);
        });
    }
  };
}

export default withContext(Breadcrumbs);
